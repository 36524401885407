import { useState } from 'react';
import { FormikProps } from 'formik';

import { destroy } from '../../api/rutilus';
import IPhoto from '../../models/Photo';

import Image from '../../components/Image/Image';
import UploadFile from '../../components/UploadFile/UploadFile';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';

import styles from '../../components/Edit/Form.module.css';
import { GetPostAvailableActions_post_opImages_edges_node } from '../../interfaces/graphql';
import { StatusControllerComponentGenerator } from './StatusControl2';
import { setImageStatusAction } from '../../mutations/PostImage';
import Post from '../../models/Post';
import Button from '../../components/Clickables/Buttons/Button';

interface Props extends FormikProps<any> {
  header: IFormHeaders;
  entry: Post;
  post: Post;
}

function InnerImage({ photo, post }: { photo: IPhoto; post: Post }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  if (isDeleted) {
    return <></>;
  }

  const removeImage = () => {
    if (confirm(`Are you sure you want to remove this image (#${photo.id})?`)) {
      setIsLoading(true);
      destroy(`/posts/images/${photo.id}`).then((resp) =>
        resp.ok ? setIsDeleted(true) : alert(`Could not remove image. HTTP status ${resp.status}`),
      );
    }
  };

  const PhotoStatusComponent =
    StatusControllerComponentGenerator<GetPostAvailableActions_post_opImages_edges_node>(
      (post) =>
        post?.opImages?.edges
          ?.filter((edge) => edge?.node?.id === photo.id)
          .map((edge) => edge?.node)[0] || null,
      setImageStatusAction,
    );

  return (
    <figure className={styles.media} key={photo.id}>
      <Image img={photo.photo} link="1440" geometry="208x208#" />
      <figcaption>
        <PhotoStatusComponent entry={post} header={{ title: 'Img', attribute: 'image' }} />
        <Button variant="buttonDanger" disabled={isLoading} onClick={removeImage}>
          Remove
        </Button>
      </figcaption>
    </figure>
  );
}

const PostImages = ({ header, entry, ...rest }: Props): JSX.Element => (
  <div className={styles.list_row}>
    <h2>{header.title}</h2>
    <div className={styles.mediaList}>
      {entry.photos &&
        entry.photos.map((photo: IPhoto) => (
          <InnerImage photo={photo} key={photo.id} post={entry} />
        ))}
      {entry.photo && (
        <figure className={styles.media}>
          <Image img={entry.photo} geometry="270x200>" />
          <figcaption>{entry.name ? entry.name : ''}</figcaption>
        </figure>
      )}
    </div>
    <UploadFile type="image" {...rest} />
  </div>
);

export default PostImages;
