import style from './Table.module.css';

interface IProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  [restAttribute: string]: any;
  spacious?: boolean;
}

const Table = ({ children, className, spacious, ...rest }: IProps): JSX.Element => (
  <table {...rest} className={`${style.table} ${spacious && style.spacious} ${className}`}>
    {children}
  </table>
);

export default Table;
