import { Link } from 'react-router-dom';

const AttributeLink = ({
  value,
  link,
  entry,
}: {
  value: any;
  link: ((entry: any) => string) | string;
  entry: any;
}): JSX.Element => {
  if (typeof link === 'function') {
    return <Link to={link(entry)}>{value}</Link>;
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return <Link to={`${link}${value}`}>{value}</Link>;
  }
  if (value) {
    return <Link to={`${link}${value.id}`}>{value.identifier}</Link>;
  }
  return <></>;
};

export default AttributeLink;
