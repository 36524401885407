import GqlBaseModel from './GqlBaseModel';
import ThumborImage, { EMPTY_IMAGE } from './ThumborImage';
import {
  GetPage_entry_ambassadors,
  GetPage_entry_publishers,
  GetPage_entry_admins,
} from '../interfaces/graphql';

export interface IPage {
  readonly id: number;
  readonly externalId: string;
  logo?: ThumborImage;
  coverImages?: any;
  published: boolean;
  readonly name: string;
  ambassadors?: GetPage_entry_ambassadors;
  publishers?: GetPage_entry_publishers;
  admins?: GetPage_entry_admins;
}

class Page extends GqlBaseModel {
  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public static fromAttributes(attributes: IPage): IPage {
    return new Page(attributes);
  }

  public get id(): number {
    return this.attributes.id;
  }

  public get externalId(): string {
    return this.attributes.externalId;
  }

  public get identifier(): string {
    return this.attributes.id.toString();
  }

  public static get modelType(): string {
    return 'Page';
  }

  public get modelType(): string {
    return 'Page';
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get published(): boolean {
    return this.attributes.published;
  }

  public get coverImages(): ThumborImage | undefined {
    const { coverImages } = this.attributes;
    if (coverImages && coverImages.edges[0]) {
      return coverImages.edges[0].node;
    }
    return EMPTY_IMAGE;
  }

  public get logo(): ThumborImage | undefined {
    const { logo } = this.attributes;
    return logo || EMPTY_IMAGE;
  }

  public isReadOnly(attribute: string): boolean {
    if (attribute === 'name') {
      return true;
    }
    return super.isReadOnly(attribute);
  }

  public static endpoint = '/pages';

  public static embeddedImageAttribute = 'logo';

  public ['constructor']: typeof Page;

  protected attributes: IPage;

  constructor(attributes: IPage) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }
}

export default Page;
