import { gql } from 'graphql-tag';

export const getPages = gql`
  query GetPages($cursor: String, $filters: PageFilters) {
    pages(first: 20, after: $cursor, filters: $filters) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          externalId
          logo(width: 340, height: 340) {
            url
          }
          coverImages(width: 480, last: 1) {
            edges {
              node {
                url
              }
            }
          }
          name
          published
          description
          promotedCallToActions(first: 1) {
            edges {
              node {
                id
                headline
                subHeadline
                targetUrl
                buttonText
                image {
                  url
                }
              }
            }
          }
          pageable {
            ... on Brand {
              id
            }
            ... on State {
              id
            }
          }
        }
      }
    }
  }
`;

export const getPage = gql`
  query GetPage($id: Int!) {
    entry: page(id: $id) {
      id
      externalId
      name
      createdAt
      published
      logo(croppingStrategy: NO_CROP, height: 1000, width: 1000) {
        url
      }
      coverImages(last: 1) {
        edges {
          node {
            url
          }
        }
      }
      followedByCurrentUser
      followers {
        totalCount
      }
      ambassadors(first: 10) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            externalId
            nickname
          }
        }
      }
      publishers(first: 10) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            externalId
            nickname
          }
        }
      }
      admins(first: 10) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            externalId
            nickname
          }
        }
      }
      promotedCallToActions(first: 1) {
        edges {
          node {
            id
            headline
            subHeadline
            buttonText
            targetUrl
            image {
              url
            }
          }
        }
      }
      description
      pageable {
        ... on Brand {
          id
        }
        ... on State {
          id
        }
      }
    }
  }
`;

export const paginatePageAmbassadors = gql`
  query PaginatePageAmbassadors($id: String!, $cursor: String) {
    entry: page(externalId: $id) {
      stuff: ambassadors(first: 10, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            externalId
            nickname
          }
        }
      }
    }
  }
`;

export const paginatePagePublishers = gql`
  query PaginatePagePublishers($id: String!, $cursor: String) {
    entry: page(externalId: $id) {
      stuff: publishers(first: 10, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            externalId
            nickname
          }
        }
      }
    }
  }
`;

export const paginatePageAdmins = gql`
  query PaginatePageAdmins($id: String!, $cursor: String) {
    entry: page(externalId: $id) {
      stuff: admins(first: 10, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            externalId
            nickname
          }
        }
      }
    }
  }
`;
