import LoadingBox from './LoadingBox';

interface IProps {
  rows: number;
  columns: number;
  rest?: any[];
}

const loadingTableBody: React.FC<IProps> = ({ rows, columns, ...rest }: IProps): JSX.Element => (
  <tbody {...rest}>
    {Array(rows)
      .fill(0)
      .map((_1, rowIndex) => (
        <tr key={rowIndex}>
          {Array(columns)
            .fill(0)
            .map((_2, columnIndex) => (
              <td key={columnIndex}>
                <LoadingBox />
              </td>
            ))}
        </tr>
      ))}
  </tbody>
);

export default loadingTableBody;
