import { gql } from 'graphql-tag';

export const getPromotionPartnerLinks = gql`
  query GetPromotionPartnerLinks(
    $cursor: String
    $promotion: String
    $token: String
    $userId: String
  ) {
    promotionPartnerLinks(
      last: 10
      before: $cursor
      promotion: $promotion
      token: $token
      userId: $userId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          createdAt
          id
          token
          metaParams
          openedAt
          promotion
          partnerUser
          userId
        }
      }
    }
  }
`;
