import BaseModel from '../../models/BaseModel';
import IHeader from '../List/HeadersInterface';
import GalleryCard from './GalleryCard';
import GalleryHeader from './GalleryHeader';

import styles from './GalleryView.module.css';
import Button from '../Clickables/Buttons/Button';

interface IProps {
  imageUrlProperty?: string;
  items?: any;
  headers: IHeader[];
  model: any;
  entries: BaseModel[];
  loading?: boolean;
  showMoreButton?: boolean;
  onShowMoreClick?: () => void;
}

const GalleryView = ({
  imageUrlProperty,
  headers,
  entries,
  loading,
  showMoreButton,
  onShowMoreClick,
}: IProps): JSX.Element => (
  <section className={`${styles.section} ${loading ? styles.sectionBlurred : ''}`}>
    <GalleryHeader headers={headers} />
    <div className={styles.grid}>
      {entries &&
        entries.length > 0 &&
        entries.map((item: any) => (
          <GalleryCard
            key={item.id}
            entry={item}
            imageUrlProperty={imageUrlProperty}
            headers={headers}
          />
        ))}
    </div>
    <div className={styles.loadMoreContainer}>
      {showMoreButton && (
        <Button disabled={loading} onClick={onShowMoreClick}>
          <i className="fa fa-arrow-down" />
          <span>&nbsp;Show More</span>
        </Button>
      )}
    </div>
  </section>
);

export default GalleryView;
