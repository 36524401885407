import { destroy } from '../../../api/rutilus';
import BaseModel from '../../../models/BaseModel';
import { IVideo } from '../../../models/Video';
import Video from '../../Video/Video';
import IFormHeaders from '../FormHeaderInterface';

import styles from '../Form.module.css';
import Button from '../../Clickables/Buttons/Button';

interface IProps {
  header: IFormHeaders;
  entry: BaseModel;
}

const remove = (video: IVideo) => () => {
  // eslint-disable-next-line no-restricted-globals
  if (confirm('Are you sure you want to remove this video?')) {
    destroy(`/posts/videos/${video.id}`).then((resp) =>
      resp.ok ? window.location.reload() : alert('Could not remove video'),
    );
  }
};

const FormVideo = ({ entry, header }: IProps): JSX.Element =>
  entry.video.id ? (
    <li className={styles.list_row}>
      <label>{header.title}</label>
      <Video className={styles.video} video={entry.video} />
      <Button variant="buttonDanger" onClick={remove(entry.video)}>
        Remove video
      </Button>
    </li>
  ) : (
    <></>
  );

export default FormVideo;
