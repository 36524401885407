import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Component } from 'react';

import Details from '../Details/Details';
import TextBox from '../TextBox/TextBox';
import { mergeQueryParams } from '../../helpers/urlhelpers';
import BaseModel from '../../models/BaseModel';
import IFormHeaders from './FormHeaderInterface';
import EditForm from './EditForm';

interface Props extends RouteComponentProps<{ id?: string }> {
  model: any;
  headers: IFormHeaders[];
  noEdit?: boolean;
  noDeletion?: boolean;
  returnPath: string;
  asyncDeletion?: boolean;
  validationSchema?: any;
}

interface State {
  entry?: BaseModel;
  loading: boolean;
  status?: string;
}

class Edit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entry: undefined,
      loading: true,
      status: undefined,
    };
    this.deleteEntry = this.deleteEntry.bind(this);
    this.editEntry = this.editEntry.bind(this);
  }

  public componentDidMount(): void {
    this.loadEntry();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.id.toString() !== prevProps.match.params.id) {
      this.loadEntry();
      this.setState({ status: undefined });
    }
  }

  public deleteEntry(): boolean {
    const { entry } = this.state;
    const { history, location, model, returnPath } = this.props;
    if (entry) {
      // eslint-disable-next-line no-restricted-globals
      const result = confirm(`Sure you want to delete ${model.modelType} #${entry.id}?`);
      if (result) {
        entry.destroy().then(() => {
          const search = mergeQueryParams(location.search, {
            fetchTime: Date.now(),
          });
          history.push(`${returnPath}?${search}`);
          return true;
        });
      }
    }
    return false;
  }

  public editEntry(params: { [key: string]: string | boolean }): void {
    const { entry } = this.state;
    if (entry) {
      entry
        .update(params)
        .then(() => {
          this.loadEntry();
          this.setState({
            status: `${this.props.model.modelType} #${this.id} has been edited. `,
          });
        })
        .catch((error) => {
          this.setState({ status: `Failed: ${error}` });
        });
    }
  }

  public render(): React.ReactElement {
    const { status, entry, loading } = this.state;
    const { headers, noDeletion, noEdit, asyncDeletion, validationSchema } = this.props;

    return (
      <Details title={`Edit ${this.props.model.modelType} #${this.id}`}>
        {loading ? (
          'loading'
        ) : (
          <>
            {status && <TextBox>{status}</TextBox>}
            {entry ? (
              <EditForm
                entry={entry}
                deleteEntry={!noDeletion ? this.deleteEntry : undefined}
                asyncDeletion={asyncDeletion}
                editEntry={!noEdit ? this.editEntry : undefined}
                headers={headers}
                validationSchema={validationSchema}
              />
            ) : (
              `Could not find ${this.props.model.modelType} #${this.id}`
            )}
          </>
        )}
      </Details>
    );
  }

  private loadEntry(): void {
    this.setState({ loading: true });
    this.props.model.find(this.id).then(
      (entry: BaseModel) => {
        this.setState({ entry, loading: false });
      },
      () => {
        this.setState({ status: 'Something went wrong', loading: false });
      },
    );
  }

  private get id(): string {
    if (!this.props.match.params.id) {
      throw new Error('No id param');
    }
    return this.props.match.params.id;
  }
}

export default withRouter(Edit);
