import { RansackMatcher } from './ransack';
import { mergeQueryParams } from './urlhelpers';

const DEFAULT_PARAMS = { page: 1 };

const URL_SEPARATOR = '__';
const FETCHING_SEPARATOR = '_';
const PREFIX = 'filter.';
const LENGTH = PREFIX.length;

interface FilterObject {
  [key: string]: string;
}

export type AttributeValues = {
  [matcher in RansackMatcher]: string;
};

interface Attributes {
  [attributeName: string]: AttributeValues;
}

const toKeyValues = (params: URLSearchParams): FilterObject => {
  const filter: FilterObject = {};
  // eslint-disable-next-line fp/no-loops
  for (const key of params.keys()) {
    if (key.startsWith(PREFIX)) {
      const keyWithoutFilterPrefix = key.substring(LENGTH);
      const value = params.get(key);
      if (value) {
        filter[keyWithoutFilterPrefix] = value;
      }
    }
  }
  return filter;
};

const filterKey = (
  name: string,
  matcher: RansackMatcher,
  separator: string = URL_SEPARATOR,
  includeMatcher = true,
): string => {
  if (includeMatcher) {
    return `${name}${separator}${matcher}`;
  }
  return name;
};

const splitKey = (key: string): [string, RansackMatcher] => {
  const splits = key.split(URL_SEPARATOR);
  const filterType: RansackMatcher = (splits.pop() || RansackMatcher.Equals) as RansackMatcher;
  return [splits[0], filterType];
};

const cleanURLSearchParams = (params: URLSearchParams): URLSearchParams => {
  // eslint-disable-next-line fp/no-loops
  for (const key of params.keys()) {
    if (params.get(key) === '') {
      params.delete(key);
    }
  }
  return params;
};

export default class Filter {
  static empty(): Filter {
    return Filter.parse('');
  }

  static parse(searchString: string): Filter {
    return new Filter(new URLSearchParams(searchString));
  }

  private readonly attributes: Attributes = {};

  constructor(params: URLSearchParams) {
    const filters = toKeyValues(params);
    Object.keys(filters).forEach((key) => {
      const [name, matcher] = splitKey(key);
      this.setAttribute(name, matcher, filters[key]);
    });
  }

  public get filtersForFetching(): FilterObject {
    return this.filters(FETCHING_SEPARATOR);
  }

  public get filtersForUrl(): FilterObject {
    return this.filters(URL_SEPARATOR);
  }

  public get filtersFlatMap(): FilterObject {
    return this.filters('', false);
  }

  private filters(separator: string, includeMatcher = true): FilterObject {
    const filters: FilterObject = {};
    Object.keys(this.attributes).forEach((attributeName: string) => {
      const attribute = this.attributes[attributeName];
      (Object.keys(attribute) as RansackMatcher[]).forEach((matcher: RansackMatcher) => {
        const key = filterKey(attributeName, matcher, separator, includeMatcher);
        filters[key] = attribute[matcher];
      });
    });
    return filters;
  }

  public toString(): string {
    return this.getUrlParameters('').toString();
  }

  public getUrlParameters(search: string): URLSearchParams {
    const query = new URLSearchParams(mergeQueryParams(search, DEFAULT_PARAMS));
    const filters = this.filtersForUrl;
    Object.keys(filters).forEach((key: string) => {
      query.set(`${PREFIX}${key}`, filters[key]);
    });
    return cleanURLSearchParams(query);
  }

  public attributeValues(attribute: string): AttributeValues {
    return this.attributes[attribute];
  }

  public setAttribute(name: string, matcher: RansackMatcher, value: string): Filter {
    this.attributes[name] = this.attributes[name] || {};
    this.attributes[name][matcher] = value;
    return this;
  }

  public deleteAttribute(name: string, matcher: RansackMatcher): Filter {
    if (this.attributes[name]) {
      const attributes: Partial<AttributeValues> = {};
      Object.keys(this.attributes[name]).forEach((key: string) => {
        if (key !== matcher) {
          attributes[key as RansackMatcher] = key;
        }
      });
      this.attributes[name] = attributes as AttributeValues;
    }
    return this;
  }
}
