import { gql } from 'graphql-tag';

export const getSpeciesTips = gql`
  query GetSpeciesTips($speciesId: Int!) {
    singleSpecies(id: $speciesId) {
      speciesTips(first: 100) {
        edges {
          node {
            externalId
            tip
            user {
              id
            }
            userDescription
            userDisplayName
          }
        }
      }
    }
  }
`;
