import { gql } from 'graphql-tag';

export default gql`
  query ListUrls($cursor: String, $status: [ModerationStatusEnum!], $url: String) {
    entries: postUrls(last: 20, before: $cursor, status: $status, url: $url) {
      edges {
        node {
          id
          longForm
          status
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export const urlOnPost = gql`
  query UrlsOnPost($postId: Int!) {
    post(id: $postId) {
      urls(first: 100) {
        edges {
          node {
            id
            status
            longForm
          }
        }
        totalCount
      }
    }
  }
`;
