import styles from './Home.module.css';
import logo from '../../logo.png';

const Home = () => {
  const GIT_HASH_LIMIT = 8;

  return (
    <div className={styles.home}>
      <div className={styles.tooltip}>
        <p>👈 Start over here</p>
      </div>

      <div className={styles.info}>
        <img src={logo} alt="Fishbrain" className={styles.logo} />
        <h1 className="text-3xl">Welcome to Ornatus</h1>

        <p className="text-midnight">
          {(process.env.REACT_APP_ENV || 'local').replace(/^\w/, (c) => c.toUpperCase())} build
          <br />
          Revision: {(process.env.REACT_APP_GIT_HASH || '-').substring(0, GIT_HASH_LIMIT)}
        </p>
      </div>
    </div>
  );
};

export default Home;
