import Details from '../Details/Details';
import IFormHeaders from './FormHeaderInterface';
import GqlForm, { IKeyValue } from './GqlForm';

interface IProps {
  headers: IFormHeaders[];
  model: any;
  mutation: any;
  prepareMutation?: (values: IKeyValue) => { [key: string]: unknown };
  afterCreate?: (data: any) => void;
  validationSchema?: any;
}

const GqlNew = ({
  headers,
  model,
  mutation,
  prepareMutation,
  afterCreate,
  validationSchema = false,
}: IProps) => (
  <Details title={`New ${model.modelType}`}>
    <GqlForm
      entry={model.fromAttributes({})}
      headers={headers}
      editEntry={mutation}
      prepareEdit={prepareMutation}
      afterEdit={afterCreate}
      validationSchema={validationSchema}
    />
  </Details>
);

export default GqlNew;
