import { QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { get } from 'ts-get';
import { Heading, Text } from 'barramundi';

import * as Yup from 'yup';

import IHeader from '../../components/List/HeadersInterface';
import { getCouponGroups } from '../../queries/CouponGroups';
import { GetCouponGroups, GetCouponGroupsVariables } from '../../interfaces/graphql';
import Flex from '../../components/Flex/Flex';
import GqlModelList from '../../components/ModelList/GqlModelList';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import {
  FormText,
  FormNumber,
  FormDecimalNumber,
} from '../../components/Edit/FormAttributes/FormAttribute';
import FormSelect from '../../components/Edit/FormAttributes/FormSelect';
import CouponGroup from '../../models/CouponGroup';
import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import { createCouponGroup } from '../../mutations/CouponGroup';
import GqlForm from '../../components/Edit/GqlForm';
import Details from '../../components/Details/Details';

const headers: IHeader[] = [
  { attribute: 'id', title: 'ID' },
  { attribute: 'couponExternalId', title: 'Stripe Coupon ID' },
  { attribute: 'expiresAt', title: 'Coupon expires at', component: AttributeDate },
  { attribute: 'name', title: 'Name' },
  { attribute: 'type', title: 'Type' },
  { attribute: 'usage', title: 'Usage' },
  { attribute: 'percentOff', title: 'Percent Off' },
  { attribute: 'stripePlan', title: 'Plan Id' },
  { attribute: 'code', title: 'Code' },
];

const StripePlanSelect = FormSelect([
  { value: '', label: 'Select one' },
  { value: 'monthly_12_99-no-trial', label: 'Monthly' },
  { value: 'yearly_79_99-no-trial', label: 'Yearly' },
]);

const CouponGroupTypeSelect = FormSelect([
  { value: '', label: 'Select one' },
  { value: 'DISCOUNT', label: 'Discount' },
  { value: 'GIFT', label: 'Gift' },
]);

const CouponGroupUsageSelect = FormSelect([
  { value: '', label: 'Select one' },
  { value: 'MULTIPLE', label: 'Multiple' },
  { value: 'ONCE', label: 'Once' },
]);

const creationFormHeaders = (): IFormHeaders[] => [
  { title: 'Name', attribute: 'name', component: FormText },
  {
    title: 'Percent Off',
    attribute: 'percentOff',
    component: FormDecimalNumber,
  },
  { title: 'Duration In Months', attribute: 'durationInMonths', component: FormNumber },
  { title: 'Stripe Coupon ID', attribute: 'stripeCouponId', component: FormText },
  { title: 'Coupon Group Type', attribute: 'typeName', component: CouponGroupTypeSelect },
  { title: 'Coupon Group Usage', attribute: 'usageName', component: CouponGroupUsageSelect },
  { title: 'Subscription Plan', attribute: 'stripePlanId', component: StripePlanSelect },
  { title: 'Code', attribute: 'code', component: FormText },
  { title: 'Quantity', attribute: 'quantity', component: FormNumber },
];

const createValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  percentOff: Yup.number().required('Percent Off is required'),
  durationInMonths: Yup.number().required('Duration In Months is required'),
  stripeCouponId: Yup.string().required('Stripe Coupon ID is required'),
  typeName: Yup.string().required('Coupon Group Type is required'),
  usageName: Yup.string().required('Coupon Group Usage is required'),
  stripePlanId: Yup.string().required('Subscription Plan is required'),
  code: Yup.string().required('Code is required').min(6, 'Code must have at least 6 characters'),
  quantity: Yup.number().required('Quantity is required'),
});

const Docs = () => (
  <>
    <Heading>Coupon Groups</Heading>
    <Text>
      Coupon groups represent a single coupon campaign. See here for more in-depth technical
      details.
    </Text>
    <Heading as="h2">Creating a Coupon Group</Heading>
    <Heading as="h3">Creating a Coupon in Stripe</Heading>
    <ul>
      <li>
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
          Login in to Stripe
        </a>
      </li>
      <li>Click Billing / Coupons / New</li>
      <li>
        Fill out the details. Don&apos;t add an ID. Note that once the coupon is created, the
        details cannot be changed.
      </li>
      <li>After creating the Coupon, save the ID.</li>
    </ul>
    <Heading as="h3">Creating Coupons in Ornatus</Heading>
    <ul>
      <li>
        <a
          href="http://ornatus.fishbrain.com/coupon-groups"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to Coupon Groups
        </a>{' '}
        and click &quot;New Coupon Group&quot;.
      </li>
      <li>Every field needs to be entered. See below for more information about the fields.</li>
      <li>
        Click &quot;Save Coupon Group&quot;. Your coupon should now be created (you&apos;ll be able
        to see it at the bottom of the list on the left). If you created a &quot;one-time&quot;
        usage coupon, you&apos;ll need to speak to a backend developer to get the list of coupon
        codes.
      </li>
    </ul>
    <Heading as="h2">Coupon Group fields</Heading>
    <Text>
      <strong>Name</strong>: This should be a human readable name for the coupon. The user will see
      it in the payment form when they use the coupon code.
    </Text>
    <Text>
      <strong>Percent off</strong>: The percentage amount for the coupon, e.g. `40` will be 40% off.
      This can also be a decimal number. Be aware NOT to add more than 2 decimals!
    </Text>
    <Text>
      <strong>Duration in months</strong>: How long the coupon will be active for from first use.
      Within this time period, any payment the user makes will have the discount applied. This means
      you will need to do some calculation about when the coupon should apply. A 100% of for 1 year
      campaign, for example, would need to have a duration of `12`.
    </Text>
    <Text>
      <strong>Stripe Coupon ID</strong>: The ID of the coupon in Stripe (see above).
    </Text>
    <Text>
      <strong>Coupon Group Type</strong>: `Gift` means the user will enter no credit card (assuming
      the discount is for 100% off). `Discount` means the user will enter a credit card (even if
      100% off).
    </Text>
    <Text>
      <strong>Coupon Group Usage</strong>: `Multiple` should be used if you have a coupon code that
      you want to be used more than once, e.g. `SUMMER50` or `SCOTTMARTIN20`. `Once` should be used
      if you want random one-time usage codes to be created that can be given directly to a user.
    </Text>
    <Text>
      <strong>Subscription Plan</strong>: The plan the coupon will be associated with. These plans
      should match the normal plans on the web, but without trial days.
    </Text>
    <Text>
      <strong>Code</strong>: The coupon code the user will use. Leave blank to generate a random
      code. If `Usage` is `Once`, this field will be ignored.
    </Text>
    <Text>
      <strong>Quantity</strong>: If usage is `Multiple`, set this to 1. Otherwise set it to the
      number of one-time codes you would like to be created. Do note that this should align with any
      limitations set in Stripe (e.g. if in Stripe you said maximum usage of 10, and in Ornatus you
      set quantity to 100, only the first 10 users will be able to use their codes).
    </Text>
  </>
);

const CreateCouponGroup = () => (
  <Details title={`New ${CouponGroup.modelType}`}>
    <details>
      <summary>NOTE: Click to read the documentation before creating a coupon group!</summary>
      <Docs />
    </details>
    <GqlForm
      model={CouponGroup}
      headers={creationFormHeaders()}
      createEntry={createCouponGroup}
      prepareCreate={(couponGroup: any) => ({
        input: {
          groupAttributes: {
            name: couponGroup.name,
            durationInMonths: couponGroup.durationInMonths,
            percentOff: couponGroup.percentOff,
            stripeCouponId: couponGroup.stripeCouponId,
            typeName: couponGroup.typeName,
            usageName: couponGroup.usageName,
            stripePlanId: couponGroup.stripePlanId,
          },
          code: couponGroup.code,
        },
      })}
      afterCreate={() => {
        document.location.href = `/coupon-groups`;
      }}
      validationSchema={createValidation}
      entry={CouponGroup.fromAttributes({} as any)}
    />
  </Details>
);

function CouponGroups() {
  const location = useLocation();
  const match = useRouteMatch();

  const onLoadMore = (fetchMore: QueryResult['fetchMore'], couponGroups: any) => {
    fetchMore({
      updateQuery: (previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
        const newEdges = fetchMoreResult.couponGroups.edges;
        const { pageInfo } = fetchMoreResult.couponGroups;

        return {
          couponGroups: {
            pageInfo,
            __typename: previousResult.couponGroups.__typename,
            edges: [...previousResult.couponGroups.edges, ...newEdges],
          },
        };
      },
      variables: {
        cursor: couponGroups.pageInfo.endCursor,
      },
    });
  };

  return (
    <Query<GetCouponGroups, GetCouponGroupsVariables>
      query={getCouponGroups}
      fetchPolicy="network-only"
    >
      {({ loading, error, data, fetchMore }) => (
        <Flex>
          <div>
            <GqlModelList
              isLoading={loading}
              error={error}
              entries={get(data, (d) => d.couponGroups)}
              headers={headers}
              path={location.pathname}
              onLoadMore={() =>
                onLoadMore(
                  fetchMore,
                  get(data, (d) => d.couponGroups),
                )
              }
            />
          </div>
          <Switch>
            <Route path={`${match.path}/new`} component={CreateCouponGroup} exact />
          </Switch>
        </Flex>
      )}
    </Query>
  );
}

export default CouponGroups;
