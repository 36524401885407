.details {
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(black, 0.3);
  height: 100%;
  right: 0;
  width: 100%;
  z-index: 10;
}

.title {
  align-items: center;
  border-bottom: 1px solid rgb(201, 201, 201);
  display: flex;
  font-weight: bold;
  height: 40px;
  justify-content: space-between;
  padding: 0 var(--padding-base);
}

.form {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.form > * {
  padding: 9px 24px;
}
