import { gql } from 'graphql-tag';

export const setTextStatusAction = gql`
  mutation editTextMutation($input: EditTextMutationInput!) {
    editText(input: $input) {
      text {
        status
      }
      userErrors {
        path
        message
      }
    }
  }
`;
