import { Field, FieldProps } from 'formik';

import Select from 'react-select';

import Fieldset from '../../Fieldset/Fieldset';
import { Option } from '../../Input/Select';
import { FormProps } from './FormAttribute';

import flexStyles from '../../Flex/Flex.module.css';
import styles from '../../Input/TagInput.module.css';

const FormMultiSelect = (options: Option[]) => {
  const inner = ({ header, field, ...props }: FieldProps & FormProps) => (
    <Fieldset label={header.title}>
      <label htmlFor="form-select" style={{ position: 'initial' }}>
        {header.title}
      </label>
      <Select
        className={`${flexStyles.resetFlex} ${styles.tagInput}`}
        isClearable
        isMulti
        placeholder="Type something and press enter..."
        {...field}
        {...props}
        options={options}
        onChange={(values: any) => {
          props.setFieldValue(
            field.name,
            values.map((v: Option) => v.value),
          );
          props.setFieldTouched(field.name, true);
        }}
        value={
          field.value ? field.value.map((v: string) => options.find((o) => o.value === v)) : []
        }
      />
    </Fieldset>
  );
  // eslint-disable-next-line react/display-name
  return (props: FormProps) => <Field component={inner} {...props} />;
};

export default FormMultiSelect;
