import { Component } from 'react';

import { FormikProps } from 'formik';

import { put } from '../../api/rutilus';

import BaseModel from '../../models/BaseModel';
import IPhoto from '../../models/Photo';

import Image from '../../components/Image/Image';
import UploadFile from '../../components/UploadFile/UploadFile';

import styles from '../../components/Edit/Form.module.css';
import Button from '../../components/Clickables/Buttons/Button';

interface Props extends FormikProps<any> {
  entry: BaseModel;
}

interface InnerCatchImageProps {
  photo: IPhoto;
  entry: BaseModel;
}

class InnerCatchImage extends Component<
  InnerCatchImageProps,
  { loading: boolean; deleted: boolean }
> {
  constructor(props: InnerCatchImageProps) {
    super(props);
    this.state = { loading: false, deleted: false };
    this.removeImage = this.removeImage.bind(this);
  }

  public render(): React.ReactElement {
    const { loading, deleted } = this.state;
    const { photo } = this.props;
    return deleted ? (
      <></>
    ) : (
      <figure className={styles.media} key={photo.id}>
        <Image img={photo.photo} link="1440" geometry="208x208#" />
        <figcaption>
          <Button variant="buttonDanger" size="sm" disabled={loading} onClick={this.removeImage}>
            Remove
          </Button>
        </figcaption>
      </figure>
    );
  }

  private removeImage(): void {
    const { photo, entry } = this.props;
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove this image (#${photo.id})?`)) {
      this.setState({ loading: true });
      put(`/catches/${entry.id}`, {
        photos_to_destroy: [
          {
            id: photo.id,
          },
        ],
      }).then((resp) =>
        resp.ok
          ? this.setState({ deleted: true })
          : alert(`Could not remove image. HTTP status ${resp.status}`),
      );
    }
  }
}

const CatchImages = ({ entry, ...rest }: Props): JSX.Element => (
  <div className={styles.list_row}>
    <div className={styles.mediaList}>
      {entry.photos &&
        entry.photos.map((photo: IPhoto) => (
          <InnerCatchImage photo={photo} entry={entry} key={photo.id} />
        ))}
      {entry.photo && (
        <figure className={styles.media}>
          <Image img={entry.photo} geometry="270x200>" />
          <figcaption>{entry.name ? entry.name : ''}</figcaption>
        </figure>
      )}
    </div>
    <UploadFile type="image" {...rest} />
  </div>
);

export default CatchImages;
