import { Component } from 'react';

import { Location } from 'history';

import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import Fieldset from '../Fieldset/Fieldset';
import Flex from '../Flex/Flex';
import IHeader from './HeadersInterface';
import { mergeQueryParams } from '../../helpers/urlhelpers';

import styles from './ListFilter.module.css';

interface IProps extends RouteComponentProps {
  headers: IHeader[];
}

interface IState {
  searchValue: string;
}

class ListSearch extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      searchValue: this.getSearchValueFromLocation(props.location as any),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    this.setState({
      searchValue: this.getSearchValueFromLocation(nextProps.location as any),
    });
  }

  public render(): React.ReactElement {
    const { headers, location } = this.props;
    const { pathname } = location;
    return (
      <tr>
        <th colSpan={headers.length} className={styles.filterCell}>
          <Flex>
            <Fieldset
              label="Search"
              inputProps={{
                value: this.state.searchValue,
                onChange: this.handleSearchChange,
                onKeyPress: this.handleKeyPress,
                placeholder: 'Enter search value, hit enter to search...',
                style: { marginBottom: 0 },
              }}
              onClear={this.clear}
            />
            <Link
              to={{ pathname, search: this.getSearchQuery().toString() }}
              className={styles.search}
            >
              Search
            </Link>
          </Flex>
        </th>
      </tr>
    );
  }

  private readonly clear = () => {
    const {
      history,
      location: { pathname },
    } = this.props;
    history.push({ pathname, search: this.getResetQuery().toString() });
  };

  private getSearchValueFromLocation({ search }: Location): string {
    return decodeURIComponent(new URLSearchParams(search).get('search') || '');
  }

  private handleSearchChange(event: React.SyntheticEvent<HTMLInputElement>): void {
    this.setState({ searchValue: event.currentTarget.value });
  }

  private handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      const { location } = this.props;
      const { pathname } = location;
      this.props.history.push({
        pathname,
        search: this.getSearchQuery().toString(),
      });
    }
  }

  private getResetQuery(): URLSearchParams {
    const { search } = this.props.location;
    const query = new URLSearchParams(mergeQueryParams(search, { page: 1 }));
    query.delete('search');
    return query;
  }

  private getSearchQuery(): URLSearchParams {
    const { search } = this.props.location;
    const query = new URLSearchParams(mergeQueryParams(search, { page: 1 }));
    if (this.state.searchValue && this.state.searchValue.length) {
      query.set('search', this.state.searchValue);
    } else {
      query.delete('search');
    }
    return query;
  }
}

export default withRouter(ListSearch);
