import { gql } from 'graphql-tag';

export const setUrlHostStatus = gql`
  mutation setUrlHostStatus($input: EditURLHostMutationInput!) {
    editUrlHost(input: $input) {
      host {
        status
        __typename
      }
      userErrors {
        path
        message
      }
    }
  }
`;
