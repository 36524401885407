import { IFishingWater } from './FishingWater';
import GqlBaseModel from './GqlBaseModel';

export interface IUserSuggestedChange {
  readonly id: number;
  readonly externalId: string;
  readonly reviewedAt: any | null;
  readonly userExternalId: string;
  additionalInformation: string;
  fishingwater: IFishingWater;
  email: string;
  longitude: number;
  latitude: number;
  rejectionReason: string;
  status: string;
  suggestedWaterName: string;
  url: string;
  // userSuggestedWaterMetadata: number;
}

class UserSuggestedChange extends GqlBaseModel {
  public static readOnly: string[] = [
    'id',
    'email',
    'externalId',
    'created_at',
    'updated_at',
    'name',
    'longitude',
    'latitude',
    'url',
    'additionalInformation',
    'status',
    'assignee',
    'reviewed_at',
    'reviewerNickname',
  ];

  public static fromAttributes(attributes: IUserSuggestedChange): UserSuggestedChange {
    return new UserSuggestedChange(attributes);
  }

  public ['constructor']: typeof UserSuggestedChange | undefined;

  protected attributes: IUserSuggestedChange;

  constructor(attributes: IUserSuggestedChange) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get modelType(): string {
    return 'UserSuggestedChange';
  }

  public static get modelType(): string {
    return 'User suggested Change';
  }

  public get reviewed_at(): Date {
    return this.dateField('reviewedAt');
  }

  protected get class(): typeof UserSuggestedChange {
    return UserSuggestedChange;
  }
}

export default UserSuggestedChange;
