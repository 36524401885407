import styles from './Header.module.css';

interface IProps {
  className?: string;
  children: React.ReactNode;
  rest?: any[];
}

const Header: React.FC<IProps> = ({ children, className, ...rest }): JSX.Element => (
  <header className={`${className || ''} ${styles.header}`} {...rest}>
    {children}
  </header>
);

export default Header;
