import { Feature, Layer } from 'react-mapbox-gl';

import Map from '../../components/Map/Map';

const CatchLocationMap = ({ lng, lat }: { lng: number; lat: number }): JSX.Element => (
  <Map
    containerStyle={{
      height: '200px',
      width: '100%',
    }}
    center={[lng, lat]}
  >
    <Layer type="symbol" id="marker" layout={{ 'icon-image': 'catch_old' }}>
      <Feature coordinates={[lng, lat]} />
    </Layer>
  </Map>
);

export default CatchLocationMap;
