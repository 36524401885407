import IHeader from '../List/HeadersInterface';
import ListFilter from '../List/ListFilter';
import ListSearch from '../List/ListSearch';

import styles from './GalleryView.module.css';

interface IProps {
  headers: IHeader[];
}

const GalleryHeader = ({ headers }: IProps): JSX.Element => (
  <table className={styles.headerContainer}>
    <thead>
      {headers.some((header) => header.filterable === true) && <ListFilter headers={headers} />}
      {headers.some((header) => header.canElasticsearch === true) && (
        <ListSearch headers={headers} />
      )}
    </thead>
  </table>
);

export default GalleryHeader;
