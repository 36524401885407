import { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';

import User from '../../models/User';
import { assignAdminRoleToUser, unassignAdminRoleToUser } from '../../mutations/User';
import {
  AssignAdminRoleToUser,
  AssignAdminRoleToUserVariables,
  UnassignAdminRoleToUser,
  UnassignAdminRoleToUserVariables,
} from '../../interfaces/graphql';

const AdminToggle = ({ entry: { email, external_id: userId, is_admin: initialIsAdmin } }: User) => {
  const isFishbrainEmail = email?.endsWith('@fishbrain.com');
  const [isAdmin, setIsAdmin] = useState(initialIsAdmin);

  const [assignAdmin, { loading: loadingAssign }] = useMutation<
    AssignAdminRoleToUser,
    AssignAdminRoleToUserVariables
  >(assignAdminRoleToUser, {
    onCompleted: (response) => {
      const { userErrors } = response.assignAdminRoleToUser || { userErrors: [] };
      if (userErrors.length !== 0) {
        alert(userErrors[0].message);
        return;
      }
      setIsAdmin(true);
    },
    onError: (err: ApolloError) => alert(err.message),
  });

  const [unAssignAdmin, { loading: loadingUnassign }] = useMutation<
    UnassignAdminRoleToUser,
    UnassignAdminRoleToUserVariables
  >(unassignAdminRoleToUser, {
    onCompleted: (response) => {
      const { userErrors } = response.unassignAdminRoleToUser || { userErrors: [] };
      if (userErrors.length !== 0) {
        alert(userErrors[0].message);
        return;
      }
      setIsAdmin(false);
    },
    onError: (err: ApolloError) => alert(err.message),
  });
  const isLoading = loadingAssign || loadingUnassign;

  const handleAdminToggle = () => {
    const newAdminStatus = !isAdmin;
    if (
      confirm(
        newAdminStatus
          ? 'Are you sure you want to make this user an admin?'
          : 'Are you sure you want to remove this user as an admin?',
      )
    ) {
      if (newAdminStatus) {
        const variables = {
          input: {
            userId,
          },
        };
        assignAdmin({ variables });
      } else {
        const variables = {
          input: {
            userId,
          },
        };
        unAssignAdmin({ variables });
      }
    }
  };

  if (!isFishbrainEmail) {
    return null;
  }

  return (
    <div className="mb-3 flex">
      <label className={`flex ${isLoading ? 'text-disabled' : 'cursor-pointer'}`}>
        <input
          type="checkbox"
          id="adminCheckbox"
          checked={isAdmin}
          onChange={handleAdminToggle}
          disabled={isLoading}
        />{' '}
        <span className="ml-2">Grant Admin access</span>
      </label>
    </div>
  );
};

export default AdminToggle;
