import { TableRow, TableCell, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useFilterParams } from '../../../helpers/hooks';
import { ITableColumn } from '../Table/Table';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
  },
}));

interface IProps {
  columns: ITableColumn[];
}

export const TableFilters = ({ columns }: IProps): JSX.Element => {
  const filterables = columns.filter((column) => column.filterable);
  const { filterValues, setFilterValues } = useFilterParams(columns);
  const classes = useStyles();

  const submitFilters = () => {
    setFilterValues(
      filterables.reduce((values, { id }) => {
        const el = document.getElementById(`filter-${id}`) as HTMLInputElement;
        const value = el && el.value ? el.value : undefined;
        return { ...values, [id]: value };
      }, {}),
    );
  };

  return (
    <TableRow
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          submitFilters();
        }
      }}
    >
      {columns.map((column) =>
        column.filterable ? (
          <TableCell padding="none" key={column.id}>
            <TextField
              id={`filter-${column.id}`}
              label={`Filter on ${column.label}`}
              variant="filled"
              size="small"
              defaultValue={filterValues[column.id]}
              fullWidth
              className={classes.root}
            />
          </TableCell>
        ) : (
          <TableCell padding="none" key={column.id} />
        ),
      )}
      <TableCell padding="none">
        {/* TODO Update button to our own */}
        <Button onClick={submitFilters}>Filter</Button>
      </TableCell>
    </TableRow>
  );
};
