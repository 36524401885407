import { useEffect } from 'react';
import { withFormik } from 'formik';

import queryString from 'query-string';
import { Redirect, useHistory } from 'react-router-dom';

import { COGNITO_LOGIN_URL } from '../../api/cognito';

import Loading from '../../components/Loading/Loading';
import { useAuth } from '../../contexts/AuthContext';
import { authenticateWithCode, getToken } from '../../helpers/authentication';

interface IAuthRedirectProps {
  code: string;
}

const AuthRedirect = ({ code }: IAuthRedirectProps): JSX.Element => {
  const { setIsLoggedIn } = useAuth();
  useEffect(() => {
    authenticateWithCode(code)
      .then((isLoggedIn) => {
        setIsLoggedIn(isLoggedIn);
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        setIsLoggedIn(false);
        window.location.href = COGNITO_LOGIN_URL;
      });
  }, [code, setIsLoggedIn]);
  return <Loading isLoading />;
};

const InnerLogin = (): JSX.Element => {
  const history = useHistory();
  const { isLoggedIn } = useAuth();
  const { code } = queryString.parse(history.location.search);

  if (isLoggedIn === true) {
    return <Redirect to="/" />;
  }
  if (code) {
    return <AuthRedirect code={window.location.href} />;
  }
  if (isLoggedIn === false) {
    window.location.href = COGNITO_LOGIN_URL;
  }

  return <Loading isLoading />;
};

const Login = withFormik({
  handleSubmit: (_, { setSubmitting, setErrors }) => {
    getToken().then(
      () => null,
      () => {
        setErrors({ username: 'Username or password is incorrect' });
        setSubmitting(false);
      },
    );
  },
  mapPropsToValues: () => ({ username: '', password: '' }),
})(InnerLogin);

export default Login;
