.box {
  background: rgba(black, 0.07);
  content: ' ';
  height: 1.1em;
  width: 100%;
}

.wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.loaderImage {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
}
