import { Field } from 'formik';

import { Link } from 'react-router-dom';

import SearchInput, { Loader, Value } from '../../Input/SearchInput';
import { FormProps } from './FormAttribute';

import styles from './AttributeWithSearch.module.css';

const AttributeWithSearch =
  (loader: Loader, converter: (value: any) => Value, prefetchedAttribute: string, link?: string) =>
  // eslint-disable-next-line react/display-name
  (props: FormProps) => {
    const change = (value: Value) => {
      props.setFieldValue(props.header.attribute, value ? value.value : null);
      props.setFieldTouched(props.header.attribute, true);
      props.setFieldValue(prefetchedAttribute, value || { label: '- not set-', value: -1 });
    };

    return (
      <div className={styles.flex}>
        <Field
          {...props}
          className={styles.grow}
          component={SearchInput}
          value={props.values[prefetchedAttribute] || converter(props.entry[prefetchedAttribute])}
          loadOptions={loader}
          onChange={change}
        />
        {link && props.value && (
          <Link to={`${link}${props.value}`} className={styles.detailsLink}>
            View details
          </Link>
        )}
      </div>
    );
  };

export default AttributeWithSearch;
