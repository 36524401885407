import loader from './loader.gif';
import styles from './LoadingBox.module.css';

interface IProps {
  isLoading: boolean;
  children?: React.ReactNode;
}

const Loading = ({ isLoading, children }: IProps): any =>
  isLoading ? (
    <div className={styles.wrapper}>
      <img src={loader} alt="Loader" className={styles.loaderImage} />
    </div>
  ) : (
    children
  );

export default Loading;
