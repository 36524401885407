import * as Yup from 'yup';

import GqlNew from '../../../components/Edit/GqlNew';
import FishingWater from '../../../models/FishingWater';
import IFormHeaders from '../../../components/Edit/FormHeaderInterface';
import { FormHidden, FormText } from '../../../components/Edit/FormAttributes/FormAttribute';
import DetailsMap from './DetailsMap';
import CreateFishingWater from '../../../mutations/CreateFishingWater';
import { WaterTypeSelect, getCoords } from '../helpers';
import { Metadata } from '../../../components/Metadata/Metadata';

const createFormHeaders: IFormHeaders[] = [
  { title: 'Name', attribute: 'name', component: FormText },
  { title: 'Water Description', attribute: 'description', component: FormText },
  {
    title: 'Water type',
    attribute: 'type',
    component: WaterTypeSelect,
    defaultValue: 'unknown',
  },
  { title: '', attribute: '', component: DetailsMap },
  {
    title: '',
    attribute: 'coords',
    row: [
      {
        title: 'Latitude (eg 59.33)',
        attribute: 'latitude',
        component: FormText,
        relatedAttribute: 'longitude',
        beforeSave: (value: any) => parseFloat(value),
      },
      {
        title: 'Longitude (eg 18.06)',
        attribute: 'longitude',
        component: FormText,
        relatedAttribute: 'latitude',
        beforeSave: (value: any) => parseFloat(value),
      },
    ],
  },
  {
    title: 'Water Metadata',
    attribute: 'metadata',
    component: (props: any) => (
      <Metadata metadata={[]} valuesToAddField="addMetadataIds" {...props} />
    ),
  },

  { title: '', attribute: 'addMetadataIds', component: FormHidden },
];

const createValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  latitude: Yup.number().required('Latitude must be a valid number'),
  longitude: Yup.number().required('Longitude must be a valid number'),
});

const NewFishingWater = (): JSX.Element => (
  <GqlNew
    model={FishingWater}
    headers={createFormHeaders}
    mutation={CreateFishingWater}
    prepareMutation={(attributes: any) => {
      const { latitude, longitude, addMetadataIds, ...rest } = attributes;
      return {
        input: {
          attributes: {
            metadataIds: addMetadataIds,
            ...getCoords(latitude, longitude),
            ...rest,
          },
        },
      };
    }}
    afterCreate={({
      createFishingWater: { fishingWater, userErrors },
    }: {
      createFishingWater: {
        fishingWater: { externalId: string };
        userErrors: { message: string }[];
      };
    }) => {
      if (userErrors.length) {
        alert(userErrors.map((error) => error.message).join(', '));
      } else {
        document.location.href = `/fishing_waters/${fishingWater.externalId}`;
      }
    }}
    validationSchema={createValidation}
  />
);

export default NewFishingWater;
