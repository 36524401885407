import React, { useState } from 'react';

import { post } from '../../api/rutilus';
import User from '../../models/User';
import Button from '../../components/Clickables/Buttons/Button';

const EmailVerificationButton = ({ entry }: { entry: User }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const send = () => {
    setLoading(true);
    post(`/admin/users/${entry.external_id}/verify_email`, {}).then((resp: Response) => {
      if (!resp.ok) {
        alert(`Something went wrong. Rutilus said: "${resp.body}"`);
      } else {
        setSent(true);
      }
      setLoading(false);
    });
  };

  const message: string = ((): string => {
    if (sent) {
      return '✔️ Sent!';
    }
    if (loading) {
      return 'Sending...';
    }
    return 'Send email verification';
  })();

  return (
    <Button variant="buttonSecondary" disabled={sent || loading} onClick={send} size="sm">
      {message}
    </Button>
  );
};

export default EmailVerificationButton;
