import { Link, useLocation } from 'react-router-dom';

import IHeader from './HeadersInterface';
import { mergeQueryParams } from '../../helpers/urlhelpers';

interface IProps {
  header: IHeader;
}

const getIcon = (currentSort: string, attribute: string, newDirection: string): string => {
  if (currentSort.includes(attribute)) {
    return newDirection === 'desc' ? 'fa-sort-up' : 'fa-sort-down';
  }
  return 'fa-sort';
};

const SortButtons = ({ header }: IProps): JSX.Element => {
  const { defaultSort, attribute, title } = header;
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(mergeQueryParams(search, { page: 1 }));
  const currentSort = query.get('sort') || (defaultSort ? `${attribute} ${defaultSort}` : '');

  const newDirection = currentSort.includes('desc') ? 'asc' : 'desc';
  const icon = getIcon(currentSort, attribute, newDirection);
  query.set('sort', `${attribute} ${newDirection}`);

  return (
    <Link to={{ pathname, search: query.toString() }}>
      {title} <i className={`fas ${icon}`} />
    </Link>
  );
};

export default SortButtons;
