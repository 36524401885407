import styles from './AttributeStyle.module.css';

const AttributeState = ({ value }: { value: string }): JSX.Element => {
  switch (value) {
    case 'approved': {
      return <span className={styles.approved}>{value}</span>;
    }
    case 'disapproved': {
      return <span className={styles.disapproved}>{value}</span>;
    }
    default: {
      return <span className={styles.pending}>{value}</span>;
    }
  }
};

export default AttributeState;
