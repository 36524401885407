import styles from './Input.module.css';

interface IProps {
  className?: string;
  value?: string;
  onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  max?: string;
  inputProps: any;
}

const DateInput = ({ className = '', inputProps, ...props }: IProps): JSX.Element => (
  <input type="date" className={`${styles.input} ${className}`} {...inputProps} {...props} />
);

export default DateInput;
