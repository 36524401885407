const TEN = 10;
const SIXTEEN = 16;
const HOUR = 60000;

const dateFormat = (date: Date, format?: string): string => {
  const formatedDate = date.toJSON();
  if (format === '/') {
    return formatedDate.slice(0, TEN).replace(/-/g, '/');
  }
  return formatedDate.slice(0, SIXTEEN);
};

// Get format suitable for datetime-local input
export const dateTimeLocalFormat = (date: string): string => {
  if (!date) {
    return date;
  }
  const dateObj = new Date(date);
  const dateString = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * HOUR).toISOString();
  // remove milliseconds and timezone marker
  return dateString.substr(0, dateString.lastIndexOf('.'));
};

export default dateFormat;
