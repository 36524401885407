import { Component } from 'react';

import Input from '../Input/Input';
import Textarea from '../Input/Textarea';

import styles from './Fieldset.module.css';
import Button from '../Clickables/Buttons/Button';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  inputProps?: { [key: string]: any };
  label?: string;
  legend?: string;
  readOnly?: boolean;
  onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  [restAttribute: string]: any;
  onClear?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

class Fieldset extends Component<IProps> {
  public static idCounter = 0;

  // eslint-disable-next-line react/static-property-placement
  public static defaultProps: Partial<IProps> = {
    className: '',
    onChange: (_event: React.SyntheticEvent<HTMLInputElement>) => null,
    readOnly: false,
  };

  private readonly id: string;

  constructor(props: IProps) {
    super(props);

    // eslint-disable-next-line no-plusplus
    ++Fieldset.idCounter;

    this.id = props.id || `input-${Fieldset.idCounter}`;
  }

  public render(): React.ReactElement {
    const { children, className, label, legend, inputProps, onChange, onClear, readOnly, type } =
      this.props;
    const InputElement = this.inputType();
    return (
      <fieldset className={`${className} ${styles.fieldset}`}>
        {children || (
          <>
            {legend && <legend className="text-error">{legend}</legend>}
            <InputElement
              id={this.id}
              onChange={onChange as any}
              {...inputProps}
              readOnly={readOnly}
            />
            <label
              className={`${styles.label} ${type === 'checkbox' ? '-mt-3' : ''}`}
              htmlFor={this.id}
            >
              {label}
            </label>
            {onClear && (
              <Button
                variant="buttonNone"
                title="Clear field"
                onClick={onClear}
                className={styles.clearButton}
              >
                &times;
              </Button>
            )}
          </>
        )}
      </fieldset>
    );
  }

  private inputType(): React.ElementType {
    const { inputProps } = this.props;
    return inputProps && inputProps.type === 'textarea' ? Textarea : Input;
  }
}

export default Fieldset;
