import UserComment from '../../../models/UserComment';
import Flex from '../../Flex/Flex';
import IHeader from '../../List/HeadersInterface';
import ModelList from '../../ModelList/ModelList';

import AttributeDate from '../../AttributeComponents/AttributeDate';
import AttributeID from '../../AttributeComponents/AttributeID';

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    filterable: false,
    link: '/catches/',
    title: 'Id',
  },
  {
    attribute: 'text',
    filterable: false,
    title: 'Text',
  },
  {
    attribute: 'created_at',
    component: AttributeDate,
    filterable: false,
    title: 'Post Date',
  },
];

const UserComments = (): JSX.Element => (
  <Flex>
    <ModelList headers={headers} model={UserComment} />
  </Flex>
);

export default UserComments;
