import { useLocation } from 'react-router-dom';

import styles from './ModelList.module.css';
import Bookmarks, { IBookmark } from '../Bookmarks/Bookmarks';
import GqlBaseModel from '../../models/GqlBaseModel';
import GqlListBody, { GqlListBodyProps } from '../List/GqlListBody';
import ListError from '../ErrorBoundary/ListError';
import LoadingTableBody from '../Loading/LoadingTableBody';
import GqlListHeader from '../List/GqlListHeader';

interface IProps<T extends GqlBaseModel> extends Omit<GqlListBodyProps<T>, 'entries'> {
  error?: { message: string };
  entries?: GqlListBodyProps<T>['entries'];
  bookmarks?: IBookmark[];
  isLoading: boolean;
}

const GqlListBodyWrapper = <T extends GqlBaseModel = any>({
  isLoading = false,
  entries,
  error,
  ...props
}: IProps<T>): JSX.Element => {
  const { search } = useLocation();

  if (isLoading) {
    return <LoadingTableBody rows={20} columns={props.headers.length} />;
  }
  if (error || !entries) {
    return <ListError message={error ? error.message : 'No data returned'} />;
  }

  return <GqlListBody entries={entries} {...props} search={search} />;
};

const GqlModelList = <T extends GqlBaseModel = any>({
  bookmarks,
  headers,
  ...rest
}: IProps<T>): JSX.Element => (
  <div className={styles.scroll}>
    <Bookmarks bookmarks={bookmarks} />
    <table className={styles.table}>
      <GqlListHeader headers={headers} />
      <GqlListBodyWrapper headers={headers} {...rest} />
    </table>
  </div>
);

export default GqlModelList;
