.table {
  border-collapse: collapse;
  width: 100%;

  & thead tr {
    border-bottom: 1px solid rgba(black, 0.07);
    font-size: 0.8em;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
  }

  & tbody tr {
    &:hover {
      background: rgba(black, 0.03);
    }
  }

  & td,
  & th {
    padding: 3px 5px;
  }
}

.spacious {
  & td,
  & th {
    padding: calc(var(--padding-base) / 2) calc(var(--padding-base) / 2);
  }
}
