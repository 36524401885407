import { gql } from 'graphql-tag';

export default gql`
  query UserWithBlocksAndBlockedTargets($id: Int!) {
    user(id: $id) {
      id
      nickname
      blockedBy(first: 50) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            nickname
          }
        }
      }
      blockedTargets(first: 50) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            nickname
          }
        }
      }
    }
  }
`;

export const userStats = gql`
  query userStats($external_id: String!) {
    user(externalId: $external_id) {
      catches {
        totalCount
      }
      followers {
        totalCount
      }
      followedUsers {
        totalCount
      }
    }
  }
`;

export const userSupportActions = gql`
  query userSupportActions($external_id: String!) {
    user(externalId: $external_id) {
      userSupportActions(first: 10) {
        edges {
          node {
            id
            actor {
              externalId
              nickname
            }
            actionType
            reason
            createdAt
          }
        }
      }
    }
  }
`;

export const userNavionicsSubscription = gql`
  query userNavionicsSubscription($external_id: String!) {
    user(externalId: $external_id) {
      navionicsSubscription {
        expiresAt
        isActive
        activeProduct {
          name
          uuid
        }
        latestPurchase {
          createdAt
          purchaseType
          transactionId
        }
      }
    }
  }
`;
