import { gql } from 'graphql-tag';

export default gql`
  mutation DeleteRecordByExternalId($input: DeleteRecordByExternalIdMutationInput!) {
    deleteRecordByExternalId(input: $input) {
      userErrors {
        message
      }
    }
  }
`;
