/* $border-radius: 5px; */

.dropzone {
  background-color: #eaeaea;
  border: 2px dotted #ccc;
  border-radius: $border-radius;
  color: #666;
  cursor: pointer;
  margin-top: 0.5em;
  width: 100%;

  & p {
    margin: 1em 0;
    text-align: center;
  }
}

.results {
  padding: 0 1em 0.5em;
}

.preview {
  width: 100%;
}

.previewRow {
  align-items: center;
  display: flex;
  padding: 0.5em 1em;
  width: 100%;

  &:hover {
    background: rgba(white, 0.2);
  }

  & > * + * {
    margin-left: 0.5rem;
  }

  & .preview {
    max-width: 100px;
  }

  &:last-child {
    margin-left: auto;
  }
}

.name {
  font-weight: bold;
}

.details {
  font-size: 0.8rem;
  text-transform: uppercase;
}

.thumbnail {
  background-color: white;
  border: 1px solid grey;
  margin: 1em 0;
  padding: 5px;
  position: relative;

  & button {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

.warning {
  @apply bg-clownfish;
  border-radius: $border-radius;
  color: white;
  padding: 1em;
}
