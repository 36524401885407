import { destroy, get, postImg, put } from '../api/rutilus';
import { IComment } from '../components/Comment/Comment';
import BaseModel from './BaseModel';
import FishingWater, { IFishingWater } from './FishingWater';
import IPhoto from './Photo';
import User, { IUser } from './User';
import { IVideo } from './Video';

export interface IText {
  text: string;
  status: string;
  id: number;
}

export interface ITitle {
  body: string;
}

export interface ILocation {
  fishing_water: IFishingWater;
}

export interface IPost {
  readonly id: number;
  photos: IPhoto[];
  video?: IVideo;
  owner: IUser;
  text?: IText;
  type: string;
  comments_count: number;
  likes_count: number;
  images_count: number;
  external_id: string;
  liked: boolean;
  title: ITitle;
  comments: IComment[];
  location: ILocation;
  postable_type: string;
  postable_id: number;
}

const DEFAULT_PER_PAGE = 1;

class Post extends BaseModel {
  public static endpoint = '/posts';

  public static fromAttributes(attributes: IPost): Post {
    return new Post(attributes);
  }

  public static async addImage(params: any, endpoint: string): Promise<Response> {
    return postImg(endpoint, params);
  }

  public ['constructor']: typeof Post;

  protected attributes: IPost;

  constructor(attributes: IPost) {
    super();
    this.attributes = attributes;
    this.update = this.update.bind(this);
    this.destroy = this.destroy.bind(this);
    this.defineAttributeGetters(attributes);
  }

  public async update(params: { [key: string]: any }): Promise<void> {
    const { text, photos, published_at, ...rest } = params;
    if (text) {
      this.updateAttr('text', text);
    }
    if (photos) {
      this.updatePhotos(photos);
    }
    return new Promise((resolve, reject) => {
      put(this.endpoint, {
        ...rest,
        published_at: new Date(published_at),
      }).then((resp) => (resp.ok ? resolve() : reject()));
    });
  }

  public async updateAttr(attr: string, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      put(`${this.endpoint}/${attr}`, { [attr]: value }).then((resp) =>
        resp.ok ? resolve() : reject(),
      );
    });
  }

  public async deleteAttr(attr: string): Promise<void> {
    return new Promise((resolve, reject) => {
      destroy(`${this.endpoint}/${attr}`).then((resp) => (resp.ok ? resolve() : reject()));
    });
  }

  public updatePhotos = (photos: File[] | undefined) => {
    if (photos) {
      const data = new FormData();
      photos.forEach((photo: File) => data.append('images[]', photo));
      Post.addImage(data, `/posts/${this.id}/images`);
    }
  };

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get textBody(): string {
    return this.attributes.text ? this.attributes.text.text : '';
  }

  public get textStatus(): string {
    return this.attributes.text ? this.attributes.text.status : '';
  }

  public get liked(): string {
    return this.attributes.liked.toString();
  }

  public get location(): FishingWater | undefined {
    return this.attributes.location
      ? FishingWater.fromAttributes(this.attributes.location.fishing_water)
      : undefined;
  }

  public get owner(): User {
    return User.fromAttributes(this.attributes.owner);
  }

  public get video(): IVideo | Record<string, unknown> {
    return this.attributes.video || {};
  }

  public get photos(): IPhoto[] {
    return this.attributes.photos || [];
  }

  public get comments(): IComment[] {
    return this.attributes.comments;
  }

  public get title(): string | undefined {
    return this.attributes.title ? this.attributes.title.body : undefined;
  }

  public get identifier(): string {
    return this.attributes.id.toString();
  }

  public get status(): string {
    const statusArr: string[] = [];
    if (this.attributes.text) {
      statusArr.push(this.attributes.text.status);
    }
    if (this.attributes.video) {
      statusArr.push(this.attributes.video.status);
    }
    if (this.attributes.photos) {
      this.attributes.photos.map((p) => statusArr.push(p.status));
    }

    if (statusArr.length === 0) {
      return '';
    }

    // eslint-disable-next-line fp/no-loops
    for (const status of statusArr) {
      if (status === 'rejected') {
        return 'disapproved';
      }
      if (status !== 'approved') {
        return 'pending';
      }
    }
    return 'approved';
  }

  public static get modelType(): string {
    return 'Post';
  }

  public get modelType(): string {
    return 'Post';
  }

  public get defaultPerPage(): number {
    return DEFAULT_PER_PAGE;
  }

  public get postable_type(): string {
    if (this.attributes.postable_type === 'Op::NamedScope') {
      return 'Feed Post';
    }
    return this.attributes.postable_type;
  }

  public async fetchComments(page?: number, perPage?: number): Promise<IComment[]> {
    return get(
      `${this.endpoint}/comments?page=${page || 1}&per_page=${perPage || this.defaultPerPage}`,
    ).then(async (response) => (response.ok ? response.json() : alert(response)));
  }

  protected get class(): typeof Post {
    return Post;
  }
}

export default Post;
