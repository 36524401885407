import { get } from '../api/rutilus';
import BaseModel, { IListOptions } from './BaseModel';

import { mergeSearchParams } from '../helpers/urlhelpers';

interface IPhoto {
  photo: {
    base_url: string;
  };
}

export interface ITag {
  readonly id: number;
  label: string;
  count: number;
}

export interface ITagDetails {
  count: number;
  items: ITagDetailsItem[];
}

export interface ITagDetailsItem {
  type: string;
  liked: boolean;
  item: {
    created_at?: string;
    external_id?: string;
    comments_count?: number;
    likes_count?: number;
    owner?: {
      first_name?: string;
      last_name?: string;
      nickname?: string;
      id?: number;
    };
    photos: IPhoto[];
  };
}

class Tag extends BaseModel {
  public static endpoint = '/tags';

  public static fromAttributes(attributes: ITag): Tag {
    return new Tag(attributes);
  }

  public static async list(
    { page, perPage, searchQuery }: IListOptions = {
      page: 0,
      perPage: 10,
      filterBy: {},
    },
  ): Promise<unknown> {
    // eslint-disable-next-line no-console
    return new Promise(async (resolve, reject = console.error) => {
      const filterParams = searchQuery ? { s: searchQuery } : { s: '*' };
      const params = {
        page,
        per_page: perPage,
        ...(searchQuery ? { s: searchQuery } : {}),
        ...filterParams,
      };
      const searchParams = mergeSearchParams(this.fetchParameters, params);

      return get(`${this.endpoint}?${searchParams.toString()}`)
        .then(async (resp) => (resp.ok ? resp.json() : reject(resp)))
        .then((json) => json && json.map((obj: any) => this.fromAttributes(obj)))
        .then(resolve, reject);
    });
  }

  public static async fetchTagDetails(
    tagParams: any,
    page: number,
    perPage: number,
  ): Promise<ITagDetails> {
    return get(
      `${this.endpoint}/${tagParams && tagParams.tag}/items?page=${page}&per_page=${perPage}`,
    )
      .then(async (response) => (response.ok ? response.json() : alert(response)))
      .then((jsonResponse: ITagDetails) => jsonResponse);
  }

  public ['constructor']: typeof Tag;

  protected attributes: ITag;

  constructor(attributes: ITag) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get identifier(): string {
    return this.attributes.id.toString();
  }

  public static get modelType(): string {
    return 'Tag';
  }

  public get modelType(): string {
    return 'Tag';
  }

  public get defaultEndpoint(): string {
    return '/tags';
  }

  protected get class(): typeof Tag {
    return Tag;
  }
}

export default Tag;
