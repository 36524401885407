/* The below styling follows the same logic as outlined here in Figma: https://www.figma.com/file/YEMI2L877peSAz2SnBZhVo/Web---Components?node-id=2335%3A5141 */
.button {
  background: var(--button-background);
  color: rgb(var(--button-text));
}

.button:active:not(:disabled) {
  background: linear-gradient(rgba(var(--button-text), 0.30), rgba(var(--button-text), 0.30)),
     linear-gradient(var(--button-background), var(--button-background)) !important; 
}

.button:hover:not(:disabled) {
  background: linear-gradient(rgba(var(--button-text), 0.15), rgba(var(--button-text), 0.15)),
    linear-gradient(var(--button-background), var(--button-background));
}


.button:focus {
  @apply outline-none;
}

.button:not(:active):focus {
  outline-color: rgba(var(--button-text), 0.4);
  outline-width: 2px;
  outline-offset: -4px;
}

/* The below CSS variable values are split up RGB values so we can insert them into rgba functions
  with a fixed opacity. I don't love having it here but the alternative was to hardcode basically
  all the possible color values with opacity in the Tailwind configuration which in my opinion would
  cause more problems in the long run.
*/
.primary,
.primaryVariant,
.danger {
  /* i.e. white */
  --button-text: 255, 255, 255;
}

.secondary,
.secondaryVariant,
.tertiary {
  /* i.e. Deep Sea */
  --button-text: 12, 49, 84;
}

.tertiaryVariant {
  /* i.e Pinktail Shade */
  --button-text: 193, 46, 74;
}

.pro {
  /* i.e. Trench */
  --button-text: 6, 29, 51;
}

.primary {
  --button-background: theme('colors.fishbrain-blue');
}

.primaryVariant {
  --button-background: theme('colors.pinktail.DEFAULT');
}

.secondary {
  --button-background: theme('colors.twilight');
}

.secondaryVariant {
  --button-background: theme('colors.sea-foam');
}

.tertiary,
.tertiaryVariant {
  --button-background: theme('colors.transparent');
}

.pro {
  --button-background: theme('colors.pike.DEFAULT');
}

.danger {
  --button-background: theme('colors.error.DEFAULT');
}
