import { gql } from 'graphql-tag';

export default gql`
  query ListBrands($cursor: String, $filters: BrandFilters) {
    brands(first: 20, after: $cursor, filters: $filters) {
      edges {
        node {
          externalId
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
