const attributeMissingError = (message: string): { message: string } => ({ message });

abstract class GqlBaseModel {
  public static readOnly: string[] = ['id', 'externalId', 'createdAt', 'updatedAt'];

  public static endpoint = '/';

  public static modelType: string;

  public abstract ['constructor']: any;

  protected abstract attributes: any;

  readonly [dynamicAttributeName: string]: any;

  protected constructor() {
    this.defineAttributeGetters = this.defineAttributeGetters.bind(this);
    this.attr = this.attr.bind(this);
  }

  public attr(name: string): any {
    if (this.attributes[name] === undefined) {
      throw attributeMissingError(`Missing attribute '${name}'`);
    }
    return this.attributes[name];
  }

  public isReadOnly(attribute: string): boolean {
    return this.class.readOnly.includes(attribute);
  }

  protected defineAttributeGetters(attributes: any = {}): void {
    Object.keys(attributes).forEach((key: string) => {
      if (this[key]) {
        return;
      }
      Object.defineProperty(this, key, {
        get: () => this.attr(key),
      });
    });
  }

  public get created_at(): Date {
    return this.dateField('createdAt');
  }

  protected dateField(attribute: string): Date {
    return new Date(this.attr(attribute));
  }

  public abstract get modelType(): string;

  public get isNew(): boolean {
    return this.attributes.id === undefined && this.attributes.externalId === undefined;
  }

  protected get class(): any {
    return GqlBaseModel;
  }
}

export default GqlBaseModel;
