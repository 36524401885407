import { FormikProps } from 'formik';
import React, { useState } from 'react';

import BaseModel from '../../models/BaseModel';
import styles from '../Edit/Form.module.css';
import IFormHeaders from '../Edit/FormHeaderInterface';
import FileDropzone from '../FileDropzone/FileDropzone';
import SimpleDropzone from '../FileDropzone/SimpleDropzone';
import Button from '../Clickables/Buttons/Button';

export interface Props extends FormikProps<any> {
  header: IFormHeaders;
  entry: BaseModel;
  name: string;
  value?: { url: string; width?: number };
  destroy?: () => void;
  directUpload?: boolean;
}

const GraphqlDropzoneImage = ({ directUpload = true, ...props }: Props) => {
  const canDelete = props.destroy !== undefined;
  const [hasImage, setHasImage] = useState(props.value !== undefined && props.value.url !== '');

  const removeImage = () => {
    if (props.destroy) {
      props.destroy();
      setHasImage(false);
    }
  };

  return (
    <div className={styles.list_row}>
      <h4>{props.header.title}</h4>
      {canDelete && hasImage && (
        <Button size="sm" variant="buttonDanger" onClick={removeImage}>
          Remove
        </Button>
      )}

      {!hasImage &&
        (directUpload ? (
          <SimpleDropzone
            type="image"
            multiple={false}
            directUpload={directUpload}
            name={props.name}
            setFieldValue={props.setFieldValue}
            setFieldTouched={props.setFieldTouched}
          />
        ) : (
          <FileDropzone type="image" multiple={false} {...props} />
        ))}

      <figure className={styles.media}>
        {hasImage && props.value && (
          <img
            src={props.value.url}
            style={{ width: `${props.value.width}px`, maxWidth: `${props.value.width}px` }}
            alt={props.header.title}
          />
        )}
      </figure>
    </div>
  );
};

export default GraphqlDropzoneImage;
