const STAGING_CLIENT_ID = '7hpia1sr6mbirkd6m474ol6opm';
const PRODUCTION_CLIENT_ID = '6lk4l1gl39ti6kid3f3fqlj0ob';

const STAGING_DOMAIN = 'https://accounts.staging.fishbrain.com';

const PRODUCTION_DOMAIN = 'https://accounts.fishbrain.com';

export const COGNITO_DOMAIN =
  process.env.REACT_APP_ENV === 'production' ? PRODUCTION_DOMAIN : STAGING_DOMAIN;

export const COGNITO_CLIENT_ID =
  process.env.REACT_APP_ENV === 'production' ? PRODUCTION_CLIENT_ID : STAGING_CLIENT_ID;

export const getCognitoCallBackURL = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://ornatus.fishbrain.com';
    case 'staging':
      return 'https://ornatus.staging.fishbrain.com';
    default:
      return 'http://localhost:3000';
  }
};

export const COGNITO_LOGIN_URL = `${COGNITO_DOMAIN}/login?response_type=code&client_id=${COGNITO_CLIENT_ID}&redirect_uri=${getCognitoCallBackURL()}`;
