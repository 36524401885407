import { gql } from 'graphql-tag';

export const setUrlStatus = gql`
  mutation setUrlStatus($input: EditURLMutationInput!) {
    editUrl(input: $input) {
      url {
        status
        __typename
      }
      userErrors {
        path
        message
      }
    }
  }
`;
