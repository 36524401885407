import { gql } from 'graphql-tag';

export default gql`
  mutation CreateFishingWater($input: CreateFishingWaterMutationInput!) {
    createFishingWater(input: $input) {
      fishingWater {
        id
        externalId
      }
      userErrors {
        path
        message
        code
      }
    }
  }
`;
