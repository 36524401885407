import { gql } from 'graphql-tag';

export const setImageStatusAction = gql`
  mutation editImageMutation($input: EditImageMutationInput!) {
    editImage(input: $input) {
      image {
        status
      }
      userErrors {
        path
        message
      }
    }
  }
`;
