import { useMutation } from '@apollo/client';

import { verifyUserEmail as mutation } from '../../mutations/User';
import { VerifyUserEmail, VerifyUserEmailVariables } from '../../interfaces/graphql';

import User from '../../models/User';
import Button from '../../components/Clickables/Buttons/Button';

const VerifyUserEmailButton = ({ entry }: { entry: User }): JSX.Element => {
  const [triggerEmailVerification, { loading, data }] = useMutation<
    VerifyUserEmail,
    VerifyUserEmailVariables
  >(mutation, {
    onCompleted: (response) => {
      const { userErrors } = response.verifyUserEmail || { userErrors: [] };
      if (userErrors.length === 0) {
        alert('Email has been verified');
      } else {
        alert(userErrors[0].message);
      }
    },
  });

  const verifyEmail = () => {
    triggerEmailVerification({
      variables: { input: { attributes: { externalId: entry.external_id } } },
    });
  };

  const errors = data?.verifyUserEmail?.userErrors;

  return (
    <Button
      variant="buttonSecondary"
      disabled={loading || entry.is_verified || (errors && errors?.length > 0)}
      onClick={verifyEmail}
      size="sm"
    >
      {loading ? 'loading...' : 'Verify Email'}
    </Button>
  );
};

export default VerifyUserEmailButton;
