.filterCell {
  padding: 0 !important;
}

input.input {
  margin-bottom: 0;
}

.search {
  padding: 16px 12px;
}
