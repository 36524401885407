.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.footer {
  align-items: center;
  background: rgba(black, 0.05);
  display: flex;
  height: var(--footer-height);
  padding: 10px var(--padding-base);
}

.header a {
  align-items: center;
  color: white;
  display: flex;
  font-size: 1.1rem;
  font-weight: 500;
}

.routes {
  height: calc(100% - var(--header-height) - var(--footer-height));
  overflow: hidden scroll;
}

.logo {
  border-radius: 4px;
  height: 32px;
  margin-right: 11px;
  vertical-align: middle;
}

.main {
  width: calc(100% - var(--sidebar-width));
}

.sidebar {
  background: #2e3f58;
  color: #fff;
  height: 100%;
  width: var(--sidebar-width);
}

.sidebar a {
  color: #fff;
  text-decoration: none;

  &:hover {
    color: darken(#fff, 20%);
    text-decoration: none;
  }
}
