import { gql } from 'graphql-tag';

export default gql`
  mutation CreateBrand($input: CreateBrandMutationInput!) {
    createBrand(input: $input) {
      brand {
        id
        externalId
        name
        __typename
      }
      userErrors {
        path
        message
        code
      }
    }
  }
`;
