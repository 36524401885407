import React, { useEffect, useState } from 'react';

import BaseModel from '../../../models/BaseModel';
import Comment, { IComment } from '../../Comment/Comment';
import IFormHeaders from '../FormHeaderInterface';

import styles from '../Form.module.css';
import Button from '../../Clickables/Buttons/Button';

interface IProps {
  header: IFormHeaders;
  entry: BaseModel;
}

const FormComments = ({ entry, header }: IProps): JSX.Element => {
  const [comments, setComments] = useState<IComment[]>([] as IComment[]);
  const [page, setPage] = useState(1);
  const [commentsCount, setCommentsCount] = useState(0);

  useEffect(() => {
    entry.fetchComments(page).then((newComments: IComment[]) => {
      setComments(newComments);
      setCommentsCount(newComments.length);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaging = (): void => {
    const newPage = page + 1;
    entry.fetchComments(newPage).then((newComments: IComment[]) => {
      setComments(comments.concat(newComments));
      setPage(newPage);
      setCommentsCount(newComments.length);
    });
  };

  const commentsDisplay = (renderComments: IComment[]): JSX.Element => (
    <>
      <div className={styles.commentsContainer}>
        {renderComments.map((c: IComment) => (
          <Comment key={c.id} attr={c} />
        ))}
      </div>
      {commentsCount === entry.defaultPerPage && (
        <div className={styles.btnWrapper}>
          <Button variant="buttonSecondary" onClick={handlePaging}>
            <span>Load more</span>
            <i className="fas fa-angle-down" />
          </Button>
        </div>
      )}
    </>
  );

  return (
    <li className={styles.list_row}>
      <h2>{header.title}</h2>
      {comments && comments.length > 0 ? commentsDisplay(comments) : <p>No comments.</p>}
    </li>
  );
};

export default FormComments;
