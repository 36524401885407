import { gql } from 'graphql-tag';

export const reverifyUser = gql`
  mutation reverifyUser($input: MarkUserForReverificationMutationInput!) {
    reverifyUser(input: $input) {
      user {
        isVerified
      }
      userErrors {
        message
      }
    }
  }
`;

export const SetUserBanStatus = gql`
  mutation SetUserBanStatus($input: SetUserBanStatusMutationInput!) {
    setUserBanStatus(input: $input) {
      user {
        externalId
      }
      userErrors {
        message
      }
    }
  }
`;

export const verifyUserEmail = gql`
  mutation VerifyUserEmail($input: VerifyUserEmailMutationInput!) {
    verifyUserEmail(input: $input) {
      user {
        isVerified
        externalId
      }
      userErrors {
        message
      }
    }
  }
`;

export const assignAdminRoleToUser = gql`
  mutation AssignAdminRoleToUser($input: AssignAdminRoleToUserMutationInput!) {
    assignAdminRoleToUser(input: $input) {
      user {
        isAdmin
      }
      userErrors {
        message
      }
    }
  }
`;

export const unassignAdminRoleToUser = gql`
  mutation UnassignAdminRoleToUser($input: UnassignAdminRoleToUserMutationInput!) {
    unassignAdminRoleToUser(input: $input) {
      user {
        isAdmin
      }
      userErrors {
        message
      }
    }
  }
`;
