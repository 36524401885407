import { IVideo, IVideoSize } from '../../models/Video';

interface IProps {
  video: IVideo;
  className?: string;
}

const component = ({ video, className = '' }: IProps): React.ReactElement | null => {
  const vidSizeWeb = video.video_sizes.find((v) => v.name === 'web') as IVideoSize;
  const vidSizeOg = video.video_sizes.find((v) => v.name === 'original') as IVideoSize;

  return (
    <video controls className={className}>
      {vidSizeWeb && <source src={vidSizeWeb.url} key={vidSizeWeb.url} />}
      {vidSizeOg && <source src={vidSizeOg.url} key={vidSizeOg.url} />}
    </video>
  );
};

export default component;
