import {
  FindAdvertSearchTaxons_categories,
  FindAdvertSearchBrands_brands,
  FindPromoPages_promoPages,
} from '../../interfaces/graphql';

export interface IProps {
  component: any;
  entry: any;
  value: any;
  link?: string | ((entry: any) => string);
  search?: string;
  lookups?: {
    categories?: FindAdvertSearchTaxons_categories;
    brands?: FindAdvertSearchBrands_brands;
    promoPages?: FindPromoPages_promoPages;
  };
}

const AttributeComponent: React.FC<IProps> = ({ component: Component, ...rest }) => (
  <Component {...rest} />
);

export default AttributeComponent;
