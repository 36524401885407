import { get } from '../api/rutilus';
import { mergeSearchParams } from '../helpers/urlhelpers';
import BaseModel, { IListOptions } from './BaseModel';
import { IUser } from './User';

interface IUserComment {
  readonly id: number;
  owner: IUser;
  caught_at_gmt: string;
  deep_link: string;
}

class UserComment extends BaseModel {
  public static endpoint = '/catch_comments';

  public static modelType = 'User catches';

  public static fromAttributes(attributes: IUserComment): UserComment {
    return new UserComment(attributes);
  }

  public static async list(
    { page, perPage, searchQuery, urlParams }: IListOptions = {
      page: 0,
      perPage: 10,
      filterBy: {},
    },
  ): Promise<unknown> {
    // eslint-disable-next-line no-console
    return new Promise(async (resolve, reject = console.error) => {
      const authorId = urlParams ? urlParams.id : '';
      const params = {
        page,
        author_id_eq: urlParams ? urlParams.id : '',
        per_page: perPage,
        ...(searchQuery ? { s: searchQuery } : {}),
      };
      const searchParams = mergeSearchParams(this.fetchParameters, params);

      return get(`${this.endpoint}?${searchParams.toString()}&q[author_id_eq]=${authorId}`)
        .then(async (resp) => (resp.ok ? resp.json() : reject(resp)))
        .then((json) => json && json.map((obj: any) => this.fromAttributes(obj)))
        .then(resolve, reject);
    });
  }

  public static get readOnly(): string[] {
    return BaseModel.readOnly.concat(
      'reason',
      'status',
      'resolved_at',
      'subject',
      'subject_type',
      'subject_id',
    );
  }

  protected static readonly DEFAULT_OPTIONS = {};

  public modelType = 'User catch';

  public ['constructor']: typeof UserComment;

  protected attributes: IUserComment;

  constructor(attributes: IUserComment) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get identifier(): string {
    return this.attr('id');
  }

  public get endpoint(): string {
    return `${this.class.endpoint}`;
  }

  protected get class(): typeof UserComment {
    return UserComment;
  }
}

export default UserComment;
