import { Link, useHistory, useLocation } from 'react-router-dom';

import { useState } from 'react';
import queryString from 'query-string';

import Fieldset from '../Fieldset/Fieldset';
import Flex from '../Flex/Flex';
import IHeader from './HeadersInterface';

import styles from './ListFilter.module.css';

interface IProps {
  headers: IHeader[];
}

const ListSearch = ({ headers }: IProps): JSX.Element => {
  const { search, pathname } = useLocation();
  const { searchQuery } = queryString.parse(search);

  const history = useHistory();

  const [searchQueryValue, setSearchQueryValue] = useState((searchQuery as string) || '');

  const clear = () => {
    setSearchQueryValue('');
    history.push({ pathname, search: '' });
  };

  const handleSearchChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
    setSearchQueryValue(event.currentTarget.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      history.push({
        pathname,
        search: getSearchQuery().toString(),
      });
    }
  };

  const getSearchQuery = (): URLSearchParams => {
    const query = new URLSearchParams(search);
    if (searchQueryValue && searchQueryValue.length) {
      query.set('searchQuery', searchQueryValue);
    } else {
      query.delete('searchQuery');
    }
    return query;
  };

  return (
    <tr>
      <th colSpan={headers.length} className={styles.filterCell}>
        <Flex>
          <Fieldset
            label="Search"
            inputProps={{
              value: searchQueryValue,
              onChange: handleSearchChange,
              onKeyPress: handleKeyPress,
              placeholder: 'Enter search value, hit enter to search...',
              style: { marginBottom: 0 },
            }}
            onClear={clear}
          />
          <Link to={{ pathname, search: getSearchQuery().toString() }} className={styles.search}>
            Search
          </Link>
        </Flex>
      </th>
    </tr>
  );
};

export default ListSearch;
