import styles from './Input.module.css';

interface IProps {
  className?: string;
  value?: string | number;
  onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  type?: string;
  [restAttribute: string]: any;
}

const Input = ({ className = '', type = 'text', ...rest }: IProps): React.ReactElement => (
  <input type={type} className={`${styles.input} ${className}`} {...rest} />
);

export default Input;
