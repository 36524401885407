import GqlBaseModel from './GqlBaseModel';

export interface IUserGraph {
  readonly externalId: string;
  readonly nickname: string;
  readonly id: number;
}

class UserGraphql extends GqlBaseModel {
  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.externalId}`;
  }

  public static fromAttributes(attributes: IUserGraph): UserGraphql {
    return new UserGraphql(attributes);
  }

  public static get modelType(): string {
    return 'User';
  }

  public get modelType(): string {
    return 'User';
  }

  public static endpoint = '/users';

  public static embeddedImageAttribute = 'logo';

  public ['constructor']: typeof UserGraphql;

  protected attributes: IUserGraph;

  constructor(attributes: IUserGraph) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }
}

export default UserGraphql;
