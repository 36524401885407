.badge {
  background-color: #0097a7;
  border-radius: 16px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
}
