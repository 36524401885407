const DECIMALS = 2;

const AttributeCoords = ({ entry }: { entry: any }): JSX.Element => {
  if (entry.latitude && entry.longitude) {
    const lng = entry.longitude.toFixed(DECIMALS).toString();
    const lat = entry.latitude.toFixed(DECIMALS).toString();

    return (
      <>
        {lat}, {lng}
      </>
    );
  }

  return <></>;
};

export default AttributeCoords;
