import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Route, useRouteMatch, Link } from 'react-router-dom';

import { getPlaces } from '../../queries/Places';
import SidePane from '../../components/SidePane/SidePane';
import { GetPlaces, GetPlacesVariables } from '../../interfaces/graphql';
import { EditPlace } from './EditPlace';
import { BASE } from '../../api/mykiss';
import { usePaginatedQuery, useFilterParams } from '../../helpers/hooks';
import { getNodesFromConnection } from '../../helpers/graphql';
import { Table, ITableColumn } from '../../components/Tables/Table/Table';

const columns: ITableColumn[] = [
  { label: 'Name', id: 'name', filterable: true },
  { label: 'Has image?', id: 'hasImage' },
  { label: 'Has description?', id: 'hasDescription' },
  { label: 'Link', id: 'link' },
];

export const Places = (): JSX.Element => {
  const match = useRouteMatch();

  const { filterValues } = useFilterParams(columns);
  const variables =
    filterValues.name && filterValues.name.length
      ? { first: 10, filters: { containingName: filterValues.name } }
      : undefined;

  const { data, fetchNextPage } = usePaginatedQuery<GetPlaces, GetPlacesVariables>(getPlaces, {
    variables,
  });
  const places = getNodesFromConnection(data?.places);

  return (
    <>
      <Table
        fetchNextPage={fetchNextPage}
        columns={columns}
        rows={places.map((place) => (
          <TableRow key={place.id}>
            <TableCell>{place.name}</TableCell>
            <TableCell>{place.unsplashImage ? 'Yes' : 'No'}</TableCell>
            <TableCell>{place.description ? 'Yes' : 'No'}</TableCell>
            <TableCell>
              <a
                href={`https://${BASE}/cities/${place.country.id}/${place.region.regionCode}/${place.slug}`.toLowerCase()}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`https://${BASE}/cities/${place.country.id}/${place.region.regionCode}/${place.slug}`.toLowerCase()}
              </a>
            </TableCell>
            <TableCell padding="none">
              <Link to={`/places/${place.country.id}/${place.region.regionCode}/${place.slug}`}>
                Edit
              </Link>
            </TableCell>
          </TableRow>
        ))}
      />
      <SidePane showOnPath={`${match.path}/:slugs`}>
        <Route path={`${match.path}/:countryCode/:regionCode/:slug`} component={EditPlace} />
      </SidePane>
    </>
  );
};
