import { Link, useLocation } from 'react-router-dom';

import { IRoute, ROUTES } from '../Routes/Routes';

import styles from './CurrentViewTitle.module.css';

type Props = {
  separator?: string;
  routes?: IRoute[];
};

const CurrentViewTitle = ({ separator, routes = ROUTES }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const route: IRoute | undefined = routes.find((r) =>
    r.path ? pathname.includes(r.path) : false,
  );
  if (!route) {
    return <></>;
  }

  const { path, title } = route;

  return (
    <>
      {separator ? <span className={styles.separator}>{separator}</span> : ''}
      {path ? (
        <Link to={path} className={styles.link}>
          {title}
        </Link>
      ) : (
        title
      )}
      {route.routes ? <CurrentViewTitle routes={route.routes} separator=" , " /> : <></>}
    </>
  );
};

export default CurrentViewTitle;
