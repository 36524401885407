import { IImage } from '../../../models/Image';

interface IProps {
  value: IImage;
  className?: string;
}

const CoverImage = ({ value, className }: IProps) => {
  const image = value.sizes.find((s) => s.geometry === '320');
  return image ? (
    <div>
      <h3>Cover image</h3>
      <img src={image.url} className={className || ''} alt="Coverimage" />
    </div>
  ) : (
    <></>
  );
};

export default CoverImage;
