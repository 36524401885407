import { FieldProps, FormikProps } from 'formik';

import { ApolloError, useMutation } from '@apollo/client';

import Button from '../../../components/Clickables/Buttons/Button';
import {
  AdministerUserSuggestedChange,
  AdministerUserSuggestedChangeVariables,
  UserSuggestedChangeStatusTypes,
} from '../../../interfaces/graphql';
import { administerUserSuggestedChange } from '../../../mutations/AdministerUserSuggestedChange';

const AcceptRejectButton = ({
  values,
}: FieldProps &
  FormikProps<{
    externalId: string;
    suggestedName: string;
    suggestedLongitude: number | null;
    suggestedLatitude: number | null;
    suggestedMetadataId: string | null;
    suggestedUrl: string | null;
    rejectionReason: string | null;
    fishingWaterId: string | null;
    newFishingWaterId: string | null;
    status: string;
  }>): JSX.Element => {
  const {
    externalId,
    suggestedName,
    suggestedLongitude,
    suggestedLatitude,
    suggestedMetadataId,
    suggestedUrl,
    rejectionReason,
    fishingWaterId,
    newFishingWaterId,
    status,
  } = values;

  const attributes = {
    ...(suggestedMetadataId && { fishingWaterMetadataExternalId: suggestedMetadataId }),
    ...(newFishingWaterId && { fishingWaterExternalId: newFishingWaterId }),
    ...(suggestedName && { newWaterName: suggestedName }),
    ...(suggestedLatitude && { latitude: suggestedLatitude }),
    ...(suggestedLongitude && { longitude: suggestedLongitude }),
    ...(suggestedUrl && { url: suggestedUrl }),
  };

  const [updateSuggestion, { loading }] = useMutation<
    AdministerUserSuggestedChange,
    AdministerUserSuggestedChangeVariables
  >(administerUserSuggestedChange, {
    onCompleted: (response) => {
      const { userErrors } = response.administerUserSuggestedChange || { userErrors: [] };
      if (userErrors.length === 0) {
        window.location.reload();
      } else {
        alert(userErrors[0].message);
      }
    },
    onError: (err: ApolloError) => alert(err.message),
  });

  const handleAccept = (): void => {
    const variables = {
      input: {
        externalId,
        attributes: { status: UserSuggestedChangeStatusTypes.accepted, ...attributes },
      },
    };
    updateSuggestion({ variables });
  };

  const handleReject = (): void => {
    if (!rejectionReason) {
      alert('please add a rejection reason');
    } else {
      const variables = {
        input: {
          externalId,
          attributes: {
            status: UserSuggestedChangeStatusTypes.rejected,
            rejectionReason,
            ...(newFishingWaterId && { fishingWaterExternalId: newFishingWaterId }),
          },
        },
      };
      updateSuggestion({ variables });
    }
  };

  const hasFishingWaterId = fishingWaterId || newFishingWaterId;

  return (
    <>
      {!hasFishingWaterId && (
        <div className=" font-semibold inline-flex p-4 w-full text-error">
          This suggestion has no fishing water linked to it. Either it is a suggestion for a new
          fishing water or the linked fishing water has been deleted.
        </div>
      )}
      {status === 'submitted' && (
        <div className="grid gap-2 grid-cols-2 grid-rows-2">
          <Button disabled={!hasFishingWaterId || loading} onClick={handleAccept}>
            Accept
          </Button>
          <Button disabled={loading} onClick={handleReject} variant="buttonDanger">
            Reject
          </Button>
        </div>
      )}
      {status === 'accepted' && (
        <div className="h-12 bg-fishbrain-blue font-semibold inline-flex justify-center items-center w-full text-white">
          Accepted
        </div>
      )}
      {status === 'rejected' && (
        <div className="h-12 bg-error font-semibold inline-flex justify-center items-center w-full text-white">
          Rejected
        </div>
      )}
    </>
  );
};

export default AcceptRejectButton;
