import { Field, FieldProps } from 'formik';

import Fieldset from '../../Fieldset/Fieldset';
import Select, { Option } from '../../Input/Select';
import { FormProps } from './FormAttribute';

const FormSelect = (options: Option[]) => {
  const inner = ({ header, field }: FieldProps & FormProps) => (
    <Fieldset label={header.title}>
      <Select {...field} name={header.attribute} id="form-select" options={options} />
      <label htmlFor="form-select">{header.title}</label>
    </Fieldset>
  );
  // eslint-disable-next-line react/display-name
  return (props: FormProps) => <Field component={inner} {...props} />;
};

export default FormSelect;
