import { useHistory } from 'react-router-dom';

import { useState } from 'react';

import Details from '../Details/Details';
import TextBox from '../TextBox/TextBox';
import BaseModel, { IBaseType } from '../../models/BaseModel';
import EditForm from './EditForm';
import IFormHeaders from './FormHeaderInterface';

interface IProps {
  model: BaseModel;
  headers: IFormHeaders[];
  returnPath: string;
  validationSchema: any;
}

const New = ({ model, headers, returnPath, validationSchema = false }: IProps): JSX.Element => {
  const [status, setStatus] = useState<string>();
  const history = useHistory();

  const entry = model.fromAttributes({});

  const createEntry = (params: { [key: string]: string | boolean }): void => {
    if (model) {
      model
        .create(params)
        .then((resp: IBaseType) => {
          setStatus(`${model.modelType} ${resp.id} has been created. `);
          history.push(
            {
              pathname: `${returnPath}/${resp.id}`,
            },
            {
              status: `${model.modelType} #${resp.id} has been created`,
            },
          );
        })
        .catch((error: string) => {
          setStatus(`Failed: ${error}`);
        });
    }
  };

  return (
    <Details title={`Add ${model.modelType}`}>
      {status && <TextBox>{status}</TextBox>}
      <EditForm
        entry={entry}
        createEntry={createEntry}
        headers={headers}
        validationSchema={validationSchema}
      />
    </Details>
  );
};

export default New;
