import { gql } from 'graphql-tag';

export default gql`
  query ListHosts($cursor: String, $status: [ModerationStatusEnum!], $hostname: String) {
    entries: urlHosts(last: 20, before: $cursor, status: $status, hostname: $hostname) {
      edges {
        node {
          id
          hostname
          status
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;
