import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import createCuratedSpeciesCatch from '../../../mutations/CreateCuratedSpeciesCatch';
import {
  CreateCuratedSpeciesCatch,
  CreateCuratedSpeciesCatchVariables,
} from '../../../interfaces/graphql';
import Button from '../../../components/Clickables/Buttons/Button';

interface IProps {
  entry: any;
}

export const CuratedCatchButton = ({ entry }: IProps): JSX.Element => {
  const [isSaving, setIsSaving] = useState(false);
  const [created, setCreated] = useState(false);
  const [setCuratedCatch] = useMutation<
    CreateCuratedSpeciesCatch,
    CreateCuratedSpeciesCatchVariables
  >(createCuratedSpeciesCatch);

  const { species } = entry;

  if (!species) {
    return <></>;
  }

  const speciesId = species.external_id;
  const catchId = entry.external_id;
  const isPrivate = entry.attributes.private_fishing_water || entry.attributes.private_position;

  const onClick = () => {
    setIsSaving(true);
    setCuratedCatch({
      variables: {
        input: {
          attributes: { catchExternalId: catchId, speciesExternalId: speciesId },
        },
      },
    })
      .then(() => {
        setIsSaving(false);
        setCreated(true);
      })
      .catch((error) => {
        alert(error);
        setIsSaving(false);
      });
  };

  return (
    <Button
      disabled={!speciesId || created || isSaving || isPrivate}
      className="my-2"
      onClick={onClick}
    >
      {isPrivate && 'This catch location or water is private, it cannot be set to a curated catch'}
      {!isPrivate && created && `This is a curated catch for ${entry.species.name}`}
      {!isPrivate && !created && `Set as curated catch for ${entry.species.name}`}
    </Button>
  );
};
