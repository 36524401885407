import { gql } from 'graphql-tag';

export const getCouponGroups = gql`
  query GetCouponGroups($cursor: String) {
    couponGroups(first: 20, after: $cursor) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          type
          usage
          name
          percentOff
          durationInMonths
          couponExternalId
          expiresAt
          stripePlan
          code
        }
      }
    }
  }
`;

export const getCouponGroup = gql`
  query GetCouponGroup($id: Int!) {
    entry: couponGroup(id: $id) {
      id
      type
      usage
      name
      percentOff
      durationInMonths
      couponExternalId
      expiresAt
      stripePlan
      code
    }
  }
`;
