.section {
  padding: 1rem;
}

.sectionBlurred {
  filter: blur(8px);
}

.grid {
  display: grid;
  grid-auto-flow: row dense;
  grid-auto-rows: 150px;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 20px;
}

.item {
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  grid-column-start: auto;
  grid-row-end: span 2;
  grid-row-start: auto;
  justify-content: flex-end;
  position: relative;
}

.item:hover {
  transform: scale(1.05);
}

.itemDetails {
  background: #fff;
  color: #444;
  letter-spacing: 1px;
  padding: 15px 0;
  position: relative;
  z-index: 1;
}

.label {
  color: #000;
  font-weight: 700;
}

.image {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 400px;
}

.loadMoreContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.headerContainer {
  margin-bottom: 20px;
  width: 100%;

  & thead {
    & tr {
      display: grid;
      grid-auto-flow: row dense;
      grid-auto-rows: 50px;
      grid-gap: 5px;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }
}
