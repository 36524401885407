import { gql } from 'graphql-tag';

export const getSharedStatus = gql`
  query PostSharedStatus($externalId: String!) {
    post(externalId: $externalId) {
      id
      sharingPost
      sharedPost {
        externalId
        user {
          externalId
          displayName
        }
      }
      sharedIn {
        totalCount
      }
    }
  }
`;
