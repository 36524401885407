import { FieldProps, FormikProps } from 'formik';
import { Feature, Layer, Marker } from 'react-mapbox-gl';

import BOWMarker from '../assets/BOW-marker.png';

import Map from '../../../components/Map/Map';

const maxLongitude = 180;
const maxLatitude = 90;
const zoomLevel = 14;

const DetailsMap = ({
  values,
  setFieldValue,
  setFieldTouched,
}: FieldProps &
  FormikProps<{
    longitude: number | string;
    suggestedLongitude: number | string;
    latitude: number | string;
    suggestedLatitude: number | string;
    name: string;
    water_type: number;
  }>): JSX.Element => {
  const save = (_map: any, { lngLat }: { lngLat: { lat: number; lng: number } }): void => {
    const { lng, lat } = lngLat;
    setFieldTouched('suggestedLongitude', true);
    setFieldTouched('suggestedLatitude', true);
    setFieldValue('suggestedLongitude', lng);
    setFieldValue('suggestedLatitude', lat);
  };

  const { longitude, latitude, suggestedLatitude, suggestedLongitude } = values;

  const long =
    Number.isNaN(Number(longitude)) || Math.abs(Number(longitude)) > maxLongitude
      ? 0
      : Number(longitude);

  const lati =
    Number.isNaN(Number(latitude)) || Math.abs(Number(latitude)) > maxLatitude
      ? 0
      : Number(latitude);

  const suggLong =
    Number.isNaN(Number(suggestedLongitude)) || Math.abs(Number(suggestedLongitude)) > maxLongitude
      ? 0
      : Number(suggestedLongitude);

  const suggLati =
    Number.isNaN(Number(suggestedLatitude)) || Math.abs(Number(suggestedLatitude)) > maxLatitude
      ? 0
      : Number(suggestedLatitude);

  return (
    <Map
      containerStyle={{
        height: '300px',
        width: '100%',
      }}
      center={[suggLong !== 0 ? suggLong : long, suggLati !== 0 ? suggLati : lati]}
      onClick={save}
      zoom={[zoomLevel]}
    >
      <Marker coordinates={[long, lati]} anchor="center">
        <img src={BOWMarker} alt="marker" />
      </Marker>
      <Layer type="symbol" id="suggestedMarker" layout={{ 'icon-image': 'POI-bow-red' }}>
        <Feature coordinates={[suggLong, suggLati]} />
      </Layer>
    </Map>
  );
};

export default DetailsMap;
