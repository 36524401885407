import { Component } from 'react';

import CatchModel from '../../models/Catch';
import { ICatchPosition } from '../../models/CatchPosition';
import Loading from '../../components/Loading/Loading';
import Fieldset from '../../components/Fieldset/Fieldset';

import PositionMap from './PositionMap';
import Button from '../../components/Clickables/Buttons/Button';

interface IState {
  position: ICatchPosition | null;
  isLoading: boolean;
}

interface IProps {
  entry: CatchModel;
}

class FormCatchPosition extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { position: null, isLoading: false };
  }

  public componentDidMount(): void {
    this.props.entry.fetchCatchPosition().then((position: ICatchPosition) => {
      this.setState({ position });
    });
  }

  public render(): JSX.Element {
    const { position, isLoading } = this.state;

    return <Loading isLoading={!position || isLoading}>{this.renderPosition()}</Loading>;
  }

  private readonly clearPosition = (): void => {
    this.setState({ isLoading: true });
    this.props.entry
      .clearPosition()
      .then(() => {
        const position = {
          exact_position: { latitude: null, longitude: null },
          type: '',
        };

        this.setState({ position, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  private renderPosition(): JSX.Element {
    const { position } = this.state;

    if (!position || !position.exact_position) {
      return <>not set</>;
    }

    const {
      exact_position: { longitude, latitude },
    } = position;
    const isButtonDisabled = !latitude || !longitude;

    return (
      <>
        <div className="flex">
          <Fieldset label="Latitude" inputProps={{ value: latitude || 'N/A', readOnly: true }} />
          <Fieldset label="Longitude" inputProps={{ value: longitude || 'N/A', readOnly: true }} />
          <Button
            variant="buttonDanger"
            size="sm"
            onClick={this.clearPosition}
            disabled={isButtonDisabled}
          >
            Clear
          </Button>
        </div>
        {longitude && latitude && <PositionMap lng={longitude} lat={latitude} />}
      </>
    );
  }
}

export default FormCatchPosition;
