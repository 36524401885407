import { Link, useLocation } from 'react-router-dom';

import Catches from '../Catches/Catches';
import CatchMap from '../Catches/CatchMap';
import CouponGroups from '../CouponGroups/CouponGroups';
import Equipment from '../Equipment/Equipment';
import FishingWaters from '../FishingWaters/FishingWaters';
import Pages from '../Pages/Pages';
import Groups from '../Groups/Groups';
import Posts from '../Posts/Posts';
import { Places } from '../Places/Places';
import UrlList from '../Posts/UrlList';
import UrlHostList from '../Posts/HostList';
import Species from '../Species/SpeciesList';
import SubscriptionPlans from '../Subscriptions/Plans';
import Tags from '../Tags/Tags';
import UserReports from '../UserReports/UserReports';
import Users from '../Users/Users';
import PartnerPromotionLinks from '../PartnerPromotionLinks/PartnerPromotionLinks';

import styles from './Routes.module.css';

import catches from './icons/catches.svg';
import equipment from './icons/equipment.svg';
import hashtag from './icons/hashtag.png';
import posts from './icons/posts.svg';
import reports from './icons/reports.svg';
import species from './icons/species.svg';
import users from './icons/users.svg';
import fishingWaters from './icons/waters.svg';
import pages from './icons/pages.svg';
import place from './icons/place.svg';
import groups from './icons/groups.svg';
import subscriptions from './icons/subscriptions.svg';
import UserSuggestedChanges from '../UserSuggestedChanges/UserSuggestedChanges';

// Use `link` if you want to make the link point somewhere other than the path
export interface IRoute {
  component?: ComponentClass | React.FunctionComponent;
  icon?: string;
  link?: string;
  path?: string;
  routes?: IRoute[];
  title: string;
}

export const ROUTES: IRoute[] = [
  {
    component: Users,
    icon: users,
    path: '/users',
    title: 'Users',
  },
  {
    component: UserReports,
    icon: reports,
    path: '/user_reports',
    title: 'User reports',
  },
  {
    component: PartnerPromotionLinks,
    path: '/partner_promotion_links',
    title: 'PartnerPromotionLinks',
  },
  {
    component: Posts,
    icon: posts,
    path: '/posts',
    title: 'Posts',
    routes: [
      { path: '/post_urls', title: 'Shared Links', component: UrlList },
      { path: '/post_url_hosts', title: 'Link hosts', component: UrlHostList },
    ],
  },
  {
    component: Tags,
    icon: hashtag,
    path: '/tags',
    title: 'Tags',
  },
  {
    component: Catches,
    icon: catches,
    path: '/catches',
    title: 'Catches',
    routes: [
      {
        path: '/catch_map/:coordinates/:zoom?',
        link: '/catch_map/59.330,18.065',
        title: 'Map',
        component: CatchMap,
      },
    ],
  },
  {
    component: Species,
    icon: species,
    path: '/species',
    title: 'Species',
  },
  {
    component: FishingWaters,
    icon: fishingWaters,
    path: '/fishing_waters',
    title: 'Fishing waters',
  },
  {
    component: UserSuggestedChanges,
    icon: fishingWaters,
    path: '/user_suggested_changes',
    title: 'User suggested changes',
  },
  {
    component: Places,
    icon: place,
    path: '/places',
    title: 'Places',
  },
  {
    component: Pages,
    icon: pages,
    path: '/pages',
    title: 'Pages',
  },
  {
    component: Groups,
    path: '/groups',
    title: 'Groups',
    icon: groups,
  },
  {
    component: Equipment,
    icon: equipment,
    path: '/equipment',
    routes: [
      { path: '/equipment/brands', title: 'Brands' },
      { path: '/equipment/categories', title: 'Categories' },
      { path: '/equipment/reviews', title: 'Reviews' },
      { path: '/equipment/products', title: 'Products' },
    ],
    title: 'Equipment',
  },
  {
    component: SubscriptionPlans,
    icon: subscriptions,
    path: '/subscriptions',
    title: 'Subscription plans',
  },
  {
    component: CouponGroups,
    path: '/coupon-groups',
    title: 'Coupon Groups',
  },
];

function RouteTitle({ icon, title }: { icon?: string; title: string }) {
  return (
    <>
      {icon ? <img src={icon} className={styles.icon} alt={title} /> : null}
      <span>{title}</span>
    </>
  );
}

function RouteWrapper({ link, icon, path, routes, title }: IRoute) {
  const location = useLocation();
  return (
    //  eslint-disable-line react/no-unescaped-entities
    <div key={title} className={styles.route}>
      {path ? (
        <Link to={link || path} className={location.pathname.startsWith(path) ? styles.active : ''}>
          <RouteTitle icon={icon} title={title} />
        </Link>
      ) : (
        <RouteTitle icon={icon} title={title} />
      )}
      {routes && routes.map((routeProps) => <RouteWrapper key={routeProps.path} {...routeProps} />)}
    </div>
  );
}

function Routes() {
  return (
    <nav>
      {ROUTES.map((routeProps) => (
        <RouteWrapper key={routeProps.path} {...routeProps} />
      ))}
    </nav>
  );
}

export default Routes;
