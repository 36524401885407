import * as Yup from 'yup';

import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import { BASE as MYKISS_BASE } from '../../api/mykiss';
import Edit from '../../components/Edit/Edit';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import Flex from '../../components/Flex/Flex';
import IHeader from '../../components/List/HeadersInterface';
import ModelList from '../../components/ModelList/ModelList';
import SidePane from '../../components/SidePane/SidePane';
import Filter from '../../helpers/filter';
import { RansackMatcher } from '../../helpers/ransack';
import { compareLocalizations } from '../../helpers/comparators';
import Species, { getChangedLocalizations } from '../../models/Species';

import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import AttributeID from '../../components/AttributeComponents/AttributeID';
import AttributeImage from '../../components/AttributeComponents/AttributeImage';
import {
  AttributeWithTitle,
  FormDateCustom,
  FormText,
  FormNumber,
  FormTextarea,
} from '../../components/Edit/FormAttributes/FormAttribute';
import FormLocalizations from '../../components/Edit/FormAttributes/FormLocalizations';
import FormSelect from '../../components/Edit/FormAttributes/FormSelect';
import EmbeddedDropzoneImage from '../../components/Image/EmbeddedDropzoneImage';
import FormMultiSelect from '../../components/Edit/FormAttributes/FormMultiSelect';
import { SpeciesTips } from './SpeciesTips';
import { CreateSpeciesTip } from './CreateSpeciesTip';
import New from '../../components/Edit/New';

const Links = ({ entry: { id } }: { entry: Species }) => (
  <>
    <Link
      to={{
        pathname: '/catches',
        search: Filter.empty()
          .setAttribute(`species_id`, RansackMatcher.Equals, id.toString())
          .toString(),
      }}
    >
      List catches
    </Link>
    {' - '}
    <a href={`https://${MYKISS_BASE}/species/${id}`}>Open on {MYKISS_BASE}</a>
  </>
);

export const TypeSelect = FormSelect([
  { value: null, label: 'Unknown' },
  { value: 'attribution', label: 'Attribution' },
  { value: 'owned', label: 'Owned' },
  { value: 'purchased', label: 'Purchased' },
  { value: 'copyright', label: 'Copyright' },
  { value: 'no_rights_reserved', label: 'No rights reserved' },
  { value: 'public_domain', label: 'Public Domain' },
]);

export const ImageAttributionTypeSelect = FormSelect([
  { value: null, label: 'Unknown' },
  { value: 'general_attribution', label: 'General Attribution' },
  { value: 'creative_commons', label: 'Creative Commons (specify CC level in image comments)' },
  { value: 'wikimedia_commons', label: 'Wikimedia Commons (specify CC level in image comments)' },
]);

const IUCNStatusSelect = FormSelect([
  { value: null, label: 'Unknown' },
  { value: 'EW', label: 'Extinct in the wild' },
  { value: 'EX', label: 'Extinct' },
  { value: 'CR', label: 'Critically endangered' },
  { value: 'EN', label: 'Endangered' },
  { value: 'VU', label: 'Vulnerable' },
  { value: 'NT', label: 'Near threatened' },
  { value: 'LC', label: 'Least concern' },
  { value: 'DD', label: 'Data deficient' },
  { value: 'NE', label: 'Not evaluated' },
]);

const WaterTypeSelect = FormMultiSelect([
  { value: 0, label: 'Saltwater' },
  { value: 1, label: 'Freshwater' },
  { value: 2, label: 'Mixed' },
]);

const ThreatToHumansSelect = FormMultiSelect([
  { value: 0, label: 'Harmless' },
  { value: 1, label: 'Poisonous to eat' },
  { value: 2, label: 'Venomous' },
  { value: 3, label: 'Potential pest' },
  { value: 4, label: 'Reports of ciguatera poisoning' },
  { value: 5, label: 'Traumatogenic' },
]);

const headers: IHeader[] = [
  { title: 'Id', attribute: 'id', component: AttributeID, link: '/species/' },
  { title: 'ExternalId', attribute: 'external_id', component: AttributeID },
  { title: 'Name', attribute: 'name', canElasticsearch: true },
  { title: 'Latin', attribute: 'species' },
  { title: 'Catches Count', attribute: 'catch_count' },
  { title: 'Image License Type', attribute: 'image_copyright_status' },
  {
    title: 'Image Copyright expiry',
    attribute: 'image_copyright_expiry_date',
    component: AttributeDate,
  },
  { title: 'Image', attribute: 'image', component: AttributeImage },
];

const editFormHeaders: IFormHeaders[] = [
  { title: 'See more', attribute: '', component: AttributeWithTitle(Links) },
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Fishbase Id', attribute: 'fishbase_id', component: FormText },
      { title: 'Fishrules Id', attribute: 'fishrules_id', component: FormText },
      { title: 'Sealifebase Id', attribute: 'sealifebase_id', component: FormText },
    ],
  },
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Default name', attribute: 'name', component: FormText },
      { title: 'Latin name', attribute: 'species', component: FormText },
    ],
  },
  { title: 'Description', attribute: 'description', component: FormTextarea },
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Max Common Depth (m)', attribute: 'max_common_depth', component: FormNumber },
      { title: 'Min Common Depth (m)', attribute: 'min_common_depth', component: FormNumber },
    ],
  },
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Max Length (m)', attribute: 'max_length', component: FormNumber },
      { title: 'Max Weight (kg)', attribute: 'max_weight', component: FormNumber },
    ],
  },
  { title: 'IUCN status', attribute: 'iucn_status', component: IUCNStatusSelect },
  { title: 'Water Type', attribute: 'water_type', component: WaterTypeSelect },
  { title: 'Threat To Humans', attribute: 'threat_to_humans', component: ThreatToHumansSelect },
  {
    title: 'Species Reference Link',
    attribute: 'fish_reference_link',
    component: FormText,
  },
  {
    title: 'Species Reference Name',
    attribute: 'fish_reference_name',
    component: FormText,
  },
  { title: 'Image', attribute: 'photo', component: EmbeddedDropzoneImage },
  {
    title: 'Artist',
    attribute: 'image_reference_name',
    component: FormText,
  },
  {
    title: 'Publisher',
    attribute: 'image_publisher',
    component: FormText,
  },
  {
    title: 'Image License Type',
    attribute: 'image_copyright_status',
    component: TypeSelect,
  },
  {
    title: 'Image Attribution Type',
    attribute: 'image_attribution_type',
    component: ImageAttributionTypeSelect,
  },
  {
    title: 'Image Copyright Expiry Date',
    attribute: 'image_copyright_expiry_date',
    component: FormDateCustom,
  },
  {
    title: 'Image Original URL',
    attribute: 'image_original_url',
    component: FormText,
  },
  {
    title: 'Image Comment',
    attribute: 'image_comment',
    component: FormText,
  },
  {
    attribute: 'localizedSpeciesNames',
    beforeSave: getChangedLocalizations,
    component: FormLocalizations,
    equals: compareLocalizations,
    title: 'Localized Species Names',
  },
  {
    attribute: 'speciesTips',
    component: SpeciesTips,
    title: 'Tips',
  },
];

const createFormHeaders: IFormHeaders[] = [
  {
    title: 'Fishbase Id',
    attribute: 'fishbase_id',
    component: FormText,
    legend:
      'Before setting the fishbase identifier, look it up on fishbase.org and ensure it isnt already in the species database.',
  },
  {
    title: 'Fishrules Id',
    attribute: 'fishrules_id',
    component: FormText,
  },
  {
    title: 'Sealifebase Id',
    attribute: 'sealifebase_id',
    component: FormText,
  },
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Default name', attribute: 'name', component: FormText },
      { title: 'Latin name', attribute: 'species', component: FormText },
    ],
  },
  {
    title: 'Species Reference Link',
    attribute: 'fish_reference_link',
    component: FormText,
  },
  {
    title: 'Species Reference Name',
    attribute: 'fish_reference_name',
    component: FormText,
  },
  {
    title: 'K Index',
    attribute: 'k_index',
    component: FormText,
    defaultValue: 0,
  },
  {
    title: 'Max Weight (kg)',
    attribute: 'max_weight',
    component: FormNumber,
    defaultValue: undefined,
  },
  { title: 'Water Type', attribute: 'water_type', component: WaterTypeSelect, defaultValue: [] },
  { title: 'Image', attribute: 'photo', component: EmbeddedDropzoneImage },
  {
    title: 'Image Reference Name (Copyright owner)',
    attribute: 'image_reference_name',
    component: FormText,
  },
];

const createValidation = Yup.object({
  fishbase_id: Yup.number().optional().positive().integer(),
  k_index: Yup.number().required('K Index is required').min(0, 'K Index must be > 0').integer(),
  max_weight: Yup.number().min(0, 'K Index must be > 0').positive(),
  name: Yup.string().required('name is required'),
  water_type: Yup.array().min(1, 'At least 1 Water Type is required'),
});

const editValidation = Yup.object({
  fishbase_id: Yup.number().optional().positive().integer(),
  max_weight: Yup.number().min(0, 'K Index must be > 0').positive(),
  name: Yup.string().required('name is required'),
  water_type: Yup.array().min(1, 'At least 1 Water Type is required'),
});

const EditSpecies = (): JSX.Element => (
  <Edit
    validationSchema={editValidation}
    model={Species}
    headers={editFormHeaders}
    noDeletion
    returnPath="/species"
  />
);

const NewSpecies = (): JSX.Element => (
  <New
    model={Species}
    headers={createFormHeaders}
    returnPath="/species"
    validationSchema={createValidation}
  />
);

const SpeciesList = (): JSX.Element => {
  const match = useRouteMatch();
  return (
    <Flex>
      <ModelList headers={headers} model={Species} />
      <SidePane showOnPath={`${match.path}/:slug`}>
        <Switch>
          <Route path={`${match.path}/new`} component={NewSpecies} exact />
          <Route path={`${match.path}/:id/create_tip`} component={CreateSpeciesTip} />
          <Route path={`${match.path}/:id`} render={EditSpecies} />
        </Switch>
      </SidePane>
    </Flex>
  );
};

export default SpeciesList;
