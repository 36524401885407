import { destroy, get, post, put } from '../api/rutilus';
import { buildFilterParams, mergeSearchParams } from '../helpers/urlhelpers';

const abstractMethodMissingError = (message: string) => ({ message });
const attributeMissingError = (message: string) => ({ message });

export interface IListOptions {
  page?: number;
  perPage?: number;
  sortBy?: string;
  filterBy?: { [key: string]: string };
  searchQuery?: string;
  urlParams?: { id?: string };
}

export interface IBaseType {
  readonly id: number;
}

abstract class BaseModel {
  public static readOnly: string[] = ['id', 'created_at', 'updated_at', 'external_id'];

  public static endpoint = '/';

  public static modelType: string;

  public static async find(id: number | string): Promise<any> {
    return new Promise(async (resolve, reject = alert) =>
      get(`${this.endpoint}/${id}?${this.fetchParameters.toString()}`)
        .then(async (resp) => (resp.ok ? resp.json() : reject(resp)))
        .then((json) => this.fromAttributes(json))
        .then(resolve, reject),
    );
  }

  public static async list(
    { page, perPage, sortBy, filterBy, searchQuery }: IListOptions = {
      page: 0,
      perPage: 10,
      filterBy: {},
    },
  ): Promise<any> {
    // eslint-disable-next-line no-console
    return new Promise(async (resolve, reject = console.error) => {
      const filterParams = buildFilterParams(sortBy, filterBy, this.filterRoot);
      const params = {
        page,
        per_page: perPage,
        ...filterParams,
        ...(searchQuery ? { s: searchQuery } : {}),
      };
      const searchParams = mergeSearchParams(this.fetchParameters, params);

      return get(`${this.listEndpoint}?${searchParams.toString()}`)
        .then(async (resp) => (resp.ok ? resp.json() : reject(resp)))
        .then((json) => json && json.map((obj: any) => this.fromAttributes(obj)))
        .then(resolve, reject);
    });
  }

  public static fromAttributes(_params: any): any {
    throw abstractMethodMissingError(`fromAttributes needs to be implemented on ${this}`);
  }

  protected static readonly DEFAULT_OPTIONS: { [key: string]: string } = {
    verbosity: 'verbose',
  };

  protected static get commonParameters(): URLSearchParams {
    return new URLSearchParams();
  }

  protected static get fetchParameters(): URLSearchParams {
    const params = this.commonParameters;
    Object.keys(this.DEFAULT_OPTIONS).forEach((key: string) =>
      params.set(key, this.DEFAULT_OPTIONS[key]),
    );
    return params;
  }

  public abstract ['constructor']: any;

  protected abstract attributes: any;

  readonly [dynamicAttributeName: string]: any;

  abstract get endpoint(): string;

  abstract get identifier(): string;

  get id(): any {
    return this.attributes.id;
  }

  protected constructor() {
    this.defineAttributeGetters = this.defineAttributeGetters.bind(this);
    this.attr = this.attr.bind(this);
  }

  public async destroy(): Promise<void> {
    return new Promise((resolve, reject) => {
      destroy(this.endpoint).then((resp) => (resp.ok ? resolve() : reject()));
    });
  }

  public static async create(params: any): Promise<void> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return new Promise((resolve, reject) => {
      post(this.endpoint, params, headers)
        .then(async (resp) => resp.json())
        .then((res) => (res.errors ? reject(res.errors.join(', ')) : resolve(res)));
    });
  }

  public async update(params: { [key: string]: any }): Promise<void> {
    return new Promise((resolve, reject) => {
      put(this.endpoint, params)
        .then(async (resp) => resp.json())
        .then((res) => (res.errors ? reject(res.errors.join(', ')) : resolve()));
    });
  }

  public getAttributes(): any {
    return this.attributes;
  }

  public attr(name: string): any {
    if (this.attributes[name] === undefined) {
      throw attributeMissingError(`Missing attribute '${name}'`);
    }
    return this.attributes[name];
  }

  public static get listEndpoint(): string {
    return this.endpoint;
  }

  public isReadOnly(attribute: string): boolean {
    return this.class.readOnly.includes(attribute);
  }

  protected defineAttributeGetters(attributes: any = {}): void {
    if (attributes) {
      Object.keys(attributes).forEach((key: string) => {
        if (this[key]) {
          return;
        }
        Object.defineProperty(this, key, {
          get: () => this.attr(key),
        });
      });
    }
  }

  public get created_at(): Date {
    return new Date(this.attr('created_at'));
  }

  public abstract get modelType(): string;

  public static get filterRoot(): string {
    return 'q';
  }
}

export default BaseModel;
