import { IImage } from '../../models/Image';

interface IProps {
  img: IImage;
  geometry: string;
  link?: string | null;
  rest?: any[];
}

const Image = ({ img, geometry, link = null, ...rest }: IProps): JSX.Element => {
  const imgSize = img.sizes.find((im) => im.geometry === geometry);
  const linkTo = img.sizes.find((im) => im.geometry === link);

  if (imgSize) {
    return link && linkTo ? (
      <a href={linkTo.url} rel="noopener noreferrer">
        <img src={imgSize.url} {...rest} alt="" />
      </a>
    ) : (
      <img src={imgSize.url} className="max-w-full" alt="" {...rest} />
    );
  }

  return <span>Image geometry &quot;{geometry}&quot; not found</span>;
};

export default Image;
