/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdministerUserSuggestedChange
// ====================================================

export interface AdministerUserSuggestedChange_administerUserSuggestedChange_userSuggestedChange {
  __typename: "UserSuggestedChange";
  id: string;
  externalId: string;
  status: string;
}

export interface AdministerUserSuggestedChange_administerUserSuggestedChange_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
  /**
   * A string which can be used by a client to handle the user error
   * 
   * The only code currently is `needsReverification`.  This could be returned from any mutation.
   * If it is returned by any of the user errors, the client must immediately re-verify the user.
   */
  code: string | null;
}

export interface AdministerUserSuggestedChange_administerUserSuggestedChange {
  __typename: "AdministerUserSuggestedChangeMutationPayload";
  userSuggestedChange: AdministerUserSuggestedChange_administerUserSuggestedChange_userSuggestedChange | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: AdministerUserSuggestedChange_administerUserSuggestedChange_userErrors[];
}

export interface AdministerUserSuggestedChange {
  administerUserSuggestedChange: AdministerUserSuggestedChange_administerUserSuggestedChange | null;
}

export interface AdministerUserSuggestedChangeVariables {
  input: AdministerUserSuggestedChangeMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignUserSuggestedChange
// ====================================================

export interface AssignUserSuggestedChange_assignUserSuggestedChange_userSuggestedChange_assignee {
  __typename: "User";
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface AssignUserSuggestedChange_assignUserSuggestedChange_userSuggestedChange {
  __typename: "UserSuggestedChange";
  /**
   * The admin user who is assigned to review the suggestion
   */
  assignee: AssignUserSuggestedChange_assignUserSuggestedChange_userSuggestedChange_assignee | null;
  externalId: string;
}

export interface AssignUserSuggestedChange_assignUserSuggestedChange_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface AssignUserSuggestedChange_assignUserSuggestedChange {
  __typename: "AssignUserSuggestedChangeMutationPayload";
  userSuggestedChange: AssignUserSuggestedChange_assignUserSuggestedChange_userSuggestedChange | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: AssignUserSuggestedChange_assignUserSuggestedChange_userErrors[];
}

export interface AssignUserSuggestedChange {
  assignUserSuggestedChange: AssignUserSuggestedChange_assignUserSuggestedChange | null;
}

export interface AssignUserSuggestedChangeVariables {
  input: AssignUserSuggestedChangeMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCouponGroup
// ====================================================

export interface CreateCouponGroup_createCouponGroup_couponGroup {
  __typename: "CouponGroup";
  name: string;
  percentOff: number;
  durationInMonths: number;
  couponExternalId: string;
  /**
   * The code of this MULTIPLE usage Coupon Group. Returns NULL if the usage is ONCE 
   */
  code: string | null;
}

export interface CreateCouponGroup_createCouponGroup_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface CreateCouponGroup_createCouponGroup {
  __typename: "CreateCouponGroupMutationPayload";
  couponGroup: CreateCouponGroup_createCouponGroup_couponGroup | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: CreateCouponGroup_createCouponGroup_userErrors[];
}

export interface CreateCouponGroup {
  createCouponGroup: CreateCouponGroup_createCouponGroup | null;
}

export interface CreateCouponGroupVariables {
  input: CreateCouponGroupMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateBrand
// ====================================================

export interface CreateBrand_createBrand_brand {
  __typename: "Brand";
  id: number;
  externalId: string;
  name: string;
}

export interface CreateBrand_createBrand_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
  /**
   * A string which can be used by a client to handle the user error
   * 
   * The only code currently is `needsReverification`.  This could be returned from any mutation.
   * If it is returned by any of the user errors, the client must immediately re-verify the user.
   */
  code: string | null;
}

export interface CreateBrand_createBrand {
  __typename: "CreateBrandMutationPayload";
  brand: CreateBrand_createBrand_brand | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: CreateBrand_createBrand_userErrors[];
}

export interface CreateBrand {
  createBrand: CreateBrand_createBrand | null;
}

export interface CreateBrandVariables {
  input: CreateBrandMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCuratedSpeciesCatch
// ====================================================

export interface CreateCuratedSpeciesCatch_createCuratedSpeciesCatch_newCuratedCatch {
  __typename: "Catch";
  externalId: string;
}

export interface CreateCuratedSpeciesCatch_createCuratedSpeciesCatch_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface CreateCuratedSpeciesCatch_createCuratedSpeciesCatch {
  __typename: "CreateCuratedSpeciesCatchMutationPayload";
  newCuratedCatch: CreateCuratedSpeciesCatch_createCuratedSpeciesCatch_newCuratedCatch | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: CreateCuratedSpeciesCatch_createCuratedSpeciesCatch_userErrors[];
}

export interface CreateCuratedSpeciesCatch {
  createCuratedSpeciesCatch: CreateCuratedSpeciesCatch_createCuratedSpeciesCatch | null;
}

export interface CreateCuratedSpeciesCatchVariables {
  input: CreateCuratedSpeciesCatchMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFishingWater
// ====================================================

export interface CreateFishingWater_createFishingWater_fishingWater {
  __typename: "FishingWater";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
}

export interface CreateFishingWater_createFishingWater_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
  /**
   * A string which can be used by a client to handle the user error
   * 
   * The only code currently is `needsReverification`.  This could be returned from any mutation.
   * If it is returned by any of the user errors, the client must immediately re-verify the user.
   */
  code: string | null;
}

export interface CreateFishingWater_createFishingWater {
  __typename: "CreateFishingWaterMutationPayload";
  fishingWater: CreateFishingWater_createFishingWater_fishingWater | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: CreateFishingWater_createFishingWater_userErrors[];
}

export interface CreateFishingWater {
  createFishingWater: CreateFishingWater_createFishingWater | null;
}

export interface CreateFishingWaterVariables {
  input: CreateFishingWaterMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSpeciesTip
// ====================================================

export interface CreateSpeciesTip_createSpeciesTip_speciesTip {
  __typename: "SpeciesTip";
  externalId: string;
}

export interface CreateSpeciesTip_createSpeciesTip {
  __typename: "CreateSpeciesTipMutationPayload";
  speciesTip: CreateSpeciesTip_createSpeciesTip_speciesTip | null;
}

export interface CreateSpeciesTip {
  createSpeciesTip: CreateSpeciesTip_createSpeciesTip | null;
}

export interface CreateSpeciesTipVariables {
  input: CreateSpeciesTipMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRecordByExternalId
// ====================================================

export interface DeleteRecordByExternalId_deleteRecordByExternalId_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface DeleteRecordByExternalId_deleteRecordByExternalId {
  __typename: "DeleteRecordByExternalIdMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: DeleteRecordByExternalId_deleteRecordByExternalId_userErrors[];
}

export interface DeleteRecordByExternalId {
  deleteRecordByExternalId: DeleteRecordByExternalId_deleteRecordByExternalId | null;
}

export interface DeleteRecordByExternalIdVariables {
  input: DeleteRecordByExternalIdMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDirectUploadUrl
// ====================================================

export interface createDirectUploadUrl_createDirectUploadUrl_directUploadUrl_headers {
  __typename: "DirectUploadRequestHeader";
  name: string;
  value: string;
}

export interface createDirectUploadUrl_createDirectUploadUrl_directUploadUrl {
  __typename: "DirectUploadURL";
  /**
   * ID with which content can be identified by backend. Use it to attach content to entities
   */
  signedId: string;
  /**
   * PUT content to the url
   */
  url: string;
  /**
   * Headers to be used in PUT request
   */
  headers: createDirectUploadUrl_createDirectUploadUrl_directUploadUrl_headers[];
}

export interface createDirectUploadUrl_createDirectUploadUrl_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface createDirectUploadUrl_createDirectUploadUrl {
  __typename: "CreateDirectUploadURLMutationPayload";
  directUploadUrl: createDirectUploadUrl_createDirectUploadUrl_directUploadUrl | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: createDirectUploadUrl_createDirectUploadUrl_userErrors[];
}

export interface createDirectUploadUrl {
  createDirectUploadUrl: createDirectUploadUrl_createDirectUploadUrl | null;
}

export interface createDirectUploadUrlVariables {
  input: CreateDirectUploadURLMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteGroup
// ====================================================

export interface deleteGroup_deleteRecordByExternalId_userErrors {
  __typename: "UserError";
  /**
   * A string which can be used by a client to handle the user error
   * 
   * The only code currently is `needsReverification`.  This could be returned from any mutation.
   * If it is returned by any of the user errors, the client must immediately re-verify the user.
   */
  code: string | null;
  /**
   * A description of the error
   */
  message: string;
  /**
   * Which input value this error came from
   */
  path: string[] | null;
}

export interface deleteGroup_deleteRecordByExternalId {
  __typename: "DeleteRecordByExternalIdMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: deleteGroup_deleteRecordByExternalId_userErrors[];
}

export interface deleteGroup {
  deleteRecordByExternalId: deleteGroup_deleteRecordByExternalId | null;
}

export interface deleteGroupVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertNavionicsSubscription
// ====================================================

export interface upsertNavionicsSubscription_upsertNavionicsSubscription_subscription {
  __typename: "NavionicsSubscription";
  customerId: string | null;
  expiresAt: any | null;
}

export interface upsertNavionicsSubscription_upsertNavionicsSubscription_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface upsertNavionicsSubscription_upsertNavionicsSubscription {
  __typename: "UpsertNavionicsSubscriptionMutationPayload";
  subscription: upsertNavionicsSubscription_upsertNavionicsSubscription_subscription | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: upsertNavionicsSubscription_upsertNavionicsSubscription_userErrors[];
}

export interface upsertNavionicsSubscription {
  upsertNavionicsSubscription: upsertNavionicsSubscription_upsertNavionicsSubscription | null;
}

export interface upsertNavionicsSubscriptionVariables {
  input: UpsertNavionicsSubscriptionMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddStaffMutation
// ====================================================

export interface AddStaffMutation_addStaffToPage_page_ambassadors_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface AddStaffMutation_addStaffToPage_page_ambassadors_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: AddStaffMutation_addStaffToPage_page_ambassadors_edges_node | null;
}

export interface AddStaffMutation_addStaffToPage_page_ambassadors {
  __typename: "UserRecordConnection";
  /**
   * A list of edges.
   */
  edges: (AddStaffMutation_addStaffToPage_page_ambassadors_edges | null)[] | null;
}

export interface AddStaffMutation_addStaffToPage_page_publishers_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface AddStaffMutation_addStaffToPage_page_publishers_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: AddStaffMutation_addStaffToPage_page_publishers_edges_node | null;
}

export interface AddStaffMutation_addStaffToPage_page_publishers {
  __typename: "UserRecordConnection";
  /**
   * A list of edges.
   */
  edges: (AddStaffMutation_addStaffToPage_page_publishers_edges | null)[] | null;
}

export interface AddStaffMutation_addStaffToPage_page_admins_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface AddStaffMutation_addStaffToPage_page_admins_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: AddStaffMutation_addStaffToPage_page_admins_edges_node | null;
}

export interface AddStaffMutation_addStaffToPage_page_admins {
  __typename: "UserRecordConnection";
  /**
   * A list of edges.
   */
  edges: (AddStaffMutation_addStaffToPage_page_admins_edges | null)[] | null;
}

export interface AddStaffMutation_addStaffToPage_page {
  __typename: "Page";
  ambassadors: AddStaffMutation_addStaffToPage_page_ambassadors;
  publishers: AddStaffMutation_addStaffToPage_page_publishers;
  admins: AddStaffMutation_addStaffToPage_page_admins;
}

export interface AddStaffMutation_addStaffToPage_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface AddStaffMutation_addStaffToPage {
  __typename: "AddStaffMutationPayload";
  page: AddStaffMutation_addStaffToPage_page;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: AddStaffMutation_addStaffToPage_userErrors[];
}

export interface AddStaffMutation {
  addStaffToPage: AddStaffMutation_addStaffToPage | null;
}

export interface AddStaffMutationVariables {
  input: AddStaffMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createBrandPage
// ====================================================

export interface createBrandPage_createBrandPage_page {
  __typename: "Page";
  /**
   * DB ID
   */
  id: number;
}

export interface createBrandPage_createBrandPage_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface createBrandPage_createBrandPage {
  __typename: "CreateBrandPageMutationPayload";
  page: createBrandPage_createBrandPage_page | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: createBrandPage_createBrandPage_userErrors[];
}

export interface createBrandPage {
  createBrandPage: createBrandPage_createBrandPage | null;
}

export interface createBrandPageVariables {
  input: CreateBrandPageMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveStaffMutation
// ====================================================

export interface RemoveStaffMutation_removeStaffFromPage_page_ambassadors_pageInfo {
  __typename: "PageInfo";
}

export interface RemoveStaffMutation_removeStaffFromPage_page_ambassadors_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface RemoveStaffMutation_removeStaffFromPage_page_ambassadors_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: RemoveStaffMutation_removeStaffFromPage_page_ambassadors_edges_node | null;
}

export interface RemoveStaffMutation_removeStaffFromPage_page_ambassadors {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: RemoveStaffMutation_removeStaffFromPage_page_ambassadors_pageInfo;
  /**
   * A list of edges.
   */
  edges: (RemoveStaffMutation_removeStaffFromPage_page_ambassadors_edges | null)[] | null;
}

export interface RemoveStaffMutation_removeStaffFromPage_page_publishers_pageInfo {
  __typename: "PageInfo";
}

export interface RemoveStaffMutation_removeStaffFromPage_page_publishers_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface RemoveStaffMutation_removeStaffFromPage_page_publishers_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: RemoveStaffMutation_removeStaffFromPage_page_publishers_edges_node | null;
}

export interface RemoveStaffMutation_removeStaffFromPage_page_publishers {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: RemoveStaffMutation_removeStaffFromPage_page_publishers_pageInfo;
  /**
   * A list of edges.
   */
  edges: (RemoveStaffMutation_removeStaffFromPage_page_publishers_edges | null)[] | null;
}

export interface RemoveStaffMutation_removeStaffFromPage_page_admins_pageInfo {
  __typename: "PageInfo";
}

export interface RemoveStaffMutation_removeStaffFromPage_page_admins_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface RemoveStaffMutation_removeStaffFromPage_page_admins_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: RemoveStaffMutation_removeStaffFromPage_page_admins_edges_node | null;
}

export interface RemoveStaffMutation_removeStaffFromPage_page_admins {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: RemoveStaffMutation_removeStaffFromPage_page_admins_pageInfo;
  /**
   * A list of edges.
   */
  edges: (RemoveStaffMutation_removeStaffFromPage_page_admins_edges | null)[] | null;
}

export interface RemoveStaffMutation_removeStaffFromPage_page {
  __typename: "Page";
  ambassadors: RemoveStaffMutation_removeStaffFromPage_page_ambassadors;
  publishers: RemoveStaffMutation_removeStaffFromPage_page_publishers;
  admins: RemoveStaffMutation_removeStaffFromPage_page_admins;
}

export interface RemoveStaffMutation_removeStaffFromPage_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface RemoveStaffMutation_removeStaffFromPage {
  __typename: "RemoveStaffMutationPayload";
  page: RemoveStaffMutation_removeStaffFromPage_page;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: RemoveStaffMutation_removeStaffFromPage_userErrors[];
}

export interface RemoveStaffMutation {
  removeStaffFromPage: RemoveStaffMutation_removeStaffFromPage | null;
}

export interface RemoveStaffMutationVariables {
  input: RemoveStaffMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageMutation
// ====================================================

export interface UpdatePageMutation_updatePage_page {
  __typename: "Page";
  /**
   * DB ID
   */
  id: number;
  published: boolean;
  description: string | null;
}

export interface UpdatePageMutation_updatePage_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdatePageMutation_updatePage {
  __typename: "UpdatePageMutationPayload";
  page: UpdatePageMutation_updatePage_page | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdatePageMutation_updatePage_userErrors[];
}

export interface UpdatePageMutation {
  updatePage: UpdatePageMutation_updatePage | null;
}

export interface UpdatePageMutationVariables {
  input: UpdatePageMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageMutationWithBrand
// ====================================================

export interface UpdatePageMutationWithBrand_updatePage_page {
  __typename: "Page";
  /**
   * DB ID
   */
  id: number;
  published: boolean;
  description: string | null;
}

export interface UpdatePageMutationWithBrand_updatePage_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdatePageMutationWithBrand_updatePage {
  __typename: "UpdatePageMutationPayload";
  page: UpdatePageMutationWithBrand_updatePage_page | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdatePageMutationWithBrand_updatePage_userErrors[];
}

export interface UpdatePageMutationWithBrand_updateBrand_brand {
  __typename: "Brand";
  id: number;
  url: string | null;
}

export interface UpdatePageMutationWithBrand_updateBrand_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdatePageMutationWithBrand_updateBrand {
  __typename: "UpdateBrandMutationPayload";
  brand: UpdatePageMutationWithBrand_updateBrand_brand | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdatePageMutationWithBrand_updateBrand_userErrors[];
}

export interface UpdatePageMutationWithBrand {
  updatePage: UpdatePageMutationWithBrand_updatePage | null;
  updateBrand: UpdatePageMutationWithBrand_updateBrand | null;
}

export interface UpdatePageMutationWithBrandVariables {
  pageInput: UpdatePageMutationInput;
  brandInput: UpdateBrandMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: editImageMutation
// ====================================================

export interface editImageMutation_editImage_image {
  __typename: "OpImage";
  /**
   * Moderation status
   */
  status: string;
}

export interface editImageMutation_editImage_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface editImageMutation_editImage {
  __typename: "EditImageMutationPayload";
  image: editImageMutation_editImage_image | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: editImageMutation_editImage_userErrors[];
}

export interface editImageMutation {
  editImage: editImageMutation_editImage | null;
}

export interface editImageMutationVariables {
  input: EditImageMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: editTextMutation
// ====================================================

export interface editTextMutation_editText_text {
  __typename: "Text";
  /**
   * Moderation status
   */
  status: string;
}

export interface editTextMutation_editText_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface editTextMutation_editText {
  __typename: "EditTextMutationPayload";
  text: editTextMutation_editText_text | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: editTextMutation_editText_userErrors[];
}

export interface editTextMutation {
  editText: editTextMutation_editText | null;
}

export interface editTextMutationVariables {
  input: EditTextMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setUrlStatus
// ====================================================

export interface setUrlStatus_editUrl_url {
  __typename: "OpUrl";
  status: string;
}

export interface setUrlStatus_editUrl_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface setUrlStatus_editUrl {
  __typename: "EditURLMutationPayload";
  url: setUrlStatus_editUrl_url | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: setUrlStatus_editUrl_userErrors[];
}

export interface setUrlStatus {
  editUrl: setUrlStatus_editUrl | null;
}

export interface setUrlStatusVariables {
  input: EditURLMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: editVideoMutation
// ====================================================

export interface editVideoMutation_editVideo_video {
  __typename: "Video";
  /**
   * Moderation status
   */
  status: string;
}

export interface editVideoMutation_editVideo_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface editVideoMutation_editVideo {
  __typename: "EditVideoMutationPayload";
  video: editVideoMutation_editVideo_video | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: editVideoMutation_editVideo_userErrors[];
}

export interface editVideoMutation {
  editVideo: editVideoMutation_editVideo | null;
}

export interface editVideoMutationVariables {
  input: EditVideoMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBrand
// ====================================================

export interface UpdateBrand_updateBrand_brand {
  __typename: "Brand";
  id: number;
  externalId: string;
  name: string;
  address: string | null;
  url: string | null;
}

export interface UpdateBrand_updateBrand {
  __typename: "UpdateBrandMutationPayload";
  brand: UpdateBrand_updateBrand_brand | null;
}

export interface UpdateBrand {
  updateBrand: UpdateBrand_updateBrand | null;
}

export interface UpdateBrandVariables {
  input: UpdateBrandMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFishingWater
// ====================================================

export interface UpdateFishingWater_updateFishingWater_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdateFishingWater_updateFishingWater {
  __typename: "UpdateFishingWaterMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdateFishingWater_updateFishingWater_userErrors[];
}

export interface UpdateFishingWater {
  updateFishingWater: UpdateFishingWater_updateFishingWater | null;
}

export interface UpdateFishingWaterVariables {
  input: UpdateFishingWaterMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePlace
// ====================================================

export interface UpdatePlace_updatePlace_place {
  __typename: "Place";
  /**
   * DB ID
   */
  id: number;
  description: string | null;
}

export interface UpdatePlace_updatePlace_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdatePlace_updatePlace {
  __typename: "UpdatePlaceMutationPayload";
  place: UpdatePlace_updatePlace_place | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdatePlace_updatePlace_userErrors[];
}

export interface UpdatePlace {
  updatePlace: UpdatePlace_updatePlace | null;
}

export interface UpdatePlaceVariables {
  input: UpdatePlaceMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePlaceUnsplashImage
// ====================================================

export interface UpdatePlaceUnsplashImage_updatePlaceUnsplashImage_place_unsplashImage {
  __typename: "UnsplashImage";
  /**
   * A URL for an image. If you use this make sure to read
   * the usage guidelines first -
   * https:                 // help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
   * You can also apply transformations to the image by altering the URL. See here
   * for more info - https: // unsplash.com/documentation#dynamically-resizable-images
   */
  imageUrl: string | null;
  /**
   * The name of the photographer.
   */
  photographerName: string | null;
  /**
   * The URL for the photographers Unsplash profile. Must be linked to w/ image.
   */
  photographerUrl: string | null;
}

export interface UpdatePlaceUnsplashImage_updatePlaceUnsplashImage_place {
  __typename: "Place";
  /**
   * DB ID
   */
  id: number;
  /**
   * An image for this place
   */
  unsplashImage: UpdatePlaceUnsplashImage_updatePlaceUnsplashImage_place_unsplashImage | null;
}

export interface UpdatePlaceUnsplashImage_updatePlaceUnsplashImage_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdatePlaceUnsplashImage_updatePlaceUnsplashImage {
  __typename: "UpdatePlaceUnsplashImageMutationPayload";
  place: UpdatePlaceUnsplashImage_updatePlaceUnsplashImage_place | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdatePlaceUnsplashImage_updatePlaceUnsplashImage_userErrors[];
}

export interface UpdatePlaceUnsplashImage {
  updatePlaceUnsplashImage: UpdatePlaceUnsplashImage_updatePlaceUnsplashImage | null;
}

export interface UpdatePlaceUnsplashImageVariables {
  input: UpdatePlaceUnsplashImageMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setUrlHostStatus
// ====================================================

export interface setUrlHostStatus_editUrlHost_host {
  __typename: "OpUrlHost";
  status: string;
}

export interface setUrlHostStatus_editUrlHost_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface setUrlHostStatus_editUrlHost {
  __typename: "EditURLHostMutationPayload";
  host: setUrlHostStatus_editUrlHost_host | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: setUrlHostStatus_editUrlHost_userErrors[];
}

export interface setUrlHostStatus {
  editUrlHost: setUrlHostStatus_editUrlHost | null;
}

export interface setUrlHostStatusVariables {
  input: EditURLHostMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: reverifyUser
// ====================================================

export interface reverifyUser_reverifyUser_user {
  __typename: "User";
  /**
   * Is this user somehow verified?
   */
  isVerified: boolean | null;
}

export interface reverifyUser_reverifyUser_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface reverifyUser_reverifyUser {
  __typename: "MarkUserForReverificationMutationPayload";
  user: reverifyUser_reverifyUser_user | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: reverifyUser_reverifyUser_userErrors[];
}

export interface reverifyUser {
  reverifyUser: reverifyUser_reverifyUser | null;
}

export interface reverifyUserVariables {
  input: MarkUserForReverificationMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserBanStatus
// ====================================================

export interface SetUserBanStatus_setUserBanStatus_user {
  __typename: "User";
  externalId: string;
}

export interface SetUserBanStatus_setUserBanStatus_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface SetUserBanStatus_setUserBanStatus {
  __typename: "SetUserBanStatusMutationPayload";
  user: SetUserBanStatus_setUserBanStatus_user | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: SetUserBanStatus_setUserBanStatus_userErrors[];
}

export interface SetUserBanStatus {
  setUserBanStatus: SetUserBanStatus_setUserBanStatus | null;
}

export interface SetUserBanStatusVariables {
  input: SetUserBanStatusMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyUserEmail
// ====================================================

export interface VerifyUserEmail_verifyUserEmail_user {
  __typename: "User";
  /**
   * Is this user somehow verified?
   */
  isVerified: boolean | null;
  externalId: string;
}

export interface VerifyUserEmail_verifyUserEmail_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface VerifyUserEmail_verifyUserEmail {
  __typename: "VerifyUserEmailMutationPayload";
  user: VerifyUserEmail_verifyUserEmail_user | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: VerifyUserEmail_verifyUserEmail_userErrors[];
}

export interface VerifyUserEmail {
  verifyUserEmail: VerifyUserEmail_verifyUserEmail | null;
}

export interface VerifyUserEmailVariables {
  input: VerifyUserEmailMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignAdminRoleToUser
// ====================================================

export interface AssignAdminRoleToUser_assignAdminRoleToUser_user {
  __typename: "User";
  isAdmin: boolean | null;
}

export interface AssignAdminRoleToUser_assignAdminRoleToUser_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface AssignAdminRoleToUser_assignAdminRoleToUser {
  __typename: "AssignAdminRoleToUserMutationPayload";
  user: AssignAdminRoleToUser_assignAdminRoleToUser_user | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: AssignAdminRoleToUser_assignAdminRoleToUser_userErrors[];
}

export interface AssignAdminRoleToUser {
  assignAdminRoleToUser: AssignAdminRoleToUser_assignAdminRoleToUser | null;
}

export interface AssignAdminRoleToUserVariables {
  input: AssignAdminRoleToUserMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnassignAdminRoleToUser
// ====================================================

export interface UnassignAdminRoleToUser_unassignAdminRoleToUser_user {
  __typename: "User";
  isAdmin: boolean | null;
}

export interface UnassignAdminRoleToUser_unassignAdminRoleToUser_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
}

export interface UnassignAdminRoleToUser_unassignAdminRoleToUser {
  __typename: "UnassignAdminRoleToUserMutationPayload";
  user: UnassignAdminRoleToUser_unassignAdminRoleToUser_user | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UnassignAdminRoleToUser_unassignAdminRoleToUser_userErrors[];
}

export interface UnassignAdminRoleToUser {
  unassignAdminRoleToUser: UnassignAdminRoleToUser_unassignAdminRoleToUser | null;
}

export interface UnassignAdminRoleToUserVariables {
  input: UnassignAdminRoleToUserMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsers
// ====================================================

export interface GetUsers_users_edges_node {
  __typename: "User";
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetUsers_users_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUsers_users_edges_node | null;
}

export interface GetUsers_users {
  __typename: "UserRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUsers_users_edges | null)[] | null;
}

export interface GetUsers {
  /**
   * Users in the app
   */
  users: GetUsers_users;
}

export interface GetUsersVariables {
  cursor?: string | null;
  filters?: UserFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindBrand
// ====================================================

export interface FindBrand_entry_page {
  __typename: "Page";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
}

export interface FindBrand_entry {
  __typename: "Brand";
  id: number;
  externalId: string;
  name: string;
  address: string | null;
  url: string | null;
  aboutTitle: string | null;
  aboutDescription: string | null;
  productsTitle: string | null;
  productsDescription: string | null;
  membersTitle: string | null;
  page: FindBrand_entry_page | null;
}

export interface FindBrand {
  /**
   * For fetching a single brand (A company which sells fishing products)
   */
  entry: FindBrand_entry;
}

export interface FindBrandVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListBrands
// ====================================================

export interface ListBrands_brands_edges_node {
  __typename: "Brand";
  externalId: string;
  id: number;
  name: string;
}

export interface ListBrands_brands_edges {
  __typename: "BrandEdge";
  /**
   * The item at the end of the edge.
   */
  node: ListBrands_brands_edges_node | null;
}

export interface ListBrands_brands_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
}

export interface ListBrands_brands {
  __typename: "BrandRecordConnection";
  /**
   * A list of edges.
   */
  edges: (ListBrands_brands_edges | null)[] | null;
  /**
   * Information to aid in pagination.
   */
  pageInfo: ListBrands_brands_pageInfo;
}

export interface ListBrands {
  /**
   * Companies which sell fishing products
   */
  brands: ListBrands_brands;
}

export interface ListBrandsVariables {
  cursor?: string | null;
  filters?: BrandFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCategories
// ====================================================

export interface ListCategories_categories_edges_node {
  __typename: "Category";
  /**
   * Category ID
   */
  id: number;
  title: string;
  ancestry: string | null;
}

export interface ListCategories_categories_edges {
  __typename: "CategoryEdge";
  /**
   * The item at the end of the edge.
   */
  node: ListCategories_categories_edges_node | null;
}

export interface ListCategories_categories_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
}

export interface ListCategories_categories {
  __typename: "CategoryRecordConnection";
  /**
   * A list of edges.
   */
  edges: (ListCategories_categories_edges | null)[] | null;
  /**
   * Information to aid in pagination.
   */
  pageInfo: ListCategories_categories_pageInfo;
}

export interface ListCategories {
  /**
   * Equipment categories
   */
  categories: ListCategories_categories;
}

export interface ListCategoriesVariables {
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindCategory
// ====================================================

export interface FindCategory_entry {
  __typename: "Category";
  /**
   * Category ID
   */
  id: number;
  title: string;
  ancestry: string | null;
}

export interface FindCategory {
  /**
   * For fetching a single category (Equipment Category)
   */
  entry: FindCategory_entry;
}

export interface FindCategoryVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCouponGroups
// ====================================================

export interface GetCouponGroups_couponGroups_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetCouponGroups_couponGroups_edges_node {
  __typename: "CouponGroup";
  /**
   * DB ID
   */
  id: number;
  /**
   * The type of this coupon group
   */
  type: CouponGroupTypeEnum;
  /**
   * The usage of this coupon group
   */
  usage: CouponGroupUsageEnum;
  name: string;
  percentOff: number;
  durationInMonths: number;
  couponExternalId: string;
  expiresAt: any;
  /**
   * The External ID of the stripe plan of this coupon group
   */
  stripePlan: string;
  /**
   * The code of this MULTIPLE usage Coupon Group. Returns NULL if the usage is ONCE 
   */
  code: string | null;
}

export interface GetCouponGroups_couponGroups_edges {
  __typename: "CouponGroupEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCouponGroups_couponGroups_edges_node | null;
}

export interface GetCouponGroups_couponGroups {
  __typename: "CouponGroupRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetCouponGroups_couponGroups_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetCouponGroups_couponGroups_edges | null)[] | null;
}

export interface GetCouponGroups {
  couponGroups: GetCouponGroups_couponGroups;
}

export interface GetCouponGroupsVariables {
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCouponGroup
// ====================================================

export interface GetCouponGroup_entry {
  __typename: "CouponGroup";
  /**
   * DB ID
   */
  id: number;
  /**
   * The type of this coupon group
   */
  type: CouponGroupTypeEnum;
  /**
   * The usage of this coupon group
   */
  usage: CouponGroupUsageEnum;
  name: string;
  percentOff: number;
  durationInMonths: number;
  couponExternalId: string;
  expiresAt: any;
  /**
   * The External ID of the stripe plan of this coupon group
   */
  stripePlan: string;
  /**
   * The code of this MULTIPLE usage Coupon Group. Returns NULL if the usage is ONCE 
   */
  code: string | null;
}

export interface GetCouponGroup {
  /**
   * A coupon group by external ID
   */
  entry: GetCouponGroup_entry;
}

export interface GetCouponGroupVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWaters
// ====================================================

export interface GetFishingWaters_fishingWaters_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetFishingWaters_fishingWaters_edges_node {
  __typename: "FishingWater";
  /**
   * DB ID
   */
  id: number;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  externalId: string;
  /**
   * The number of followers for this water
   */
  followersCount: number;
  latitude: number;
  longitude: number;
  name: string | null;
  waterType: number | null;
}

export interface GetFishingWaters_fishingWaters_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWaters_fishingWaters_edges_node | null;
}

export interface GetFishingWaters_fishingWaters {
  __typename: "FishingWaterRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetFishingWaters_fishingWaters_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetFishingWaters_fishingWaters_edges | null)[] | null;
}

export interface GetFishingWaters {
  fishingWaters: GetFishingWaters_fishingWaters;
}

export interface GetFishingWatersVariables {
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWatersSearch
// ====================================================

export interface GetFishingWatersSearch_fishingWatersSearch_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetFishingWatersSearch_fishingWatersSearch_edges_node {
  __typename: "FishingWater";
  /**
   * DB ID
   */
  id: number;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  externalId: string;
  /**
   * The number of followers for this water
   */
  followersCount: number;
  latitude: number;
  longitude: number;
  name: string | null;
  waterType: number | null;
}

export interface GetFishingWatersSearch_fishingWatersSearch_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWatersSearch_fishingWatersSearch_edges_node | null;
}

export interface GetFishingWatersSearch_fishingWatersSearch {
  __typename: "FishingWaterRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetFishingWatersSearch_fishingWatersSearch_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetFishingWatersSearch_fishingWatersSearch_edges | null)[] | null;
}

export interface GetFishingWatersSearch {
  /**
   * Returns a search result of fishing waters
   */
  fishingWatersSearch: GetFishingWatersSearch_fishingWatersSearch;
}

export interface GetFishingWatersSearchVariables {
  query?: string | null;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWater
// ====================================================

export interface GetFishingWater_fishingWater_country {
  __typename: "Country";
  countryName: string;
}

export interface GetFishingWater_fishingWater_region {
  __typename: "Region";
  localName: string | null;
}

export interface GetFishingWater_fishingWater_metadata_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
  name: string;
}

export interface GetFishingWater_fishingWater_metadata {
  __typename: "FishingWaterMetadata";
  group: GetFishingWater_fishingWater_metadata_group;
  id: string;
  name: string;
}

export interface GetFishingWater_fishingWater {
  __typename: "FishingWater";
  /**
   * DB ID
   */
  id: number;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  description: string | null;
  /**
   * Human readable string of water type. Treat as a key for translation purposes
   */
  descriptionOfWaterType: WaterTypes;
  displayName: string;
  externalId: string;
  /**
   * The number of followers for this water
   */
  followersCount: number;
  latitude: number;
  longitude: number;
  country: GetFishingWater_fishingWater_country | null;
  region: GetFishingWater_fishingWater_region | null;
  /**
   * The metadata related to a fishing water
   */
  metadata: GetFishingWater_fishingWater_metadata[];
  name: string | null;
  waterType: number | null;
  /**
   * The fishing water's official website
   */
  url: string | null;
  /**
   * Boolean indicating if the water has local guide info
   */
  hasLocalGuideInfo: boolean | null;
}

export interface GetFishingWater {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetFishingWater_fishingWater;
}

export interface GetFishingWaterVariables {
  id?: number | null;
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGroupsOrnatus
// ====================================================

export interface GetGroupsOrnatus_groups_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetGroupsOrnatus_groups_edges_node_displayIcon {
  __typename: "Image";
  url: string;
}

export interface GetGroupsOrnatus_groups_edges_node_coverImage {
  __typename: "Image";
  url: string;
}

export interface GetGroupsOrnatus_groups_edges_node_members_edges_node {
  __typename: "User";
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  /**
   * DB ID
   */
  id: number;
}

export interface GetGroupsOrnatus_groups_edges_node_members_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetGroupsOrnatus_groups_edges_node_members_edges_node | null;
}

export interface GetGroupsOrnatus_groups_edges_node_members {
  __typename: "GroupMembersConnection";
  /**
   * A list of edges.
   */
  edges: (GetGroupsOrnatus_groups_edges_node_members_edges | null)[] | null;
}

export interface GetGroupsOrnatus_groups_edges_node {
  __typename: "Group";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  displayIcon: GetGroupsOrnatus_groups_edges_node_displayIcon;
  coverImage: GetGroupsOrnatus_groups_edges_node_coverImage | null;
  displayName: string;
  /**
   * A description of the group
   */
  description: string;
  /**
   * Type of a group
   */
  type: Visibility;
  /**
   * Users that are members of this group
   */
  members: GetGroupsOrnatus_groups_edges_node_members;
}

export interface GetGroupsOrnatus_groups_edges {
  __typename: "GroupEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetGroupsOrnatus_groups_edges_node | null;
}

export interface GetGroupsOrnatus_groups {
  __typename: "GroupRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetGroupsOrnatus_groups_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetGroupsOrnatus_groups_edges | null)[] | null;
}

export interface GetGroupsOrnatus {
  groups: GetGroupsOrnatus_groups;
}

export interface GetGroupsOrnatusVariables {
  cursor?: string | null;
  filters?: GroupFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGroupMembersOrnatus
// ====================================================

export interface GetGroupMembersOrnatus_entry_members_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetGroupMembersOrnatus_entry_members_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  externalId: string;
}

export interface GetGroupMembersOrnatus_entry_members_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetGroupMembersOrnatus_entry_members_edges_node | null;
}

export interface GetGroupMembersOrnatus_entry_members {
  __typename: "GroupMembersConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetGroupMembersOrnatus_entry_members_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetGroupMembersOrnatus_entry_members_edges | null)[] | null;
}

export interface GetGroupMembersOrnatus_entry {
  __typename: "Group";
  /**
   * Users that are members of this group
   */
  members: GetGroupMembersOrnatus_entry_members;
}

export interface GetGroupMembersOrnatus {
  /**
   * For fetching a single group (Group)
   */
  entry: GetGroupMembersOrnatus_entry;
}

export interface GetGroupMembersOrnatusVariables {
  id: string;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGroupFeedOrnatus
// ====================================================

export interface GetGroupFeedOrnatus_entry_feed_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetGroupFeedOrnatus_entry_feed_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetGroupFeedOrnatus_entry_feed_edges_node {
  __typename: "Post";
  /**
   * DB ID
   */
  id: number;
  /**
   * Text content in this post
   */
  text: GetGroupFeedOrnatus_entry_feed_edges_node_text;
  externalId: string;
}

export interface GetGroupFeedOrnatus_entry_feed_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetGroupFeedOrnatus_entry_feed_edges_node | null;
}

export interface GetGroupFeedOrnatus_entry_feed {
  __typename: "PostRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetGroupFeedOrnatus_entry_feed_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetGroupFeedOrnatus_entry_feed_edges | null)[] | null;
}

export interface GetGroupFeedOrnatus_entry {
  __typename: "Group";
  feed: GetGroupFeedOrnatus_entry_feed | null;
}

export interface GetGroupFeedOrnatus {
  /**
   * For fetching a single group (Group)
   */
  entry: GetGroupFeedOrnatus_entry;
}

export interface GetGroupFeedOrnatusVariables {
  id: string;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGroupOrnatus
// ====================================================

export interface GetGroupOrnatus_entry_displayIcon {
  __typename: "Image";
  url: string;
}

export interface GetGroupOrnatus_entry_coverImage {
  __typename: "Image";
  url: string;
}

export interface GetGroupOrnatus_entry_feed_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetGroupOrnatus_entry_feed_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetGroupOrnatus_entry_feed_edges_node {
  __typename: "Post";
  /**
   * DB ID
   */
  id: number;
  /**
   * Text content in this post
   */
  text: GetGroupOrnatus_entry_feed_edges_node_text;
  externalId: string;
}

export interface GetGroupOrnatus_entry_feed_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetGroupOrnatus_entry_feed_edges_node | null;
}

export interface GetGroupOrnatus_entry_feed {
  __typename: "PostRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetGroupOrnatus_entry_feed_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetGroupOrnatus_entry_feed_edges | null)[] | null;
}

export interface GetGroupOrnatus_entry_members_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetGroupOrnatus_entry_members_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  externalId: string;
}

export interface GetGroupOrnatus_entry_members_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetGroupOrnatus_entry_members_edges_node | null;
}

export interface GetGroupOrnatus_entry_members {
  __typename: "GroupMembersConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetGroupOrnatus_entry_members_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetGroupOrnatus_entry_members_edges | null)[] | null;
}

export interface GetGroupOrnatus_entry {
  __typename: "Group";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  displayIcon: GetGroupOrnatus_entry_displayIcon;
  coverImage: GetGroupOrnatus_entry_coverImage | null;
  displayName: string;
  /**
   * A description of the group
   */
  description: string;
  /**
   * Type of a group
   */
  type: Visibility;
  feed: GetGroupOrnatus_entry_feed | null;
  /**
   * Users that are members of this group
   */
  members: GetGroupOrnatus_entry_members;
}

export interface GetGroupOrnatus {
  /**
   * For fetching a single group (Group)
   */
  entry: GetGroupOrnatus_entry;
}

export interface GetGroupOrnatusVariables {
  externalId: string;
  membersCursor?: string | null;
  feedCursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWatersMetadata
// ====================================================

export interface GetWatersMetadata_fishingWaterMetadata_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetWatersMetadata_fishingWaterMetadata_edges_node_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
  name: string;
}

export interface GetWatersMetadata_fishingWaterMetadata_edges_node {
  __typename: "FishingWaterMetadata";
  id: string;
  name: string;
  group: GetWatersMetadata_fishingWaterMetadata_edges_node_group;
}

export interface GetWatersMetadata_fishingWaterMetadata_edges {
  __typename: "FishingWaterMetadataEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetWatersMetadata_fishingWaterMetadata_edges_node | null;
}

export interface GetWatersMetadata_fishingWaterMetadata {
  __typename: "FishingWaterMetadataRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetWatersMetadata_fishingWaterMetadata_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetWatersMetadata_fishingWaterMetadata_edges | null)[] | null;
}

export interface GetWatersMetadata {
  fishingWaterMetadata: GetWatersMetadata_fishingWaterMetadata;
}

export interface GetWatersMetadataVariables {
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPages
// ====================================================

export interface GetPages_pages_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetPages_pages_edges_node_logo {
  __typename: "Image";
  url: string;
}

export interface GetPages_pages_edges_node_coverImages_edges_node {
  __typename: "Image";
  url: string;
}

export interface GetPages_pages_edges_node_coverImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPages_pages_edges_node_coverImages_edges_node | null;
}

export interface GetPages_pages_edges_node_coverImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPages_pages_edges_node_coverImages_edges | null)[] | null;
}

export interface GetPages_pages_edges_node_promotedCallToActions_edges_node_image {
  __typename: "Image";
  url: string;
}

export interface GetPages_pages_edges_node_promotedCallToActions_edges_node {
  __typename: "PagesPromotedCallToAction";
  /**
   * ID
   */
  id: number;
  headline: string;
  subHeadline: string | null;
  targetUrl: string;
  buttonText: string;
  image: GetPages_pages_edges_node_promotedCallToActions_edges_node_image;
}

export interface GetPages_pages_edges_node_promotedCallToActions_edges {
  __typename: "PagesPromotedCallToActionEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPages_pages_edges_node_promotedCallToActions_edges_node | null;
}

export interface GetPages_pages_edges_node_promotedCallToActions {
  __typename: "PagesPromotedCallToActionRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPages_pages_edges_node_promotedCallToActions_edges | null)[] | null;
}

export interface GetPages_pages_edges_node_pageable_Brand {
  __typename: "Brand";
  id: number;
}

export interface GetPages_pages_edges_node_pageable_State {
  __typename: "State";
  /**
   * DB ID
   */
  id: number;
}

export type GetPages_pages_edges_node_pageable = GetPages_pages_edges_node_pageable_Brand | GetPages_pages_edges_node_pageable_State;

export interface GetPages_pages_edges_node {
  __typename: "Page";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  logo: GetPages_pages_edges_node_logo | null;
  coverImages: GetPages_pages_edges_node_coverImages;
  name: string | null;
  published: boolean;
  description: string | null;
  promotedCallToActions: GetPages_pages_edges_node_promotedCallToActions;
  pageable: GetPages_pages_edges_node_pageable | null;
}

export interface GetPages_pages_edges {
  __typename: "PageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPages_pages_edges_node | null;
}

export interface GetPages_pages {
  __typename: "PageRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetPages_pages_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetPages_pages_edges | null)[] | null;
}

export interface GetPages {
  pages: GetPages_pages;
}

export interface GetPagesVariables {
  cursor?: string | null;
  filters?: PageFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPage
// ====================================================

export interface GetPage_entry_logo {
  __typename: "Image";
  url: string;
}

export interface GetPage_entry_coverImages_edges_node {
  __typename: "Image";
  url: string;
}

export interface GetPage_entry_coverImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_coverImages_edges_node | null;
}

export interface GetPage_entry_coverImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_coverImages_edges | null)[] | null;
}

export interface GetPage_entry_followers {
  __typename: "UserRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPage_entry_ambassadors_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetPage_entry_ambassadors_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPage_entry_ambassadors_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_ambassadors_edges_node | null;
}

export interface GetPage_entry_ambassadors {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetPage_entry_ambassadors_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_ambassadors_edges | null)[] | null;
}

export interface GetPage_entry_publishers_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetPage_entry_publishers_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPage_entry_publishers_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_publishers_edges_node | null;
}

export interface GetPage_entry_publishers {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetPage_entry_publishers_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_publishers_edges | null)[] | null;
}

export interface GetPage_entry_admins_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetPage_entry_admins_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPage_entry_admins_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_admins_edges_node | null;
}

export interface GetPage_entry_admins {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetPage_entry_admins_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_admins_edges | null)[] | null;
}

export interface GetPage_entry_promotedCallToActions_edges_node_image {
  __typename: "Image";
  url: string;
}

export interface GetPage_entry_promotedCallToActions_edges_node {
  __typename: "PagesPromotedCallToAction";
  /**
   * ID
   */
  id: number;
  headline: string;
  subHeadline: string | null;
  buttonText: string;
  targetUrl: string;
  image: GetPage_entry_promotedCallToActions_edges_node_image;
}

export interface GetPage_entry_promotedCallToActions_edges {
  __typename: "PagesPromotedCallToActionEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_promotedCallToActions_edges_node | null;
}

export interface GetPage_entry_promotedCallToActions {
  __typename: "PagesPromotedCallToActionRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_promotedCallToActions_edges | null)[] | null;
}

export interface GetPage_entry_pageable_Brand {
  __typename: "Brand";
  id: number;
}

export interface GetPage_entry_pageable_State {
  __typename: "State";
  /**
   * DB ID
   */
  id: number;
}

export type GetPage_entry_pageable = GetPage_entry_pageable_Brand | GetPage_entry_pageable_State;

export interface GetPage_entry {
  __typename: "Page";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  name: string | null;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  published: boolean;
  logo: GetPage_entry_logo | null;
  coverImages: GetPage_entry_coverImages;
  followedByCurrentUser: boolean;
  followers: GetPage_entry_followers;
  ambassadors: GetPage_entry_ambassadors;
  publishers: GetPage_entry_publishers;
  admins: GetPage_entry_admins;
  promotedCallToActions: GetPage_entry_promotedCallToActions;
  description: string | null;
  pageable: GetPage_entry_pageable | null;
}

export interface GetPage {
  /**
   * For fetching a single page
   */
  entry: GetPage_entry;
}

export interface GetPageVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatePageAmbassadors
// ====================================================

export interface PaginatePageAmbassadors_entry_stuff_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface PaginatePageAmbassadors_entry_stuff_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface PaginatePageAmbassadors_entry_stuff_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: PaginatePageAmbassadors_entry_stuff_edges_node | null;
}

export interface PaginatePageAmbassadors_entry_stuff {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: PaginatePageAmbassadors_entry_stuff_pageInfo;
  /**
   * A list of edges.
   */
  edges: (PaginatePageAmbassadors_entry_stuff_edges | null)[] | null;
}

export interface PaginatePageAmbassadors_entry {
  __typename: "Page";
  stuff: PaginatePageAmbassadors_entry_stuff;
}

export interface PaginatePageAmbassadors {
  /**
   * For fetching a single page
   */
  entry: PaginatePageAmbassadors_entry;
}

export interface PaginatePageAmbassadorsVariables {
  id: string;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatePagePublishers
// ====================================================

export interface PaginatePagePublishers_entry_stuff_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface PaginatePagePublishers_entry_stuff_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface PaginatePagePublishers_entry_stuff_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: PaginatePagePublishers_entry_stuff_edges_node | null;
}

export interface PaginatePagePublishers_entry_stuff {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: PaginatePagePublishers_entry_stuff_pageInfo;
  /**
   * A list of edges.
   */
  edges: (PaginatePagePublishers_entry_stuff_edges | null)[] | null;
}

export interface PaginatePagePublishers_entry {
  __typename: "Page";
  stuff: PaginatePagePublishers_entry_stuff;
}

export interface PaginatePagePublishers {
  /**
   * For fetching a single page
   */
  entry: PaginatePagePublishers_entry;
}

export interface PaginatePagePublishersVariables {
  id: string;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatePageAdmins
// ====================================================

export interface PaginatePageAdmins_entry_stuff_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface PaginatePageAdmins_entry_stuff_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface PaginatePageAdmins_entry_stuff_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: PaginatePageAdmins_entry_stuff_edges_node | null;
}

export interface PaginatePageAdmins_entry_stuff {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: PaginatePageAdmins_entry_stuff_pageInfo;
  /**
   * A list of edges.
   */
  edges: (PaginatePageAdmins_entry_stuff_edges | null)[] | null;
}

export interface PaginatePageAdmins_entry {
  __typename: "Page";
  stuff: PaginatePageAdmins_entry_stuff;
}

export interface PaginatePageAdmins {
  /**
   * For fetching a single page
   */
  entry: PaginatePageAdmins_entry;
}

export interface PaginatePageAdminsVariables {
  id: string;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlaces
// ====================================================

export interface GetPlaces_places_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetPlaces_places_edges_node_unsplashImage {
  __typename: "UnsplashImage";
  /**
   * DB ID
   */
  id: number;
}

export interface GetPlaces_places_edges_node_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetPlaces_places_edges_node_region {
  __typename: "Region";
  regionCode: string | null;
  name: string | null;
}

export interface GetPlaces_places_edges_node {
  __typename: "Place";
  /**
   * DB ID
   */
  id: number;
  name: string;
  slug: string | null;
  description: string | null;
  /**
   * An image for this place
   */
  unsplashImage: GetPlaces_places_edges_node_unsplashImage | null;
  /**
   * The country this place belongs to
   */
  country: GetPlaces_places_edges_node_country;
  /**
   * The region this place belongs to
   */
  region: GetPlaces_places_edges_node_region;
}

export interface GetPlaces_places_edges {
  __typename: "PlaceEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPlaces_places_edges_node | null;
}

export interface GetPlaces_places {
  __typename: "PlaceRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetPlaces_places_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetPlaces_places_edges | null)[] | null;
}

export interface GetPlaces {
  places: GetPlaces_places;
}

export interface GetPlacesVariables {
  first: number;
  filters?: PlaceFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlace
// ====================================================

export interface GetPlace_place_unsplashImage {
  __typename: "UnsplashImage";
  /**
   * DB ID
   */
  id: number;
  /**
   * A URL for an image. If you use this make sure to read
   * the usage guidelines first -
   * https:                 // help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
   * You can also apply transformations to the image by altering the URL. See here
   * for more info - https: // unsplash.com/documentation#dynamically-resizable-images
   */
  imageUrl: string | null;
  /**
   * The name of the photographer.
   */
  photographerName: string | null;
  /**
   * The URL for the photographers Unsplash profile. Must be linked to w/ image.
   */
  photographerUrl: string | null;
}

export interface GetPlace_place {
  __typename: "Place";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  name: string;
  description: string | null;
  /**
   * An image for this place
   */
  unsplashImage: GetPlace_place_unsplashImage | null;
}

export interface GetPlace {
  place: GetPlace_place;
}

export interface GetPlaceVariables {
  countryCode: string;
  regionCode: string;
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostAvailableActions
// ====================================================

export interface GetPostAvailableActions_post_text {
  __typename: "Text";
  /**
   * DB ID
   */
  id: number | null;
  /**
   * Moderation status
   */
  status: string;
  /**
   * Available moderation actions
   */
  availableActions: string[];
}

export interface GetPostAvailableActions_post_video {
  __typename: "Video";
  id: number;
  /**
   * Moderation status
   */
  status: string;
  /**
   * Available moderation actions
   */
  availableActions: string[];
}

export interface GetPostAvailableActions_post_opImages_edges_node {
  __typename: "OpImage";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  /**
   * Moderation status
   */
  status: string;
  /**
   * Available moderation actions
   */
  availableActions: string[];
}

export interface GetPostAvailableActions_post_opImages_edges {
  __typename: "OpImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPostAvailableActions_post_opImages_edges_node | null;
}

export interface GetPostAvailableActions_post_opImages {
  __typename: "OpImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPostAvailableActions_post_opImages_edges | null)[] | null;
}

export interface GetPostAvailableActions_post {
  __typename: "Post";
  /**
   * Text content in this post
   */
  text: GetPostAvailableActions_post_text;
  /**
   * Video in this post
   */
  video: GetPostAvailableActions_post_video | null;
  /**
   * Used for reading status of images by moderation
   */
  opImages: GetPostAvailableActions_post_opImages;
}

export interface GetPostAvailableActions {
  /**
   * For fetching a single post
   */
  post: GetPostAvailableActions_post;
}

export interface GetPostAvailableActionsVariables {
  postId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatePostModerationTraces
// ====================================================

export interface PaginatePostModerationTraces_post_moderationTraces_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface PaginatePostModerationTraces_post_moderationTraces_edges_node {
  __typename: "ModerationTrace";
  status: string | null;
  reason: string | null;
  entityType: string | null;
  entityValue: string | null;
}

export interface PaginatePostModerationTraces_post_moderationTraces_edges {
  __typename: "ModerationTraceEdge";
  /**
   * The item at the end of the edge.
   */
  node: PaginatePostModerationTraces_post_moderationTraces_edges_node | null;
}

export interface PaginatePostModerationTraces_post_moderationTraces {
  __typename: "ModerationTraceEdgeConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: PaginatePostModerationTraces_post_moderationTraces_pageInfo;
  /**
   * A list of edges.
   */
  edges: (PaginatePostModerationTraces_post_moderationTraces_edges | null)[] | null;
}

export interface PaginatePostModerationTraces_post {
  __typename: "Post";
  /**
   * Moderation traces on this post
   */
  moderationTraces: PaginatePostModerationTraces_post_moderationTraces | null;
}

export interface PaginatePostModerationTraces {
  /**
   * For fetching a single post
   */
  post: PaginatePostModerationTraces_post;
}

export interface PaginatePostModerationTracesVariables {
  postId: number;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostSharedStatus
// ====================================================

export interface PostSharedStatus_post_sharedPost_user {
  __typename: "User";
  externalId: string;
  displayName: string;
}

export interface PostSharedStatus_post_sharedPost {
  __typename: "Post";
  externalId: string;
  /**
   * The user that created this post
   */
  user: PostSharedStatus_post_sharedPost_user;
}

export interface PostSharedStatus_post_sharedIn {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface PostSharedStatus_post {
  __typename: "Post";
  /**
   * DB ID
   */
  id: number;
  /**
   * Is this post sharing another post
   */
  sharingPost: boolean;
  /**
   * The shared post, if any
   */
  sharedPost: PostSharedStatus_post_sharedPost | null;
  /**
   * This post is has been shared by
   */
  sharedIn: PostSharedStatus_post_sharedIn;
}

export interface PostSharedStatus {
  /**
   * For fetching a single post
   */
  post: PostSharedStatus_post;
}

export interface PostSharedStatusVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListUrls
// ====================================================

export interface ListUrls_entries_edges_node {
  __typename: "OpUrl";
  /**
   * DB ID
   */
  id: number;
  longForm: string;
  status: string;
}

export interface ListUrls_entries_edges {
  __typename: "OpUrlEdge";
  /**
   * The item at the end of the edge.
   */
  node: ListUrls_entries_edges_node | null;
}

export interface ListUrls_entries_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface ListUrls_entries {
  __typename: "OpUrlRecordConnection";
  /**
   * A list of edges.
   */
  edges: (ListUrls_entries_edges | null)[] | null;
  /**
   * Information to aid in pagination.
   */
  pageInfo: ListUrls_entries_pageInfo;
}

export interface ListUrls {
  /**
   * URLs shared in posts
   */
  entries: ListUrls_entries;
}

export interface ListUrlsVariables {
  cursor?: string | null;
  status?: ModerationStatusEnum[] | null;
  url?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UrlsOnPost
// ====================================================

export interface UrlsOnPost_post_urls_edges_node {
  __typename: "OpUrl";
  /**
   * DB ID
   */
  id: number;
  status: string;
  longForm: string;
}

export interface UrlsOnPost_post_urls_edges {
  __typename: "OpUrlEdge";
  /**
   * The item at the end of the edge.
   */
  node: UrlsOnPost_post_urls_edges_node | null;
}

export interface UrlsOnPost_post_urls {
  __typename: "OpUrlRecordConnection";
  /**
   * A list of edges.
   */
  edges: (UrlsOnPost_post_urls_edges | null)[] | null;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface UrlsOnPost_post {
  __typename: "Post";
  /**
   * The urls extracted from the text of the post
   */
  urls: UrlsOnPost_post_urls | null;
}

export interface UrlsOnPost {
  /**
   * For fetching a single post
   */
  post: UrlsOnPost_post;
}

export interface UrlsOnPostVariables {
  postId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindProduct
// ====================================================

export interface FindProduct_entry {
  __typename: "Product";
  /**
   * DB ID
   */
  id: number;
  name: string;
}

export interface FindProduct {
  /**
   * For fetching a single product
   */
  entry: FindProduct_entry;
}

export interface FindProductVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListProducts
// ====================================================

export interface ListProducts_products_edges_node_brand {
  __typename: "Brand";
  name: string;
}

export interface ListProducts_products_edges_node_category {
  __typename: "Category";
  title: string;
}

export interface ListProducts_products_edges_node_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface ListProducts_products_edges_node {
  __typename: "Product";
  /**
   * DB ID
   */
  id: number;
  name: string;
  brand: ListProducts_products_edges_node_brand;
  category: ListProducts_products_edges_node_category;
  reviews: ListProducts_products_edges_node_reviews | null;
}

export interface ListProducts_products_edges {
  __typename: "ProductEdge";
  /**
   * The item at the end of the edge.
   */
  node: ListProducts_products_edges_node | null;
}

export interface ListProducts_products_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
}

export interface ListProducts_products {
  __typename: "ProductRecordConnection";
  /**
   * A list of edges.
   */
  edges: (ListProducts_products_edges | null)[] | null;
  /**
   * Information to aid in pagination.
   */
  pageInfo: ListProducts_products_pageInfo;
}

export interface ListProducts {
  /**
   * Equipment products, excluding legacy baits
   */
  products: ListProducts_products;
}

export interface ListProductsVariables {
  cursor?: string | null;
  filters?: ProductFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPromotionPartnerLinks
// ====================================================

export interface GetPromotionPartnerLinks_promotionPartnerLinks_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetPromotionPartnerLinks_promotionPartnerLinks_edges_node {
  __typename: "PromotionPartnerLink";
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  id: number;
  token: string;
  metaParams: any | null;
  openedAt: any | null;
  promotion: string | null;
  partnerUser: string | null;
  userId: number | null;
}

export interface GetPromotionPartnerLinks_promotionPartnerLinks_edges {
  __typename: "PromotionPartnerLinkEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPromotionPartnerLinks_promotionPartnerLinks_edges_node | null;
}

export interface GetPromotionPartnerLinks_promotionPartnerLinks {
  __typename: "PromotionPartnerLinkRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetPromotionPartnerLinks_promotionPartnerLinks_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetPromotionPartnerLinks_promotionPartnerLinks_edges | null)[] | null;
}

export interface GetPromotionPartnerLinks {
  promotionPartnerLinks: GetPromotionPartnerLinks_promotionPartnerLinks;
}

export interface GetPromotionPartnerLinksVariables {
  cursor?: string | null;
  promotion?: string | null;
  token?: string | null;
  userId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpeciesName
// ====================================================

export interface GetSpeciesName_singleSpecies {
  __typename: "Species";
  /**
   * Default name for this species
   */
  displayName: string;
  externalId: string;
}

export interface GetSpeciesName {
  /**
   * For fetching a single single species
   */
  singleSpecies: GetSpeciesName_singleSpecies;
}

export interface GetSpeciesNameVariables {
  speciesId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpeciesTips
// ====================================================

export interface GetSpeciesTips_singleSpecies_speciesTips_edges_node_user {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
}

export interface GetSpeciesTips_singleSpecies_speciesTips_edges_node {
  __typename: "SpeciesTip";
  externalId: string;
  tip: string;
  /**
   * Creator of the tip
   */
  user: GetSpeciesTips_singleSpecies_speciesTips_edges_node_user;
  userDescription: string | null;
  /**
   * If set this will use the custom display name for the user, if not the user's full name will be returned.
   */
  userDisplayName: string;
}

export interface GetSpeciesTips_singleSpecies_speciesTips_edges {
  __typename: "SpeciesTipEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSpeciesTips_singleSpecies_speciesTips_edges_node | null;
}

export interface GetSpeciesTips_singleSpecies_speciesTips {
  __typename: "SpeciesTipRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSpeciesTips_singleSpecies_speciesTips_edges | null)[] | null;
}

export interface GetSpeciesTips_singleSpecies {
  __typename: "Species";
  speciesTips: GetSpeciesTips_singleSpecies_speciesTips;
}

export interface GetSpeciesTips {
  /**
   * For fetching a single single species
   */
  singleSpecies: GetSpeciesTips_singleSpecies;
}

export interface GetSpeciesTipsVariables {
  speciesId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListHosts
// ====================================================

export interface ListHosts_entries_edges_node {
  __typename: "OpUrlHost";
  /**
   * DB ID
   */
  id: number;
  hostname: string;
  status: string;
}

export interface ListHosts_entries_edges {
  __typename: "OpUrlHostEdge";
  /**
   * The item at the end of the edge.
   */
  node: ListHosts_entries_edges_node | null;
}

export interface ListHosts_entries_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface ListHosts_entries {
  __typename: "OpUrlHostRecordConnection";
  /**
   * A list of edges.
   */
  edges: (ListHosts_entries_edges | null)[] | null;
  /**
   * Information to aid in pagination.
   */
  pageInfo: ListHosts_entries_pageInfo;
}

export interface ListHosts {
  /**
   * Hosts used in shared URLs
   */
  entries: ListHosts_entries;
}

export interface ListHostsVariables {
  cursor?: string | null;
  status?: ModerationStatusEnum[] | null;
  hostname?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserWithBlocksAndBlockedTargets
// ====================================================

export interface UserWithBlocksAndBlockedTargets_user_blockedBy_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
}

export interface UserWithBlocksAndBlockedTargets_user_blockedBy_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface UserWithBlocksAndBlockedTargets_user_blockedBy_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: UserWithBlocksAndBlockedTargets_user_blockedBy_edges_node | null;
}

export interface UserWithBlocksAndBlockedTargets_user_blockedBy {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: UserWithBlocksAndBlockedTargets_user_blockedBy_pageInfo;
  /**
   * A list of edges.
   */
  edges: (UserWithBlocksAndBlockedTargets_user_blockedBy_edges | null)[] | null;
}

export interface UserWithBlocksAndBlockedTargets_user_blockedTargets_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
}

export interface UserWithBlocksAndBlockedTargets_user_blockedTargets_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface UserWithBlocksAndBlockedTargets_user_blockedTargets_edges {
  __typename: "UserEdge";
  /**
   * The item at the end of the edge.
   */
  node: UserWithBlocksAndBlockedTargets_user_blockedTargets_edges_node | null;
}

export interface UserWithBlocksAndBlockedTargets_user_blockedTargets {
  __typename: "UserRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: UserWithBlocksAndBlockedTargets_user_blockedTargets_pageInfo;
  /**
   * A list of edges.
   */
  edges: (UserWithBlocksAndBlockedTargets_user_blockedTargets_edges | null)[] | null;
}

export interface UserWithBlocksAndBlockedTargets_user {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  /**
   * Users who have blocked this user
   */
  blockedBy: UserWithBlocksAndBlockedTargets_user_blockedBy;
  /**
   * Users who this user blocks
   */
  blockedTargets: UserWithBlocksAndBlockedTargets_user_blockedTargets;
}

export interface UserWithBlocksAndBlockedTargets {
  /**
   * A user in the app
   */
  user: UserWithBlocksAndBlockedTargets_user;
}

export interface UserWithBlocksAndBlockedTargetsVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userStats
// ====================================================

export interface userStats_user_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface userStats_user_followers {
  __typename: "UserRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface userStats_user_followedUsers {
  __typename: "UserRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface userStats_user {
  __typename: "User";
  catches: userStats_user_catches;
  followers: userStats_user_followers;
  followedUsers: userStats_user_followedUsers;
}

export interface userStats {
  /**
   * A user in the app
   */
  user: userStats_user;
}

export interface userStatsVariables {
  external_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userSupportActions
// ====================================================

export interface userSupportActions_user_userSupportActions_edges_node_actor {
  __typename: "User";
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface userSupportActions_user_userSupportActions_edges_node {
  __typename: "UserSupportAction";
  /**
   * DB ID
   */
  id: number;
  actor: userSupportActions_user_userSupportActions_edges_node_actor | null;
  actionType: string;
  reason: string | null;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
}

export interface userSupportActions_user_userSupportActions_edges {
  __typename: "UserSupportActionEdge";
  /**
   * The item at the end of the edge.
   */
  node: userSupportActions_user_userSupportActions_edges_node | null;
}

export interface userSupportActions_user_userSupportActions {
  __typename: "UserSupportActionConnection";
  /**
   * A list of edges.
   */
  edges: (userSupportActions_user_userSupportActions_edges | null)[] | null;
}

export interface userSupportActions_user {
  __typename: "User";
  userSupportActions: userSupportActions_user_userSupportActions;
}

export interface userSupportActions {
  /**
   * A user in the app
   */
  user: userSupportActions_user;
}

export interface userSupportActionsVariables {
  external_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userNavionicsSubscription
// ====================================================

export interface userNavionicsSubscription_user_navionicsSubscription_activeProduct {
  __typename: "NavionicsProduct";
  name: string;
  uuid: string;
}

export interface userNavionicsSubscription_user_navionicsSubscription_latestPurchase {
  __typename: "NavionicsPurchase";
  createdAt: any;
  purchaseType: string;
  transactionId: string;
}

export interface userNavionicsSubscription_user_navionicsSubscription {
  __typename: "NavionicsSubscription";
  expiresAt: any | null;
  isActive: boolean;
  activeProduct: userNavionicsSubscription_user_navionicsSubscription_activeProduct | null;
  latestPurchase: userNavionicsSubscription_user_navionicsSubscription_latestPurchase | null;
}

export interface userNavionicsSubscription_user {
  __typename: "User";
  /**
   * Status of Navionics depth maps subscription
   */
  navionicsSubscription: userNavionicsSubscription_user_navionicsSubscription | null;
}

export interface userNavionicsSubscription {
  /**
   * A user in the app
   */
  user: userNavionicsSubscription_user;
}

export interface userNavionicsSubscriptionVariables {
  external_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserSuggestedChanges
// ====================================================

export interface GetUserSuggestedChanges_userSuggestedChanges_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetUserSuggestedChanges_userSuggestedChanges_edges_node_fishingWater {
  __typename: "FishingWater";
  externalId: string;
}

export interface GetUserSuggestedChanges_userSuggestedChanges_edges_node_user {
  __typename: "User";
  externalId: string;
}

export interface GetUserSuggestedChanges_userSuggestedChanges_edges_node_reviewer {
  __typename: "User";
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetUserSuggestedChanges_userSuggestedChanges_edges_node_assignee {
  __typename: "User";
  externalId: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetUserSuggestedChanges_userSuggestedChanges_edges_node {
  __typename: "UserSuggestedChange";
  externalId: string;
  fishingWater: GetUserSuggestedChanges_userSuggestedChanges_edges_node_fishingWater | null;
  id: string;
  status: string;
  user: GetUserSuggestedChanges_userSuggestedChanges_edges_node_user | null;
  email: string | null;
  /**
   * The admin user who reviewed the suggestion
   */
  reviewer: GetUserSuggestedChanges_userSuggestedChanges_edges_node_reviewer | null;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Date suggestion was reviewed (UTC)
   */
  reviewedAt: any | null;
  /**
   * The admin user who is assigned to review the suggestion
   */
  assignee: GetUserSuggestedChanges_userSuggestedChanges_edges_node_assignee | null;
}

export interface GetUserSuggestedChanges_userSuggestedChanges_edges {
  __typename: "UserSuggestedChangeEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserSuggestedChanges_userSuggestedChanges_edges_node | null;
}

export interface GetUserSuggestedChanges_userSuggestedChanges {
  __typename: "UserSuggestedChangeRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetUserSuggestedChanges_userSuggestedChanges_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetUserSuggestedChanges_userSuggestedChanges_edges | null)[] | null;
}

export interface GetUserSuggestedChanges {
  /**
   * User suggested changes
   */
  userSuggestedChanges: GetUserSuggestedChanges_userSuggestedChanges;
}

export interface GetUserSuggestedChangesVariables {
  cursor?: string | null;
  filters?: UserSuggestedChangeFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserSuggestedChange
// ====================================================

export interface GetUserSuggestedChange_userSuggestedChange_attachments_edges_node {
  __typename: "Attachment";
  contentType: string;
  /**
   * For image/jpeg and image/png attachments only
   */
  url: string;
}

export interface GetUserSuggestedChange_userSuggestedChange_attachments_edges {
  __typename: "AttachmentEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserSuggestedChange_userSuggestedChange_attachments_edges_node | null;
}

export interface GetUserSuggestedChange_userSuggestedChange_attachments {
  __typename: "AttachmentRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUserSuggestedChange_userSuggestedChange_attachments_edges | null)[] | null;
}

export interface GetUserSuggestedChange_userSuggestedChange_fishingWater_metadata_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
  name: string;
}

export interface GetUserSuggestedChange_userSuggestedChange_fishingWater_metadata {
  __typename: "FishingWaterMetadata";
  group: GetUserSuggestedChange_userSuggestedChange_fishingWater_metadata_group;
  id: string;
  name: string;
}

export interface GetUserSuggestedChange_userSuggestedChange_fishingWater {
  __typename: "FishingWater";
  externalId: string;
  displayName: string;
  /**
   * The metadata related to a fishing water
   */
  metadata: GetUserSuggestedChange_userSuggestedChange_fishingWater_metadata[];
  name: string | null;
  latitude: number;
  longitude: number;
  /**
   * The fishing water's official website
   */
  url: string | null;
}

export interface GetUserSuggestedChange_userSuggestedChange_suggestedWaterName {
  __typename: "SuggestedWaterName";
  id: string;
  name: string | null;
}

export interface GetUserSuggestedChange_userSuggestedChange_user {
  __typename: "User";
  externalId: string;
}

export interface GetUserSuggestedChange_userSuggestedChange_userSuggestedWaterMetadata_fishingWaterMetadata_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
  name: string;
}

export interface GetUserSuggestedChange_userSuggestedChange_userSuggestedWaterMetadata_fishingWaterMetadata {
  __typename: "FishingWaterMetadata";
  group: GetUserSuggestedChange_userSuggestedChange_userSuggestedWaterMetadata_fishingWaterMetadata_group;
  id: string;
  name: string;
}

export interface GetUserSuggestedChange_userSuggestedChange_userSuggestedWaterMetadata {
  __typename: "UserSuggestedWaterMetadata";
  /**
   * DB ID
   */
  id: number;
  fishingWaterMetadata: GetUserSuggestedChange_userSuggestedChange_userSuggestedWaterMetadata_fishingWaterMetadata;
}

export interface GetUserSuggestedChange_userSuggestedChange {
  __typename: "UserSuggestedChange";
  additionalInformation: string | null;
  email: string | null;
  externalId: string;
  attachments: GetUserSuggestedChange_userSuggestedChange_attachments;
  fishingWater: GetUserSuggestedChange_userSuggestedChange_fishingWater | null;
  id: string;
  latitude: number | null;
  longitude: number | null;
  rejectionReason: string | null;
  status: string;
  suggestedWaterName: GetUserSuggestedChange_userSuggestedChange_suggestedWaterName | null;
  url: string | null;
  user: GetUserSuggestedChange_userSuggestedChange_user | null;
  userSuggestedWaterMetadata: GetUserSuggestedChange_userSuggestedChange_userSuggestedWaterMetadata | null;
}

export interface GetUserSuggestedChange {
  /**
   * A user suggested change
   */
  userSuggestedChange: GetUserSuggestedChange_userSuggestedChange | null;
}

export interface GetUserSuggestedChangeVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: boundingBoxCatches
// ====================================================

export interface boundingBoxCatches_catches_edges_node_user {
  __typename: "User";
  id: string;
  /**
   * Unique identifying nickname for user
   */
  identifier: string;
}

export interface boundingBoxCatches_catches_edges_node_fishingWater {
  __typename: "FishingWater";
  identifier: string | null;
  id: string;
}

export interface boundingBoxCatches_catches_edges_node {
  __typename: "Catch";
  externalId: string;
  id: string;
  lng: number | null;
  lat: number | null;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  caughtAtGmt: any | null;
  privateFishingWater: boolean;
  privatePosition: boolean;
  /**
   * Owner of the catch
   */
  user: boundingBoxCatches_catches_edges_node_user;
  fishingWater: boundingBoxCatches_catches_edges_node_fishingWater | null;
}

export interface boundingBoxCatches_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: boundingBoxCatches_catches_edges_node | null;
}

export interface boundingBoxCatches_catches_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  cursor: string | null;
}

export interface boundingBoxCatches_catches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (boundingBoxCatches_catches_edges | null)[] | null;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: boundingBoxCatches_catches_pageInfo;
}

export interface boundingBoxCatches {
  catches: boundingBoxCatches_catches;
}

export interface boundingBoxCatchesVariables {
  boundingBox: BoundingBoxInputObject;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostTargetDisplayEntity
// ====================================================

export interface GetPostTargetDisplayEntity_post_targetDisplayEntity_displayIcon {
  __typename: "Image";
  photoUrl: string;
}

export interface GetPostTargetDisplayEntity_post_targetDisplayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  /**
   * ID of display entity for deep links
   */
  id: number;
  externalId: string;
  displayIcon: GetPostTargetDisplayEntity_post_targetDisplayEntity_displayIcon;
  displayName: string;
}

export interface GetPostTargetDisplayEntity_post {
  __typename: "Post";
  /**
   * The target (page, group) that this post was posted to
   */
  targetDisplayEntity: GetPostTargetDisplayEntity_post_targetDisplayEntity | null;
}

export interface GetPostTargetDisplayEntity {
  /**
   * For fetching a single post
   */
  post: GetPostTargetDisplayEntity_post;
}

export interface GetPostTargetDisplayEntityVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostDisplayEntity
// ====================================================

export interface GetPostDisplayEntity_post_displayEntity_displayIcon {
  __typename: "Image";
  photoUrl: string;
}

export interface GetPostDisplayEntity_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  /**
   * ID of display entity for deep links
   */
  id: number;
  externalId: string;
  displayIcon: GetPostDisplayEntity_post_displayEntity_displayIcon;
  displayName: string;
}

export interface GetPostDisplayEntity_post {
  __typename: "Post";
  displayEntity: GetPostDisplayEntity_post_displayEntity;
}

export interface GetPostDisplayEntity {
  /**
   * For fetching a single post
   */
  post: GetPostDisplayEntity_post;
}

export interface GetPostDisplayEntityVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DisplayEntity
// ====================================================

export interface DisplayEntity_displayIcon {
  __typename: "Image";
  photoUrl: string;
}

export interface DisplayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  /**
   * ID of display entity for deep links
   */
  id: number;
  externalId: string;
  displayIcon: DisplayEntity_displayIcon;
  displayName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The types of Coupon Groups
 */
export enum CouponGroupTypeEnum {
  DISCOUNT = "DISCOUNT",
  GIFT = "GIFT",
}

/**
 * The usage frequency for Coupon Groups
 */
export enum CouponGroupUsageEnum {
  MULTIPLE = "MULTIPLE",
  ONCE = "ONCE",
}

export enum DeleteRecordByExternalIdTypeEnum {
  CATCH_SPECIES_IDENTIFICATION = "CATCH_SPECIES_IDENTIFICATION",
  FISHING_WATER = "FISHING_WATER",
  FISHING_WATER_REVIEW = "FISHING_WATER_REVIEW",
  GROUP = "GROUP",
  POST = "POST",
  PRODUCT_REVIEW = "PRODUCT_REVIEW",
  SPECIES_TIP = "SPECIES_TIP",
  WAYPOINT = "WAYPOINT",
}

export enum GroupLatestPostOrdering {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum ModerationStatusEnum {
  APPROVED = "APPROVED",
  BEING_MODERATED = "BEING_MODERATED",
  NEW = "NEW",
  REJECTED = "REJECTED",
  SCHEDULED_FOR_MODERATION = "SCHEDULED_FOR_MODERATION",
  UNDER_REVIEW = "UNDER_REVIEW",
}

export enum OrderTypes {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PagePopularityOrdering {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum RolesEnum {
  ADMIN = "ADMIN",
  AMBASSADOR = "AMBASSADOR",
  PUBLISHER = "PUBLISHER",
}

/**
 * The status of a user suggested change
 */
export enum UserSuggestedChangeStatusTypes {
  accepted = "accepted",
  rejected = "rejected",
  submitted = "submitted",
}

export enum UserSuggestedChangeTypes {
  coordinates = "coordinates",
  metadata = "metadata",
  other = "other",
  url = "url",
  water_name = "water_name",
}

export enum Visibility {
  HIDDEN = "HIDDEN",
  PUBLIC = "PUBLIC",
}

export enum WaterTypes {
  canal = "canal",
  estuary = "estuary",
  lake = "lake",
  ocean = "ocean",
  stream = "stream",
  swamp = "swamp",
  unknown = "unknown",
}

export interface ActiveStorageImageUpload {
  signedId: string;
}

/**
 * Autogenerated input type of AddStaffMutation
 */
export interface AddStaffMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  attributes: PageUsers;
}

export interface AdministerUserSuggestedChangeAttributes {
  fishingWaterExternalId?: string | null;
  fishingWaterMetadataExternalId?: string | null;
  newWaterName?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  url?: string | null;
  status: UserSuggestedChangeStatusTypes;
  rejectionReason?: string | null;
}

/**
 * Autogenerated input type of AdministerUserSuggestedChangeMutation
 */
export interface AdministerUserSuggestedChangeMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  attributes: AdministerUserSuggestedChangeAttributes;
}

/**
 * Autogenerated input type of AssignAdminRoleToUserMutation
 */
export interface AssignAdminRoleToUserMutationInput {
  clientMutationId?: string | null;
  userId: string;
}

/**
 * Autogenerated input type of AssignUserSuggestedChangeMutation
 */
export interface AssignUserSuggestedChangeMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  userExternalId: string;
}

export interface BoundingBoxInputObject {
  southWest: PositionInputObject;
  northEast: PositionInputObject;
}

export interface BrandFilters {
  orderByName?: boolean | null;
  orderByPopularity?: boolean | null;
  nameContains?: string | null;
  forCategoryExternalId?: string | null;
  withProducts?: boolean | null;
}

export interface CouponGroupAttributes {
  name: string;
  percentOff: number;
  durationInMonths: number;
  typeName: CouponGroupTypeEnum;
  usageName: CouponGroupUsageEnum;
  stripeCouponId: string;
  stripePlanId: string;
}

/**
 * Attributes for creating a brand.
 */
export interface CreateBrandAttributes {
  brandImage?: ActiveStorageImageUpload | null;
  address?: string | null;
  url?: string | null;
  name: string;
}

/**
 * Autogenerated input type of CreateBrandMutation
 */
export interface CreateBrandMutationInput {
  clientMutationId?: string | null;
  attributes: CreateBrandAttributes;
}

/**
 * Autogenerated input type of CreateBrandPageMutation
 */
export interface CreateBrandPageMutationInput {
  clientMutationId?: string | null;
  brandId: string;
  gearTab?: boolean | null;
}

/**
 * Autogenerated input type of CreateCouponGroupMutation
 */
export interface CreateCouponGroupMutationInput {
  clientMutationId?: string | null;
  groupAttributes: CouponGroupAttributes;
  code?: string | null;
  quantity?: number | null;
}

export interface CreateCuratedSpeciesCatchAttributes {
  speciesExternalId: string;
  catchExternalId: string;
}

/**
 * Autogenerated input type of CreateCuratedSpeciesCatchMutation
 */
export interface CreateCuratedSpeciesCatchMutationInput {
  clientMutationId?: string | null;
  attributes: CreateCuratedSpeciesCatchAttributes;
}

/**
 * Autogenerated input type of CreateDirectUploadURLMutation
 */
export interface CreateDirectUploadURLMutationInput {
  clientMutationId?: string | null;
  attributes: ImageUploadAttributes;
}

/**
 * Autogenerated input type of CreateFishingWaterMutation
 */
export interface CreateFishingWaterMutationInput {
  clientMutationId?: string | null;
  attributes: FishingWaterCreateAttributes;
}

/**
 * Autogenerated input type of CreateSpeciesTipMutation
 */
export interface CreateSpeciesTipMutationInput {
  clientMutationId?: string | null;
  attributes: SpeciesTipAttributes;
}

/**
 * Autogenerated input type of DeleteRecordByExternalIdMutation
 */
export interface DeleteRecordByExternalIdMutationInput {
  clientMutationId?: string | null;
  type: DeleteRecordByExternalIdTypeEnum;
  externalId: string;
  mergedIntoExternalId?: string | null;
}

/**
 * Autogenerated input type of EditImageMutation
 */
export interface EditImageMutationInput {
  clientMutationId?: string | null;
  id: string;
  attributes: ImageAttributes;
}

/**
 * Autogenerated input type of EditTextMutation
 */
export interface EditTextMutationInput {
  clientMutationId?: string | null;
  id: string;
  attributes: TextAttributes;
}

/**
 * Autogenerated input type of EditURLHostMutation
 */
export interface EditURLHostMutationInput {
  clientMutationId?: string | null;
  id: string;
  attributes: URLHostAttributes;
}

/**
 * Autogenerated input type of EditURLMutation
 */
export interface EditURLMutationInput {
  clientMutationId?: string | null;
  id: string;
  attributes: URLAttributes;
}

/**
 * Autogenerated input type of EditVideoMutation
 */
export interface EditVideoMutationInput {
  clientMutationId?: string | null;
  id: string;
  attributes: VideoAttributes;
}

export interface FishingWaterCreateAttributes {
  description?: string | null;
  url?: string | null;
  metadataIds?: string[] | null;
  name: string;
  type: WaterTypes;
  coordinates: PositionInputObject;
}

export interface FishingWaterUpdateAttributes {
  description?: string | null;
  url?: string | null;
  addMetadataIds?: string[] | null;
  removeMetadataIds?: string[] | null;
  name?: string | null;
  type?: WaterTypes | null;
  geonamesId?: string | null;
  coordinates?: PositionInputObject | null;
  hasLocalGuideInfo?: boolean | null;
}

export interface GroupFilters {
  containingName?: string | null;
  orderByLatestPost?: GroupLatestPostOrdering | null;
  orderByUnseenPosts?: GroupLatestPostOrdering | null;
  nonMember?: boolean | null;
}

export interface ImageAttributes {
  moderationAction?: string | null;
  moderationReason?: string | null;
}

export interface ImageUploadAttributes {
  contentType: string;
  filename: string;
  byteSize: number;
  checksum: string;
  width: number;
  height: number;
}

/**
 * Autogenerated input type of MarkUserForReverificationMutation
 */
export interface MarkUserForReverificationMutationInput {
  clientMutationId?: string | null;
  userId: number;
}

export interface PageAttributes {
  published?: boolean | null;
  description?: string | null;
  title?: string | null;
  membersTitle?: string | null;
  logo?: ActiveStorageImageUpload | null;
  coverImages?: ActiveStorageImageUpload[] | null;
}

export interface PageFilters {
  published?: boolean | null;
  publishedBrands?: boolean | null;
  publishedStates?: boolean | null;
  orderByPopularity?: PagePopularityOrdering | null;
  containingName?: string | null;
  brands?: boolean | null;
  brandsOrderedByName?: boolean | null;
  orderByLatestPost?: boolean | null;
  orderRandomly?: boolean | null;
}

export interface PageUsers {
  users: UserWithRole[];
}

export interface PlaceAttributes {
  description?: string | null;
}

export interface PlaceFilters {
  containingName?: string | null;
  orderedByPopulation?: boolean | null;
}

export interface PositionInputObject {
  latitude: number;
  longitude: number;
}

export interface ProductFilters {
  orderByName?: boolean | null;
  orderByPopularity?: boolean | null;
  brandIds?: number[] | null;
  brandExternalIds?: string[] | null;
  externalIds?: string[] | null;
  webAdvertIds?: number[] | null;
  categoryIds?: number[] | null;
  categoryExternalIds?: string[] | null;
  inShop?: boolean | null;
  nameContains?: string | null;
  nameContainsOrWebAdvertId?: string | null;
  allStatuses?: boolean | null;
}

/**
 * Autogenerated input type of RemoveStaffMutation
 */
export interface RemoveStaffMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  attributes: PageUsers;
}

/**
 * Autogenerated input type of SetUserBanStatusMutation
 */
export interface SetUserBanStatusMutationInput {
  clientMutationId?: string | null;
  userId: number;
  banned: boolean;
  reason: string;
}

export interface SpeciesTipAttributes {
  tip: string;
  userId: string;
  speciesId: string;
  userDisplayName?: string | null;
  userDescription?: string | null;
}

export interface TextAttributes {
  moderationAction?: string | null;
  moderationReason?: string | null;
}

export interface URLAttributes {
  status?: string | null;
}

export interface URLHostAttributes {
  status?: string | null;
}

/**
 * Autogenerated input type of UnassignAdminRoleToUserMutation
 */
export interface UnassignAdminRoleToUserMutationInput {
  clientMutationId?: string | null;
  userId: string;
}

/**
 * Attributes for updating a brand and page. For a family of *_title and *_description
 *             arguments please refer to PageMutation
 */
export interface UpdateBrandAttributes {
  brandImage?: ActiveStorageImageUpload | null;
  address?: string | null;
  url?: string | null;
  name?: string | null;
  aboutDescription?: string | null;
  aboutTitle?: string | null;
  productsTitle?: string | null;
  productsDescription?: string | null;
  membersTitle?: string | null;
}

/**
 * Autogenerated input type of UpdateBrandMutation
 */
export interface UpdateBrandMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  attributes: UpdateBrandAttributes;
}

/**
 * Autogenerated input type of UpdateFishingWaterMutation
 */
export interface UpdateFishingWaterMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  attributes: FishingWaterUpdateAttributes;
}

/**
 * Autogenerated input type of UpdatePageMutation
 */
export interface UpdatePageMutationInput {
  clientMutationId?: string | null;
  id: string;
  attributes: PageAttributes;
}

/**
 * Autogenerated input type of UpdatePlaceMutation
 */
export interface UpdatePlaceMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  attributes: PlaceAttributes;
}

export interface UpdatePlaceUnsplashImageAttributes {
  imageUrl: string;
  photographerName: string;
  photographerUrl: string;
}

/**
 * Autogenerated input type of UpdatePlaceUnsplashImageMutation
 */
export interface UpdatePlaceUnsplashImageMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  attributes?: UpdatePlaceUnsplashImageAttributes | null;
}

/**
 * Autogenerated input type of UpsertNavionicsSubscriptionMutation
 */
export interface UpsertNavionicsSubscriptionMutationInput {
  clientMutationId?: string | null;
  userId?: string | null;
}

export interface UserFilters {
  havingAvatar?: boolean | null;
  nonbanned?: boolean | null;
  currentUserFollowedFirst?: boolean | null;
  followedByCurrentUser?: boolean | null;
  adminOnly?: boolean | null;
  nicknameStartsWith?: string | null;
}

export interface UserSuggestedChangeFilters {
  orderByCreation?: OrderTypes | null;
  externalId?: string | null;
  userExternalId?: string | null;
  email?: string | null;
  fishingWaterExternalId?: string | null;
  changeType?: UserSuggestedChangeTypes | null;
  status?: UserSuggestedChangeStatusTypes | null;
  reviewerNickname?: string | null;
  assignee?: string | null;
}

export interface UserWithRole {
  externalId: string;
  role: RolesEnum;
}

export interface VerifyUserEmailAttributes {
  externalId: string;
}

/**
 * Autogenerated input type of VerifyUserEmailMutation
 */
export interface VerifyUserEmailMutationInput {
  clientMutationId?: string | null;
  attributes: VerifyUserEmailAttributes;
}

export interface VideoAttributes {
  moderationAction?: string | null;
  moderationReason?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
