import { gql } from 'graphql-tag';

export const paginatePostModerationTraces = gql`
  query PaginatePostModerationTraces($postId: Int!, $cursor: String) {
    post(id: $postId) {
      moderationTraces(first: 10, after: $cursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            status
            reason
            entityType
            entityValue
          }
        }
      }
    }
  }
`;
