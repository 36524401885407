/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import Details from '../../components/Details/Details';
import {
  GetSpeciesName,
  GetSpeciesNameVariables,
  CreateSpeciesTip as ICreateSpeciesTipMutation,
  CreateSpeciesTipVariables,
  SpeciesTipAttributes,
} from '../../interfaces/graphql';
import { getSpeciesName } from '../../queries/Species';
import Fieldset from '../../components/Fieldset/Fieldset';
import Input from '../../components/Input/Input';
import Textarea from '../../components/Input/Textarea';
import CreateSpeciesTipMutation from '../../mutations/CreateSpeciesTip';
import LoadingButton from '../../components/Clickables/Buttons/LoadingButton';

interface IAttributes {
  userId: string;
  tip: string;
  userDisplayName: string;
  userDescription: string;
}

function validateAttributes(attributes: IAttributes): boolean {
  if (attributes.tip.length === 0 || attributes.userId.length === 0) {
    return false;
  }
  return true;
}

function transformAttributes(
  speciesId: string,
  { userDescription, userDisplayName, ...rest }: IAttributes,
): SpeciesTipAttributes {
  return {
    ...rest,
    speciesId,
    userDescription: userDescription.length ? userDescription : undefined,
    userDisplayName: userDisplayName.length ? userDisplayName : undefined,
  };
}

function getDefaultAttributes(): IAttributes {
  return {
    userId: '',
    tip: '',
    userDisplayName: '',
    userDescription: '',
  };
}

export const CreateSpeciesTip = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [attributes, setAttributes] = useState<IAttributes>(() => getDefaultAttributes());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data } = useQuery<GetSpeciesName, GetSpeciesNameVariables>(getSpeciesName, {
    variables: { speciesId: parseInt(id, 10) },
  });
  const [createTip] = useMutation<ICreateSpeciesTipMutation, CreateSpeciesTipVariables>(
    CreateSpeciesTipMutation,
  );

  const externalId = data && data.singleSpecies.externalId;

  const submit = () => {
    setIsSubmitting(true);
    if (externalId && validateAttributes(attributes)) {
      createTip({
        variables: { input: { attributes: transformAttributes(externalId, attributes) } },
      })
        .then(() => {
          setAttributes(getDefaultAttributes());
          setIsSubmitting(false);
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <Details title="Create Species Tip">
      <form id="new-tip">
        <Fieldset>
          <Input
            type="text"
            value={data ? data.singleSpecies.displayName : ''}
            name="species"
            readOnly
          />
          <label>Species</label>
        </Fieldset>
        <Fieldset>
          <Input
            value={attributes.userId}
            onChange={(e) => setAttributes({ ...attributes, userId: e.currentTarget.value })}
          />
          <label>User External ID</label>
        </Fieldset>
        <Fieldset>
          <Textarea
            value={attributes.tip}
            onChange={(e) => setAttributes({ ...attributes, tip: e.currentTarget.value })}
          />
          <label>Tip</label>
        </Fieldset>
        <Fieldset>
          <Input
            type="text"
            value={attributes.userDisplayName}
            name="userDisplayName"
            onChange={(e) =>
              setAttributes({ ...attributes, userDisplayName: e.currentTarget.value })
            }
          />
          <label>User Display Name</label>
        </Fieldset>
        <Fieldset>
          <Input
            type="text"
            value={attributes.userDescription}
            name="userDescription"
            onChange={(e) =>
              setAttributes({ ...attributes, userDescription: e.currentTarget.value })
            }
          />
          <label>User Description</label>
        </Fieldset>
        <LoadingButton
          type="submit"
          isLoading={isSubmitting}
          onClick={submit}
          loadingText="Saving..."
        >
          Create Species Tip
        </LoadingButton>
      </form>
    </Details>
  );
};
