import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import Table from '../../components/Table/Table';
import LoadingTableBody from '../../components/Loading/LoadingTableBody';
import AttributeDate from '../../components/AttributeComponents/AttributeDate';

import User from '../../models/User';
import { userSupportActions as query } from '../../queries/User';
import {
  userSupportActions,
  userSupportActionsVariables,
  userSupportActions_user_userSupportActions_edges_node,
} from '../../interfaces/graphql';

const SIZE = 4;

const UserSupportActionParser = (data: userSupportActions | undefined) =>
  ((data && data.user.userSupportActions.edges) || [])
    .map((n) => n && n.node)
    .filter((n) => n) as userSupportActions_user_userSupportActions_edges_node[];

const UserSupportAction = ({ entry }: { entry: User }): JSX.Element => {
  const { loading, error, data } = useQuery<userSupportActions, userSupportActionsVariables>(
    query,
    {
      variables: { external_id: entry.external_id },
    },
  );

  if (error) {
    throw new Error('Data not found.');
  }

  const actions = UserSupportActionParser(data);

  return (
    <Table>
      <thead>
        <tr>
          <th>Actor</th>
          <th>Action Type</th>
          <th>Reason</th>
          <th>Date</th>
        </tr>
      </thead>
      {loading ? (
        <LoadingTableBody rows={1} columns={SIZE} />
      ) : (
        <tbody>
          {actions.map((action) => (
            <tr key={action.id}>
              <td>
                {action.actor ? (
                  <Link to={`/users/${action.actor.externalId}`}>{action.actor.nickname}</Link>
                ) : (
                  'rutilus'
                )}
              </td>
              <td>{action.actionType}</td>
              <td>{action.reason}</td>
              <td>
                <AttributeDate value={action.createdAt} />
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </Table>
  );
};

export default UserSupportAction;
