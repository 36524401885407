import { Link, useLocation } from 'react-router-dom';

import { IClickableProps, getVariantClass } from '../variants';
import { findCreateNewRoute } from './helpers/helpers';
import { VIEWS_WITH_CREATE_NEW_BUTTON } from './helpers/constants';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    IClickableProps {}

const CreateNewButton = ({
  size = 'default',
  variant = 'buttonPrimary',
}: IButtonProps): JSX.Element => {
  const { pathname } = useLocation();
  const view = findCreateNewRoute(pathname, VIEWS_WITH_CREATE_NEW_BUTTON);

  if (!view) {
    return <></>;
  }

  return (
    <Link
      to={`${view.path}/new`}
      className={`${getVariantClass(
        variant,
        size,
      )} text-base bg-primary rounded-md hover:bg-deep-sea`}
    >
      Create New {view.title}
    </Link>
  );
};

export default CreateNewButton;
