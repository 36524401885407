import { QueryResult } from '@apollo/client';
import { get } from 'ts-get';

// This helper function extracts the nodes from a GQL connection into an array an filters out any
// null edges/nodes.
export function getNodesFromConnection<T>(
  connection?: {
    edges: ({ node: T | null } | null)[] | null;
  } | null,
): T[] {
  return get(connection, (c) => c.edges.filter((e) => e && e.node).map((e) => e.node), []) as T[];
}

export const genericLoadMore =
  (dataName: string) => (fetchMore: QueryResult['fetchMore'], currentData: any) => {
    fetchMore({
      updateQuery: (previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
        const newEdges = fetchMoreResult[dataName].edges;
        const { pageInfo } = fetchMoreResult[dataName];

        return {
          [dataName]: {
            pageInfo,
            __typename: previousResult[dataName].__typename,
            edges: [...previousResult[dataName].edges, ...newEdges],
          },
        };
      },
      variables: {
        cursor: currentData.pageInfo.endCursor,
      },
    });
  };
