import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Brands from './Brands';
import Categories from './Categories';
import Products from './Products';

const Equipment = (): JSX.Element => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/brands`} component={Brands} />
      <Route path={`${match.path}/categories`} component={Categories} />
      <Route path={`${match.path}/products`} component={Products} />
    </Switch>
  );
};

export default Equipment;
