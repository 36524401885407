.header {
  align-items: center;
  background: rgba(black, 0.03);
  display: flex;
  font-size: 2rem;
  font-weight: 600;
  height: var(--header-height);
  padding: 0 var(--padding-base);
  width: 100%;
}
