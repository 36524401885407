/* $input-color: rgb(61, 61, 61);
$uneditable-color: lighten($input-color, 20%);
$label-color: $input-color;
$edit-blue: rgb(92, 177, 235); */

.list {
  list-style: none;
  margin-top: 0;
}

.list_row {
  margin-bottom: 10px;
}

.list_row > .values {
  border-bottom: 1px solid rgb(219, 219, 219);
}

.form_label {
  color: $label-color;
  display: block;
  font-size: 10px;
  position: relative;
}

.buttons {
  margin-bottom: var(--padding-base);
}

.deletion_note {
  margin-top: calc(var(--padding-base) / 2);
}

.likes {
  margin-bottom: 10px;
}

.media {
  margin: 0;
  padding: 0;

  & img {
    width: 100%;
  }

  & figcaption {
    font-size: 0.8rem;
  }

  &:hover {
    opacity: 0.9;
  }
}

.mediaList {
  display: grid;
  grid-gap: 7.5px;
  grid-template-columns: repeat(3, calc((100% / 3) - 5px));
}

.msg {
  color: $input-color;
  font-size: 15px;
  font-style: italic;
  margin-left: 10px;
  margin-top: 10px;
}

.video {
  max-height: 400px;
  width: 100%;
}

.select {
  margin-right: 10px;
}

.formSpacer {
  margin-bottom: 10px;
}

.commentsContainer {
  padding: 5px;
}

.btnWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  & button {
    align-items: center;
    display: flex;

    & i {
      margin-left: 5px;
      margin-top: 3px;
    }
  }
}
