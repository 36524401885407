import { gql } from 'graphql-tag';

export const searchAdminusers = gql`
  query GetUsers($cursor: String, $filters: UserFilters) {
    users(first: 50, after: $cursor, filters: $filters) {
      edges {
        node {
          externalId
          nickname
        }
      }
    }
  }
`;
