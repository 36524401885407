import { Component } from 'react';

import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import Details from '../../components/Details/Details';
import Tag, { ITagDetails, ITagDetailsItem } from '../../models/Tag';
import Loading from '../../components/Loading/Loading';

import styles from '../../components/Edit/Form.module.css';
import modelListStyles from '../../components/ModelList/ModelList.module.css';
import tagDetailsStyles from './TagDetails.module.css';
import Button from '../../components/Clickables/Buttons/Button';

interface IState {
  details: ITagDetails;
  loading: boolean;
  page: number;
}

const PER_PAGE = 10;

class TagDetails extends Component<RouteComponentProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      details: {
        count: 0,
        items: [],
      },
      loading: false,
      page: 1,
    };
  }

  public componentDidMount(): void {
    this.fetchItems();
  }

  public componentDidUpdate(nextProps: RouteComponentProps): void {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.fetchItems();
    }
  }

  public render(): React.ReactElement {
    const { details, loading } = this.state;

    return (
      <Details title="Tag items">
        <Loading isLoading={loading}>
          <ul className={styles.list}>
            <li className={styles.list_row}>
              <h4>Items Count:{details.count || 0}</h4>
            </li>
            {this.renderItems(details.items)}
          </ul>
          {this.state.page * PER_PAGE < details.count ? (
            <div className={modelListStyles.loadPage}>
              <Button className={modelListStyles.loadPageBtn} onClick={this.handlePaging}>
                Load more <br />
                <i className="fas fa-angle-down" />
              </Button>
            </div>
          ) : null}
        </Loading>
      </Details>
    );
  }

  private renderItems(items: ITagDetailsItem[]): JSX.Element[] {
    return items.map((singleItem: ITagDetailsItem, index: number) => {
      const imageUrl =
        singleItem.item.photos &&
        singleItem.item.photos[0] &&
        singleItem.item.photos[0].photo &&
        singleItem.item.photos[0].photo.base_url;

      return (
        <div
          key={index}
          className={`${tagDetailsStyles.item} ${imageUrl ? tagDetailsStyles.joindItems : ''}`}
        >
          {imageUrl && (
            <li className={`${styles.list_row} ${imageUrl ? tagDetailsStyles.imageContainer : ''}`}>
              <img
                src={singleItem.item.photos[0].photo.base_url}
                className={tagDetailsStyles.image}
                alt="item"
              />
            </li>
          )}
          <div className={`${imageUrl ? tagDetailsStyles.listItemSplitted : ''}`}>
            <li className={styles.list_row}>
              <label>Type:{singleItem.type || ''}</label>
            </li>
            <li className={styles.list_row}>
              <label>
                Owner:
                {`${singleItem.item.owner && singleItem.item.owner.first_name} ${
                  singleItem.item.owner && singleItem.item.owner.last_name
                }`}
              </label>
            </li>
            <li className={styles.list_row}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                Owners nickname:
                <strong>
                  <Link to={`/users/${singleItem.item.owner && singleItem.item.owner.id}`}>
                    {singleItem.item.owner && singleItem.item.owner.nickname}{' '}
                    <i className="fas fa-pen fa-xs" />
                  </Link>
                </strong>
              </label>
            </li>
            <li className={styles.list_row}>
              <label>Liked:{singleItem.liked ? 'Yes' : 'No'}</label>
            </li>
            <li className={styles.list_row}>
              <label>
                Created at:
                {singleItem.item.created_at
                  ? new Date(singleItem.item.created_at).toLocaleString()
                  : ''}
              </label>
            </li>
            <li className={styles.list_row}>
              <label>External id:{singleItem.item.external_id || ''}</label>
            </li>
            <li className={styles.list_row}>
              <label>Comments count:{singleItem.item.comments_count || 0}</label>
            </li>
            <li className={styles.list_row}>
              <label>Likes count:{singleItem.item.likes_count || 0}</label>
            </li>
          </div>
        </div>
      );
    });
  }

  private fetchItems(isPaging?: boolean): void {
    const { match } = this.props;
    const { page } = this.state;
    const newPage = isPaging ? page + 1 : 1;
    const params = match && match.params ? { ...match.params } : { tag: '' };
    this.setState({ loading: true });

    Tag.fetchTagDetails(params, newPage, PER_PAGE).then((res) => {
      const details = {
        count: res.count,
        items: isPaging ? this.state.details.items.concat(res.items) : res.items,
      };

      this.setState({ details, loading: false, page: newPage });
    });
  }

  private readonly handlePaging = (): void => {
    this.fetchItems(true);
  };
}

export default withRouter(TagDetails);
