/* eslint-disable jsx-a11y/control-has-associated-label */

import { Link } from 'react-router-dom';
import queryString from 'query-string';

import SelectFilterInput from '../../components/FilterInputs/SelectFilterInput';
import { FormProps, FormText } from '../../components/Edit/FormAttributes/FormAttribute';
import styles from '../../components/Fieldset/Fieldset.module.css';

export const UserExternalId = ({ entry }: { entry: any }): JSX.Element => {
  if (entry.user.externalId) {
    return (
      <Link
        to={{
          pathname: `/users/${entry.user.externalId}`,
        }}
      >
        {entry.user.externalId}
      </Link>
    );
  }

  return <></>;
};

export const ReviewerNickname = ({ entry }: { entry: any }): JSX.Element => {
  if (entry.reviewer?.nickname) {
    return <>{entry.reviewer.nickname}</>;
  }

  return <></>;
};

export const FishingWaterExternalId = ({ entry }: { entry: any }): JSX.Element => {
  if (entry?.fishingWater?.externalId) {
    return (
      <Link
        to={{
          pathname: `/fishing_waters/${entry?.fishingWater?.externalId}`,
        }}
      >
        {entry.fishingWater.externalId}
      </Link>
    );
  }

  return <></>;
};

export const statusFilters = (props: any): JSX.Element => (
  <SelectFilterInput {...props}>
    <option />
    <option>submitted</option>
    <option>accepted</option>
    <option>rejected</option>
  </SelectFilterInput>
);

export const FormTextSuggestion = (props: FormProps): JSX.Element => {
  const { attribute } = props.header;
  return <FormText className={props.values[attribute] && styles.suggestedchange} {...props} />;
};

type SortAttributeValue = 'asc' | 'desc';

export const getSortAttributeValue = (
  search: string,
  currentAttributeName: string,
): SortAttributeValue => {
  const parsed = queryString.parse(search);

  // Get the value of the 'sort' parameter
  const sortParam = parsed.sort;

  // If the sort parameter exists, split it and get the attributes and orders
  if (sortParam !== null && typeof sortParam === 'string') {
    // Split by comma for multiple sort attributes
    const sortPairs = sortParam.split(',');
    const matchedSortPair = sortPairs.find((pair) => {
      const [attribute] = pair.trim().split(' ');
      return attribute === currentAttributeName;
    });

    if (matchedSortPair) {
      const [, order] = matchedSortPair.trim().split(' ');
      return order as SortAttributeValue;
    }
  }

  return 'desc';
};
