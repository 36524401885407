import AsyncSelect from 'react-select/async';

export interface Value {
  value: any;
  label: string;
}

export type Loader = (value: string) => Promise<Value[]>;

export const isMultiValue = (val: Value | Value[]): val is Value[] =>
  val && (val as Value[]).length !== undefined;

interface Props {
  loadOptions: Loader;
  value?: Value;
}

const SearchInput = (props: Props): React.ReactElement => {
  const { value, loadOptions, ...rest } = props;
  return (
    <AsyncSelect
      {...rest}
      value={value}
      defaultOptions
      loadOptions={loadOptions}
      isClearable
      onInputChange={(inputValue: string): string => inputValue}
    />
  );
};

export default SearchInput;
