import { gql } from 'graphql-tag';

export default gql`
  query ListCategories($cursor: String) {
    categories(first: 20, after: $cursor) {
      edges {
        node {
          id
          title
          ancestry
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
