export interface ViewWithCreateNewButton {
  path: string;
  title: string;
}

export const VIEWS_WITH_CREATE_NEW_BUTTON: ViewWithCreateNewButton[] = [
  {
    path: '/posts',
    title: 'Post',
  },
  {
    path: '/equipment/brands',
    title: 'Brand',
  },
  {
    path: '/species',
    title: 'Species',
  },
  {
    path: '/fishing_waters',
    title: 'Fishing water',
  },
  {
    path: '/ads',
    title: 'Ad',
  },
  {
    path: '/coupon-groups',
    title: 'Coupon Group',
  },
];
