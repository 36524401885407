import UserCatch from '../../../models/UserCatch';
import Flex from '../../Flex/Flex';
import ModelList from '../../ModelList/ModelList';

import AttributeDate from '../../AttributeComponents/AttributeDate';
import AttributeExternalLink from '../../AttributeComponents/AttributeExternalLink';
import AttributeID from '../../AttributeComponents/AttributeID';
import IHeader from '../../List/HeadersInterface';

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    filterable: false,
    link: '/catches/',
    title: 'Id',
  },
  {
    attribute: 'caught_at_gmt',
    component: AttributeDate,
    filterable: false,
    title: 'Caught at',
  },
  {
    attribute: 'deep_link',
    component: AttributeExternalLink,
    title: 'Deeplink',
  },
];

const UserCatches = (): JSX.Element => (
  <Flex>
    <ModelList headers={headers} model={UserCatch} />
  </Flex>
);

export default UserCatches;
