import { gql } from 'graphql-tag';

export const getUserSuggestedChanges = gql`
  query GetUserSuggestedChanges($cursor: String, $filters: UserSuggestedChangeFilters) {
    userSuggestedChanges(first: 20, after: $cursor, filters: $filters) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          externalId
          fishingWater {
            externalId
          }
          id
          status
          user {
            externalId
          }
          email
          reviewer {
            nickname
          }
          createdAt
          reviewedAt
          assignee {
            externalId
            nickname
          }
        }
      }
    }
  }
`;

export const getUserSuggestedChange = gql`
  query GetUserSuggestedChange($externalId: String!) {
    userSuggestedChange(externalId: $externalId) {
      additionalInformation
      email
      externalId
      attachments(first: 10) {
        edges {
          node {
            contentType
            url
          }
        }
      }
      fishingWater {
        externalId
        displayName
        metadata {
          group {
            id
            name
          }
          id
          name
        }
        name
        latitude
        longitude
        url
      }
      id
      latitude
      longitude
      rejectionReason
      status
      suggestedWaterName {
        id
        name
      }
      url
      user {
        externalId
      }
      userSuggestedWaterMetadata {
        id
        fishingWaterMetadata {
          group {
            id
            name
          }
          id
          name
        }
      }
    }
  }
`;
