.head {
  color: #4d4d4d;
}

.head th {
  background: white;
  position: sticky;
  text-align: left;
  top: 0;
  white-space: nowrap;
}

.head > :last-child th {
  top: 31px;
}

.head a,
.head tr:first-child th {
  font-size: 0.85rem;
  text-transform: uppercase;
}

/* // This is needed because position:sticky on the th element doesnt apply to borders for some reason. */
.head th::after {
  border-bottom: 1px solid #cecece;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  width: 100%;
}

/* // Overrides the above style in the case that there is no filter. */
.head tr:only-child th {
  top: 0 !important;
}
