// https://github.com/activerecord-hackery/ransack/blob/6434e3bedfbf4cc5d97d33c84d10682970a95083/lib/ransack/locale/en.yml#L15

export enum RansackMatcher {
  Equals = 'eq',
  EqualsAny = 'eq_any',
  EqualsAll = 'eq_all',
  NotEquals = 'not_eq',
  NotEqualsToAny = 'not_eq_any',
  NotEqualsToAll = 'not_eq_all',
  Matches = 'matches',
  MatchesAny = 'matches_any',
  MatchesAll = 'matches_all',
  DoesNotMatch = 'does_not_match',
  DoesNotMatchToAny = 'does_not_match_any',
  DoesNotMatchToAll = 'does_not_match_all',
  LessThan = 'lt',
  LessThanAny = 'lt_any',
  LessThanAll = 'lt_all',
  LessThanOrEquals = 'lteq',
  LessThanOrEqualsToAny = 'lteq_any',
  LessThanOrEqualsToAall = 'lteq_all',
  GreaterThan = 'gt',
  GreaterThanAny = 'gt_any',
  GreaterThanAll = 'gt_all',
  GreaterThanOrEquals = 'gteq',
  GreaterThanOrEqualsToAny = 'gteq_any',
  GreaterThanOrEqualsToAll = 'gteq_all',
  In = 'in',
  InAny = 'in_any',
  InAll = 'in_all',
  NotIn = 'not_in',
  NotInAny = 'not_in_any',
  NotInAll = 'not_in_all',
  Contains = 'cont',
  ContainsAny = 'cont_any',
  ContainsAll = 'cont_all',
  DoesntContain = 'not_cont',
  DoesntContainAny = 'not_cont_any',
  DoesntContainAll = 'not_cont_all',
  Start = 'start',
  StartAny = 'start_any',
  StartAll = 'start_all',
  DoesntStart = 'not_start',
  DoesntStartAny = 'not_start_any',
  DoesntStartALl = 'not_start_all',
  Ends = 'end',
  EndsAny = 'end_any',
  EndsAll = 'end_all',
  DoesntEnd = 'not_end',
  DoesntEndAny = 'not_end_any',
  DoesntEndAll = 'not_end_all',
  IsTrue = 'true',
  IsFalse = 'false',
  IsPresent = 'present',
  IsBlank = 'blank',
  IsNull = 'null',
  IsNotNull = 'not_null',
}
