import { ViewWithCreateNewButton } from './constants';

export const findCreateNewRoute = (
  pathname: string,
  views: ViewWithCreateNewButton[],
): ViewWithCreateNewButton | undefined => {
  const route: ViewWithCreateNewButton | undefined = views.find((r) =>
    r.path ? pathname.includes(r.path) : false,
  );
  return route || undefined;
};
