import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { SetUserBanStatus as mutation } from '../../mutations/User';
import { SetUserBanStatus, SetUserBanStatusVariables } from '../../interfaces/graphql';

import User from '../../models/User';
import Fieldset from '../../components/Fieldset/Fieldset';
import Button from '../../components/Clickables/Buttons/Button';

function getButtonText(banned: boolean): string {
  if (banned === false) {
    return 'Ban User';
  }
  return 'Unban User';
}

const ShadowBanButton = ({ entry }: { entry: User }): JSX.Element => {
  const [triggerShadowBan, { loading }] = useMutation<SetUserBanStatus, SetUserBanStatusVariables>(
    mutation,
  );

  const [banned, setState] = useState(entry.shadow_ban);
  const [reason, setReason] = useState('');

  const text = banned ? 'Remove shadow ban' : 'Shadow ban user';

  const shadowban = () => {
    if (reason === '') {
      alert('Please provide a reason');
      return;
    }
    triggerShadowBan({
      variables: { input: { userId: entry.id, banned: !banned, reason } },
    });
    setReason('');
    setState(!banned);
  };

  return (
    <form id="new-form">
      <span>{text}</span>
      <Fieldset
        label="Reason"
        onChange={({ currentTarget: { value } }: React.SyntheticEvent<HTMLInputElement>) =>
          setReason(value)
        }
        inputProps={{ value: reason, placeholder: '', required: true }}
      />
      <Button
        variant="buttonSecondary"
        onClick={shadowban}
        size="sm"
        disabled={loading}
        data-testid="shadowban"
      >
        {getButtonText(banned)}
      </Button>
    </form>
  );
};

export default ShadowBanButton;
