import { SyntheticEvent, useState } from 'react';

import { useMutation, ApolloError } from '@apollo/client';

import { addStaffToPage as addStaffToPageMutation } from '../../mutations/Pages/AddStaff';
import {
  AddStaffMutationVariables,
  AddStaffMutation as AddStaffMutationOutput,
  RolesEnum,
} from '../../interfaces/graphql';
import Button from '../../components/Clickables/Buttons/Button';

class UserWithRole {
  public externalId: string;

  private static idCounter = 0;

  public id: number;

  public role: RolesEnum;

  public incorrectId = false;

  public constructor(externalId: string, role: RolesEnum) {
    this.externalId = externalId;
    this.role = role;

    // eslint-disable-next-line no-plusplus
    ++UserWithRole.idCounter; // eslint-disable-line no-plusplus

    this.id = UserWithRole.idCounter;
  }

  public markInvalid() {
    this.incorrectId = true;
  }
}

interface IAddStaffListComponentProps {
  pageExternalId: string;
}

const AddStaffListComponent = (props: IAddStaffListComponentProps) => {
  const externalIdInErrorRegex = /\[([^\]]+)]/;
  const { pageExternalId } = props;
  const [userList, setUserList] = useState([] as UserWithRole[]);

  const [addStaff] = useMutation<AddStaffMutationOutput, AddStaffMutationVariables>(
    addStaffToPageMutation,
    {
      onCompleted: ({ addStaffToPage }: AddStaffMutationOutput) => {
        if (addStaffToPage) {
          const { userErrors } = addStaffToPage;

          if (userErrors === undefined || userErrors.length === 0) {
            setUserList([]);
            return;
          }

          const { message } = userErrors[0];
          if (message && externalIdInErrorRegex.test(message)) {
            const matchArray = message.match(externalIdInErrorRegex);
            if (matchArray && matchArray.length > 0) {
              const externalId = matchArray[1];
              const newList = userList.map((user: UserWithRole) => {
                if (user.externalId === externalId) {
                  user.markInvalid();
                }
                return user;
              });
              setUserList(newList);
            }
          }
        } else {
          alert("Operation didn't complete");
          setUserList([]);
        }
      },
      onError: (error: ApolloError) => alert(error.message),
      variables: {
        input: {
          externalId: pageExternalId,
          attributes: {
            users: userList.map((user: UserWithRole) => ({
              externalId: user.externalId,
              role: user.role,
            })),
          },
        },
      },
    },
  );

  const [inputExternalId, setInputedExternalId] = useState('');
  const [chosenRole, chooseRole] = useState(RolesEnum.AMBASSADOR);
  const handleInputExternalId = (externalId: string) => {
    setInputedExternalId(externalId);
  };

  const handleRoleChange = (role: RolesEnum) => {
    chooseRole(role);
  };

  const removeFromList = (userId: number) => {
    setUserList(userList.filter((user) => user.id !== userId));
  };

  const handleAddNewUser = () => {
    if (inputExternalId) {
      const user = new UserWithRole(inputExternalId, chosenRole);
      setUserList(userList.concat(user));
      setInputedExternalId('');
    }
  };

  return (
    <div>
      <h3>Add new staff</h3>
      <table>
        <thead>
          <tr>
            <td>External Id</td>
            <td>Role</td>
            <td>Remove</td>
          </tr>
        </thead>
        <tbody>
          {userList.map((r: UserWithRole) => (
            <tr key={r.id} style={r.incorrectId ? { backgroundColor: 'red' } : {}}>
              <td>{r.externalId}</td>
              <td>{r.role}</td>
              <td>
                <Button
                  size="sm"
                  variant="buttonDanger"
                  onClick={() => {
                    removeFromList(r.id);
                  }}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <Button size="sm" onClick={async () => addStaff()}>
          Save
        </Button>
      </table>
      <input
        type="text"
        placeholder="User external id "
        value={inputExternalId}
        onChange={(event: SyntheticEvent<HTMLInputElement>) =>
          handleInputExternalId(event.currentTarget.value)
        }
      />
      <select
        name="role"
        onChange={(event: SyntheticEvent<HTMLSelectElement>) => {
          handleRoleChange(RolesEnum[event.currentTarget.value as keyof typeof RolesEnum]);
        }}
        value={chosenRole}
      >
        <option value={RolesEnum.AMBASSADOR}>Ambassador</option>
        <option value={RolesEnum.PUBLISHER}>Publisher</option>
        <option value={RolesEnum.ADMIN}>Admin</option>
      </select>
      <Button size="sm" onClick={() => handleAddNewUser()}>
        Add User
      </Button>
    </div>
  );
};

export default AddStaffListComponent;
