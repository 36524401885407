import DateInput from '../Input/DateInput';
import { RansackMatcher } from '../../helpers/ransack';
import styles from './DateFilterInput.module.css';

import { IProps as FilterInputProps } from './FilterInput';

const DateFilterInput = (props: FilterInputProps): React.ReactElement<FilterInputProps> => {
  const { inputProps, filters } = props;
  const gteqValue: string | undefined = filters && filters[RansackMatcher.GreaterThanOrEquals];
  const ltValue: string | undefined = filters && filters[RansackMatcher.LessThan];
  const onChange =
    (matcher: RansackMatcher): ((event: React.SyntheticEvent<HTMLInputElement>) => void) =>
    (event: React.SyntheticEvent<HTMLInputElement>): void => {
      props.onChange(props.attributeName, matcher, event.currentTarget.value, {
        refresh: true,
      });
    };
  return (
    <div className={styles.wrapper}>
      <DateInput
        value={gteqValue}
        className={styles.input}
        inputProps={inputProps}
        onChange={onChange(RansackMatcher.GreaterThanOrEquals)}
      />
      <DateInput
        value={ltValue}
        className={styles.input}
        inputProps={inputProps}
        onChange={onChange(RansackMatcher.LessThan)}
      />
    </div>
  );
};

export default DateFilterInput;
