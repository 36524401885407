import { Component } from 'react';

import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import Bookmarks, { IBookmark } from '../Bookmarks/Bookmarks';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import IHeader from '../List/HeadersInterface';
import LoadingTableBody from '../Loading/LoadingTableBody';
import { mergeQueryParams } from '../../helpers/urlhelpers';
import Filter from '../../helpers/filter';
import BaseModel from '../../models/BaseModel';
import GalleryView from '../GalleryView/GalleryView';
import ListBody from '../List/ListBody';
import ListHeader from '../List/ListHeader';
import Switcher, { ITab } from '../Switcher/Switcher';

import styles from './ModelList.module.css';

const ROWS_COUNT = 20;
const BASE = 10;

interface IState {
  entries: BaseModel[];
  loadedPages: number;
  loading: boolean;
}

interface IProps extends RouteComponentProps {
  headers: IHeader[];
  model: any;
  bookmarks?: IBookmark[];
  isGalleryView?: boolean;
  imageUrlProperty?: string;
  activeTab?: number;
  tabs?: ITab[];
  onTabClick?: (tabIndex: number) => void;
}

class ModelList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.loadEntries = this.loadEntries.bind(this);
    this.showMoreButton = this.showMoreButton.bind(this);
    this.state = {
      entries: [],
      loadedPages: 0,
      loading: true,
    };
  }

  public componentDidMount(): void {
    this.loadEntries(1);
  }

  public componentDidUpdate(prevProps: IProps): void {
    // check if shall reset the entries that has been loaded
    const oldQuery = new URLSearchParams(prevProps.location.search);
    const oldPage = Number.parseInt(oldQuery.get('page') || '1', BASE);
    const { currentQuery } = this;
    oldQuery.delete('page');
    currentQuery.delete('page');
    if (this.page < oldPage || currentQuery.toString() !== oldQuery.toString()) {
      this.setState({ loadedPages: 0, entries: [] });
    }

    // check if we should load more pages
    const { loadedPages, loading } = this.state;
    if (!loading && loadedPages < this.page) {
      this.loadEntries(loadedPages + 1);
    }
  }

  public render(): React.ReactElement {
    const { entries, loading } = this.state;
    const {
      headers,
      location,
      bookmarks,
      isGalleryView,
      model,
      imageUrlProperty,
      tabs,
      activeTab,
      onTabClick,
    } = this.props;
    const { pathname, search } = location;
    const parameters = {
      pathname,
      search: this.mergeQueryParams({ page: this.page + 1 }),
    };
    return (
      <div className={styles.scroll}>
        <Bookmarks bookmarks={bookmarks} />
        {tabs && <Switcher tabs={tabs} active={activeTab} onClick={onTabClick} />}
        {isGalleryView ? (
          <GalleryView
            entries={entries}
            headers={headers}
            model={model}
            showMoreButton={this.showMoreButton()}
            onShowMoreClick={this.onShowMoreClick}
            loading={loading}
            imageUrlProperty={imageUrlProperty}
          />
        ) : (
          <>
            <table className={styles.table}>
              <ListHeader headers={headers} />
              <ErrorBoundary>
                <ListBody headers={headers} entries={entries} path={pathname} search={search} />
              </ErrorBoundary>
              {loading && <LoadingTableBody rows={ROWS_COUNT} columns={headers.length} />}
            </table>
            <div className={styles.loadPage}>
              {this.showMoreButton() ? (
                <Link className={styles.loadPageBtn} to={parameters}>
                  Load more <br />
                  <i className="fas fa-angle-down" />
                </Link>
              ) : null}
            </div>
          </>
        )}
      </div>
    );
  }

  private loadEntries(page: number): void {
    this.setState({ loading: true });
    const perPage = ROWS_COUNT;
    const { sortBy, filterBy, currentSearchQuery } = this;
    const urlParams = this.getUrlParams();
    this.props.model
      .list({
        page,
        perPage,
        sortBy,
        filterBy,
        urlParams,
        searchQuery: currentSearchQuery,
      })
      .then((newEntries: BaseModel[]) => {
        this.setState((prevState: IState) => ({
          entries: prevState.entries.concat(newEntries),
          loadedPages: prevState.loadedPages + 1,
          loading: false,
        }));
      })
      .catch(alert.bind(this, 'Could not load entries'));
  }

  private showMoreButton(): boolean {
    const { entries } = this.state;
    return entries.length >= this.page * ROWS_COUNT;
  }

  private get sortBy(): string | undefined {
    return this.currentQuery.get('sort') || undefined;
  }

  private get filterBy(): Record<string, unknown> {
    return Filter.parse(this.props.location.search).filtersForFetching;
  }

  private get page(): number {
    return Number.parseInt(this.currentQuery.get('page') || '1', BASE);
  }

  private mergeQueryParams(newQuery: { [key: string]: any }): string {
    const { search } = this.props.location;
    return mergeQueryParams(search, newQuery);
  }

  private get currentQuery(): URLSearchParams {
    const { search } = this.props.location;
    return new URLSearchParams(search);
  }

  private get currentSearchQuery(): string | null {
    return this.currentQuery.get('search');
  }

  private getUrlParams(): any {
    const { match } = this.props;
    return match && match.params ? match.params : {};
  }

  private readonly onShowMoreClick = () => {
    const { pathname } = this.props.location;
    const parameters = {
      pathname,
      search: this.mergeQueryParams({ page: this.page + 1 }),
    };

    this.props.history.push(parameters);
  };
}

export default withRouter(ModelList);
