import { createRoot } from 'react-dom/client';
import { ThemeProvider, DefaultTheme } from 'barramundi';
import { CookiesProvider } from 'react-cookie';

import App from './App';
import './index.css';
import { AuthProvider } from './contexts/AuthContext';

const AppWithTheme = (): JSX.Element => (
  <CookiesProvider>
    <ThemeProvider theme={DefaultTheme}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </CookiesProvider>
);

const root = createRoot(document.getElementById('root')!);
root.render(<AppWithTheme />);
