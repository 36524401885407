import { Component } from 'react';

import Fieldset from '../Fieldset/Fieldset';
import { RansackMatcher } from '../../helpers/ransack';

import { IProps as IFilterInputProps } from './FilterInput';

interface IProps extends IFilterInputProps {
  filterType: RansackMatcher;
}

class TextFilterInput extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public render(): React.ReactElement {
    const { inputProps, attributeName, filters, filterType } = this.props;
    const value = (filters && filters[filterType]) || '';
    return (
      <Fieldset
        label={`Filter on ${attributeName.replace(/_/g, ' ')}`}
        inputProps={{ value, onChange: this.onChange, ...inputProps }}
      />
    );
  }

  private onChange(event: React.SyntheticEvent<HTMLInputElement>): void {
    this.props.onChange(this.props.attributeName, this.props.filterType, event.currentTarget.value);
  }
}

export default TextFilterInput;
