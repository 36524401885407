import styles from './Center.module.css';

interface Props {
  vertical?: boolean;
  horizontal?: boolean;
  children: React.ReactNode;
}

const Center = ({ vertical = false, horizontal = false, children }: Props): JSX.Element => (
  <div
    className={`${styles.wrapper} ${vertical && styles.vertical} ${
      horizontal && styles.horizontal
    }`}
  >
    {children}
  </div>
);

export default Center;
