import GqlBaseModel from './GqlBaseModel';
import { ListUrls_entries_edges_node as node } from '../interfaces/graphql';

class PostUrl extends GqlBaseModel {
  public static fromAttributes(attributes: node): PostUrl {
    return new PostUrl(attributes);
  }

  public ['constructor']: typeof PostUrl;

  protected attributes: node;

  constructor(attributes: node) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get modelType(): string {
    return 'PostUrl';
  }
}

export default PostUrl;
