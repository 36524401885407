@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll {
  height: 100%;
  overflow-y: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table td,
.table th {
  padding: calc(var(--padding-base) / 2) calc(var(--padding-base) / 2);
}

.table td {
  vertical-align: top;
}

.table tr td:first-of-type,
.table tr th:first-of-type {
  padding-left: var(--padding-base);
}

.table tr {
  &:hover {
    background: darken(white, 2%);
  }
}

.loadPage {
  margin: 30px;
  text-align: center;
}

.loadPageBtn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
}

.loadPageBtn:hover {
  opacity: 0.5;
}

.rotation {
  animation-duration: 3s;
  animation-name: rotation;
}
