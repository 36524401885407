const AttributeExternalLink = ({ value }: { value: string }): JSX.Element => {
  const link = value && value.startsWith('http') ? value : `http://${value}`;
  return value ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {value}
    </a>
  ) : (
    <></>
  );
};

export default AttributeExternalLink;
