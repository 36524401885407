.item {
  border: 1px solid;
  margin-bottom: 10px;
  padding: 10px;
}

.listItemSplitted {
  height: 100%;
  width: 60%;
}

.joindItems {
  display: flex;
}

.image {
  height: 100%;
  width: 100%;
}

.imageContainer {
  height: 100%;
  padding-right: 10px;
  width: 40%;
}
