import { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ValueType, OptionTypeBase } from 'react-select';

import flexStyles from '../Flex/Flex.module.css';
import styles from './TagInput.module.css';

interface IProps {
  value: string[];
  onChange: (newValue: string[]) => void;
}

interface IState {
  inputValue: string;
}

interface OptionType extends OptionTypeBase {
  value?: string;
  label?: string;
}

const createOption = (value: string): { value: string; label: string } => ({
  value,
  label: value,
});

class TagInput extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }

  public render(): React.ReactElement {
    const { inputValue } = this.state;
    return (
      <CreatableSelect
        className={`${flexStyles.resetFlex} ${styles.tagInput}`}
        components={{ DropdownIndicator: null }}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder="Type something and press enter..."
        value={this.props.value.map(createOption)}
        styles={{ placeholder: () => ({ flex: '1' }) }}
      />
    );
  }

  private readonly handleChange = (value: ValueType<OptionType, boolean>): void => {
    if (value && Array.isArray(value)) {
      this.props.onChange(value.map((v: any) => v.value));
    }
  };

  private readonly handleInputChange = (inputValue: string): void => {
    this.setState({ inputValue });
  };

  private readonly handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    const { inputValue } = this.state;
    const { value } = this.props;
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({ inputValue: '' });
        this.props.onChange([...value, inputValue]);
        event.preventDefault();
        break;
      default:
    }
  };
}

export default TagInput;
