import { gql } from 'graphql-tag';

export default gql`
  mutation CreateCuratedSpeciesCatch($input: CreateCuratedSpeciesCatchMutationInput!) {
    createCuratedSpeciesCatch(input: $input) {
      newCuratedCatch {
        externalId
      }
      userErrors {
        path
        message
      }
    }
  }
`;
