import { dateTimeLocalFormat } from '../../../helpers/DateFormat/DateFormat';
import Fieldset from '../../Fieldset/Fieldset';
import { FormProps } from './FormAttribute';

const FormDate = ({ header, entry, handleBlur, handleChange, value }: FormProps): JSX.Element => (
  <Fieldset
    label={header.title}
    onChange={handleChange}
    inputProps={{
      name: header.attribute,
      onBlur: handleBlur,
      placeholder: 'YYYY-MM-DD HH:MM:SS',
      readOnly: entry.isReadOnly(header.attribute),
      type: 'datetime-local',
      value: dateTimeLocalFormat(value),
    }}
  />
);

export default FormDate;
