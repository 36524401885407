import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Component } from 'react';

import IHeader from './HeadersInterface';
import { RansackMatcher } from '../../helpers/ransack';
import Filter, { AttributeValues } from '../../helpers/filter';
import FilterInput from '../FilterInputs/FilterInput';

import styles from './ListFilter.module.css';

interface IProps extends RouteComponentProps {
  headers: IHeader[];
}

interface IState {
  filters: Filter;
}

const DEFAULT_FILTER_TYPE: RansackMatcher = RansackMatcher.Start;

class ListFilter extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.updateFilterUrl = this.updateFilterUrl.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.state = {
      filters: Filter.parse(props.location.search),
    };
  }

  public componentDidUpdate(prevProps: IProps): void {
    if (this.props.location !== prevProps.location) {
      this.setState({
        filters: Filter.parse(this.props.location.search),
      });
    }
  }

  public render(): React.ReactElement {
    const { headers } = this.props;
    return (
      <tr>
        {headers.map((header) => (
          <th key={header.attribute} className={styles.filterCell}>
            {header.filterable ? this.getFilterInput(header) : null}
          </th>
        ))}
      </tr>
    );
  }

  private handleFilterChange(
    attributeName: string,
    filterType: RansackMatcher,
    value: string,
    options: { refresh: boolean } = { refresh: false },
  ) {
    this.setState(({ filters }) => {
      filters.setAttribute(attributeName, filterType, value.trim());
      if (options.refresh) {
        this.updateFilterUrl(filters);
      }
      return {
        filters,
      };
    });
  }

  private readonly handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      this.updateFilterUrl();
    }
  };

  private getFilterInput(header: IHeader): React.ReactElement {
    const attributeName = this.getKeyFromHeader(header);
    const filters: AttributeValues = this.state.filters.attributeValues(attributeName);
    return (
      <FilterInput
        onChange={this.handleFilterChange}
        attributeName={attributeName}
        filterOptions={{ filterType: DEFAULT_FILTER_TYPE, ...header.filterOptions }}
        inputProps={{ onKeyPress: this.handleKeyPress }}
        filters={filters}
      />
    );
  }

  private getKeyFromHeader(header: IHeader): string {
    return header.filterOptions && header.filterOptions.filterKey
      ? header.filterOptions.filterKey
      : header.attribute;
  }

  private updateFilterUrl(updatedFilters: Filter | undefined = undefined): void {
    const {
      location: { pathname, search },
    } = this.props;
    const filters = updatedFilters || this.state.filters;
    this.props.history.push({
      pathname,
      search: filters.getUrlParameters(search).toString(),
    });
  }
}

export default withRouter(ListFilter);
