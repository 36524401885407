import { Component } from 'react';

import { Link } from 'react-router-dom';

import LoadingTableBody from '../../components/Loading/LoadingTableBody';
import RelativeDate from '../../components/RelativeDate/RelativeDate';
import Table from '../../components/Table/Table';
import UserReport from '../../models/UserReport';

interface IState {
  loading: boolean;
  reports: UserReport[];
}

interface IProps {
  entry: { subjectType: string; subjectId: number };
}

const SIZE = 5;

class SubjectReports extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.loadReports = this.loadReports.bind(this);
    this.state = { loading: false, reports: [] };
  }

  public componentDidMount(): void {
    this.loadReports();
  }

  public render(): React.ReactElement {
    const { loading, reports } = this.state;
    return reports.length || loading ? (
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Reporter</th>
            <th>Reason</th>
            <th>Created at</th>
            <th>Status</th>
          </tr>
        </thead>
        {loading ? (
          <LoadingTableBody rows={SIZE} columns={SIZE} />
        ) : (
          <tbody>
            {reports.map((report) => (
              <tr key={report.id}>
                <td>
                  <Link to={`/user_reports/${report.id}`}>#{report.id}</Link>
                </td>
                <td>
                  {report.user ? (
                    <Link to={`/users/${report.user_id}`}>{report.user?.nickname}</Link>
                  ) : (
                    <p>Rutilus (or deleted user)</p>
                  )}
                </td>
                <td>{report.reason}</td>
                <td>
                  <RelativeDate date={report.created_at} />
                </td>
                <td>{report.status}</td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    ) : (
      <p>No reports.</p>
    );
  }

  private loadReports(): void {
    this.setState({ loading: true });
    const { entry } = this.props;
    UserReport.list({
      filterBy: {
        subject_type_eq: entry.subjectType,
        subject_id_eq: entry.subjectId.toString(),
      },
      page: 0,
      perPage: 30,
      sortBy: 'created_at desc',
    }).then((newReports: UserReport[]) => {
      this.setState((prevState: IState) => {
        const { reports } = prevState;
        return { reports: reports.concat(newReports), loading: false };
      });
    });
  }
}

export default SubjectReports;
