import { IFilterOptions } from '../List/HeadersInterface';
import TextFilterInput from './TextFilterInput';

import styles from '../List/ListFilter.module.css';
import { AttributeValues } from '../../helpers/filter';
import { RansackMatcher } from '../../helpers/ransack';

export interface IProps {
  attributeName: string;
  filterOptions: IFilterOptions;
  onChange: (
    attributeName: string,
    filterType: RansackMatcher,
    value: string,
    options?: { refresh: boolean },
  ) => void;
  inputProps: Record<string, unknown>;
  filters?: AttributeValues;
}

const FilterInput = ({ filterOptions, inputProps, ...props }: IProps): JSX.Element => {
  const Component = filterOptions.component || TextFilterInput;
  return (
    <Component
      filterType={filterOptions.filterType}
      filterOptions={filterOptions}
      inputProps={{ ...inputProps, className: styles.input }}
      {...props}
    />
  );
};

export default FilterInput;
