import Button, { IButtonProps } from './Button';

interface IProps extends IButtonProps {
  isLoading: boolean;
  loadingText?: string;
}

const LoadingButton = ({
  isLoading = false,
  loadingText = 'Loading...',
  disabled = false,
  className = '',
  children,
  ...rest
}: IProps): JSX.Element => (
  <Button className={className} disabled={isLoading || disabled} {...rest}>
    {isLoading ? loadingText : children}
  </Button>
);

export default LoadingButton;
