import React, { FC, ReactNode } from 'react';
import { useQuery } from '@apollo/client';

import { GetPostAvailableActions, GetPostAvailableActions_post } from '../interfaces/graphql';
import { getPostAvailableActions } from '../queries/Post';

type ModerationContextType = {
  post: GetPostAvailableActions_post | null;
};

const defaultContext: ModerationContextType = {
  post: null,
};

export const ModerationContext = React.createContext(defaultContext);

// Post actions are re-used between multiple components, on different levels,
// using context for caching the result
export const ModerationContextProvider: FC<{ postId: string; children: ReactNode }> = ({
  children,
  postId,
}) => {
  const { data } = useQuery<GetPostAvailableActions>(getPostAvailableActions, {
    variables: { postId: Number(postId) },
  });
  return (
    <ModerationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        post: data?.post || null,
      }}
    >
      {children}
    </ModerationContext.Provider>
  );
};
