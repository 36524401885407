import { get } from 'ts-get';

import IHeader from './HeadersInterface';
import truncate from '../../helpers/Truncate/Truncate';
import GqlBaseModel from '../../models/GqlBaseModel';
import AttributeComponent from '../AttributeComponents/AttributeComponent';

import listStyles from '../ModelList/ModelList.module.css';
import bodyStyles from './ListBody.module.css';
import Button from '../Clickables/Buttons/Button';

const MAX_LENGTH = 20;

// TODO: Replace `any` with the raw repesentation of the node as given by the
// query's generated type. T is the optional model as given to GqlBaseModel.
interface IEdge<T> {
  node: T | any;
}

export interface IEntries<T> {
  edges: (IEdge<T> | null)[] | null;
  pageInfo: {
    endCursor?: string | null;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    startCursor?: string | null;
    __typename?: string;
  };
}

export interface GqlListBodyProps<T extends GqlBaseModel> {
  entries: IEntries<T>;
  headers: IHeader[];
  path: string;
  search?: string;
  reverse?: boolean;
  onLoadMore: () => unknown;
  model?: new (...args: any) => T;
}

const truncateIfString = (property: any): any =>
  typeof property === 'string' ? truncate(property, MAX_LENGTH) : property;

const GqlListBody = <T extends GqlBaseModel = any>({
  entries,
  headers,
  path,
  search,
  onLoadMore,
  model: Model,
  reverse = false,
}: GqlListBodyProps<T>): JSX.Element => {
  const getValue = (edge: IEdge<T>, header: IHeader): string => {
    if (edge.node === null) {
      throw new Error('Null node received');
    }
    if (!header.subAttr) {
      return edge.node[header.attribute];
    }
    return header.subAttr.reduce((accumulator, currentValue) => {
      if (!accumulator || !accumulator[currentValue]) {
        return '-';
      }
      return accumulator[currentValue];
    }, edge.node[header.attribute]);
  };

  // Extract entries and convert them to proper model instances
  const edges = get(entries, (e) => e.edges, []).map(({ node, ...e }: IEdge<T>) => ({
    ...e,
    node: Model ? new Model(node) : node,
  }));
  const renderEntries = reverse ? edges.reverse() : edges;

  return (
    <>
      <tbody>
        {renderEntries.map((edge: IEdge<T>, index) => (
          <tr
            key={`${edge.node.id}-${index}`}
            className={path.endsWith(`/${edge.node.id}`) ? bodyStyles.active : ''}
          >
            {headers.map((header, idx) => (
              <td key={`${edge.node.id}-${header.attribute}-${idx}`}>
                {header.component ? (
                  <AttributeComponent
                    link={header.link}
                    search={search}
                    component={header.component}
                    entry={edge.node}
                    value={getValue(edge, header)}
                    lookups={header.lookups}
                  />
                ) : (
                  truncateIfString(getValue(edge, header))
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {((!reverse && get(entries, (e) => e.pageInfo.hasNextPage, false)) ||
        (reverse && get(entries, (e) => e.pageInfo.hasPreviousPage, false))) && (
        <tfoot>
          <tr>
            <td colSpan={headers.length}>
              <div className={listStyles.loadPage}>
                <Button
                  variant="textPrimary"
                  className={listStyles.loadPageBtn}
                  onClick={onLoadMore}
                >
                  Load more
                  <br />
                  <i className="fas fa-angle-down" />
                </Button>
              </div>
            </td>
          </tr>
        </tfoot>
      )}
    </>
  );
};

export default GqlListBody;
