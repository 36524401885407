import BaseModel from '../../../models/BaseModel';
import IFormHeaders from '../FormHeaderInterface';

interface IProps {
  header: IFormHeaders;
  entry: BaseModel;
}

const Likes = ({ entry, header: { attribute } }: IProps): JSX.Element => (
  <>
    <i className="fas fa-heart" /> {entry[attribute]}
  </>
);

export default Likes;
