import { gql } from 'graphql-tag';

export const getWatersMetadata = gql`
  query GetWatersMetadata($cursor: String) {
    fishingWaterMetadata(first: 100, after: $cursor) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          __typename
          id
          name
          group {
            id
            name
          }
        }
      }
    }
  }
`;
