import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';

import User from '../../models/User';
import { userNavionicsSubscription as query } from '../../queries/User';
import Fieldset from '../../components/Fieldset/Fieldset';
import { UpsertNavionicsSubscription as navionicsSubscriptionMutation } from '../../mutations/NavionicsSubscription';
import {
  upsertNavionicsSubscription,
  upsertNavionicsSubscriptionVariables,
  userNavionicsSubscription,
  userNavionicsSubscriptionVariables,
} from '../../interfaces/graphql';
import Button from '../../components/Clickables/Buttons/Button';

const NavionicsSubscriptionStatus = ({ entry }: { entry: User }): JSX.Element => {
  const { id, external_id } = entry;

  const { loading, error, data, refetch } = useQuery<
    userNavionicsSubscription,
    userNavionicsSubscriptionVariables
  >(query, { variables: { external_id } });

  const [purchaseSubscription, { loading: loadingPurchase }] = useMutation<
    upsertNavionicsSubscription,
    upsertNavionicsSubscriptionVariables
  >(navionicsSubscriptionMutation, {
    onCompleted: (response) => {
      const { userErrors } = response.upsertNavionicsSubscription || { userErrors: [] };
      if (userErrors.length === 0) {
        refetch();
      } else {
        alert(userErrors[0].message);
      }
    },
    onError: (err: ApolloError) => alert(err.message),
  });

  const handlePurchase = () => {
    if (window.confirm(`Are you sure about purchasing Navionics subscription for user ${id}?`)) {
      const variables = { input: { userId: external_id } };
      purchaseSubscription({ variables });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data || error) {
    return <div>Subscription data not found</div>;
  }

  const {
    user: { navionicsSubscription },
  } = data;

  const { activeProduct, latestPurchase } = navionicsSubscription || {};

  return (
    <div>
      {navionicsSubscription ? (
        <>
          <div className="flex">
            {activeProduct && (
              <Fieldset
                label="Product"
                inputProps={{
                  value: activeProduct.name,
                  disabled: true,
                  name: 'product',
                  title: `UUID: ${activeProduct.uuid}`,
                }}
              />
            )}
            <Fieldset
              label="Active"
              inputProps={{
                checked: navionicsSubscription.isActive,
                disabled: true,
                name: 'active',
                type: 'checkbox',
              }}
            />
          </div>
          <div className="flex">
            {latestPurchase && (
              <Fieldset
                label="Last purchase"
                inputProps={{
                  value: format(new Date(latestPurchase.createdAt), 'yyyy-MM-dd'),
                  disabled: true,
                  name: 'createdAt',
                  type: 'date',
                  title: `[${latestPurchase.purchaseType}] Transaction id: ${latestPurchase.transactionId}`,
                }}
              />
            )}
            <Fieldset
              label="Expires at"
              inputProps={{
                value: format(new Date(navionicsSubscription.expiresAt), 'yyyy-MM-dd'),
                disabled: true,
                name: 'expiresAt',
                type: 'date',
              }}
            />
          </div>
        </>
      ) : (
        <p style={{ flex: 1 }}>No subscription</p>
      )}

      {!(navionicsSubscription && navionicsSubscription.isActive) && (
        <div style={{ maxWidth: 100 }}>
          <Button
            variant="buttonSecondary"
            disabled={loadingPurchase}
            size="sm"
            title="Purchase Navionics subscription"
            onClick={handlePurchase}
          >
            {loadingPurchase ? 'Loading...' : 'Purchase Navionics'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NavionicsSubscriptionStatus;
