import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

import SearchInput, { Value, isMultiValue } from '../Input/SearchInput';
import { FormProps } from '../Edit/FormAttributes/FormAttribute';

import styles from '../Edit/FormAttributes/AttributeWithSearch.module.css';

export type ValueAttribute = 'id' | 'externalId';

interface IAttributeWithGqlSearchProps {
  initialOptions: Value[];
  query: any;
  singleQuery?: any;
  converter: (value: any, valueAttribute?: any) => Value[];
  singleConverter?: (value: any, valueAttribute?: any) => Value;
  prefetchedAttribute: string;
  isMulti?: boolean;
  disabled?: any;
  valueAttribute?: ValueAttribute;
  valueFilterFunction?: (
    propsValue: any,
    v: Value | Value[] | undefined,
  ) => Value | Value[] | undefined;
}

const AttributeWithGqlSearch = ({
  initialOptions,
  query,
  singleQuery,
  converter,
  singleConverter,
  prefetchedAttribute,
  isMulti = false,
  valueAttribute = 'id',
  valueFilterFunction = (_propsValue: any, v: Value | Value[] | undefined) => v,
  disabled,
  ...props
}: IAttributeWithGqlSearchProps & FormProps) => {
  const change = (value: Value | Value[]) => {
    props.setFieldTouched(props.header.attribute, true);
    props.setFieldValue(prefetchedAttribute, value || { label: '', value: null });
    if (isMultiValue(value)) {
      props.setFieldValue(
        props.header.attribute,
        value.length ? value.map((val) => val.value) : null,
      );
    } else {
      props.setFieldValue(props.header.attribute, value ? value.value : null);
    }
  };

  const client = useApolloClient();

  const loader = async (searchTerm: string) => {
    const variables =
      props.header.attribute === 'fishing_water_id'
        ? {
            query: searchTerm,
          }
        : {
            searchTerm,
          };

    const { data } = await client.query({
      query,
      variables,
    });
    return converter(data, valueAttribute);
  };

  const [value, setValue] = useState<Value[] | Value | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getValuePromise = async () =>
      new Promise<any>((resolve) => {
        // Applies to:-
        // ProductSearch component in the AdvertSearches view
        // BrandSearch component in the AdvertSearches view
        if (
          isMulti &&
          props.values[props.header.attribute] &&
          props.values[props.header.attribute].length > 0
        ) {
          const getValues = async (): Promise<Value[]> => {
            const { data } = await client.query({
              query,
              variables: {
                [props.header.attribute]: props.values[props.header.attribute],
              },
            });
            return converter(data, valueAttribute);
          };
          return resolve(getValues());
        }
        // When no saved values
        if (isMulti) {
          return resolve([] as Value[]);
        }

        // Applies to:-
        // FishingWater search component in the Catch view
        if (props.values[props.header.attribute] && props.header.attribute === 'fishing_water_id') {
          const getValue = async () => {
            const { data } = await client.query({
              query: singleQuery,
              variables: {
                [valueAttribute]: props.values[props.header.attribute],
              },
            });
            if (singleConverter) {
              return singleConverter(data);
            }
            return undefined;
          };
          return resolve(getValue());
        }

        // Applies when no value has been specified
        return resolve(undefined);
      });

    const asyncGetValue = async () => {
      setIsLoading(true);
      const result = await getValuePromise();
      setValue(result);
      setIsLoading(false);
    };
    asyncGetValue();
  }, [
    isMulti,
    props.header.attribute,
    props.values,
    client,
    converter,
    singleConverter,
    query,
    singleQuery,
    valueAttribute,
    props.entry,
  ]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const MainComponent = () => {
    if (isLoading) {
      return 'Loading...';
    }
    if (disabled) {
      return 'Disabled for this type of promotion';
    }
    const filteredValue = valueFilterFunction(props.value, value);
    return (
      <div className={styles.flex}>
        <Field
          key={`${prefetchedAttribute}-dropdown`}
          {...props}
          isMulti={isMulti}
          className={styles.grow}
          component={SearchInput}
          value={filteredValue}
          defaultOptions={initialOptions}
          loadOptions={loader}
          onChange={change}
        />
      </div>
    );
  };

  return <> {MainComponent()} </>;
};

export default AttributeWithGqlSearch;
