import React, { useState } from 'react';
import { Note } from 'barramundi';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Fieldset from '../../components/Fieldset/Fieldset';
import { createBrandPage as mutation } from '../../mutations/Pages/CreateBrandPage';
import { createBrandPage } from '../../interfaces/graphql';
import Button from '../../components/Clickables/Buttons/Button';

const CreateBrandPage = ({ brandId }: { brandId: number }) => {
  const [createPage, { data, loading }] = useMutation<createBrandPage>(mutation);
  const [gearTab, setGearTab] = useState(false);

  const send = () => {
    createPage({ variables: { input: { brandId, gearTab } } });
  };

  return (
    <>
      {data && data.createBrandPage && data.createBrandPage.userErrors && (
        <Note>
          {data.createBrandPage.userErrors
            .map(({ message }: { message: string }) => message)
            .join(' ')}
        </Note>
      )}
      {data && data.createBrandPage && data.createBrandPage.page && (
        <Note>
          <Link to={`/pages/${data.createBrandPage.page.id}`}>Go to page</Link>
        </Note>
      )}
      {(!data || !(data && data.createBrandPage && data.createBrandPage.page)) && (
        <div className="flex">
          <Fieldset
            label="Create gear tab"
            onChange={(event: React.SyntheticEvent<HTMLInputElement>) =>
              setGearTab(event.currentTarget.checked)
            }
            inputProps={{
              checked: gearTab,
              disabled: loading,
              name: 'setGearTab',
              type: 'checkbox',
            }}
          />
          <Button size="sm" disabled={loading} onClick={send}>
            Create brand page
          </Button>
        </div>
      )}
    </>
  );
};

export default CreateBrandPage;
