import { gql } from 'graphql-tag';

export const getGroups = gql`
  query GetGroupsOrnatus($cursor: String, $filters: GroupFilters) {
    groups: groupsForAdmins(first: 20, after: $cursor, filters: $filters) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          externalId
          displayIcon(width: 340, height: 340) {
            url
          }
          coverImage(width: 480) {
            url
          }
          displayName
          description
          type
          members(first: 100) {
            edges {
              node {
                externalId
                nickname
                id
              }
            }
          }
        }
      }
    }
  }
`;

// For these quries to work correctly `group` field should be put in `entry`
// using aliasing

export const getGroupMembers = gql`
  query GetGroupMembersOrnatus($id: ID!, $cursor: String) {
    entry: group(id: $id) {
      members(first: 50, after: $cursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            id
            nickname
            externalId
          }
        }
      }
    }
  }
`;

export const getGroupFeed = gql`
  query GetGroupFeedOrnatus($id: ID!, $cursor: String) {
    entry: group(id: $id) {
      feed(first: 50, after: $cursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            id
            text {
              text
            }
            externalId
          }
        }
      }
    }
  }
`;

export const getGroup = gql`
  query GetGroupOrnatus($externalId: ID!, $membersCursor: String, $feedCursor: String) {
    entry: group(id: $externalId) {
      id
      externalId
      displayIcon(width: 340, height: 340) {
        url
      }
      coverImage(width: 480) {
        url
      }
      displayName
      description
      type
      feed(first: 50, after: $feedCursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            id
            text {
              text
            }
            externalId
          }
        }
      }
      members(first: 50, after: $membersCursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            id
            nickname
            externalId
          }
        }
      }
    }
  }
`;
