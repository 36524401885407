import { RansackMatcher } from './ransack';

const FILTER_PARAMS_LEN = 2;

export type FilterType = string;

export const mergeSearchParams = (
  search: URLSearchParams,
  query: { [key: string]: any },
): URLSearchParams => {
  Object.keys(query).forEach((key: string) => {
    search.set(key, query[key]);
  });
  return search;
};

export const mergeQueryParams = (search: string, query: { [key: string]: any }): string =>
  mergeSearchParams(new URLSearchParams(search), query).toString();

export const filterParamToObj = (
  filterKey: string,
): { attributeName: string; filterType: FilterType } => {
  const splits = filterKey.split('_');
  const filterType = splits.pop() || RansackMatcher.Equals;
  return {
    attributeName: splits.join('_'),
    filterType,
  };
};

interface Filter {
  [key: string]: string;
}

export const buildFilterParams = (
  sort = 'id desc',
  filters: Filter = {},
  root = 'q',
): Record<string, unknown> => {
  const build = (key: string, value: string): Record<string, unknown> => ({
    [`${root}[${key}]`]: value,
  });
  const filterObjects = Object.keys(filters).map((key: string) => build(key, filters[key]));
  return Object.assign({}, build('s', sort), ...filterObjects);
};

export const filterFromString = (filterString: string): Filter => {
  const filter: Filter = {};
  JSON.parse(decodeURIComponent(filterString)).forEach((element: string) => {
    const splitElement = element.split('+', FILTER_PARAMS_LEN);
    if (splitElement.length === FILTER_PARAMS_LEN) {
      // eslint-disable-next-line prefer-destructuring
      filter[splitElement[0]] = splitElement[1];
    }
  });
  return filter;
};

export const stringFromFilter = (filterObject: Filter): string => {
  const filters = Object.keys(filterObject).map((key) => `${key}+${filterObject[key]}`);
  return JSON.stringify(filters);
};

// the magic length of the string `filter.`
const MAGIC = 7;

export const filterObjectFromSearch = (search: string): { [key: string]: string } => {
  const searchParams = new URLSearchParams(search);
  const paramObject: { [key: string]: string } = {};

  // eslint-disable-next-line fp/no-loops
  for (const key of searchParams.keys()) {
    if (key.startsWith('filter.')) {
      const keyWithoutFilterPrefix = key.substring(MAGIC);
      paramObject[keyWithoutFilterPrefix] = decodeURIComponent(searchParams.get(key) || '');
    }
  }

  return paramObject;
};
