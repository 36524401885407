import { Route, useLocation, useRouteMatch } from 'react-router-dom';

import { get } from 'ts-get';
import { useQuery } from '@apollo/client';

import Flex from '../../components/Flex/Flex';
import IHeader from '../../components/List/HeadersInterface';
import SidePane from '../../components/SidePane/SidePane';

import AttributeID from '../../components/AttributeComponents/AttributeID';

import { getFishingWaters, getFishingWatersSearch } from '../../queries/FishingWaters';
import {
  GetFishingWaters,
  GetFishingWatersSearch,
  GetFishingWatersSearchVariables,
  GetFishingWatersVariables,
} from '../../interfaces/graphql';
import GqlModelList from '../../components/ModelList/GqlModelList';
import NewFishingWater from './components/NewFishingWater';
import EditFishingWater from './components/EditFishingWater';
import AttributeCoords from './components/AttributeCoords';

const headers: IHeader[] = [
  {
    attribute: 'id',
    title: 'Id',
  },
  {
    title: 'External ID',
    attribute: 'externalId',
    component: AttributeID,
    link: '/fishing_waters/',
  },
  { title: 'Name', attribute: 'name', canGqlsearch: true },
  { title: 'Coordinates', attribute: 'coordinates', component: AttributeCoords },
  { title: 'Catches', attribute: 'catchesCount' },
];

const FishingWaters = (): JSX.Element => {
  const match = useRouteMatch();
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const searchString = params.get('searchQuery');

  const { loading, error, data, fetchMore } = useQuery<GetFishingWaters, GetFishingWatersVariables>(
    getFishingWaters,
    {
      skip: searchString !== null,
    },
  );

  const {
    loading: searchLoading,
    error: searchError,
    data: searchData,
    fetchMore: searchFetchMore,
  } = useQuery<GetFishingWatersSearch, GetFishingWatersSearchVariables>(getFishingWatersSearch, {
    variables: { query: searchString },
    skip: searchString === null,
  });

  const onLoadMore = async () => {
    return searchString
      ? searchFetchMore({
          updateQuery: (
            previousResult: any,
            options: {
              fetchMoreResult: any;
              variables: {
                cursor: string;
              };
            },
          ) => {
            const newEdges = options.fetchMoreResult.fishingWatersSearch.edges;
            const { pageInfo } = options.fetchMoreResult.fishingWatersSearch;

            return {
              fishingWatersSearch: {
                pageInfo,
                __typename: previousResult.fishingWatersSearch.__typename,
                edges: [...previousResult.fishingWatersSearch.edges, ...newEdges],
              },
            };
          },
          variables: {
            cursor: get(searchData, (d) => d.fishingWatersSearch.pageInfo.endCursor),
          },
        })
      : fetchMore({
          updateQuery: (
            previousResult: any,
            options: {
              fetchMoreResult: any;
              variables: {
                cursor: string;
              };
            },
          ) => {
            const newEdges = options.fetchMoreResult.fishingWaters.edges;
            const { pageInfo } = options.fetchMoreResult.fishingWaters;

            return {
              fishingWaters: {
                pageInfo,
                __typename: previousResult.fishingWaters.__typename,
                edges: [...previousResult.fishingWaters.edges, ...newEdges],
              },
            };
          },
          variables: {
            cursor: get(data, (d) => d.fishingWaters.pageInfo.endCursor),
          },
        });
  };

  return (
    <Flex>
      <div>
        {searchString ? (
          <GqlModelList
            isLoading={searchLoading}
            error={searchError}
            entries={get(searchData, (d) => d.fishingWatersSearch)}
            headers={headers}
            path={location.pathname}
            onLoadMore={onLoadMore}
          />
        ) : (
          <GqlModelList
            isLoading={loading}
            error={error}
            entries={get(data, (d) => d.fishingWaters)}
            headers={headers}
            path={location.pathname}
            onLoadMore={onLoadMore}
          />
        )}
      </div>
      <SidePane showOnPath={`${match.path}/:slug`}>
        <Route path={`${match.path}/new`} component={NewFishingWater} />
        <Route path={`${match.path}/:externalId`} render={EditFishingWater} />
      </SidePane>
    </Flex>
  );
};

export default FishingWaters;
