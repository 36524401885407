import { Route, useRouteMatch } from 'react-router-dom';

import Flex from '../../components/Flex/Flex';
import IHeader from '../../components/List/HeadersInterface';
import ModelList from '../../components/ModelList/ModelList';
import User from '../../models/User';
import SidePane from '../../components/SidePane/SidePane';

import Filter from '../../helpers/filter';
import { RansackMatcher } from '../../helpers/ransack';

import SelectFilterInput from '../../components/FilterInputs/SelectFilterInput';

/* ATTRIBUTE COMPONENTS */
import AttributeBoolean from '../../components/AttributeComponents/AttributeBoolean';
import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import AttributeID from '../../components/AttributeComponents/AttributeID';
import DateFilterInput from '../../components/FilterInputs/DateFilterInput';

import UserForm from './UserForm';

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    filterable: true,
    filterOptions: { filterType: 'eq' },
    link: '/users/',
    title: 'Id',
  },
  {
    attribute: 'external_id',
    component: AttributeID,
    filterable: true,
    filterOptions: { filterType: 'eq' },
    title: 'External Id',
  },
  {
    attribute: 'nickname',
    title: 'Nickname',
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  { title: 'First Name', attribute: 'first_name', filterable: true },
  { title: 'Last Name', attribute: 'last_name', filterable: true },
  {
    attribute: 'email',
    filterable: true,
    filterOptions: { filterType: 'eq' },
    title: 'Email',
  },
  { title: 'Country', attribute: 'country_code', filterable: true },
  { title: 'State', attribute: 'state_code', filterable: true },
  {
    attribute: 'created_at',
    component: AttributeDate,
    filterable: true,
    filterOptions: { component: DateFilterInput },
    title: 'Created',
  },
  { title: 'Premium', attribute: 'is_premium', component: AttributeBoolean },
  {
    attribute: 'shadow_ban',
    component: AttributeBoolean,
    filterable: true,
    filterOptions: { component: SelectFilterInput, filterType: 'eq' },
    title: 'Shadow Banned',
  },
];

const filter = (attribute: string, value: string): string =>
  `/users?${Filter.empty().setAttribute(attribute, RansackMatcher.Equals, value).toString()}`;

const bookmarks = [
  {
    path: filter('shadow_ban', 'true'),
    title: 'Shadow Banned',
  },
  {
    path: filter('country_code', 'US'),
    title: 'US Users',
  },
  {
    path: filter('country_code', 'SE'),
    title: 'Swedish Users',
  },
  {
    path: filter('country_code', 'GB'),
    title: 'UK Users',
  },
  {
    path: filter('country_code', 'AU'),
    title: 'Australian Users',
  },
  {
    path: filter('country_code', 'CA'),
    title: 'Canadian Users',
  },
];

const Users = (): JSX.Element => {
  const match = useRouteMatch();
  return (
    <Flex>
      <ModelList headers={headers} model={User} bookmarks={bookmarks} />
      <SidePane showOnPath={`${match.path}/:slug`}>
        <Route path={`${match.path}/:id`} render={UserForm} />
      </SidePane>
    </Flex>
  );
};
export default Users;
