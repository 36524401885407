.container {
  padding: calc(var(--padding-base) / 3) var(--padding-base);
}

.chip {
  @apply rounded-full;
  background-color: #e5e5e5;
  cursor: default;
  display: inline-flex;
  flex-direction: row;
  font-size: 13px;
  height: 26px;
  margin-bottom: 3px;
  margin-right: 3px;
  text-decoration: none;
  white-space: nowrap;
}

.chipHead {
  align-items: center;
  background-color: #32c5d2;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-shrink: 0;
  font-size: 16px;
  height: 26px;
  justify-content: center;
  margin-right: -4px;
  overflow: hidden;
  position: relative;
  width: 26px;
}

.chipContent {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}

.buttonWrapper {
  padding-top: 20px;
  text-align: center;
}

.bookmarkBtn {
  border-radius: 16px;
  padding: 10px;

  & span {
    margin-left: 5px;
  }
}
