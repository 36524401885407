import BaseModel from './BaseModel';
import { IUser } from './User';

export interface ICatchComment {
  readonly id: number;
  author: IUser; // Might not be an exact match due to Rutilus serializer differences.
  catch: {
    type: 'catch_ref';
    id: number;
  };
  comments_count: number;
  created_at: string;
  text: string;
  type: 'catch_comment';
}

class CatchComment extends BaseModel {
  public static fromAttributes(attributes: ICatchComment): CatchComment {
    return new CatchComment(attributes);
  }

  public ['constructor']: typeof CatchComment;

  protected attributes: ICatchComment;

  constructor(attributes: ICatchComment) {
    super();
    this.attributes = attributes;
  }

  public get endpoint(): string {
    return '/catches';
  }

  public get identifier(): string {
    return this.attributes.id.toString();
  }

  public get id(): string {
    return this.attributes.id.toString();
  }

  public get text(): string {
    return this.attributes.text;
  }

  public get author(): IUser {
    return this.attributes.author;
  }

  public get avatar(): string | void {
    return this.attributes.author.avatar && this.attributes.author.avatar.sizes.length
      ? this.attributes.author.avatar.sizes[0].url
      : undefined;
  }

  public get modelType(): string {
    return 'CatchComment';
  }
}

export default CatchComment;
