import { useMutation, useQuery, TypedDocumentNode, QueryHookOptions } from '@apollo/client';
import { get } from 'ts-get';
import { useState } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import CreateUrl from '../mutations/DirectUpload';
import { uploadToS3 } from './files';
import { ITableColumn } from '../components/Tables/Table/Table';

export interface CreateDirectUploadURLAttributes {
  contentType: string;
  filename: string;
  byteSize: number;
  checksum: string;
  width: number;
  height: number;
}

type UploadImage = (
  image: File,
  uploadAttributes: CreateDirectUploadURLAttributes,
) => Promise<string>;
export function useImageUpload(): UploadImage {
  const [getDirectUploadUrl] = useMutation(CreateUrl);

  return async (image: File, uploadAttributes: CreateDirectUploadURLAttributes): Promise<string> =>
    getDirectUploadUrl({
      variables: { input: { attributes: uploadAttributes } },
    }).then(async (result) => {
      const { data } = result;
      const url = get(data, (d) => d.createDirectUploadUrl.directUploadUrl.url, null);
      const headers = get(data, (d) => d.createDirectUploadUrl.directUploadUrl.headers, null);
      const signedId = get(data, (d) => d.createDirectUploadUrl.directUploadUrl.signedId, null);
      if (!url || !headers || !signedId) {
        throw new Error('Required values not returned from createDirectUploadUrl');
      }
      return uploadToS3(url, image, headers).then(() => signedId);
    });
}

/**
 * This hook will turn a standard graphql query into a "paginated" query. Note that it's not true,
 * pagination, as instead of paging we just extend the size of the page each time (with Apollo's
 * caching ability this is functionally the same for the actual request).
 *
 * Note that the query MUST have a variable named `first` that maps to a `first` argument on a
 * connection in the query.
 */
const DEFAULT_PAGE_SIZE = 20;
export function usePaginatedQuery<Query, QueryVariables>(
  query: TypedDocumentNode<Query, QueryVariables>,
  queryOptions: QueryHookOptions<Query, QueryVariables> = {},
  pageSize = DEFAULT_PAGE_SIZE,
) {
  const [first, setFirst] = useState(pageSize);
  const response = useQuery<Query, QueryVariables>(query, {
    ...queryOptions,
    variables: {
      ...(queryOptions.variables || {}),
      first,
    } as unknown as QueryVariables,
  });
  const fetchNextPage = () => {
    setFirst(first + pageSize);
  };

  return {
    ...response,
    // This prevents no data being returned when the variables have changed.
    data: response.data || response.previousData,
    fetchNextPage,
  };
}

/**
 * This hook can be used to get and set the filter values for a table view. It looks for search
 * params matching the `id` field from the values in `columns` and returns them as an object
 * `filterValues`. It also returns a function `setFilterValues` that will receive a similar object
 * and apply it to the current URL.
 */
export function useFilterParams(columns: ITableColumn[]): {
  setFilterValues: (values: { [key: string]: string }) => void;
  filterValues: { [key: string]: string };
} {
  const location = useLocation();
  const history = useHistory();
  const parsed = queryString.parse(location.search);

  const filterables = columns.filter((column) => column.filterable);
  const filterValues = filterables.reduce(
    (values, filterable) => ({ ...values, [filterable.id]: parsed[filterable.id] || '' }),
    {},
  );

  const setFilterValues = (values: { [key: string]: string }): void => {
    history.push({ search: queryString.stringify(values) });
  };

  return { setFilterValues, filterValues };
}
