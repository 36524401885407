import { Route, useLocation, useRouteMatch } from 'react-router-dom';

import { get } from 'ts-get';
import { useQuery } from '@apollo/client';

import Flex from '../../components/Flex/Flex';
import IHeader from '../../components/List/HeadersInterface';
import SidePane from '../../components/SidePane/SidePane';

import AttributeID from '../../components/AttributeComponents/AttributeID';

import {
  GetUserSuggestedChanges,
  GetUserSuggestedChangesVariables,
} from '../../interfaces/graphql';
import GqlModelList from '../../components/ModelList/GqlModelList';
import { getUserSuggestedChanges } from '../../queries/UserSuggestedChanges';
import {
  FishingWaterExternalId,
  ReviewerNickname,
  UserExternalId,
  getSortAttributeValue,
  statusFilters,
} from './helpers';
import Filter from '../../helpers/filter';
import editUserSuggestedChange from './components/editUserSuggestedChange';
import { RansackMatcher } from '../../helpers/ransack';
import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import SearchAssignee from './components/SearchAssignee';
import { OrderTypes } from '../../interfaces/graphql';

const headers: IHeader[] = [
  {
    title: 'External ID',
    attribute: 'externalId',
    component: AttributeID,
    link: '/user_suggested_changes/',
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  {
    title: 'Fishingwater External ID',
    attribute: 'fishingWaterExternalId',
    component: FishingWaterExternalId,
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  {
    title: 'User External ID',
    attribute: 'userExternalId',
    component: UserExternalId,
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  {
    title: 'User Email',
    attribute: 'email',
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  {
    title: 'Status',
    attribute: 'status',
    filterable: true,
    filterOptions: { component: statusFilters, filterType: 'eq' },
  },
  {
    title: 'Created At',
    attribute: 'createdAt',
    component: AttributeDate,
    sortable: true,
    defaultSort: 'desc',
  },
  {
    title: 'Assigned to',
    attribute: 'assignee',
    component: SearchAssignee,
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  {
    title: 'Reviewed At',
    attribute: 'reviewedAt',
    component: AttributeDate,
  },
  {
    title: 'Reviewed by',
    attribute: 'reviewerNickname',
    component: ReviewerNickname,
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
];

const filter = (attribute: string, value: string): string =>
  `/user_suggested_changes?${Filter.empty()
    .setAttribute(attribute, RansackMatcher.Equals, value)
    .toString()}`;

const bookmarks = [
  {
    path: filter('changeType', 'water_name'),
    title: 'Water Name',
  },
  {
    path: filter('changeType', 'coordinates'),
    title: 'Coordinates',
  },
  {
    path: filter('changeType', 'metadata'),
    title: 'Metadata',
  },
  {
    path: filter('changeType', 'url'),
    title: 'URL',
  },
  {
    path: filter('changeType', 'other'),
    title: 'Other',
  },
];

const UserSuggestedChanges = (): JSX.Element => {
  const match = useRouteMatch();
  const location = useLocation();
  const filters = Filter.parse(location.search);
  const filterVariables = filters.filtersFlatMap;
  const orderByCreation =
    getSortAttributeValue(location.search, 'createdAt') === 'desc'
      ? OrderTypes.DESC
      : OrderTypes.ASC;

  const { loading, error, data, fetchMore } = useQuery<
    GetUserSuggestedChanges,
    GetUserSuggestedChangesVariables
  >(getUserSuggestedChanges, {
    variables: { filters: { ...filterVariables, orderByCreation } },
  });

  const onLoadMore = async () => {
    return fetchMore({
      updateQuery: (
        previousResult: any,
        options: {
          fetchMoreResult: any;
          variables: {
            cursor: string;
          };
        },
      ) => {
        const newEdges = options.fetchMoreResult.userSuggestedChanges.edges;
        const { pageInfo } = options.fetchMoreResult.userSuggestedChanges;

        return {
          userSuggestedChanges: {
            pageInfo,
            __typename: previousResult.userSuggestedChanges.__typename,
            edges: [...previousResult.userSuggestedChanges.edges, ...newEdges],
          },
        };
      },
      variables: {
        cursor: get(data, (d) => d.userSuggestedChanges.pageInfo.endCursor),
      },
    });
  };

  return (
    <Flex>
      <div>
        <GqlModelList
          isLoading={loading}
          error={error}
          entries={get(data, (d) => d.userSuggestedChanges)}
          headers={headers}
          path={location.pathname}
          onLoadMore={onLoadMore}
          bookmarks={bookmarks}
        />
      </div>
      <SidePane showOnPath={`${match.path}/:slug`}>
        <Route path={`${match.path}/:externalId`} component={editUserSuggestedChange} />
      </SidePane>
    </Flex>
  );
};

export default UserSuggestedChanges;
