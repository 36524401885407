export default interface ThumborImage {
  readonly url: string;
  readonly width: number;
  readonly height: number;
}

export const EMPTY_IMAGE: ThumborImage = {
  url: '',
  width: 0,
  height: 0,
};
