import { Component } from 'react';

import styles from './Switcher.module.css';

export interface ITab {
  index: number;
  name: string;
  icon: string;
}

interface IProps {
  active?: number;
  tabs: ITab[];
  onClick?: (tabIndex: number) => void;
}

class Switcher extends Component<IProps> {
  public render(): React.ReactElement {
    const { active, tabs } = this.props;
    return (
      <div className={styles.container}>
        {tabs.map((tab: ITab, index: number) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
          <i
            className={`${tab.icon} ${styles.item} ${active === tab.index ? styles.active : ''}`}
            onClick={this.onItemClick.bind(this, tab.index)}
            key={index}
          />
        ))}
      </div>
    );
  }

  private readonly onItemClick = (index: number) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(index);
    }
  };
}

export default Switcher;
