import { gql } from 'graphql-tag';

export default gql`
  mutation UpdateBrand($input: UpdateBrandMutationInput!) {
    updateBrand(input: $input) {
      brand {
        id
        externalId
        name
        address
        url
        __typename
      }
    }
  }
`;
