import { TableRow, TableCell } from '@material-ui/core';

import { ITableColumn } from '../Table/Table';

interface IProps {
  columns: ITableColumn[];
}

export const TableHeaders = ({ columns }: IProps): JSX.Element => (
  <TableRow>
    {columns.map(({ id, label }) => (
      <TableCell key={`header-${id}`}>{label}</TableCell>
    ))}
    <TableCell />
  </TableRow>
);
