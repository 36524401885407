import { useState } from 'react';

import { IProps } from '../../components/AttributeComponents/AttributeComponent';
import UserReport from '../../models/UserReport';
import Button from '../../components/Clickables/Buttons/Button';

interface IProgressProps extends IProps {
  entry: UserReport;
}

function ReportProgress({ entry }: IProgressProps) {
  const [status, setStatus] = useState(entry.status);
  const changeValue = status === 'resolved' ? 'reopen' : 'progress';

  const onClick = () => {
    entry
      .move(changeValue)
      .then(
        async (resp) => resp.json(),
        () => alert('Could not update the status'),
      )
      .then((json) => setStatus(UserReport.fromAttributes(json).status));
  };

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <span>{entry.status}</span>
      <Button size="sm" onClick={onClick} style={{ float: 'right' }}>
        changeValue
      </Button>
    </div>
  );
}

export default ReportProgress;
