.input {
  background: transparent;
  border: none;
  border-bottom: 1px solid lightgrey;
  font-family: inherit;
  font-size: var(--input-size);
  margin: 0;
  padding: calc(var(--input-padding) / 2) var(--input-padding);
  transition: all 0.3s ease-in-out;

  &:not([type='checkbox']) {
    width: 100%;
  }

  &:focus {
    @apply border-fishbrain-blue;
  }

  &:disabled,
  &:read-only:not([type='checkbox']):not(select) {
    cursor: not-allowed;
    border-color: lightgrey;
  }
}

textarea.input {
  resize: horizontal;
}

select.input {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: right 15px top 1em, right 10px top 1em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px;
  border-radius: 0;
  cursor: pointer;
}

.small {
  font-size: calc(0.9 * var(--input-size));
  padding: calc(var(--input-padding) / 3) calc(var(--input-padding) * (2 / 3));
}
