import Cookies from 'universal-cookie';

import { get } from '../api/rutilus';
import { COGNITO_CLIENT_ID } from '../api/cognito';
import { OAUTHClient, TOKEN_URL } from '../api/oauth2';

export const ACCESS_TOKEN_KEY = 'ornatus-access-token';
export const REFRESH_TOKEN_KEY = 'ornatus-refresh-token';
export const DEFAULT_COOKIE_OPTIONS = {
  path: '/',
};

const REFRESH_BUFFER_IN_SECONDS = 120;
const SECONDS_TTL = 3600;
const MS_IN_SECONDS = 1000;

const cookies = new Cookies(null, { path: '/' });

export async function checkRutilusAcceptsAuth(): Promise<boolean> {
  const resp = await get('/admin/users?per_page=0');
  if (!resp.ok) {
    logout();
    alert('You are not authorized to access this website. Please verify you are an admin');
    return new Promise((resolve) => resolve(false));
  }
  return new Promise((resolve) => resolve(true));
}

export function isValidToken(token: string): boolean {
  return typeof token === 'string' && token !== '' && token !== 'undefined';
}

export function logout(): void {
  cookies.remove(ACCESS_TOKEN_KEY, DEFAULT_COOKIE_OPTIONS);
  cookies.remove(REFRESH_TOKEN_KEY, DEFAULT_COOKIE_OPTIONS);
}

export async function authenticateWithCode(uri: string): Promise<boolean> {
  const response = await OAUTHClient.code.getToken(uri);
  setAccessToken(response.accessToken, new Date().getSeconds() + SECONDS_TTL);
  cookies.set(REFRESH_TOKEN_KEY, response.refreshToken, DEFAULT_COOKIE_OPTIONS);
  return await checkRutilusAcceptsAuth();
}

function setAccessToken(accessToken: string, expireIn: number) {
  // We set the cookie expiry a little earlier to avoid race conditions
  const expires = new Date(Date.now() + (expireIn - REFRESH_BUFFER_IN_SECONDS) * MS_IN_SECONDS);
  cookies.set(ACCESS_TOKEN_KEY, accessToken, { ...DEFAULT_COOKIE_OPTIONS, expires });
}

export async function getToken() {
  const accessToken = cookies.get(ACCESS_TOKEN_KEY);
  const refreshToken = cookies.get(REFRESH_TOKEN_KEY);

  if (isValidToken(accessToken)) {
    return accessToken;
  }
  if (isValidToken(refreshToken)) {
    try {
      const params = {
        client_id: COGNITO_CLIENT_ID,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      };
      const url = new URL(TOKEN_URL);

      Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));
      const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(async (resp) => {
        if (!resp.ok) {
          // `invalid_grant` error, refresh token is revoked
          // https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
          throw Error('Your authentication credentials was expired, logging out...');
        }

        return resp.json();
      });

      setAccessToken(response.access_token, response.expires_in);
      await checkRutilusAcceptsAuth();

      return response.access_token;
    } catch (error) {
      // Report error to user and log user out by clearing all auth tokens
      alert(error);
      logout();
      return null;
    }
  }
  return null;
}
