.overlap {
  background-color: transparent;
  display: flex;
  height: calc(100%);
  max-width: calc(100% - var(--sidebar-width));
  min-width: 500px;
  position: fixed;
  right: 0;
  top: 0;
}

.resizer {
  background-color: transparent;
  cursor: ew-resize;
  user-select: none;
  width: 10px;

  &:hover {
    background-color: rgba(black, 0.2);
    transition: all 1s ease;
  }
}
