import { get } from '../api/rutilus';
import { buildFilterParams, mergeSearchParams } from '../helpers/urlhelpers';
import BaseModel, { IListOptions } from './BaseModel';
import { IUser } from './User';

interface IUserCatch {
  readonly id: number;
  owner: IUser;
  caught_at_gmt: string;
  deep_link: string;
}

class UserCatch extends BaseModel {
  public static endpoint = '/users';

  public static modelType = 'User catches';

  public static fromAttributes(attributes: IUserCatch): UserCatch {
    return new UserCatch(attributes);
  }

  public static get readOnly(): string[] {
    return BaseModel.readOnly.concat(
      'reason',
      'status',
      'resolved_at',
      'subject',
      'subject_type',
      'subject_id',
    );
  }

  public static async list(
    { page, perPage, sortBy, searchQuery, urlParams }: IListOptions = {
      page: 0,
      perPage: 10,
      filterBy: {},
      sortBy: 'created_at desc',
    },
  ): Promise<unknown> {
    // eslint-disable-next-line no-console
    return new Promise(async (resolve, reject = console.error) => {
      const filterParams = buildFilterParams(sortBy);
      const params = {
        page,
        per_page: perPage,
        ...filterParams,
        ...(searchQuery ? { s: searchQuery } : {}),
      };
      const searchParams = mergeSearchParams(this.fetchParameters, params);

      return get(
        `${this.endpoint}/${
          urlParams && urlParams.id ? `${urlParams.id}/` : ''
        }catches?${searchParams.toString()}`,
      )
        .then(async (resp) => (resp.ok ? resp.json() : reject(resp)))
        .then((json) => json && json.map((obj: any) => this.fromAttributes(obj)))
        .then(resolve, reject);
    });
  }

  protected static readonly DEFAULT_OPTIONS = {};

  public modelType = 'User catch';

  public ['constructor']: typeof UserCatch;

  protected attributes: IUserCatch;

  constructor(attributes: IUserCatch) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get identifier(): string {
    return this.attr('id');
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}/catches`;
  }

  protected get class(): typeof UserCatch {
    return UserCatch;
  }
}

export default UserCatch;
