.tabItem {
  background: #eee;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline;
  list-style-type: none;
  padding: 10px;
}

.tabs {
  margin-bottom: 9px;
  padding-left: 0;
}

.activeTab {
  background: #fff;
  border-bottom: none;
  cursor: unset;
  pointer-events: none;
}

.content {
  border: 1px solid #ccc;
  padding: 10px;
}
