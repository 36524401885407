import { Component } from 'react';

import { post } from '../../api/rutilus';
import Button from '../../components/Clickables/Buttons/Button';

interface IProps {
  [restAttribute: string]: any;
}

interface State {
  selectedValue?: string;
  loading: boolean;
}

class GrantPremiumPromotion extends Component<IProps, State> {
  constructor(props: IProps) {
    super(props);
    this.state = { selectedValue: undefined, loading: false };
  }

  public change(event: React.FormEvent<HTMLSelectElement>): void {
    const periodValue: string = event.currentTarget.value;

    this.setState({
      selectedValue: periodValue,
    });
  }

  public render(): React.ReactElement {
    const { entry } = this.props;
    const userId = entry.id;
    const duration = this.state.selectedValue;

    const submitPremiumGrant = () => {
      if (
        window.confirm(
          `Are you sure about granting a ${duration} premium duration to user ${userId}?`,
        )
      ) {
        this.setState({ loading: true });
        post(
          '/admin/premium/grants',
          { user_id: userId, duration },
          { 'Content-Type': 'application/json', Accept: 'application/json' },
        ).then((response) => {
          if (response.ok) {
            alert(`User ${userId} was successfully given ${duration} premium time.`);
          } else {
            alert('Something went wrong, try again later or report a bug.');
          }
          this.setState({ loading: false });
        });
      }
    };

    return (
      <>
        <div>
          <select
            id="grant-premium-promotion"
            name="period"
            onChange={(e) => this.change(e)}
            value={this.state.selectedValue}
          >
            <option selected>Select premium time</option>
            <option value="daily">1 day</option>
            <option value="weekly">1 week</option>
            <option value="monthly">1 month</option>
            <option value="two_month">2 months</option>
            <option value="three_month">3 months</option>
            <option value="six_month">6 months</option>
            <option value="yearly">1 year</option>
            <option value="lifetime">Lifetime</option>
          </select>
        </div>
        <div>
          <Button
            variant="buttonSecondary"
            disabled={this.state.loading}
            size="sm"
            title="Grant premium"
            onClick={submitPremiumGrant}
          >
            {this.state.loading ? 'Loading...' : 'Grant'}
          </Button>
        </div>
      </>
    );
  }
}

export default GrantPremiumPromotion;
