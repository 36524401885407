import IFormHeaders from '../../../components/Edit/FormHeaderInterface';
import {
  FormHidden,
  FormProps,
  FormText,
  FormTextarea,
} from '../../../components/Edit/FormAttributes/FormAttribute';
import DetailsMap from './DetailsMap';
import UserSuggestedChange from '../../../models/UserSuggestedChange';
import { getUserSuggestedChange } from '../../../queries/UserSuggestedChanges';
import GqlEdit from '../../../components/Edit/GqlEdit';
import { Metadata } from '../../../components/Metadata/Metadata';
import AcceptRejectButton from './AcceptRejectButton';
import styles from '../../../components/Metadata/Metadata.module.css';

import { FormTextSuggestion } from '../helpers';
import SuggestionAttachments from './SuggestionAttachments';
import { REJECTION_REASON_MAX_LENGTH } from '../constants';

const editFormHeaders: IFormHeaders[] = [
  { title: 'External ID', attribute: 'externalId', component: FormText, readonly: true },
  {
    title: 'Fishing Water ID',
    attribute: 'fishingWaterId',
    component: (props: FormProps): JSX.Element => {
      if (props.values.fishingWaterId) {
        return <FormText {...props} />;
      }
      return <></>;
    },
    readonly: true,
  },
  {
    title: 'New Fishing Water ID',
    attribute: 'newFishingWaterId',
    component: (props: FormProps): JSX.Element => {
      if (props.values.fishingWaterId) {
        return <></>;
      }
      return <FormText inputClassName="!mb-0" {...props} />;
    },
  },
  {
    title: '',
    attribute: 'names',
    row: [
      {
        title: 'Name',
        attribute: 'name',
        component: FormText,
      },
      {
        title: 'Suggested Water Name',
        attribute: 'suggestedName',
        component: FormTextSuggestion,
      },
    ],
  },
  { title: '', attribute: '', component: DetailsMap },
  {
    title: '',
    attribute: 'coords',
    row: [
      {
        title: 'Latitude',
        attribute: 'latitude',
        component: (props: FormProps): JSX.Element => {
          return <FormText inputClassName="!mb-0" {...props} />;
        },
        readonly: true,
      },
      {
        title: 'Longitude',
        attribute: 'longitude',
        component: (props: FormProps): JSX.Element => {
          return <FormText inputClassName="!mb-0" {...props} />;
        },
        readonly: true,
      },
    ],
  },
  {
    title: '',
    attribute: 'suggestedCoords',
    row: [
      {
        title: 'Suggested Latitude',
        attribute: 'suggestedLatitude',
        component: FormTextSuggestion,
      },
      {
        title: 'Suggested Longitude',
        attribute: 'suggestedLongitude',
        component: FormTextSuggestion,
      },
    ],
  },
  {
    title: 'Water Metadata',
    attribute: '',
    row: [
      {
        title: '',
        attribute: 'metadata',
        component: (props: any) => (
          <Metadata
            metadata={props.values.suggestedMetadata ? props.values.metadata : undefined}
            groupFilter={[props.values.suggestedMetadata?.group?.id]}
            disable
            {...props}
          />
        ),
      },
      {
        title: '',
        attribute: 'suggestedMetadata',
        component: (props: any) => (
          <Metadata
            metadata={props.values.suggestedMetadata ? [props.values.suggestedMetadata] : undefined}
            valuesField="suggestedMetadataId"
            groupFilter={[props.values.suggestedMetadata?.group?.id]}
            className={styles.suggestedChange}
            {...props}
          />
        ),
      },
    ],
  },
  {
    title: '',
    attribute: 'suggestedMetadataId',
    component: FormHidden,
  },
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Official Website URL', attribute: 'url', component: FormText },
      {
        title: 'Suggested URL',
        attribute: 'suggestedUrl',
        component: FormTextSuggestion,
      },
    ],
  },
  {
    title: 'Additional Information',
    attribute: 'additionalInformation',
    component: FormTextarea,
  },
  {
    title: 'Attachments',
    attribute: 'attachments',
    component: SuggestionAttachments,
  },
  {
    title: 'Rejection Reason',
    attribute: 'rejectionReason',
    component: (props: any) => (
      <FormTextarea {...props} inputMaxLength={REJECTION_REASON_MAX_LENGTH} />
    ),
  },
  {
    title: 'Accept/Reject',
    attribute: 'status',
    component: AcceptRejectButton,
  },
];

const editUserSuggestedChange = (): JSX.Element => (
  <GqlEdit
    model={UserSuggestedChange}
    headers={editFormHeaders}
    editQuery={getUserSuggestedChange}
    prepareEditQuery={(data: any) => {
      const {
        fishingWater,
        suggestedWaterName,
        userSuggestedWaterMetadata,
        longitude,
        latitude,
        url,
        ...rest
      } = data.userSuggestedChange;
      return {
        metadata: fishingWater?.metadata || null,
        fishingWaterId: fishingWater?.externalId || '',
        name: fishingWater?.name || '',
        suggestedName: suggestedWaterName?.name || '',
        latitude: fishingWater?.latitude || '',
        longitude: fishingWater?.longitude || '',
        url: fishingWater?.url || '',
        suggestedLatitude: latitude,
        suggestedLongitude: longitude,
        suggestedUrl: url,
        suggestedMetadata: userSuggestedWaterMetadata?.fishingWaterMetadata || null,
        suggestedMetadataId: userSuggestedWaterMetadata?.fishingWaterMetadata.id || '',
        ...rest,
      };
    }}
    validationSchema={undefined}
  />
);

export default editUserSuggestedChange;
