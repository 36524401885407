import { gql } from 'graphql-tag';

export const updatePageMutation = gql`
  mutation UpdatePageMutation($input: UpdatePageMutationInput!) {
    updatePage(input: $input) {
      page {
        id
        published
        description
      }
      userErrors {
        path
        message
      }
    }
  }
`;

export const updatePageMutationWithBrand = gql`
  mutation UpdatePageMutationWithBrand(
    $pageInput: UpdatePageMutationInput!
    $brandInput: UpdateBrandMutationInput!
  ) {
    updatePage(input: $pageInput) {
      page {
        id
        published
        description
      }
      userErrors {
        path
        message
      }
    }
    updateBrand(input: $brandInput) {
      brand {
        id
        url
      }
      userErrors {
        path
        message
      }
    }
  }
`;
