.metadata select {
    background-color: rgba(black, 0.02);
    &:focus {
        background-color: rgba(white, 0.3);
        outline: 0;
    }

    &:disabled {
        background-color: transparent;
    }
}

.suggestedChange select {
    @apply border border-error;
}
