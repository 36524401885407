import SplitPane from 'react-split-pane';

import './SplitPane.css';

interface Props {
  children: React.ReactNode;
  name?: string;
  minSize?: number;
  isSwitcherShown?: boolean;
}

const MIN_SIZE = 100;

const update =
  (name: string): ((n: number) => void) =>
  (size: number): void =>
    localStorage.setItem(name, size.toString());

const CustomSplitPane = ({
  children,
  minSize = MIN_SIZE,
  name = 'split-panel',
  isSwitcherShown,
}: Props): React.ReactElement => (
  <SplitPane
    minSize={minSize}
    split="vertical"
    defaultSize={parseInt(localStorage.getItem(name) || '50', 10)}
    onChange={update(name)}
    className={isSwitcherShown ? 'PanelMoved' : ''}
  >
    {children}
  </SplitPane>
);

export default CustomSplitPane;
