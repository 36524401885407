import { Component, createRef } from 'react';

import styles from './Input.module.css';

interface Props {
  className?: string;
  onChange?: (event: React.SyntheticEvent<HTMLTextAreaElement>) => void;
  rest?: any[];
  [keyForRestEntrie: string]: any;
}

class Textarea extends Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps: Partial<Props> = {
    onChange: (_event: React.SyntheticEvent<HTMLTextAreaElement>) => null,
  };

  private readonly myRef: React.RefObject<HTMLTextAreaElement> = createRef();

  constructor(props: Props) {
    super(props);
    this.resize = this.resize.bind(this);
  }

  public componentDidMount(): void {
    this.resize();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      this.resize();
    }
  }

  public render(): React.ReactElement {
    const { onChange, className, ...rest } = this.props;
    return (
      <textarea
        name="text"
        onChange={onChange}
        onKeyUp={this.resize}
        ref={this.myRef}
        rows={1}
        className={`${styles.input} ${className}`}
        {...rest}
      />
    );
  }

  private resize(): void {
    if (!this.myRef.current) {
      return;
    }

    this.myRef.current.style.height = 'auto';
    this.myRef.current.style.height = `${this.myRef.current.scrollHeight + 1}px`;
    this.myRef.current.removeAttribute('headers');
    this.myRef.current.removeAttribute('entry');
    this.myRef.current.removeAttribute('values');
    this.myRef.current.removeAttribute('errors');
    this.myRef.current.removeAttribute('initialValues');
    this.myRef.current.removeAttribute('touched');
  }
}

export default Textarea;
