import SparkMD5 from 'spark-md5';

export const uploadToS3 = async (
  url: string,
  file: File,
  headers: { name: string; value: string }[],
): Promise<Response> => {
  const headersObj = new Headers();
  headers.forEach(({ name, value }) => headersObj.append(name, value));
  return fetch(url, { headers: headersObj, method: 'PUT', body: file }).then(
    (response: Response) => {
      if (!response.ok) {
        throw response;
      }
      return response;
    },
  );
};

export const getFileChecksum = async (file: File): Promise<string> =>
  new Promise((resolve, _reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () =>
      resolve(
        Buffer.from(SparkMD5.ArrayBuffer.hash(fileReader.result as any), 'hex').toString('base64'),
      );
    fileReader.readAsArrayBuffer(file);
  });

export const getImageDimensions = (imageDataUrl: string): { width: number; height: number } => {
  const image = new Image();
  image.src = imageDataUrl;
  return { width: image.width, height: image.height };
};
