import { get } from 'ts-get';

import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import { BASE as MYKISS_BASE } from '../../api/mykiss';

import Edit from '../../components/Edit/Edit';
import Checkbox from '../../components/Edit/FormAttributes/Checkbox';
import {
  AttributeWithTitle,
  FormProps,
  FormText,
  FormTextarea,
} from '../../components/Edit/FormAttributes/FormAttribute';
import FormCatchComments from '../../components/Edit/FormAttributes/FormCatchComments';
import FormCatchPosition from './Position';
import FormDate from '../../components/Edit/FormAttributes/FormDate';
import FormLink from '../../components/Edit/FormAttributes/FormLink';
import SubjectReports from '../UserReports/SubjectReports';
import AttributeWithSearch from '../../components/Edit/FormAttributes/AttributeWithSearch';
import FormSelect from '../../components/Edit/FormAttributes/FormSelect';
import CatchImages from './Images';
import { Value } from '../../components/Input/SearchInput';

import Catch from '../../models/Catch';
import Species from '../../models/Species';
import { CuratedCatchButton } from './components/CuratedCatchButton';
import AttributeWithGqlSearch from '../../components/AttributeComponents/AttributeWithGqlSearch';
import { getFishingWater, getFishingWatersSearch } from '../../queries/FishingWaters';
import {
  GetFishingWater,
  GetFishingWatersSearch,
  GetFishingWatersSearch_fishingWatersSearch_edges,
} from '../../interfaces/graphql';
import { compareStrings } from '../../helpers/comparators';

const UserReportsOnCatch = ({ entry }: { entry: Catch }): JSX.Element => (
  <div>
    <h2>Reports on this catch</h2>
    <SubjectReports entry={{ subjectType: 'Catch', subjectId: entry.id }} />
  </div>
);

const Links = ({ entry }: { entry: Catch }): JSX.Element => (
  <a href={`https://${MYKISS_BASE}/catches/${entry.external_id}`}>View on {MYKISS_BASE}</a>
);

const speciesConverter = (species?: Species): Value => ({
  label: species ? `${species.name} (${species.species})` : '- not set-',
  value: species ? species.id : -1,
});
const speciesFetcher = async (search: string): Promise<Value[]> =>
  Species.list({ searchQuery: search, page: 0, perPage: 20 }).then((species: Species[]) =>
    species.map(speciesConverter),
  );
const speciesSearch = AttributeWithSearch(speciesFetcher, speciesConverter, 'species', '/species/');

const fishingWaterConverter = ({ fishingWatersSearch }: GetFishingWatersSearch): Value[] => {
  if (!fishingWatersSearch || !fishingWatersSearch.edges) {
    return [];
  }
  return fishingWatersSearch.edges.map(
    (edge: GetFishingWatersSearch_fishingWatersSearch_edges | null) => ({
      label: get(edge, (e) => e.node.name + ' (' + e.node.externalId + ')', '- not set-'),
      value: get(edge, (e) => e.node.id, null),
    }),
  );
};

const singlefishingWaterConverter = ({ fishingWater }: GetFishingWater): Value => ({
  label: fishingWater.name + ' (' + fishingWater.externalId + ')' || '- not set-',
  value: fishingWater.externalId || null,
});

const fishingWaterSearch = (props: FormProps) => {
  const options = {
    initialOptions: [],
    query: getFishingWatersSearch,
    singleQuery: getFishingWater,
    singleConverter: singlefishingWaterConverter,
    converter: fishingWaterConverter,
    prefetchedAttribute: 'fishing_water_id',
  };

  return props
    ? AttributeWithGqlSearch({
        ...options,
        ...props,
      })
    : (formProps: FormProps) =>
        AttributeWithGqlSearch({
          ...options,
          ...formProps,
        });
};

const StatusSelect = FormSelect([
  { value: 'approved' },
  { value: 'pending' },
  { value: 'disapproved' },
]);

const formHeaders: IFormHeaders[] = [
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Id', attribute: 'id', component: FormText },
      { title: 'External ID', attribute: 'external_id', component: FormText },
    ],
  },
  {
    title: '',
    attribute: '',
    row: [
      {
        title: 'Owner',
        attribute: 'owner',
        component: AttributeWithTitle(FormLink),
        link: '/users/',
      },
      {
        title: 'Post',
        attribute: 'post_external_id',
        component: AttributeWithTitle(FormLink),
        link: '/posts/',
      },
      { title: 'See more', attribute: '', component: AttributeWithTitle(Links) },
    ],
  },
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Staff picked?', attribute: 'staff_picked', component: Checkbox },
      {
        title: 'Catch and release',
        attribute: 'catch_and_release',
        component: Checkbox,
      },
      {
        title: 'Shop unfriendly',
        attribute: 'shop_unfriendly',
        component: Checkbox,
      },
    ],
  },
  // The equals here is in aid of our end to end tests. The way Playwright works, we can update the select option, but
  // it doesn't trigger the onBlur function that Formik pays attention to in order to set the field as 'touched'. Later
  // when we save changes we look at this 'touched' state to determine if we should save the field. This hack allows us
  // us to skip that check and instead use the 'equals' function to determine if the field has changed. All of this is
  // a bit weird as our save button uses a different value (Formik's dirty) to determine if we've modified the content.
  { title: 'Status', attribute: 'status', component: StatusSelect, equals: compareStrings },
  {
    title: 'Species',
    attribute: 'species_id',
    component: AttributeWithTitle(speciesSearch),
  },
  {
    title: 'Curated catch',
    attribute: '',
    component: CuratedCatchButton,
  },
  { title: 'Description', attribute: 'description', component: FormTextarea },
  {
    title: '',
    attribute: '',
    row: [
      { title: 'Weight', attribute: 'weight', component: FormText },
      { title: 'Length', attribute: 'length', component: FormText },
    ],
  },
  { title: 'Images', attribute: 'photos', component: AttributeWithTitle(CatchImages) },
  {
    title: '',
    attribute: '',
    row: [
      {
        title: 'Private location',
        attribute: 'private_position',
        component: Checkbox,
      },
      {
        title: 'Private water',
        attribute: 'private_fishing_water',
        component: Checkbox,
      },
    ],
  },
  {
    title: 'Fishing water',
    attribute: 'fishing_water_id',
    component: AttributeWithTitle(fishingWaterSearch),
  },
  {
    title: 'Catch position',
    attribute: 'catch_position',
    component: AttributeWithTitle(FormCatchPosition),
  },
  { title: 'Caught at', attribute: 'caught_at_gmt', component: FormDate },
  { title: 'Comments', attribute: 'comments', component: FormCatchComments },
  {
    title: 'Reports on this catch',
    attribute: '',
    component: UserReportsOnCatch,
  },
];

const EditCatch = (): JSX.Element => (
  <Edit model={Catch} headers={formHeaders} returnPath="/catches" />
);

export default EditCatch;
