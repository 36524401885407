import { useQuery } from '@apollo/client';
import { Link, Route, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { get } from 'ts-get';

import { genericLoadMore } from '../../helpers/graphql';
import SidePane from '../../components/SidePane/SidePane';
import GqlEdit from '../../components/Edit/GqlEdit';
import FormHeaders from '../../components/Edit/FormHeaderInterface';
import Header from '../../components/List/HeadersInterface';
import GqlModelList from '../../components/ModelList/GqlModelList';
import Brand from '../../models/Equipment/Brand';
import mutation from '../../mutations/UpdateBrand';
import AttributeID from '../../components/AttributeComponents/AttributeID';
import {
  FormText,
  FormTextarea,
  AttributeWithTitle,
} from '../../components/Edit/FormAttributes/FormAttribute';
import editQuery from '../../queries/Brand';
import query from '../../queries/Brands';
import { ListBrands, ListBrandsVariables } from '../../interfaces/graphql';
import Flex from '../../components/Flex/Flex';

import CreateBrandPage from '../Pages/CreateBrandPage';
import NewBrand from './NewBrand';

const headers: Header[] = [
  {
    title: 'externalId',
    attribute: 'externalId',
    component: AttributeID,
    link: '/equipment/brands/',
  },
  { title: 'Name', attribute: 'name', filterable: true },
];

const Page = ({ entry }: { entry: Brand }) =>
  entry.page ? (
    <Link to={`/pages/${entry.page.id}`}>Go to page</Link>
  ) : (
    <CreateBrandPage brandId={parseInt(entry.id, 10)} />
  );

const formHeaders: FormHeaders[] = [
  { title: 'externalId', attribute: 'externalId', component: FormText, readonly: true },
  { title: 'Page', attribute: 'page', component: AttributeWithTitle(Page), readonly: true },
  { title: 'Name', attribute: 'name', component: FormText, readonly: true },
  { title: 'Address', attribute: 'address', component: FormText },
  { title: 'Url', attribute: 'url', component: FormText },
  { title: 'About title', attribute: 'aboutTitle', component: FormText },
  {
    title: 'About description',
    attribute: 'aboutDescription',
    component: FormTextarea,
  },
  { title: 'Products title', attribute: 'productsTitle', component: FormText },
  {
    title: 'Products description',
    attribute: 'productsDescription',
    component: FormTextarea,
  },
  { title: 'Members title', attribute: 'membersTitle', component: FormText },
];

const EditBrand = () => (
  <GqlEdit headers={formHeaders} model={Brand} mutation={mutation} editQuery={editQuery} />
);

const BrandsView = () => {
  const { pathname, search } = useLocation();
  const match = useRouteMatch();
  const { 'filter.name__start': nameContains } = queryString.parse(search);

  const variables =
    nameContains && typeof nameContains === 'string' ? { filters: { nameContains } } : undefined;

  const { loading, error, data, fetchMore } = useQuery<ListBrands, ListBrandsVariables>(query, {
    fetchPolicy: 'network-only',
    variables,
  });

  const onLoadMore = genericLoadMore('brands');

  return (
    <Flex>
      <GqlModelList
        isLoading={loading}
        error={error}
        entries={get(data, (d) => d.brands)}
        headers={headers}
        path={pathname}
        onLoadMore={() => onLoadMore(fetchMore, data && data.brands)}
      />
      <SidePane showOnPath={`${match.path}/:slugs`}>
        <Route path={`${match.path}/new`} component={NewBrand} />
        <Route path={`${match.path}/:externalId`} component={EditBrand} />
      </SidePane>
    </Flex>
  );
};

export default BrandsView;
