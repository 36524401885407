import Fieldset from '../../Fieldset/Fieldset';
import { FormProps } from './FormAttribute';

const Checkbox = ({
  header,
  entry,
  handleChange,
  handleBlur,
  value,
  ...rest
}: FormProps): JSX.Element => {
  const handle = (event: any): void => {
    handleChange(event);
    handleBlur(event);
  };

  return (
    <Fieldset
      label={header.title}
      onChange={handle}
      inputProps={{
        checked: value,
        disabled: entry.isReadOnly(header.attribute),
        name: header.attribute,
        type: 'checkbox',
        defaultValue: false,
        ...rest,
      }}
    />
  );
};

export default Checkbox;
