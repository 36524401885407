import { useQuery } from '@apollo/client';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { mergeQueryParams } from '../../helpers/urlhelpers';
import GqlBaseModel from '../../models/GqlBaseModel';
import Details from '../Details/Details';
import ServerError from '../ErrorBoundary/ServerError';
import IFormHeaders from './FormHeaderInterface';
import GqlForm, { IKeyValue } from './GqlForm';

interface IProps {
  editQuery: any;
  prepareEditQuery?: (data: any) => Record<string, unknown>;
  headers: IFormHeaders[];
  model: Partial<GqlBaseModel>;
  // GraphQL update mutation for GraphQL model.
  // Must accept $input variable
  mutation?: any;
  prepareMutation?: (values: IKeyValue) => { [key: string]: unknown };
  prepareDelete?: (entry: any | undefined) => any;
  confirmDeleteText?: string;
  deletion?: any;
  asyncDeletion?: boolean;
  afterEdit?: (data: any) => any;
  afterDelete?: (data: any) => any;
  validationSchema: any;
}

const GqlEdit = ({
  prepareEditQuery = (data) => data.entry,
  validationSchema = false,
  ...props
}: IProps) => {
  const {
    headers,
    model,
    mutation,
    editQuery,
    deletion,
    asyncDeletion,
    afterEdit,
    confirmDeleteText,
  } = props;
  const { id, externalId } = useParams<{ id?: string; externalId?: string }>();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const variables = id ? { id: parseInt(id, 10) } : { externalId };

  const { loading, error, data } = useQuery(editQuery, { variables, fetchPolicy: 'no-cache' });

  const afterDelete = () => {
    const search = mergeQueryParams(location.search, {
      fetchTime: Date.now(),
    });
    const path = match.path.slice(0, match.path.lastIndexOf('/'));
    history.push(`${path}?${search}`);
    window.location.reload();
  };

  if (loading) {
    return <Details title="Loading...">Loading...</Details>;
  }

  if (error) {
    return (
      <Details title="Error!">
        <ServerError message={error.message} />
      </Details>
    );
  }

  const entry = model.fromAttributes({ ...prepareEditQuery(data) });
  const titleId = variables.id ? variables.id : variables.externalId;

  return (
    <Details title={`Edit ${entry.modelType} #${titleId}`}>
      <GqlForm
        entry={entry}
        headers={headers}
        prepareDelete={props.prepareDelete}
        deleteEntry={deletion}
        confirmDeleteText={confirmDeleteText}
        asyncDeletion={asyncDeletion}
        editEntry={mutation}
        prepareEdit={props.prepareMutation}
        afterDelete={props.afterDelete || afterDelete}
        afterEdit={afterEdit}
        validationSchema={validationSchema}
      />
    </Details>
  );
};

export default GqlEdit;
