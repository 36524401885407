/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { ZoomControl, Feature, Layer, GeoJSONLayer, Popup } from 'react-mapbox-gl';
import { Link, useParams, useHistory } from 'react-router-dom';
import MapboxGL from 'mapbox-gl';
import debounce from 'lodash.debounce';
import SplitPane from 'react-split-pane';

import Filter from '../../helpers/filter';
import { RansackMatcher } from '../../helpers/ransack';
import { get, put } from '../../api/rutilus';
import AttributeLink from '../../components/AttributeComponents/AttributeLink';
import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import Fieldset from '../../components/Fieldset/Fieldset';
import Select from '../../components/Input/Select';
import Flex from '../../components/Flex/Flex';
import ListBody from '../../components/List/ListBody';
import ListHeader from '../../components/List/ListHeader';
import LoadingTableBody from '../../components/Loading/LoadingTableBody';
import Map from '../../components/Map/Map';
import Table from '../../components/Table/Table';
import IHeader from '../../components/List/HeadersInterface';
import {
  boundingBoxCatches,
  boundingBoxCatches_catches_edges_node as Catch,
  boundingBoxCatches_catches_pageInfo as PageInfo,
} from '../../interfaces/graphql';

import styles from './CatchMap.module.css';
import Button from '../../components/Clickables/Buttons/Button';

const ROWS_COUNT = 5;
const DEFAULT_ZOOM = 15;

const fetchCatches = gql`
  query boundingBoxCatches($boundingBox: BoundingBoxInputObject!, $cursor: String) {
    catches(
      first: 50
      after: $cursor
      boundingBox: $boundingBox
      filters: { orderByRecent: true }
    ) {
      edges {
        node {
          externalId
          id: externalId
          lng: longitude
          lat: latitude
          createdAt
          caughtAtGmt
          privateFishingWater
          privatePosition
          user {
            id: externalId
            identifier: nickname
          }
          fishingWater {
            identifier: name
            id: externalId
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        cursor: endCursor
      }
    }
  }
`;

// convert a string such as `true` to a boolean
const stringToBool = (str: string): boolean | undefined => {
  if (str === 'true') {
    return true;
  }
  if (str === 'false') {
    return false;
  }
  return undefined;
};

// remove blank values from obj
const compact = (obj: { [key: string]: unknown }): { [key: string]: unknown } =>
  Object.entries(obj).reduce(
    (newObj: { [key: string]: unknown }, [key, value]: [string, unknown]) =>
      value == null ? newObj : { ...newObj, [key]: value },
    {},
  );

// Use this to filter out nullable values from an array.
function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

const Privacy = ({ entry: { privatePosition, privateFishingWater } }: { entry: Catch }) => {
  if (privatePosition && privateFishingWater) {
    return <>private</>;
  }
  if (privatePosition) {
    return <>competitive</>;
  }
  return <>public</>;
};

const setCursor = (cursor: string, { map }: any) => {
  map.getCanvas().style.cursor = cursor; // eslint-disable-line no-param-reassign
};
const EMPTY_PAGEINFO: PageInfo = {
  hasNextPage: false,
  cursor: null,
  __typename: 'PageInfo',
};

interface MapStyle {
  map: string;
  satelite: string;
}

interface ParamTypes {
  zoom: string;
  coordinates: string;
}

const MAP_STYLES: MapStyle = {
  map: 'mapbox://styles/fishbrain-dev/cjab6kel633dm2so9xp9nzn3u',
  satelite: 'mapbox://styles/fishbrain-dev/cjm977yx0f4z12rnif7qm1r51',
};

const CatchMap = () => {
  const client = useApolloClient();
  const history = useHistory();
  const [pageInfo, setPageInfo] = useState<PageInfo>(EMPTY_PAGEINFO);
  const [catches, setCatches] = useState<Catch[]>([] as Catch[]);
  const [selectedCatches, setSelectedCatches] = useState<{ [externalId: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [bounds, setBounds] = useState<MapboxGL.LngLatBounds>(
    new MapboxGL.LngLatBounds([
      [0, 0],
      [0, 0],
    ]),
  );
  const [waters, setWaters] = useState<GeoJSON.FeatureCollection>({
    type: 'FeatureCollection',
    features: [],
  });
  const [water, setWater] = useState<any>(null);
  const [style, setStyle] = useState<keyof MapStyle>('map');

  // This dance is to prevent spasmic map movements caused by fast panning. It
  // uses the URL parameters first render only, and after that it just updates
  // the URL for shareability.
  const { coordinates, zoom } = useParams<ParamTypes>();
  const [initialParams, setInitialParams] = useState<any>({
    center: coordinates.split(',').map(parseFloat).reverse(),
    zoom: [zoom ? parseFloat(zoom) : DEFAULT_ZOOM],
  });
  useEffect(() => {
    return () => setInitialParams(null);
  }, []);

  const loadCatches = debounce((mapBounds: MapboxGL.LngLatBounds, append = false) => {
    setLoading(true);
    const { lat: swLat, lng: swLng } = mapBounds.getSouthWest();
    const { lat: neLat, lng: neLng } = mapBounds.getNorthEast();
    client
      .query<boundingBoxCatches>({
        query: fetchCatches,
        fetchPolicy: 'network-only',
        variables: {
          boundingBox: {
            southWest: { latitude: swLat, longitude: swLng },
            northEast: { latitude: neLat, longitude: neLng },
          },
          cursor: append ? pageInfo.cursor : null,
        },
      })
      .then(
        ({
          data: {
            catches: { edges, pageInfo: newPageInfo, totalCount: newTotalCount },
          },
        }) => {
          setLoading(false);
          setPageInfo(newPageInfo);
          setTotalCount(newTotalCount);
          if (edges) {
            const newCatches = edges.map((edge) => edge && edge.node).filter(notEmpty);
            setCatches(append ? [...catches, ...newCatches] : newCatches);
          }
        },
      );
  }, 500); // eslint-disable-line no-magic-numbers

  const loadWaters = debounce((mapBounds: MapboxGL.LngLatBounds) => {
    const [[swLat, swLng], [neLat, neLng]] = mapBounds.toArray();
    get(`/maps/${[swLng, swLat, neLng, neLat].join(',')}/explore?filter[types]=fishing_water`)
      .then(async (data) => data.json())
      .then((data: GeoJSON.FeatureCollection) => setWaters(data));
  }, 500); // eslint-disable-line no-magic-numbers

  const onMove = (map: MapboxGL.Map, _event: React.SyntheticEvent<unknown>) => {
    const mapBounds = map.getBounds();
    const center = map.getCenter();
    const newZoom = map.getZoom();
    history.push(`/catch_map/${center.lat},${center.lng}/${newZoom}`);
    loadCatches(mapBounds);
    loadWaters(mapBounds);
    setBounds(mapBounds);
  };

  const select = (externalId: string, selected: boolean) =>
    setSelectedCatches({ ...selectedCatches, [externalId]: selected });

  // eslint-disable-next-line react/no-unstable-nested-components
  const Checkbox = ({ entry: { externalId } }: { entry: Catch }) => {
    const checked = selectedCatches[externalId];
    const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
      select(externalId, event.currentTarget.checked);
    };
    return <input type="checkbox" checked={checked} onChange={onChange} />;
  };

  const setAll = (selected: boolean): void =>
    setSelectedCatches(
      catches.reduce((acc: any, { externalId }: Catch) => ({ ...acc, [externalId]: selected }), {}),
    );
  const selectedAndVisible = catches.filter(({ externalId }: Catch) => selectedCatches[externalId]);
  const unselected = catches.filter(({ externalId }: Catch) => !selectedCatches[externalId]);
  const hasAnySelected =
    Object.keys(selectedCatches).length > 0 && Object.values(selectedCatches).some((v) => v);

  const submitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const { privateWater, privatePosition, fishingWater } = form;
    const properties = compact({
      private_fishing_water: stringToBool(privateWater.value),
      private_position: stringToBool(privatePosition.value),
      fishing_water_id: fishingWater.value || null,
    });
    // eslint-disable-next-line fp/no-let
    let step = 0;
    setProgress(step);
    selectedAndVisible.forEach(async ({ externalId }: Catch) => {
      await put(`/catches/${externalId}`, properties);
      setProgress(++step); // eslint-disable-line no-plusplus
      if (step === selectedAndVisible.length) {
        // refresh catches list
        if (bounds != null) {
          loadCatches(bounds);
        }
        setTimeout(() => {
          setProgress(null);
          form.reset();
        }, 1000); // eslint-disable-line
      }
    });
  };

  const headers: IHeader[] = [
    {
      attribute: 'select',
      component: Checkbox,
      title: '',
    },
    {
      attribute: 'externalId',
      component: AttributeLink,
      title: 'Catch',
      link: '/catches/',
    },
    {
      attribute: 'createdAt',
      component: AttributeDate,
      title: 'Created At',
    },
    {
      attribute: 'user',
      component: AttributeLink,
      link: '/users/',
      title: 'User',
    },
    {
      attribute: 'fishingWater',
      component: AttributeLink,
      link: '/fishing_waters/',
      title: 'Water',
    },
    {
      attribute: 'privacy',
      component: Privacy,
      title: 'Privacy',
    },
    {
      attribute: 'caughtAtGmt',
      component: AttributeDate,
      title: 'Caught at',
    },
  ];

  return (
    <Flex>
      {progress !== null && (
        <div className={styles.progress}>
          {progress} of {selectedAndVisible.length} done
        </div>
      )}
      <SplitPane defaultSize="50%">
        <div className={styles.tableAndElse}>
          <div className={styles.controls}>
            <div className={styles.toggleButtons}>
              <Button
                size="sm"
                disabled={loading || catches.length === 0}
                onClick={() => setAll(true)}
              >
                Select all ({catches.length})
              </Button>{' '}
              <Button
                size="sm"
                disabled={loading || !hasAnySelected}
                onClick={() => setAll(false)}
                variant="buttonDanger"
              >
                Select none
              </Button>
              <span>
                {' '}
                {selectedAndVisible.length} catches selected, {totalCount} catches within map area
              </span>
            </div>
            <form action="" method="" onSubmit={submitForm} className={styles.form}>
              <Fieldset>
                <Select
                  name="privateWater"
                  id="privateWater"
                  disabled={selectedAndVisible.length === 0}
                >
                  <option value="">leave unchanged</option>
                  <option value="true">private (yes)</option>
                  <option value="false">public (no)</option>
                </Select>
                <label htmlFor="privateWater">Private Water</label>
              </Fieldset>
              <Fieldset>
                <Select
                  name="privatePosition"
                  id="privatePosition"
                  disabled={selectedAndVisible.length === 0}
                >
                  <option value="">leave unchanged</option>
                  <option value="true">private (yes)</option>
                  <option value="false">public (no)</option>
                </Select>
                <label htmlFor="privatePosition">Private position</label>
              </Fieldset>
              <Fieldset>
                <Select
                  name="fishingWater"
                  id="fishingWater"
                  disabled={selectedAndVisible.length === 0}
                >
                  <option value="">leave unchanged</option>
                  {waters &&
                    waters.features.map(
                      ({ properties }) =>
                        properties && (
                          <option key={properties.id} value={properties.id}>
                            {properties.name} [{properties.external_id}]
                          </option>
                        ),
                    )}
                </Select>
                <label htmlFor="fishingWater">Fishing Water</label>
              </Fieldset>
              <div className={styles.submit}>
                <Button disabled={selectedAndVisible.length === 0}>Save</Button>
              </div>
            </form>
          </div>
          <Table spacious>
            <ListHeader headers={headers} />
            <ListBody path="/catch_map" headers={headers} entries={catches} />
            {loading && <LoadingTableBody rows={ROWS_COUNT} columns={headers.length} />}
          </Table>
          {!loading && catches.length === 0 && <span>No catches in that area</span>}
          {!loading && pageInfo.hasNextPage && (
            <Button
              onClick={() => {
                if (bounds != null) {
                  loadCatches(bounds, true);
                }
              }}
            >
              Load more!
            </Button>
          )}
        </div>

        <Map
          style={MAP_STYLES[style]}
          onMoveEnd={onMove}
          onResize={onMove}
          onStyleLoad={onMove}
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          {...initialParams}
        >
          <div className={styles.bottomBar}>
            <Button size="sm" disabled={style === 'map'} onClick={() => setStyle('map')}>
              Map
            </Button>
            <Button size="sm" disabled={style === 'satelite'} onClick={() => setStyle('satelite')}>
              Hybrid
            </Button>
          </div>
          <Layer
            type="symbol"
            layout={{ 'icon-image': 'circle-stroked-15', 'icon-allow-overlap': true }}
          >
            {unselected.map(({ lng, lat, externalId }: Catch) =>
              lng && lat ? (
                <Feature
                  key={externalId}
                  coordinates={[lng, lat]}
                  onClick={() => select(externalId, true)}
                  onMouseEnter={setCursor.bind(null, 'pointer')}
                  onMouseLeave={setCursor.bind(null, '')}
                />
              ) : null,
            )}
          </Layer>
          <Layer
            type="symbol"
            id="marker"
            layout={{ 'icon-image': 'circle-15', 'icon-allow-overlap': true }}
          >
            {selectedAndVisible.map(({ lng, lat, externalId }: Catch) =>
              lng && lat ? (
                <Feature
                  key={externalId}
                  coordinates={[lng, lat]}
                  onClick={() => select(externalId, false)}
                  onMouseEnter={setCursor.bind(null, 'pointer')}
                  onMouseLeave={setCursor.bind(null, '')}
                />
              ) : null,
            )}
          </Layer>
          <GeoJSONLayer
            data={waters}
            symbolLayout={{ 'icon-image': 'POI-bow', visibility: 'visible' }}
            symbolPaint={{}}
            symbolOnClick={({ features: [{ properties }] }: any) => setWater(properties)}
          />
          {water && (
            <Popup coordinates={[water.longitude, water.latitude]}>
              <div style={{ background: 'white', borderRadius: '3px' }}>
                <Link to={`/fishing_waters/${water.external_id}`} style={{ fontWeight: 'bold' }}>
                  {water.name}
                </Link>{' '}
                <Button onClick={() => setWater(null)} size="sm">
                  Close
                </Button>
                <br />
                <Link
                  to={{
                    pathname: '/catches',
                    search: Filter.empty()
                      .setAttribute(`fishing_water_id`, RansackMatcher.Equals, water.id.toString())
                      .toString(),
                  }}
                >
                  {water.catches_counter} catches
                </Link>
                , {water.followers_count} followers
              </div>
            </Popup>
          )}
          <ZoomControl />
        </Map>
      </SplitPane>
    </Flex>
  );
};

export default CatchMap;
