import React, { useState, SyntheticEvent } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

import { PreviewFile } from './FileDropzone';
import { useImageUpload } from '../../helpers/hooks';
import { getImageDimensions, getFileChecksum } from '../../helpers/files';

import styles from './FileDropzone.module.css';
import Button from '../Clickables/Buttons/Button';

interface Props {
  type: string;
  multiple: boolean;
  directUpload?: boolean;
  name: string;
  setFieldValue?: (name: string, f: PreviewFile[]) => void;
  setFieldTouched?: (name: string) => void;
}

interface FileFlag {
  [name: string]: boolean;
}

const SimpleDropzone: React.FC<Props> = ({
  name: fieldName,
  type,
  multiple,
  setFieldValue,
  setFieldTouched,
  directUpload,
}: Props) => {
  const accept = ['image', 'video'].includes(type) ? `${type}/*` : type;

  const [accepted, setAccepted] = useState<PreviewFile[]>([]);
  const [rejected, setRejected] = useState<File[]>([]);
  const [loading, setLoading] = useState<FileFlag>({});
  const uploadImage = useImageUpload();

  const markAsEdited = (value: any): void => {
    if (setFieldValue) {
      setFieldValue(fieldName, value);
    }
    if (setFieldTouched) {
      setFieldTouched(fieldName);
    }
  };

  const prepareAndUploadImage = async (file: File, dataUrl: string): Promise<boolean> =>
    getFileChecksum(file)
      .then(async (checksum) =>
        uploadImage(file, {
          contentType: file.type,
          filename: file.name,
          byteSize: file.size,
          checksum,
          ...getImageDimensions(dataUrl),
        }),
      )
      .then((signedId: string) => {
        setLoading({ ...loading, [file.name]: false });
        markAsEdited(signedId);
        return true;
      })
      .catch((error: Error) => {
        alert(`Could not upload file: ${error}`);
        return false;
      });

  const onDrop = (newAccepted: File[], newRejected: FileRejection[]): void => {
    if (newRejected.length > 0) {
      const rejectedFiles = newRejected.map(({ file }: FileRejection) => file);
      setRejected(rejected.concat(rejectedFiles));
    }
    if (newAccepted.length === 0) {
      return;
    }
    setRejected([] as File[]);
    const previews: PreviewFile[] = newAccepted.map(
      (f: File) =>
        // `File` is not compatible with spread
        Object.assign(f, { preview: URL.createObjectURL(f) }), // eslint-disable-line fp/no-mutating-assign
    );
    const selected = multiple ? accepted.concat(previews) : [previews[0]];
    setAccepted(selected);

    if (directUpload && type === 'image') {
      const currentlyLoading = selected.reduce(
        (l: FileFlag, file: PreviewFile) => ({ ...l, [file.name]: true }),
        loading,
      );
      setLoading(currentlyLoading);
      selected.forEach((file: PreviewFile) => {
        if (!file.preview) {
          throw new Error('no file preview found');
        }
        prepareAndUploadImage(file, file.preview || '');
      });
    } else {
      markAsEdited(selected);
    }
  };

  const removeFile =
    (fileName: string) =>
    (event: SyntheticEvent): void => {
      event.preventDefault();
      event.stopPropagation();
      const fileIndex = accepted.findIndex(({ name }: PreviewFile) => name === fileName);
      if (fileIndex > -1) {
        const removed = accepted.splice(fileIndex, 1);
        setAccepted(accepted);
        URL.revokeObjectURL(removed[0].preview || '');
      }
    };

  return (
    <Dropzone onDrop={onDrop} accept={accept} multiple={multiple}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={styles.dropzone}>
          <input {...getInputProps()} />
          {rejected.length > 0 && (
            <div className={styles.warning}>
              Filetype for {rejected.map((f) => f.name).join(', ')} is not supported
            </div>
          )}
          <p>Drop {multiple ? 'files' : 'file'} here, or click to browse</p>
          {accepted.map(({ name, preview, size, type: fileType }: PreviewFile) => (
            <div key={name} className={styles.previewRow}>
              {accept === 'image/*' && <img src={preview} alt={name} className={styles.preview} />}
              <div className={styles.name}>
                {loading[name] ? 'Uploading ' : null}
                {name}
              </div>
              <div className={styles.details}>
                {size}b {fileType}
              </div>{' '}
              <Button
                onClick={removeFile(name)}
                variant="buttonDanger"
                title="Remove this file"
                type="button"
                size="sm"
              >
                &times;
              </Button>
            </div>
          ))}
        </div>
      )}
    </Dropzone>
  );
};

export default SimpleDropzone;
