.tableAndElse {
  height: 100%;
  overflow-y: scroll;
}

.controls {
  background: darken(white, 2%);
  border-bottom: 1px solid darken(white, 10%);
  width: 100%;
}

.toggleButtons {
  padding: calc(var(--padding-base) / 2);
}

.form {
  border-top: 1px solid darken(white, 10%);
  display: flex;
  padding-top: calc(var(--padding-base) / 2);
}

.submit {
  align-self: flex-end;
  padding: 0 calc(var(--padding-base) / 2) 12px;
}

.progress {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 3rem;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.bottomBar {
  left: 15px;
  position: absolute;
  top: 15px;
}
