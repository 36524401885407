// Source: https://medium.com/@650egor/react-30-day-challenge-day-2-image-upload-preview-2d534f8eaaa
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
import { Component } from 'react';

import styles from './UploadFile.module.css';

interface IProps {
  type: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

interface State {
  files: File[];
}

class Upload extends Component<IProps, State> {
  constructor(props: IProps) {
    super(props);
    this.state = { files: [] };
    this.handleChange = this.handleChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  public handleChange(event: any): void {
    // eslint-disable-next-line fp/no-loops
    for (const newFile of event.target.files) {
      if (!newFile.type.startsWith(this.props.type)) {
        alert(
          `"${newFile.name}" has the wrong file type. Got a "${newFile.type}", was expecting a "${this.props.type}".`,
        );
        break;
      }
      this.setState((prevState: State) => {
        const { files } = prevState;
        files.push(newFile);
        if (this.props.type === 'image') {
          this.props.setFieldValue('newPhotos', files);
        }
        return { files };
      });
    }
  }

  public render(): React.ReactElement {
    const { files } = this.state;
    const id = `${this.props.type}-input`;
    return (
      <div className={styles.uploadContainer}>
        <label htmlFor={id}>
          <i className="fa fa-upload" /> Upload {this.props.type}(s)
        </label>
        <input id={id} type="file" accept={this.props.type} onChange={this.handleChange} multiple />
        <div className={styles.previews}>
          {files.length !== 0
            ? files.map((f: File) => (
                <div className={styles.imgContainer} key={f.name}>
                  <img
                    key={`${f.name}`}
                    className={styles.preview}
                    src={window.URL.createObjectURL(f)}
                    alt={f.name}
                  />
                  <button
                    key={`${f.name}-button`}
                    type="button"
                    name={f.name}
                    className={styles.deleteBtn}
                    onClick={this.removeFile}
                    title="Remove this image"
                  >
                    &times;
                  </button>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }

  private removeFile(event: any): void {
    const fileName = event.target.name;
    const { files } = this.state;
    const fileIndex = files.findIndex((f) => f.name === fileName);
    if (fileIndex > -1) {
      files.splice(fileIndex, 1);
      this.setState({ files });
    }
  }
}

export default Upload;
