import { gql } from 'graphql-tag';

export const createBrandPage = gql`
  mutation createBrandPage($input: CreateBrandPageMutationInput!) {
    createBrandPage(input: $input) {
      page {
        id
      }
      userErrors {
        message
      }
    }
  }
`;
