import Post from '../../models/Post';
import truncate from '../../helpers/Truncate/Truncate';
import { IImage as Image } from '../../models/Image';
import { IVideo as Video } from '../../models/Video';

import styles from './PostPreview.module.css';

const PostPreview = ({ entry }: { entry: Post }) => {
  const images: string[] = entry.photos.map(({ photo }: { photo: Image }) => photo.sizes[0].url);
  const text = truncate(entry.textBody, 20); // eslint-disable-line no-magic-numbers
  const video = entry.video as Video;
  if (video.screenshot) {
    images.push(video.screenshot.sizes[0].url);
  }
  return (
    <span>
      {images.map((i) => (
        <img src={i} key={i} alt="" className={styles.img} />
      ))}
      {video.id && '📹'}
      {text}
    </span>
  );
};

export default PostPreview;
