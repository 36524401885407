import IHeader from './HeadersInterface';
import ListFilter from './ListFilter';
import ListSearch from './ListSearch';
import SortButtons from './SortButtons';

import styles from './ListHeader.module.css';

interface IProps {
  headers: IHeader[];
}

const ListHeader = ({ headers }: IProps): JSX.Element => {
  const canSearch = headers.some((header) => header.canElasticsearch === true);
  const canFilter = headers.some((header) => header.filterable === true);
  return (
    <thead className={styles.head}>
      <tr>
        {headers.map((header, idx) => (
          <th key={header.attribute + idx}>
            {header.filterable ? <SortButtons header={header} /> : header.title}
          </th>
        ))}
      </tr>
      {canFilter && !canSearch && <ListFilter headers={headers} />}
      {canSearch && <ListSearch headers={headers} />}
    </thead>
  );
};

export default ListHeader;
