import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { TextField } from '@material-ui/core';

import Details from '../../components/Details/Details';
import { StockImageInput } from '../../components/StockImageInput/StockImageInput';
import {
  GetPlaceVariables,
  GetPlace,
  UpdatePlaceUnsplashImage,
  UpdatePlaceUnsplashImageVariables,
  UpdatePlace,
  UpdatePlaceVariables,
} from '../../interfaces/graphql';
import { getPlace } from '../../queries/Places';
import updatePlaceUnsplashImage from '../../mutations/UpdatePlaceUnsplashImage';
import updatePlaceMutation from '../../mutations/UpdatePlace';
import Button from '../../components/Clickables/Buttons/Button';

export const EditPlace = () => {
  const params = useParams<GetPlaceVariables>();

  const [updateImage, { loading: isSavingImage }] = useMutation<
    UpdatePlaceUnsplashImage,
    UpdatePlaceUnsplashImageVariables
  >(updatePlaceUnsplashImage);
  const [updatePlace, { loading: isSavingDescription }] = useMutation<
    UpdatePlace,
    UpdatePlaceVariables
  >(updatePlaceMutation);
  const isSaving = isSavingImage || isSavingDescription;

  const [image, setImage] = useState<{
    imageUrl: string;
    photographerName: string;
    photographerUrl: string;
  } | null>(null);
  const [description, setDescription] = useState('');

  const { data } = useQuery<GetPlace, GetPlaceVariables>(getPlace, {
    variables: params,
  });

  const onSave = () => {
    if (data && data.place.unsplashImage !== image) {
      updateImage({
        variables: { input: { externalId: data.place.externalId, attributes: image } },
      });
    }

    if (data && data.place.description !== description) {
      updatePlace({
        variables: { input: { externalId: data.place.externalId, attributes: { description } } },
      });
    }
  };

  useEffect(() => {
    if (data) {
      setImage(data.place.unsplashImage as any); // the any is needed due to some Rutilus type issue
      setDescription(data.place.description || '');
    }
  }, [data, setImage]);

  if (!data) {
    return <></>;
  }

  const isTouched =
    data.place.unsplashImage !== image || (data.place.description || '') !== description;

  return (
    <Details title={`Editing ${data.place.name}`}>
      <div className="flex flex-col space-y-2">
        <label htmlFor="name" className="font-bold">
          Name
        </label>
        <input id="name" value={data.place.name} disabled />
        <TextField
          id="place-description"
          label="Place Description"
          multiline
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          className="w-3/4"
          helperText="Use this to override the description text for a city on fishbrain.com."
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="font-bold">Image</label>
        {image ? <img src={image.imageUrl} alt="" /> : <p className="italic">No image set</p>}
        <div className="flex">
          <StockImageInput
            initialValue={data.place.name}
            onImageSelected={(
              imageUrl: string,
              photographerName: string,
              photographerUrl: string,
            ) => setImage({ imageUrl, photographerName, photographerUrl })}
          />
          {image && (
            <Button
              variant="buttonDanger"
              size="sm"
              onClick={() => setImage(null)}
              className="ml-4"
            >
              Remove image
            </Button>
          )}
        </div>
      </div>
      <div className="flex">
        <Button className="ml-auto mt-4" disabled={isSaving || !isTouched} onClick={onSave}>
          Save
        </Button>
      </div>
    </Details>
  );
};
