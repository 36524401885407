import { Route, useRouteMatch } from 'react-router-dom';

import AttributeID from '../../components/AttributeComponents/AttributeID';
import Flex from '../../components/Flex/Flex';
import IHeader from '../../components/List/HeadersInterface';
import ModelList from '../../components/ModelList/ModelList';
import SidePane from '../../components/SidePane/SidePane';
import Tag from '../../models/Tag';
import TagDetails from './TagDetails';

const headers: IHeader[] = [
  { attribute: 'id', title: 'Id' },
  {
    title: 'Tag',
    attribute: 'label',
    component: AttributeID,
    link: '/tags/',
    canElasticsearch: true,
  },
  { title: 'Count', attribute: 'count' },
];

const Tags = (): React.ReactElement => {
  const match = useRouteMatch();
  return (
    <Flex>
      <ModelList headers={headers} model={Tag} />
      <SidePane showOnPath={`${match.path}/:slug`}>
        <Route path={`${match.path}/:tag`} component={TagDetails} />
      </SidePane>
    </Flex>
  );
};

export default Tags;
