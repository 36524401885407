.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.vertical {
  align-items: center;
}

.horizontal {
  justify-content: center;
  text-align: center;
}
