import { get } from 'ts-get';
import { useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { BASE as MYKISS_BASE } from '../../api/mykiss';
import Edit from '../../components/Edit/Edit';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import Checkbox from '../../components/Edit/FormAttributes/Checkbox';
import {
  AttributeWithTitle,
  FormText,
  FormTextarea,
} from '../../components/Edit/FormAttributes/FormAttribute';
import FormComments from '../../components/Edit/FormAttributes/FormComments';
import FormDate from '../../components/Edit/FormAttributes/FormDate';
import FormLink from '../../components/Edit/FormAttributes/FormLink';
import FormVideo from '../../components/Edit/FormAttributes/FormVideo';
import Likes from '../../components/Edit/FormAttributes/Likes';
import PolymorphicLink from '../../components/PolymorphicLink';
import AttributeExternalLink from '../../components/AttributeComponents/AttributeExternalLink';
import Table from '../../components/Table/Table';

import PostUrl from '../../models/PostUrl';
import Post from '../../models/Post';
import { EditableStatus } from './UrlList';
import { urlOnPost } from '../../queries/PostUrls';
import {
  UrlsOnPost,
  UrlsOnPostVariables,
  UrlsOnPost_post_urls_edges_node,
} from '../../interfaces/graphql';

import { FromDisplayEntity, TargetDisplayEntity } from './DisplayEntity';
import SubjectReports from '../UserReports/SubjectReports';
import { TextStatusControl, VideoStatusControl } from './StatusControl2';
import PostImages from './Images';
import SharedStatus from './SharedStatus';
import PostModerationTraces from './ModerationTraces';
import { ModerationContextProvider } from '../../contexts/ModerationContext';

const MykissLink = ({ entry }: { entry: Post }): JSX.Element => (
  <a href={`https://${MYKISS_BASE}/posts/${entry.external_id}`}>View on {MYKISS_BASE}</a>
);

const UserReportsOnPost = ({ entry }: { entry: Post }): JSX.Element => (
  <div>
    <h2>Reports on this post</h2>
    <SubjectReports entry={{ subjectType: 'Op::Post', subjectId: entry.id }} />
  </div>
);

const PostUrls = ({ entry }: { entry: Post }): JSX.Element => {
  const { data, loading } = useQuery<UrlsOnPost, UrlsOnPostVariables>(urlOnPost, {
    variables: { postId: entry.id },
  });

  if (loading) {
    return <>loading...</>;
  }

  const urls = get(data, (it) => it.post.urls.edges, [])
    .filter((node) => !!node)
    .map(({ node }: { node: UrlsOnPost_post_urls_edges_node }) => new PostUrl(node));

  return urls.length === 0 ? (
    <span>no urls</span>
  ) : (
    <Table>
      <thead>
        <tr>
          <th>Link</th>
          <th>status</th>
        </tr>
      </thead>
      <tbody>
        {urls.map((url: PostUrl) => (
          <tr key={url.longForm}>
            <td>
              <AttributeExternalLink value={url.longForm} />
            </td>
            <td>
              <EditableStatus entry={url} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const formHeaders: IFormHeaders[] = [
  {
    title: '',
    attribute: 'ids',
    row: [
      { title: 'Id', attribute: 'id', component: FormText },
      { title: 'External ID', attribute: 'external_id', component: FormText },
    ],
  },
  {
    title: '',
    attribute: 'info',
    row: [
      {
        title: 'Posted/Displayed as',
        attribute: 'displayEntity',
        component: AttributeWithTitle(FromDisplayEntity),
      },
      {
        title: 'Post target',
        attribute: 'displayEntity',
        component: AttributeWithTitle(TargetDisplayEntity),
      },
    ],
  },
  { title: 'Staff picked?', attribute: 'staff_picked', component: Checkbox },
  {
    title: '',
    attribute: 'owner and link',
    row: [
      {
        title: 'Owner',
        attribute: 'owner',
        component: AttributeWithTitle(FormLink),
        link: '/users/',
      },
      {
        title: 'Public link',
        attribute: 'dummy',
        component: AttributeWithTitle(MykissLink),
      },
      {
        title: 'Likes',
        attribute: 'likes_count',
        component: AttributeWithTitle(Likes),
      },
    ],
  },
  {
    title: 'Type of post',
    attribute: '',
    component: AttributeWithTitle(PolymorphicLink('postable')),
  },
  { title: 'Text', attribute: 'textBody', component: FormTextarea },
  { title: 'Text status', attribute: 'text', component: TextStatusControl },
  { title: 'Created at', attribute: 'created_at', component: FormDate },
  { title: 'Published at', attribute: 'published_at', component: FormDate },
  {
    title: 'Location',
    attribute: 'location',
    component: AttributeWithTitle(FormLink),
    link: '/fishing_waters/',
  },
  { title: 'Links on this post', attribute: '', component: AttributeWithTitle(PostUrls) },
  { title: 'Images', attribute: 'photos', component: PostImages },
  { title: 'Video', attribute: 'video', component: FormVideo },
  { title: 'Video status', attribute: 'video', component: VideoStatusControl },
  { title: 'Shared status', attribute: '', component: SharedStatus },
  { title: 'Moderation traces', attribute: '', component: PostModerationTraces },
  { title: 'Comments', attribute: 'comments', component: FormComments },
  {
    title: 'Reports on this post',
    attribute: '',
    component: UserReportsOnPost,
  },
];

const EditPost = (): JSX.Element => {
  const { id } = useParams();

  return (
    <ModerationContextProvider postId={id}>
      <Edit key={`EditPost-${id}`} model={Post} headers={formHeaders} returnPath="/posts" />;
    </ModerationContextProvider>
  );
};

export default EditPost;
