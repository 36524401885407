import { Fragment } from 'react';

import truncate from '../../helpers/Truncate/Truncate';
import noImagePlaceholder from '../../images/no-image-placeholder.png';
import AttributeComponent from '../AttributeComponents/AttributeComponent';
import IHeader from '../List/HeadersInterface';

import styles from './GalleryView.module.css';

interface IProps {
  imageUrlProperty?: string;
  entry: any;
  headers: IHeader[];
}

const MAX_LENGTH = 20;

const GalleryCard = ({ imageUrlProperty, entry, headers }: IProps): React.ReactElement => {
  const truncateIfString = (property: any): string =>
    typeof property === 'string' ? truncate(property, MAX_LENGTH) : '';

  const renderDescriptionItem = (header: IHeader): JSX.Element | string => (
    <div className={styles.itemDetailsSingle}>
      <label className={styles.label}>{header.title}: </label>
      {header.component ? (
        <AttributeComponent
          link={header.link}
          component={header.component}
          entry={entry}
          value={entry[header.attribute]}
        />
      ) : (
        <span>{truncateIfString(entry[header.attribute])}</span>
      )}
    </div>
  );

  const renderDescriptionItems = (headerItems: IHeader[]): JSX.Element[] =>
    headerItems.map((header: IHeader, index: number) => (
      <Fragment key={index}>
        {!header.hideOnGallery &&
        entry[header.attribute] &&
        typeof entry[header.attribute] !== 'object'
          ? renderDescriptionItem(header)
          : ''}
      </Fragment>
    ));

  const image =
    imageUrlProperty && entry[imageUrlProperty] ? entry[imageUrlProperty] : noImagePlaceholder;

  return (
    <div className={styles.item} id={`entry-${entry.id}`}>
      <div className={styles.image} style={{ background: `url(${image})` }} />
      <div className={styles.itemDetails}>{renderDescriptionItems(headers)}</div>
    </div>
  );
};

export default GalleryCard;
