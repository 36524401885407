import styles from './Badge.module.css';

interface IProps {
  children?: React.ReactNode;
}

const Badge = ({ children }: IProps): JSX.Element => (
  <>{children ? <span className={styles.badge}>{children}</span> : null}</>
);

export default Badge;
