import React, { useState, useEffect } from 'react';
import { Modal, FormInput } from 'barramundi';

import Button from '../Clickables/Buttons/Button';

enum ModalState {
  Closed = 0,
  Search,
  Preview,
}

interface IData {
  total_pages: number;
  results: {
    alt_description: string;
    urls: {
      raw: string;
    };
    user: {
      raw: string;
      name: string;
      links: {
        html: string;
      };
    };
  }[];
}

async function getImages(searchTerm: string, page: number) {
  return fetch(
    `https://api.unsplash.com/search/photos?page=${page}&query=${searchTerm
      .toLowerCase()
      .split(' ')
      .join('+')}&client_id=7ZNXLmsSSrUf9s8gPg-jLTMk0exJydyybmk1FkGl8tE&orientation=landscape`,
  ).then(async (r) => r.json());
}

interface IProps {
  initialValue?: string;
  onImageSelected: (imageUrl: string, photographerName: string, photographerUrl: string) => void;
}

export const StockImageInput = ({ initialValue = '', onImageSelected }: IProps): JSX.Element => {
  const [modalState, setModalState] = useState(ModalState.Closed);
  const [searchValue, setSearchValue] = useState(initialValue);
  const [{ page, data, previewImageIndex }, setState] = useState<{
    page: number;
    data: null | IData;
    previewImageIndex: number;
  }>({
    page: 1,
    data: null,
    previewImageIndex: 0,
  });

  const onSearchClicked = (value: string) => {
    getImages(value, page).then((response) => {
      setState({ page, data: response, previewImageIndex: 0 });
    });
  };

  useEffect(() => {
    setSearchValue(initialValue);
    if (initialValue !== '') {
      onSearchClicked(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, setSearchValue]);

  const onNextPageClicked = () => {
    const nextPage = page + 1;
    getImages(searchValue, nextPage).then((response) => {
      setState({ page: nextPage, data: response, previewImageIndex: 0 });
    });
  };

  const onPrevPageClicked = () => {
    const prevPage = page - 1;
    getImages(searchValue, prevPage).then((response) => {
      setState({ page: prevPage, data: response, previewImageIndex: 0 });
    });
  };

  const onClose = () => {
    setModalState(ModalState.Closed);
    setState({ page: 1, data: null, previewImageIndex: 0 });
  };

  const results = data ? data.results : [];
  const hasNextPage = data && data.total_pages > page;

  return (
    <>
      <Button size="sm" onClick={() => setModalState(ModalState.Search)}>
        Find Stock Image
      </Button>
      {modalState !== ModalState.Closed && (
        <Modal
          shown
          onClose={onClose}
          size="lg"
          disableClose={{ disableCloseButton: modalState === ModalState.Preview }}
        >
          {modalState === ModalState.Search ? (
            <div className="m-4">
              <FormInput
                id="search-value"
                value={searchValue}
                label="Search term"
                onChange={setSearchValue}
              />
              <Button
                onClick={() => onSearchClicked(searchValue)}
                disabled={!searchValue.length}
                className="mt-2"
              >
                Search
              </Button>
              <div className="grid grid-cols-2 gap-4 max-w-full mt-4">
                {results.map((r: any, index: number) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                  <img
                    key={r.id}
                    src={r.urls.regular}
                    alt={r.alt_description}
                    className="max-w-full cursor-pointer"
                    onClick={() => {
                      setState({ page, data, previewImageIndex: index });
                      setModalState(ModalState.Preview);
                    }}
                  />
                ))}
              </div>
              <div className="flex mt-4">
                {page > 1 && <Button onClick={onPrevPageClicked}>Previous Page</Button>}
                {hasNextPage && (
                  <Button onClick={onNextPageClicked} className="ml-auto">
                    Next Page
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className="m-4 space-y-2">
              <p className="italic">
                Below is a rough example of how this image might appear on different devices. Note
                that it is not exact and will depend on how the apps utilise the image.
              </p>
              <h2 className="font-bold">Desktop</h2>
              <img
                src={`${results[previewImageIndex].urls.raw}&w=1442&h=482&fit=crop`}
                alt={results[previewImageIndex].alt_description}
                className="max-w-full"
              />
              <h2 className="font-bold">Mobile</h2>
              <img
                src={`${results[previewImageIndex].urls.raw}&w=400&h=400&fit=crop`}
                alt={results[previewImageIndex].alt_description}
                className="max-w-full"
              />
              <div className="flex">
                <Button onClick={() => setModalState(ModalState.Search)}>Back to search</Button>
                <Button
                  className="ml-auto"
                  onClick={() => {
                    setModalState(ModalState.Closed);
                    onImageSelected(
                      results[previewImageIndex].urls.raw,
                      results[previewImageIndex].user.name,
                      results[previewImageIndex].user.links.html,
                    );
                  }}
                >
                  Select this image
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
