import styles from './LoadingBox.module.css';

interface IProps {
  width?: string;
}

const randomNumber = ({ min, max }: { min: number; max: number }): number =>
  min + Math.random() * (max - min);

const loadingBox: React.FC<IProps> = ({
  width = `${randomNumber({ min: 30, max: 100 })}%`,
}: IProps): JSX.Element => <div className={styles.box} style={{ width }} />;

export default loadingBox;
