/* eslint-disable import/order */
import { COGNITO_DOMAIN, COGNITO_CLIENT_ID, getCognitoCallBackURL } from './cognito';

import ClientOAuth2 from 'client-oauth2';

export const TOKEN_URL = `${COGNITO_DOMAIN}/oauth2/token`;
export const AUTH_URL = `${COGNITO_DOMAIN}/oauth2/authorize`;

export const OAUTHClient = new ClientOAuth2({
  clientId: COGNITO_CLIENT_ID,
  redirectUri: getCognitoCallBackURL(),
  accessTokenUri: TOKEN_URL,
  authorizationUri: AUTH_URL,
});
