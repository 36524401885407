import { Link } from 'react-router-dom';

import FormSelect from '../../components/Edit/FormAttributes/FormSelect';
import FishingWater from '../../models/FishingWater';

import Filter from '../../helpers/filter';
import { RansackMatcher } from '../../helpers/ransack';
import { BASE as MYKISS_BASE } from '../../api/mykiss';

export const WaterTypeSelect = FormSelect([
  { value: 'unknown', label: 'Unknown' },
  { value: 'ocean', label: 'Ocean / Sea' },
  { value: 'lake', label: 'Lake / Reservoir / Pond / stillwater' },
  { value: 'canal', label: 'Canal / Ditch / Drain' },
  { value: 'stream', label: 'Stream / River / Creek / running water' },
  { value: 'swamp', label: 'Swamp / Marsh / Wetland' },
  { value: 'estuary', label: 'Estuary / Inlet' },
]);

export const getCoords = (latitude: number, longitude: number): object => {
  if (latitude && longitude) {
    return {
      coordinates: {
        latitude,
        longitude,
      },
    };
  }
  return {};
};

export const Links = ({
  entry: { id, externalId, catches_counter, longitude: lng, latitude: lat },
}: {
  entry: FishingWater;
}) => (
  <>
    <Link
      to={{
        pathname: '/catches',
        search: Filter.empty()
          .setAttribute(`fishing_water_id`, RansackMatcher.Equals, id.toString())
          .toString(),
      }}
    >
      List {catches_counter} catches
    </Link>
    {' - '}
    <Link to={`/catch_map/${lat},${lng}`}>View surrounding catches</Link>
    {' - '}
    <a href={`https://${MYKISS_BASE}/fishing-waters/${externalId}`}>Open on {MYKISS_BASE}</a>
  </>
);
