import GqlBaseModel from '../GqlBaseModel';

export interface IBrand {
  readonly id: number;
  readonly externalId: string;
  readonly name: string;
  address?: string;
  url?: string;
  aboutTitle?: string;
  aboutDescription?: string;
  productsTitle?: string;
  productsDescription?: string;
  membersTitle?: string;
}

class Brand extends GqlBaseModel {
  public static readOnly: string[] = ['id', 'externalId', 'name', 'created_at', 'updated_at'];

  public static endpoint = '/equipment/brands';

  public static fromAttributes(attributes: IBrand): Brand {
    return new Brand(attributes);
  }

  public ['constructor']: typeof Brand;

  protected attributes: IBrand;

  constructor(attributes: IBrand) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.externalId}`;
  }

  public get identifier(): string {
    return this.attributes.name;
  }

  public get id(): string {
    return this.attributes.id.toString();
  }

  public get externalId(): string {
    return this.attributes.externalId;
  }

  public get modelType(): string {
    return 'Brand';
  }

  public static get modelType(): string {
    return 'Brand';
  }

  protected get class(): typeof Brand {
    return Brand;
  }
}

export default Brand;
