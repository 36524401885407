import React, { useState, useEffect, ReactNode } from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';

import styles from './SidePane.module.css';

interface IProps {
  name?: string;
  showOnPath?: string;
  children?: ReactNode;
}

const RESIZER_WIDTH = 10;

const SidePane = ({ children, name = 'overlap-pane', showOnPath = '' }: IProps): JSX.Element => {
  const match = useRouteMatch(showOnPath);
  const [width, setWidth] = useState(parseInt(localStorage.getItem(name) || '500', 10));
  const [resizing, setResizing] = useState(false);

  const mouseUp = (e: MouseEvent) => {
    e.preventDefault();
    setResizing(false);
    localStorage.setItem(name, width.toString());
  };

  const mouseMove = (e: MouseEvent) => {
    e.preventDefault();
    if (resizing) {
      setWidth(window.innerWidth - e.x + RESIZER_WIDTH / 2);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', mouseMove);
    document.addEventListener('mouseup', mouseUp);
    return () => {
      document.removeEventListener('mousemove', mouseMove);
      document.removeEventListener('mouseup', mouseUp);
    };
  });

  if (!match) {
    return <></>;
  }

  return (
    <div className={styles.overlap} style={{ width }}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span className={`${styles.resizer}`} onMouseDown={() => setResizing(true)} />
      <Switch>{children}</Switch>
    </div>
  );
};

export default SidePane;
