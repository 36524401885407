import { useState } from 'react';

import { destroy } from '../../api/rutilus';
import User from '../../models/User';
import Button from '../../components/Clickables/Buttons/Button';

interface Props {
  entry: User;
}

function RevokeGrantButton({ entry: { id: userId, is_premium } }: Props) {
  const [loading, setLoading] = useState(false);

  const revokePremium = () => {
    if (window.confirm(`You are about to revoke pro from ${userId}. Are you sure?`)) {
      setLoading(true);
      destroy(`/admin/premium/grants/${userId}`).then((response) => {
        if (response.status === 204) {
          alert(`User's ${userId} pro grant was revoked`);
        } else {
          alert('Something went wrong, try again later or report a bug.');
        }
        setLoading(false);
      });
    }
  };

  return (
    <div className="flex items-center justify-center px-1">
      <Button
        disabled={loading || !is_premium}
        variant="buttonDanger"
        size="sm"
        className="w-[160px]"
        title="Revoke Grant"
        onClick={revokePremium}
      >
        {loading ? 'Loading...' : 'Revoke Grant'}
      </Button>
    </div>
  );
}

export default RevokeGrantButton;
