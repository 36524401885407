import { Component, SyntheticEvent } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

import styles from './FileDropzone.module.css';
import Button from '../Clickables/Buttons/Button';

interface Props {
  type: string;
  multiple: boolean;
  name: string;
  setFieldTouched: (field: string, value: boolean, shouldValidate?: boolean) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export interface PreviewFile extends File {
  preview?: string;
}

interface State {
  accepted: PreviewFile[];
  rejected: File[];
}

class FileDropzone extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { accepted: [], rejected: [] };
    this.onDrop = this.onDrop.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  public componentWillUnmount(): void {
    this.state.accepted.forEach((file: PreviewFile) => {
      this.revokeObjectURL(file);
    });
  }

  public onCancel(): void {
    this.setState({ accepted: [], rejected: [] });
    this.props.setFieldTouched(this.props.name, false);
    this.props.setFieldValue(this.props.name, undefined);
  }

  public onDrop(accepted: PreviewFile[], rejected: FileRejection[]): void {
    if (this.state.accepted.length === 1 && !this.props.multiple) {
      this.setState((oldState: State) => ({
        ...oldState,
        rejected: oldState.rejected.concat(accepted),
      }));
      return;
    }

    const newPreviewFiles = accepted.map((file) =>
      // eslint-disable-next-line
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    const rejectedFiles = rejected.map(({ file }: FileRejection) => file);

    this.setState({
      accepted: this.state.accepted.concat(newPreviewFiles),
      rejected: this.state.rejected.concat(rejectedFiles),
    });
    this.props.setFieldTouched(this.props.name, true);
    this.props.setFieldValue(this.props.name, accepted);
  }

  public removeFile(fileName: string): (event: SyntheticEvent) => void {
    return (event: SyntheticEvent): void => {
      event.preventDefault();
      const { accepted } = this.state;
      const fileIndex = accepted.findIndex((file: PreviewFile) => file.name === fileName);
      if (fileIndex > -1) {
        this.revokeObjectURL(accepted[fileIndex]);
        accepted.splice(fileIndex, 1);
        this.setState({ accepted });
      }
    };
  }

  public render(): React.ReactElement {
    const label = this.props.multiple ? ' files ' : ' a file ';
    const { accepted, rejected } = this.state;
    const accept = ['image', 'video'].includes(this.props.type)
      ? `${this.props.type}/*`
      : this.props.type;

    return (
      <div>
        <Dropzone
          accept={accept}
          multiple={this.props.multiple}
          onDrop={this.onDrop}
          onFileDialogCancel={this.onCancel}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={styles.dropzone}>
              <input {...getInputProps()} />
              {rejected.length > 0 && accepted.length === 0 && (
                <div className={styles.warning}>
                  Selected filetype ({rejected.map((file: File) => file.type).join(', ')}) is not
                  supported
                </div>
              )}
              <p>
                Drop {label} here, or click to select {label}
              </p>
              <p>Only {this.props.type} files will be accepted</p>
              <div className={styles.results}>
                {accepted.length > 0 &&
                  this.props.type === 'image' &&
                  accepted.map((file: PreviewFile, index: number) => (
                    <div key={`${file.name}-${index}-preview`} className={styles.thumbnail}>
                      <img
                        alt={file.name}
                        key={file.preview}
                        src={file.preview}
                        className={styles.preview}
                      />
                      <Button
                        onClick={this.removeFile(file.name)}
                        variant="buttonDanger"
                        title="Remove this image"
                      >
                        &times;
                      </Button>
                    </div>
                  ))}

                {accepted.length > 0 && this.props.type !== 'image' && (
                  <div>
                    <strong>Accepted:</strong>
                    {accepted.map((file: PreviewFile, index: number) => (
                      <div key={`${file.name}-${index}-accepted`}>
                        {file.name} - {file.size} bytes
                        <Button
                          onClick={this.removeFile(file.name)}
                          variant="buttonDanger"
                          title="Remove this image"
                        >
                          &times;
                        </Button>
                      </div>
                    ))}
                  </div>
                )}

                {this.state.rejected.length > 0 && (
                  <div>
                    <strong>Rejected:</strong>
                    {this.state.rejected.map((file: File, index: number) => (
                      <div key={`${file.name}-${index}-rejected`}>
                        {file.name} -{file.size} bytes
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  // Revoke the data uris to avoid memory leaks
  private revokeObjectURL(file: PreviewFile): void {
    if (this.props.type === 'image' && typeof file.preview !== 'undefined') {
      URL.revokeObjectURL(file.preview || '');
    }
  }
}

export default FileDropzone;
