.container {
  padding: 10px 20px;
  text-align: left;
  width: 100%;
}

.item {
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  margin-right: 5px;
}

.active {
  color: #000;
}
