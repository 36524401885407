import IHeader from './HeadersInterface';
import truncate from '../../helpers/Truncate/Truncate';
import BaseModel from '../../models/BaseModel';
import AttributeComponent from '../AttributeComponents/AttributeComponent';

import styles from './ListBody.module.css';

const MAX_LENGTH = 20;

interface Props<T> {
  headers: IHeader[];
  entries: T[];
  path: string;
  search?: string;
}

interface ObjectWithId {
  id: any;
  [rest: string]: any;
}

const truncateIfString = (property: React.ReactNode): React.ReactNode =>
  typeof property === 'string' ? truncate(property, MAX_LENGTH) : property;

const listBody = <T extends ObjectWithId = BaseModel>({
  entries,
  headers,
  path,
  search,
}: Props<T>): JSX.Element => (
  <tbody>
    {entries.map((entry) => (
      <tr key={entry.id} className={path.endsWith(`/${entry.id}`) ? styles.active : ''}>
        {headers.map((header) => (
          <td key={`${entry.id}-${header.attribute}`}>
            {header.component ? (
              <AttributeComponent
                link={header.link}
                search={search}
                component={header.component}
                entry={entry}
                value={entry[header.attribute]}
              />
            ) : (
              truncateIfString(entry[header.attribute])
            )}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export default listBody;
