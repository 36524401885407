import { gql } from 'graphql-tag';

export const getSpeciesName = gql`
  query GetSpeciesName($speciesId: Int!) {
    singleSpecies(id: $speciesId) {
      displayName
      externalId
    }
  }
`;
