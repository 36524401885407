import { put } from '../api/rutilus';
import BaseModel from './BaseModel';
import User, { IUser } from './User';

interface IUserReport {
  id: number;
  reason: string;
  subject_id: string;
  subject_type: string;
  user: IUser;
  created_at: string;
  resolved_at?: string;
}

class UserReport extends BaseModel {
  public static endpoint = '/user_reports';

  public static modelType = 'User report';

  public static fromAttributes(attributes: IUserReport): UserReport {
    return new UserReport(attributes);
  }

  public static get readOnly(): string[] {
    return BaseModel.readOnly.concat(
      'reason',
      'status',
      'resolved_at',
      'subject',
      'subject_type',
      'subject_id',
    );
  }

  protected static readonly DEFAULT_OPTIONS = {};

  public modelType = 'User report';

  public ['constructor']: typeof UserReport;

  protected attributes: IUserReport;

  constructor(attributes: IUserReport) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get identifier(): string {
    return this.attr('id');
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get user(): User | null {
    if (this.attr('user')) {
      return User.fromAttributes(this.attr('user'));
    }
    return null;
  }

  public get user_id(): User | null {
    if (this.attr('user')) {
      return this.attr('user').id;
    }
    return null;
  }

  public get resolved_at(): Date | null {
    const date = this.attr('resolved_at');
    return date ? new Date(date) : null;
  }

  public get subject(): string {
    return `${this.subject_type} #${this.subject_id}`;
  }

  public async move(direction: 'progress' | 'reopen'): Promise<Response> {
    return new Promise((resolve, reject) => {
      put(`${this.endpoint}/${direction}`, {}).then((resp) => (resp.ok ? resolve(resp) : reject()));
    });
  }

  protected get class(): typeof UserReport {
    return UserReport;
  }
}

export default UserReport;
