.info {
  margin: 0 auto 0;
  max-width: 300px;
  text-align: center;
}

.tooltip {
  display: flex;
  flex-direction: row;
  padding: var(--padding-base);
}

.logo {
  border-radius: 32px;
  margin: 40px auto 0;
  margin-bottom: 24px;
  width: 220px;
}
