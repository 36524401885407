import styles from './Input.module.css';

export interface Option {
  value: string | number | null;
  label?: string;
}

interface Props {
  children?: React.ReactElement | React.ReactNode | React.ReactNode[];
  value?: string;
  options?: Option[];
  small?: boolean;
  className?: string;
  [restAttribute: string]: any;
}

const Options = ({ options }: { options: Option[] }): JSX.Element => (
  <>
    {options.map(({ value, label }) => (
      <option key={value || label} value={value || undefined}>
        {label || value}
      </option>
    ))}
  </>
);

const Select = ({
  options,
  children,
  className = '',
  small = false,
  ...rest
}: Props): JSX.Element => (
  <select {...rest} className={`${styles.input} ${className} ${small ? styles.small : ''}`}>
    {options ? <Options options={options} /> : children}
  </select>
);

export default Select;
