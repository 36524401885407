import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getToken } from '../helpers/authentication';

const POST_OPTIONS = { method: 'POST' };
const PUT_OPTIONS = { method: 'PUT' };
const DELETE_OPTIONS = { method: 'DELETE' };
const DEFAULT_HEADERS = {
  'X-FIB-PLATFORM': 'ornatus',
};

const BASE_URL =
  process.env.REACT_APP_RUTILUS_BASE_URL ||
  (process.env.REACT_APP_ENV === 'production'
    ? 'https://rutilus.fishbrain.com'
    : 'https://rutilus.staging.fishbrain.com');

/**
 * merge `objects` into one new object
 */
const merge = (...objects: any[]) => Object.assign({}, ...objects);

const getAuthHeaders = async (): Promise<{ Authorization: string } | any> => {
  const token = await getToken();
  return token && token !== 'undefined' ? { Authorization: `Bearer ${token}` } : {};
};

const buildHeaders = async (headers = {}): Promise<Headers> =>
  new Headers(merge(await getAuthHeaders(), DEFAULT_HEADERS, headers));

const send = async (path: string, rawHeaders: any, ...options: any[]): Promise<Response> => {
  const headers = await buildHeaders(rawHeaders);
  return fetch(`${BASE_URL}${path}`, merge({ headers }, ...options));
};

export const destroy = async (path: string, headers = {}, options = {}): Promise<Response> =>
  send(path, headers, DELETE_OPTIONS, options);

export const get = async (path: string, headers = {}, options = {}): Promise<Response> =>
  send(path, headers, options);

export const post = async (
  path: string,
  body = {},
  headers = {},
  options = {},
): Promise<Response> => send(path, headers, { body: JSON.stringify(body) }, POST_OPTIONS, options);

export const put = async (
  path: string,
  body = {},
  headers = { 'Content-Type': 'application/json', Accept: 'application/json' },
  options = {},
): Promise<Response> => send(path, headers, { body: JSON.stringify(body) }, PUT_OPTIONS, options);

export const postImg = async (
  path: string,
  body = {},
  headers = {},
  options = {},
): Promise<Response> => send(path, headers, { body }, POST_OPTIONS, options);

export const postWithImg = async (
  path: string,
  body = {},
  headers = { Accept: 'application/json' },
  options = {},
): Promise<Response> => send(path, headers, { body }, POST_OPTIONS, options);

export const putWithImg = async (
  path: string,
  body = {},
  headers = { Accept: 'application/json' },
  options = {},
): Promise<Response> => send(path, headers, { body }, PUT_OPTIONS, options);

const authLink = setContext(async (_, { headers }) => {
  const asyncHeaders = await getAuthHeaders();
  return { headers: { ...asyncHeaders, ...headers } };
});

const httpLink = createHttpLink({
  uri: `${BASE_URL}/graphql`,
  headers: merge(DEFAULT_HEADERS, { 'Accept-Language': 'en-us' }),
});

export const GraphqlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
