import GqlBaseModel from './GqlBaseModel';

export interface ICouponGroup {
  readonly id: number;
  name: string;
  percentOff: number;
  durationInMonths: number;
  typeName: string;
  usageName: string;
  stripeCouponId: string;
  expiresAt: number;
  stripePlanId: string;
  code: string;
  quantity: number;
}

class CouponGroup extends GqlBaseModel {
  public static readOnly: string[] = ['id', 'created_at', 'updated_at'];

  public static fromAttributes(attributes: ICouponGroup): CouponGroup {
    return new CouponGroup(attributes);
  }

  public ['constructor']: typeof CouponGroup;

  protected attributes: ICouponGroup;

  constructor(attributes: ICouponGroup) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get modelType(): string {
    return 'CouponGroup';
  }

  public static get modelType(): string {
    return 'Coupon Group';
  }
}

export default CouponGroup;
