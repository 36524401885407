import { gql } from 'graphql-tag';

export const getFishingWaters = gql`
  query GetFishingWaters($cursor: String) {
    fishingWaters(first: 20, after: $cursor) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          catchesCount
          externalId
          followersCount
          latitude
          longitude
          name
          waterType
        }
      }
    }
  }
`;

export const getFishingWatersSearch = gql`
  query GetFishingWatersSearch($query: String, $cursor: String) {
    fishingWatersSearch(first: 20, after: $cursor, query: $query) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          catchesCount
          externalId
          followersCount
          latitude
          longitude
          name
          waterType
        }
      }
    }
  }
`;

export const getFishingWater = gql`
  query GetFishingWater($id: Int, $externalId: String) {
    fishingWater(id: $id, externalId: $externalId) {
      id
      catchesCount
      description
      descriptionOfWaterType
      displayName
      externalId
      followersCount
      latitude
      longitude
      country {
        countryName
      }
      region {
        localName
      }
      metadata {
        group {
          id
          name
        }
        id
        name
      }
      name
      waterType
      url
      hasLocalGuideInfo
    }
  }
`;
