import { gql } from 'graphql-tag';

export default gql`
  mutation UpdateFishingWater($input: UpdateFishingWaterMutationInput!) {
    updateFishingWater(input: $input) {
      userErrors {
        message
      }
    }
  }
`;
