/* $edit-blue: rgb(92, 177, 235); */
/* $btn-details: #686868; */
/* $btn-background: rgb(219, 218, 218); */

.uploadContainer {
  border-bottom: 1px solid rgb(219, 219, 219);
  border-top: 1px solid rgb(219, 219, 219);
  padding: 10px 0;
}

.uploadContainer input {
  opacity: 0;
}

.imgContainer {
  display: inline-block;
  position: relative;

  & + & {
    margin-left: 10px;
  }
}

.previews {
  margin-top: 15px;
}

.preview {
  height: 100px;
}

.deleteBtn {
  background-color: black;
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  padding: 0;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;
  vertical-align: top;
  width: 20px;

  &:hover {
    background: #555;
  }
}
