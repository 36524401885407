import ServerError, { ServerErrorMessageProps } from './ServerError';

const ListError = (props: ServerErrorMessageProps) => (
  <tbody>
    <tr>
      <td>
        <ServerError message={props.message} />
      </td>
    </tr>
  </tbody>
);

export default ListError;
