import { gql } from 'graphql-tag';

export default gql`
  mutation createDirectUploadUrl($input: CreateDirectUploadURLMutationInput!) {
    createDirectUploadUrl(input: $input) {
      directUploadUrl {
        signedId
        url
        headers {
          name
          value
        }
      }
      userErrors {
        path
        message
      }
    }
  }
`;
