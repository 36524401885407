import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import styles from './Details.module.css';

interface IProps {
  children?: React.ReactNode;
  title: string;
}

const Details: React.FC<IProps> = ({ children, title }: IProps): JSX.Element => {
  const { path } = useRouteMatch();
  const { search } = useLocation();
  const lastSlashIndex = path.includes(':') ? path.indexOf(':') : path.indexOf('new');
  const pathname = path.slice(0, lastSlashIndex - 1);

  return (
    <div className={styles.details}>
      <div className={styles.title}>
        <span>{title}</span>
        <Link title="Close" to={{ pathname, search }} className={styles.close}>
          <i className="fas fa-times" />
        </Link>
      </div>
      <div className={styles.form}>{children}</div>
    </div>
  );
};

export default Details;
