import { Link } from 'react-router-dom';

import styles from './CurrentUser.module.css';
import Button from '../../components/Clickables/Buttons/Button';
import { useAuth } from '../../contexts/AuthContext';
import { logout } from '../../helpers/authentication';

const CurrentUser = () => {
  const { user } = useAuth();

  if (!user) {
    return <>Loading</>;
  }

  return (
    <div className={styles.wrapper}>
      <Link to={`/users/${user.external_id}`}>
        <strong>{user.nickname}</strong>
      </Link>
      <br />
      {user.email}
      <Button variant="buttonNone" onClick={logout} className={styles.signout}>
        <i className="fas fa-sign-out-alt" />
      </Button>
    </div>
  );
};

export default CurrentUser;
