import { gql } from 'graphql-tag';

export default gql`
  query FindBrand($externalId: String!) {
    entry: brand(externalId: $externalId) {
      id
      externalId
      name
      address
      url
      __typename
      aboutTitle
      aboutDescription
      productsTitle
      productsDescription
      membersTitle
      page {
        id
        externalId
      }
    }
  }
`;
