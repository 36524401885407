import { Link } from 'react-router-dom';

import styles from './Comment.module.css';

import CatchComment from '../../models/CatchComment';
import { IUser } from '../../models/User';

import avatarPlaceholder from '../../images/avatar-placeholder.png';

export interface IComment {
  id: number;
  created_at: string;
  text: string;
  author: IUser;
  avatar?: string;
}

interface IProps {
  attr: IComment | CatchComment;
}

const Comment = ({ attr }: IProps): JSX.Element => (
  <div className={styles.container}>
    <img
      className={styles.avatar}
      src={attr.avatar || avatarPlaceholder}
      alt={attr.author.nickname}
    />
    <div className={styles.info}>
      <Link to={`/posts/${attr.id}`}>
        {' '}
        <p>#{attr.id}</p>{' '}
      </Link>
      <Link to={`/users/${attr.author.id}`}>
        {' '}
        <p>{attr.author.nickname}</p>{' '}
      </Link>
      <p>{attr.created_at.toString()}</p>
    </div>
    <p className={styles.text}>{attr.text}</p>
  </div>
);

export default Comment;
