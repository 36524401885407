import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

import { GraphqlClient as client } from './api/rutilus';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Header from './components/Header/Header';
import Home from './views/Home/Home';
import CurrentUser from './views/CurrentUser/CurrentUser';
import CurrentViewTitle from './views/CurrentViewTitle/CurrentViewTitle';
import Login from './views/Login/Login';
import Routes, { ROUTES, IRoute } from './views/Routes/Routes';

import styles from './App.module.css';
import logo from './logo.png';
import { useAuth } from './contexts/AuthContext';
import CreateNewButton from './components/Clickables/CreateNewButton/CreateNewButton';
import Loading from './components/Loading/Loading';

// Flatten and filter routes
const routesToRender = (routes: IRoute[]): IRoute[] =>
  routes
    .filter(({ component, path }: IRoute) => component && path)
    .reduce((allRoutes: IRoute[], route: IRoute) => {
      if (!route.routes) {
        return allRoutes.concat(route);
      }
      return allRoutes.concat(route, routesToRender(route.routes));
    }, [] as IRoute[]);

function App() {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn === 'loading') {
    return <Loading isLoading />;
  }

  if (!isLoggedIn) {
    return (
      <Router>
        <Login />
      </Router>
    );
  }

  return (
    <Router>
      <ApolloProvider client={client}>
        <div className={styles.wrapper}>
          <section className={styles.sidebar}>
            <Header className={styles.header}>
              <Link to="/">
                <img src={logo} alt="Fishbrain" className={styles.logo} />
                Ornatus
              </Link>
            </Header>
            <section className={styles.routes}>
              <Routes />
            </section>
            <footer className={styles.footer}>
              <CurrentUser />
            </footer>
          </section>
          <section className={styles.main}>
            <Header className="flex justify-between">
              <CurrentViewTitle />
              <CreateNewButton />
            </Header>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/" component={Home} />
                {routesToRender(ROUTES).map(({ component, path }) => (
                  <Route key={path} path={path} component={component} />
                ))}
              </Switch>
            </ErrorBoundary>
          </section>
        </div>
      </ApolloProvider>
    </Router>
  );
}

export default App;
