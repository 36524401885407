import * as Yup from 'yup';

import GqlEdit from '../../../components/Edit/GqlEdit';
import IFormHeaders from '../../../components/Edit/FormHeaderInterface';
import {
  AttributeWithTitle,
  FormCheckbox,
  FormHidden,
  FormText,
} from '../../../components/Edit/FormAttributes/FormAttribute';
import { Links, WaterTypeSelect, getCoords } from '../helpers';
import DetailsMap from './DetailsMap';
import FishingWater from '../../../models/FishingWater';
import UpdateFishingWater from '../../../mutations/UpdateFishingWater';
import { getFishingWater } from '../../../queries/FishingWaters';
import DeleteRecordByExternalId from '../../../mutations/DeleteRecordByExternalId';
import { Metadata } from '../../../components/Metadata/Metadata';

const editFormHeaders: IFormHeaders[] = [
  { title: 'Content', attribute: '', component: AttributeWithTitle(Links) },
  { title: 'External ID', attribute: 'externalId', component: FormText, readonly: true },
  { title: 'Name', attribute: 'name', component: FormText },
  { title: 'Water Description', attribute: 'description', component: FormText },
  { title: 'Has Local Guide Info', attribute: 'hasLocalGuideInfo', component: FormCheckbox },
  { title: 'Official Website URL', attribute: 'url', component: FormText },
  {
    title: 'Water type',
    attribute: 'type',
    component: WaterTypeSelect,
  },
  { title: '', attribute: '', component: DetailsMap },
  {
    title: '',
    attribute: 'coords',
    row: [
      {
        title: 'Latitude (eg 59.33)',
        attribute: 'latitude',
        component: FormText,
        relatedAttribute: 'longitude',
        beforeSave: (value: any) => parseFloat(value),
      },
      {
        title: 'Longitude (eg 18.06)',
        attribute: 'longitude',
        component: FormText,
        relatedAttribute: 'latitude',
        beforeSave: (value: any) => parseFloat(value),
      },
    ],
  },
  {
    title: 'Water Metadata',
    attribute: 'metadata',
    component: (props: any) => (
      <Metadata
        metadata={props.values.metadata ? props.values.metadata : undefined}
        valuesToAddField="addMetadataIds"
        valuesToRemoveField="removeMetadataIds"
        {...props}
      />
    ),
  },
  { title: '', attribute: 'addMetadataIds', component: FormHidden },
  { title: '', attribute: 'removeMetadataIds', component: FormHidden },
];

const editValidation = Yup.object({
  latitude: Yup.number().required('Latitude must be a valid number'),
  longitude: Yup.number().required('Longitude must be a valid number'),
});

const EditFishingWater = (): JSX.Element => (
  <GqlEdit
    headers={editFormHeaders}
    model={FishingWater}
    mutation={UpdateFishingWater}
    prepareMutation={(attributes: any) => {
      const { latitude, longitude, ...rest } = attributes;
      return {
        input: {
          attributes: {
            ...getCoords(latitude, longitude),
            ...rest,
          },
        },
      };
    }}
    editQuery={getFishingWater}
    prepareEditQuery={(data: any) => {
      const { descriptionOfWaterType, ...rest } = data.fishingWater;
      return {
        type: descriptionOfWaterType,
        ...rest,
      };
    }}
    deletion={DeleteRecordByExternalId}
    asyncDeletion
    prepareDelete={(entry: any) => ({
      variables: { input: { externalId: entry.externalId, type: 'FISHING_WATER' } },
    })}
    validationSchema={editValidation}
  />
);

export default EditFishingWater;
