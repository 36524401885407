import UserPost from '../../../models/UserPost';
import Flex from '../../Flex/Flex';
import IHeader from '../../List/HeadersInterface';
import ModelList from '../../ModelList/ModelList';

/* ATTRIBUTE COMPONENTS */
import AttributeDate from '../../AttributeComponents/AttributeDate';
import AttributeID from '../../AttributeComponents/AttributeID';
import AttributeStatus from '../../AttributeComponents/AttributeStatus';

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    filterable: false,
    link: '/posts/',
    title: 'ID',
  },
  { title: 'Text', attribute: 'textBody' },
  {
    attribute: 'created_at',
    component: AttributeDate,
    filterable: false,
    title: 'Post Date',
  },
  { title: 'Comments', attribute: 'comments_count' },
  { title: 'Status', attribute: 'textStatus', component: AttributeStatus },
];

const UserPosts = (): JSX.Element => (
  <Flex>
    <ModelList headers={headers} model={UserPost} />
  </Flex>
);

export default UserPosts;
