import { get } from '../api/rutilus';

export interface ICountry {
  id: string;
  country_name: string;
  iso_no: number;
}

class Countries {
  private countries?: ICountry[];

  public async getCountries(): Promise<ICountry[]> {
    return this.countries
      ? Promise.resolve(this.countries)
      : get('/countries?q[s]=country_name+asc&per_page=400')
          .then(async (resp) => (resp.ok ? resp.json() : alert(resp)))
          .then((jsonResponse) => {
            if (jsonResponse) {
              this.countries = jsonResponse;
            }
            return jsonResponse || [];
          });
  }
}

const instance = new Countries();
export default instance;
