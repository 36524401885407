.route a {
  align-items: center;
  display: grid;
  grid-template-columns: calc(2 * var(--sidebar-icon-size)) 1fr;
  padding: calc(var(--padding-base) * 5 / 6) var(--padding-base);
}

.route a span {
  grid-column-end: 3;
  grid-column-start: 2;
}

.route a.active {
  background: #192639;
  font-weight: 500;
}

.route > .route {
  font-size: 0.9em;

  & a {
    padding: calc(var(--padding-base) / 2) var(--padding-base);
  }
}

.icon {
  max-width: var(--sidebar-icon-size);
}
