import GqlBaseModel from '../GqlBaseModel';

export interface ICategory {
  readonly id: number;
  readonly title: string;
  readonly ancestry: string;
}

class Category extends GqlBaseModel {
  public static readOnly: string[] = ['id', 'title', 'ancestry', 'created_at', 'updated_at'];

  public static endpoint = '/equipment/categories';

  public static fromAttributes(attributes: ICategory): Category {
    return new Category(attributes);
  }

  public ['constructor']: typeof Category;

  protected attributes: ICategory;

  constructor(attributes: ICategory) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get title(): string {
    return this.attributes.title;
  }

  public get identifier(): string {
    return this.attributes.title;
  }

  public get id(): string {
    return this.attributes.id.toString();
  }

  public get modelType(): string {
    return 'Category';
  }

  public static get modelType(): string {
    return 'Category';
  }

  protected get class(): typeof Category {
    return Category;
  }
}

export default Category;
