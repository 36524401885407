import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  PostSharedStatus,
  PostSharedStatusVariables,
  PostSharedStatus_post_sharedPost,
} from '../../interfaces/graphql';
import { getSharedStatus } from '../../queries/PostSharedStatus';

import Post from '../../models/Post';
import Fieldset from '../../components/Fieldset/Fieldset';
import styles from '../../components/Edit/FormAttributes/FormAttribute.module.css';
import Filter from '../../helpers/filter';
import { RansackMatcher } from '../../helpers/ransack';
import Button from '../../components/Clickables/Buttons/Button';

const SharingTable = ({
  value,
}: {
  value: PostSharedStatus_post_sharedPost | null;
}): JSX.Element => {
  if (value === null) {
    throw new Error('Value is null!');
  }

  return (
    <div className="flex">
      <Fieldset>
        <div className={styles.wrapper}>
          <div className={styles.title}>This post is sharing</div>
          <Link to={`/posts/${value.externalId}`}>{value.externalId}</Link>
        </div>
      </Fieldset>

      <Fieldset>
        <div className={styles.wrapper}>
          <div className={styles.title}>By</div>
          <Link to={`/users/${value.user.externalId}`}>{value.user.displayName}</Link>
        </div>
      </Fieldset>
    </div>
  );
};

const SharedStatus = ({ entry }: { entry: Post }): JSX.Element => {
  const history = useHistory();
  const { data, error, loading } = useQuery<PostSharedStatus, PostSharedStatusVariables>(
    getSharedStatus,
    { variables: { externalId: entry.external_id } },
  );

  if (loading || error || data === undefined) {
    return <h2>Sharing status</h2>;
  }

  const isSharing = data.post.sharingPost;
  const isBeingShared = data.post.sharedIn.totalCount > 0;

  const handleClick = (): void => {
    history.push({
      pathname: '/posts',
      search: Filter.empty()
        .setAttribute(`shared_post_id`, RansackMatcher.Equals, data.post.id.toString())
        .toString(),
    });
  };

  return (
    <div>
      <h2>Sharing status</h2>
      {isSharing && <SharingTable value={data.post.sharedPost} />}
      <Fieldset
        label="This post has been shared"
        inputProps={{ readOnly: true, value: data.post.sharedIn.totalCount, type: 'number' }}
      />
      {isBeingShared && <Button onClick={handleClick}>See all sharings</Button>}
    </div>
  );
};

export default SharedStatus;
