import { useState } from 'react';

import styles from './FormAttribute.module.css';

export const CopyToClipboard = ({
  value,
  copyToClipboard,
}: {
  value: string;
  copyToClipboard: any;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copiedClass = copySuccess.length > 0 ? styles.copied : '';
  const containerClass = `${styles.copybutton} ${copiedClass}`;
  const timeForReset = 10_000;
  const onTick = () => {
    setCopySuccess('');
  };

  if (copyToClipboard) {
    return (
      <div className={containerClass}>
        <button
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(value);
            setCopySuccess('Copied!');
            setInterval(onTick, timeForReset);
          }}
        >
          Copy to Clipboard
        </button>
        <span>{copySuccess}</span>
      </div>
    );
  }
  return null;
};

export default CopyToClipboard;
