import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import {
  DisplayEntity,
  GetPostDisplayEntity,
  GetPostDisplayEntityVariables,
  GetPostTargetDisplayEntity,
  GetPostTargetDisplayEntityVariables,
} from '../../interfaces/graphql';
import Post from '../../models/Post';

const displayEntityFragment = gql`
  fragment DisplayEntity on PostsFeedDisplayEntity {
    __typename
    id
    externalId
    displayIcon(width: 100, height: 100) {
      photoUrl: url
    }
    displayName
  }
`;

const targetQuery = gql`
  query GetPostTargetDisplayEntity($id: String!) {
    post(externalId: $id) {
      targetDisplayEntity {
        ...DisplayEntity
      }
    }
  }
  ${displayEntityFragment}
`;

const fromQuery = gql`
  query GetPostDisplayEntity($id: String!) {
    post(externalId: $id) {
      displayEntity {
        ...DisplayEntity
      }
    }
  }
  ${displayEntityFragment}
`;

const ROUTES = {
  Page: '/pages',
  User: '/users',
  FishingMethod: '#',
  FishingWater: '/fishing_water',
  Species: '/species',
  Group: '/groups',
  CurrentUser: '/user',
};

const USE_EXTERNAL_IDS = ['User', 'Group'];

const Inner = ({
  externalId,
  id,
  displayIcon,
  displayName,
  __typename: typename,
}: DisplayEntity) => (
  <Link to={`${ROUTES[typename] || '#'}/${USE_EXTERNAL_IDS.includes(typename) ? externalId : id}`}>
    {displayIcon && (
      <img
        src={displayIcon.photoUrl}
        alt={`Display icon for ${externalId}`}
        style={{ height: '1em', borderRadius: '100%' }}
      />
    )}{' '}
    {displayName}
  </Link>
);

export const TargetDisplayEntity = ({ entry: { external_id: externalId } }: { entry: Post }) => {
  const { data, loading } = useQuery<
    GetPostTargetDisplayEntity,
    GetPostTargetDisplayEntityVariables
  >(targetQuery, {
    variables: {
      id: externalId as string,
    },
    fetchPolicy: 'no-cache',
  });

  if (!(data && data.post && data.post.targetDisplayEntity) || loading) {
    return <>-</>;
  }

  return <Inner {...data.post.targetDisplayEntity} />;
};

export const FromDisplayEntity = ({ entry: { external_id: externalId } }: { entry: Post }) => {
  const { data, loading } = useQuery<GetPostDisplayEntity, GetPostDisplayEntityVariables>(
    fromQuery,
    {
      variables: {
        id: externalId as string,
      },
      fetchPolicy: 'no-cache',
    },
  );

  if (!data || loading) {
    return <>-</>;
  }

  return <Inner {...data.post.displayEntity} />;
};
