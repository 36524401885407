import Button, { IButtonProps } from './Button';

interface IProps extends IButtonProps {
  isSaving?: boolean;
  savingLabel?: string;
  className?: string;
}

const SaveButton = ({
  disabled = false,
  isSaving = false,
  children,
  savingLabel = 'Saving...',
  className = '',
  ...rest
}: IProps): JSX.Element => {
  return (
    <Button className={className} disabled={isSaving || disabled} {...rest}>
      {isSaving ? savingLabel : children}
    </Button>
  );
};

export default SaveButton;
