import { gql } from 'graphql-tag';

export const addStaffToPage = gql`
  mutation AddStaffMutation($input: AddStaffMutationInput!) {
    addStaffToPage(input: $input) {
      page {
        ambassadors(first: 50) {
          edges {
            node {
              id
              externalId
              nickname
            }
          }
        }
        publishers(first: 50) {
          edges {
            node {
              id
              externalId
              nickname
            }
          }
        }
        admins(first: 50) {
          edges {
            node {
              id
              externalId
              nickname
            }
          }
        }
      }
      userErrors {
        path
        message
      }
    }
  }
`;
