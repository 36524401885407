import React, { ReactNode } from 'react';
import {
  TableContainer,
  Table as MaterialTable,
  Button,
  TableHead,
  TableBody,
} from '@material-ui/core';

import { TableHeaders } from '../TableHeaders/TableHeaders';
import { TableFilters } from '../TableFilters/TableFilters';

export interface ITableColumn {
  id: string;
  label: string;
  filterable?: boolean;
}

interface IProps {
  fetchNextPage?: () => void;
  columns: ITableColumn[];
  rows: ReactNode[];
}
export const Table = ({ columns, fetchNextPage, rows }: IProps): JSX.Element => (
  <TableContainer style={{ maxHeight: 'calc(100vh - 88px)' }}>
    <MaterialTable>
      <TableHead>
        <TableHeaders columns={columns} />
        {columns.some((c) => c.filterable) && <TableFilters columns={columns} />}
      </TableHead>
      <TableBody>{rows}</TableBody>
    </MaterialTable>
    {fetchNextPage && (
      <div className="flex justify-center my-4">
        {/* TODO Update button to our own */}
        <Button variant="contained" color="primary" onClick={fetchNextPage}>
          Load More
        </Button>
      </div>
    )}
  </TableContainer>
);
