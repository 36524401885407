import React, { useState } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import IHeader from '../../components/List/HeadersInterface';
import ModelList from '../../components/ModelList/ModelList';
import SidePane from '../../components/SidePane/SidePane';
import Catch from '../../models/Catch';
import DateFilterInput from '../../components/FilterInputs/DateFilterInput';

import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import AttributeID from '../../components/AttributeComponents/AttributeID';
import AttributeImage from '../../components/AttributeComponents/AttributeImage';
import AttributeLink from '../../components/AttributeComponents/AttributeLink';

import { ITab } from '../../components/Switcher/Switcher';

import EditCatch from './EditCatch';

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    filterable: true,
    filterOptions: { filterType: 'eq' },
    link: '/catches/',
    title: 'Id',
  },
  {
    attribute: 'external_id',
    component: AttributeID,
    filterable: true,
    filterOptions: { filterType: 'eq' },
    title: 'External Id',
  },
  {
    attribute: 'post_external_id',
    component: AttributeID,
    filterable: true,
    link: '/posts/',
    filterOptions: { filterType: 'eq' },
    title: 'Post',
  },
  {
    attribute: 'species',
    component: AttributeLink,
    filterable: true,
    filterOptions: { filterKey: 'species_name' },
    link: '/species/',
    title: 'Species',
  },
  {
    attribute: 'description',
    filterable: true,
    title: 'Description',
  },
  {
    attribute: 'owner',
    component: AttributeLink,
    filterable: true,
    filterOptions: { filterKey: 'owner_nickname' },
    link: '/users/',
    title: 'Owner',
  },
  {
    attribute: 'imageUrl',
    component: AttributeImage,
    hideOnGallery: true,
    title: 'Image',
  },
  {
    attribute: 'caught_at_gmt',
    component: AttributeDate,
    filterable: true,
    filterOptions: { component: DateFilterInput },
    title: 'Caught at',
  },
  {
    attribute: 'fishing_water',
    component: AttributeLink,
    filterable: true,
    filterOptions: { filterKey: 'fishing_water_name' },
    link: '/fishing_waters/',
    title: 'Fishing Water',
  },
];

const tabs: ITab[] = [
  {
    icon: 'fa fa-table',
    index: 1,
    name: 'Table',
  },
  {
    icon: 'fa fa-th',
    index: 2,
    name: 'Grid',
  },
];

const Catches = (): JSX.Element => {
  const [activeTab, setActive] = useState(1);
  const match = useRouteMatch();

  return (
    <>
      <ModelList
        headers={headers}
        model={Catch}
        isGalleryView={activeTab === tabs[1].index}
        imageUrlProperty="imageUrl"
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={setActive}
      />
      <SidePane showOnPath={`${match.path}/:slug`}>
        <Route path={`${match.path}/:id`} component={EditCatch} />
      </SidePane>
    </>
  );
};

export default Catches;
