import { Route, useRouteMatch } from 'react-router-dom';

import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import AttributeID from '../../components/AttributeComponents/AttributeID';
import AttributeLink from '../../components/AttributeComponents/AttributeLink';
import DateFilterInput from '../../components/FilterInputs/DateFilterInput';
import Flex from '../../components/Flex/Flex';
import IHeader from '../../components/List/HeadersInterface';
import ModelList from '../../components/ModelList/ModelList';
import SidePane from '../../components/SidePane/SidePane';

import Post from '../../models/Post';

import NewPost from './NewPost';
import PostPreview from './PostPreview';
import EditPost from './EditPost';

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    filterable: true,
    filterOptions: { filterType: 'eq' },
    link: '/posts/',
    title: 'ID',
  },
  {
    attribute: 'external_id',
    component: AttributeID,
    filterable: true,
    filterOptions: { filterType: 'eq' },
    title: 'External Id',
  },
  {
    attribute: 'owner',
    component: AttributeLink,
    filterable: true,
    filterOptions: { filterKey: 'user_nickname' },
    link: '/users/',
    title: 'Owner',
  },
  { title: 'Preview', attribute: 'textBody', component: PostPreview },
  {
    attribute: 'created_at',
    component: AttributeDate,
    filterable: true,
    filterOptions: { component: DateFilterInput },
    title: 'Creation Date',
  },
  {
    attribute: 'published_at',
    component: AttributeDate,
    filterable: true,
    filterOptions: { component: DateFilterInput },
    title: 'Published Date',
  },
  { title: 'Comments', attribute: 'comments_count' },
];

const Posts = (): JSX.Element => {
  const match = useRouteMatch();
  return (
    <Flex>
      <ModelList headers={headers} model={Post} />
      <SidePane showOnPath={`${match.path}/:slug`}>
        <Route path={`${match.path}/new`} component={NewPost} exact />
        <Route path={`${match.path}/:id`} component={EditPost} />
      </SidePane>
    </Flex>
  );
};
export default Posts;
