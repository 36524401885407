import { Field, FieldProps, FormikProps } from 'formik';

import { dateTimeLocalFormat } from '../../../helpers/DateFormat/DateFormat';

import BaseModel from '../../../models/BaseModel';
import GqlBaseModel from '../../../models/GqlBaseModel';
import Fieldset from '../../Fieldset/Fieldset';
import Flex from '../../Flex/Flex';
import IFormHeader from '../FormHeaderInterface';
import FormCustomDate from './FormCustomDate';
import CopyToClipboard from './CopyToClipboard';

import styles from './FormAttribute.module.css';

export interface FormProps<T = any> extends FormikProps<T> {
  entry: BaseModel | GqlBaseModel;
  header: IFormHeader;
  value?: any;
  className?: string;
  inputClassName?: string;
  inputMaxLength?: number;
}

export const InnerField = ({
  field,
  form: _,
  header,
  ...props
}: FieldProps & FormProps & { type: string }): JSX.Element => {
  return (
    <Fieldset
      label={header.title === '' ? undefined : header.title}
      legend={header.legend}
      inputProps={{
        ...field,
        type: props.type,
        className: props.inputClassName,
        ...(props.inputMaxLength && { maxLength: props.inputMaxLength }),
      }}
      className={props.type === 'hidden' ? 'hidden' : props.className}
      {...props}
    />
  );
};

export const FormText = (props: FormProps): JSX.Element => {
  return <Field component={InnerField} {...props} />;
};
export const FormHidden = (props: FormProps): JSX.Element => {
  return <Field component={InnerField} type="hidden" {...props} />;
};
export const FormTextarea = (props: FormProps): JSX.Element => (
  <Field component={InnerField} type="textarea" {...props} />
);
export const FormCheckbox = (props: FormProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, ...rest } = props;
  return <Field className="text-lg" component={InnerField} type="checkbox" {...rest} />;
};
export const FormDate = (props: FormProps): JSX.Element => (
  <Field component={InnerField} type="date" {...props} />
);
export const FormNumber = (props: FormProps): JSX.Element => (
  <Field component={InnerField} type="number" {...props} />
);
export const FormDecimalNumber = (props: FormProps): JSX.Element => (
  <Field component={InnerField} type="number" step="0.01" {...props} />
);

export const FormDateTime = ({ value, ...props }: FormProps): JSX.Element => (
  <Field
    component={InnerField}
    type="datetime-local"
    value={dateTimeLocalFormat(value)}
    {...props}
    placeholder="YYYY-MM-DD HH:MM:SS"
  />
);
export const AttributeWithTitle =
  (Component: any): any =>
  // eslint-disable-next-line react/display-name
  (props: FormProps): JSX.Element => (
    <div className={styles.wrapper}>
      <div className={styles.title}>{props.header.title}</div>
      <Component {...props} />
    </div>
  );
export const FormDateCustom = (props: FormProps): JSX.Element => <FormCustomDate {...props} />;

export const ConditionallyDisabledAttribute =
  (Component: any, conditionalField: string, conditionalValue: any): any =>
  // eslint-disable-next-line react/display-name
  ({ values, value, ...rest }: FormProps): JSX.Element => {
    if (values[conditionalField] === conditionalValue) {
      return <Component values={values} value={value} {...rest} />;
    }

    return <Component values={values} value="" disabled {...rest} />;
  };

const FormComponent: React.FC<FormProps> = ({ header, ...props }: FormProps): JSX.Element => {
  if (header.row) {
    return (
      <>
        {header.title && <div className="py-4">{header.title}</div>}
        <Flex>
          {header.row.map((innerHeader: IFormHeader, index) => (
            <FormComponent
              {...props}
              key={innerHeader.attribute !== '' ? innerHeader.attribute : `innerKey-${index}`}
              value={props.values[innerHeader.attribute]}
              header={innerHeader}
            />
          ))}
        </Flex>
      </>
    );
  }
  const Component = header.component;
  if (Component) {
    return (
      <div>
        <Component
          {...props}
          header={header}
          name={header.attribute}
          readOnly={props.entry.isReadOnly(header.attribute) || header.readonly}
        />
        <CopyToClipboard value={props.value} copyToClipboard={header.copyToClipboard} />
      </div>
    );
  }

  return <>{props.value}</>;
};

export default FormComponent;
