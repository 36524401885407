import AttributeLink from '../../AttributeComponents/AttributeLink';
import { FormProps, FormText } from './FormAttribute';

const FormLink = (props: FormProps): JSX.Element => {
  const { entry, header } = props;
  if (entry[header.attribute]) {
    return header.link ? (
      <AttributeLink value={entry[header.attribute]} link={header.link} entry={entry} />
    ) : (
      <FormText {...props} />
    );
  }
  return <></>;
};

export default FormLink;
