import { gql } from 'graphql-tag';

export default gql`
  mutation UpdatePlaceUnsplashImage($input: UpdatePlaceUnsplashImageMutationInput!) {
    updatePlaceUnsplashImage(input: $input) {
      place {
        id
        unsplashImage {
          imageUrl
          photographerName
          photographerUrl
        }
      }
      userErrors {
        message
      }
    }
  }
`;
