import { gql } from 'graphql-tag';

export default gql`
  mutation CreateSpeciesTip($input: CreateSpeciesTipMutationInput!) {
    createSpeciesTip(input: $input) {
      speciesTip {
        externalId
      }
    }
  }
`;
