import styles from './ErrorBoundary.module.css';

export interface ServerErrorMessageProps {
  message: string;
}

const ServerError = (props: ServerErrorMessageProps): JSX.Element => (
  <div className={styles.wrapper}>
    <p>{props.message ? props.message : 'Something went wrong'}</p>
  </div>
);

export default ServerError;
