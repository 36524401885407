/* eslint-disable jsx-a11y/control-has-associated-label */
import { useQuery } from '@apollo/client';
import { get } from 'ts-get';

import { Button } from '@material-ui/core';

import Post from '../../models/Post';
import { PaginatePostModerationTraces } from '../../interfaces/graphql';
import { paginatePostModerationTraces } from '../../queries/PostModerationTraces';
import Table from '../../components/Table/Table';
import IFormHeader from '../../components/Edit/FormHeaderInterface';
import SelectFilterInput from '../../components/FilterInputs/SelectFilterInput';

export const moderationStatusFilters = (props: any): JSX.Element => (
  <SelectFilterInput {...props}>
    <option />
    <option>NEW</option>
    <option>SCHEDULED_FOR_MODERATION</option>
    <option>BEING_MODERATED</option>
    <option>UNDER_REVIEW</option>
    <option>REJECTED</option>
    <option>APPROVED</option>
  </SelectFilterInput>
);

const PostModerationTraces = ({
  entry,
  header,
}: {
  entry: Post;
  header: IFormHeader;
}): JSX.Element => {
  const { data, loading, fetchMore } = useQuery<PaginatePostModerationTraces>(
    paginatePostModerationTraces,
    {
      variables: { postId: entry.id },
    },
  );

  const onLoadMore = async () => {
    if (!data) {
      throw new Error("Can't load more Moderation traces when data is not fetched.");
    }
    return fetchMore({
      variables: {
        cursor: data.post.moderationTraces?.pageInfo.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
        const newEdges = fetchMoreResult.post.moderationTraces.edges;
        const newResult = { ...fetchMoreResult };
        newResult.post.moderationTraces.edges = [
          ...previousResult.post.moderationTraces.edges,
          ...newEdges,
        ];
        return newResult;
      },
    });
  };
  if (loading) {
    return <>loading...</>;
  }
  const traces = get(data, (it) => it.post.moderationTraces.edges, []).filter((node) => !!node);

  if (!traces) {
    return (
      <div>
        <label>{header.title}</label>
        <span>no urls</span>;
      </div>
    );
  }

  return (
    <div>
      <label>{header.title}</label>
      <Table>
        <thead>
          <tr>
            <th>Entity type</th>
            <th>Status</th>
            <th>Entity value</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          {traces.map((trace, index) => (
            <tr key={`${index}-${trace.node.entityType}-${trace.node.status}`}>
              <td>{trace.node.entityType}</td>
              <td>{trace.node.status}</td>
              <td>{trace.node.entityValue}</td>
              <td>{trace.node.reason}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* TODO Update button to our own */}
      {data?.post.moderationTraces?.pageInfo.hasNextPage ? (
        <Button onClick={onLoadMore}>Load More</Button>
      ) : null}
    </div>
  );
};

export default PostModerationTraces;
