import { Link } from 'react-router-dom';

import styles from './Bookmarks.module.css';

export interface IBookmark {
  title: string;
  path: string;
  imageUrl?: string;
  openInNewTab?: boolean;
}

const Bookmarks = ({ bookmarks }: { bookmarks?: IBookmark[] }): JSX.Element | null => {
  if (!bookmarks || bookmarks.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {bookmarks.map(
        (bookmark: IBookmark): JSX.Element => (
          <div className={styles.chip} key={bookmark.title}>
            {bookmark.imageUrl ? (
              <img src={bookmark.imageUrl} alt="Bookmark" />
            ) : (
              <div className={styles.chipHead}>B</div>
            )}
            <Link
              className={styles.chipContent}
              to={bookmark.path}
              target={bookmark.openInNewTab ? '_blank' : ''}
            >
              {bookmark.title}
            </Link>
          </div>
        ),
      )}
    </div>
  );
};

export default Bookmarks;
