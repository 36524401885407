.disapproved {
  color: #e94a4a;
}

.approved {
  color: #222;
}

.pending {
  color: #f5a623;
}
