import { useMutation, useQuery, QueryResult, ApolloError } from '@apollo/client';

import { useEffect, useState } from 'react';

import {
  RemoveStaffMutation,
  RemoveStaffMutationVariables,
  RolesEnum,
} from '../../interfaces/graphql';

import { removeStaffFromPage as removeStaffFromPageMutation } from '../../mutations/Pages/RemoveStaff';
import UserGraphql from '../../models/UserGraphql';
import IHeader from '../../components/List/HeadersInterface';
import styles from '../../components/ModelList/ModelList.module.css';
import { IEntries } from '../../components/List/GqlListBody';
import GqlModelList from '../../components/ModelList/GqlModelList';
import Button from '../../components/Clickables/Buttons/Button';

export interface IViewStaffListComponentProps {
  pageExternalId: string;
  staffRole: RolesEnum;
  users: IEntries<UserGraphql>;
  title: string;
  onLoadMore: (fetchMore: QueryResult['fetchMore'], pages: any, externalId: string) => any;
  loadQuery: any;
}

const ViewStaffListComponent: React.FC<IViewStaffListComponentProps> = (
  props: IViewStaffListComponentProps,
) => {
  const { loading, data, fetchMore } = useQuery(props.loadQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setUsersToDisplay(data.entry.stuff);
    }
  }, [data, loading]);

  const { staffRole, pageExternalId } = props;
  const [usersToDisplay, setUsersToDisplay] = useState(props.users);
  const [externalId, setExternalId] = useState('');
  const [removeStaff] = useMutation<RemoveStaffMutation, RemoveStaffMutationVariables>(
    removeStaffFromPageMutation,
    {
      onCompleted: ({ removeStaffFromPage }: RemoveStaffMutation) => {
        if (removeStaffFromPage) {
          const { userErrors } = removeStaffFromPage;
          if (userErrors !== undefined && userErrors.length > 0) {
            alert(userErrors[0].message);
          }

          const { page } = removeStaffFromPage;
          switch (staffRole) {
            case RolesEnum.AMBASSADOR: {
              setUsersToDisplay(page.ambassadors);
              break;
            }
            case RolesEnum.ADMIN: {
              setUsersToDisplay(page.admins);
              break;
            }
            case RolesEnum.PUBLISHER: {
              setUsersToDisplay(page.publishers);
              break;
            }
            default: {
              break;
            }
          }
        }
      },
      onError: (error: ApolloError) => alert(error.message),
      variables: {
        input: {
          externalId: pageExternalId,
          attributes: {
            users: [
              {
                role: staffRole,
                externalId,
              },
            ],
          },
        },
      },
    },
  );

  useEffect(() => {
    if (externalId) {
      removeStaff();
    }
  }, [externalId, removeStaff]);

  const userHeaders: IHeader[] = [
    {
      attribute: 'externalId',
      filterable: false,
      title: 'External ID',
    },
    {
      attribute: 'nickname',
      title: 'NickName',
    },
    {
      attribute: 'externalId',
      title: 'Remove',
      // eslint-disable-next-line react/no-unstable-nested-components
      component: (componentProps: { value: string }) => (
        <Button
          size="sm"
          variant="buttonDanger"
          onClick={() => {
            setExternalId(componentProps.value);
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.scroll}>
      <h3> {props.title} </h3>
      <GqlModelList
        headers={userHeaders}
        isLoading={loading}
        onLoadMore={() => props.onLoadMore(fetchMore, usersToDisplay, props.pageExternalId)}
        path=""
        model={UserGraphql}
        entries={usersToDisplay}
      />
    </div>
  );
};

export default ViewStaffListComponent;
