import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApolloError, useQuery, useMutation } from '@apollo/client';
import { get } from 'ts-get';

import AttributeID from '../../components/AttributeComponents/AttributeID';
import GqlModelList from '../../components/ModelList/GqlModelList';
import IHeader from '../../components/List/HeadersInterface';
import AttributeExternalLink from '../../components/AttributeComponents/AttributeExternalLink';
import Select from '../../components/Input/Select';
import PostUrl from '../../models/PostUrl';
import {
  ListUrls,
  ListUrlsVariables,
  setUrlStatusVariables,
  setUrlStatus,
} from '../../interfaces/graphql';
import listUrls from '../../queries/PostUrls';
import { setUrlStatus as mutateStatus } from '../../mutations/PostUrl';
import Filter from '../../helpers/filter';
import { moderationStatusFilters } from './ModerationTraces';

export const EditableStatus = ({ entry }: { entry: PostUrl }): JSX.Element => {
  const [status, setStatus] = useState(entry.status);
  const [updateStatus, { loading }] = useMutation<setUrlStatus, setUrlStatusVariables>(
    mutateStatus,
    {
      onCompleted: ({ editUrl }: setUrlStatus) => {
        if (editUrl && editUrl.url) {
          setStatus(editUrl.url.status);
        }
      },
      onError: (error: ApolloError) => alert(error.message),
    },
  );

  const style = status !== 'rejected' ? {} : { backgroundColor: 'red', color: 'white' };

  return (
    <Select
      value={status}
      disabled={loading}
      onChange={({ currentTarget: { value } }: React.SyntheticEvent<HTMLSelectElement>) => {
        if (!value) {
          return;
        }
        const variables: setUrlStatusVariables = {
          input: { id: entry.id as string, attributes: { status: value } },
        };
        updateStatus({ variables });
      }}
      style={style}
      small
    >
      <option>new</option>
      <option value="scheduled_for_moderation">scheduled for moderation</option>
      <option value="being_moderated">being moderated</option>
      <option value="under_review">under review</option>
      <option>rejected</option>
      <option>approved</option>
    </Select>
  );
};

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    title: 'Id',
  },
  {
    attribute: 'longForm',
    filterable: true,
    filterOptions: { filterKey: 'url', filterType: 'cont' },
    component: AttributeExternalLink,
    title: 'Link',
  },
  {
    attribute: 'status',
    filterable: true,
    filterOptions: { filterType: 'cont', component: moderationStatusFilters },
    component: EditableStatus,
    title: 'Status',
  },
];

const StoryEntries: React.FC = () => {
  const location = useLocation();
  const filters = Filter.parse(location.search);
  const filterVariables = filters.filtersFlatMap;

  const { loading, fetchMore, error, data } = useQuery<ListUrls, ListUrlsVariables>(listUrls, {
    variables: filterVariables,
    ...filterVariables,
  });

  const onLoadMore = async () => {
    if (!data) {
      throw new Error('Data not found.');
    }
    return fetchMore({
      variables: {
        cursor: data.entries.pageInfo.startCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        fetchMoreResult && fetchMoreResult.entries.edges && fetchMoreResult.entries.edges.length
          ? {
              entries: {
                ...previousResult.entries,
                pageInfo: fetchMoreResult.entries.pageInfo,
                edges: [...fetchMoreResult.entries.edges, ...(previousResult.entries.edges as any)],
              },
            }
          : previousResult,
    });
  };

  return (
    <GqlModelList
      headers={headers}
      entries={get(data, (d) => d.entries)}
      path={location.pathname}
      onLoadMore={onLoadMore}
      model={PostUrl}
      reverse
      error={error}
      isLoading={loading}
    />
  );
};

export default StoryEntries;
