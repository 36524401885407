import React, { useState, useEffect } from 'react';
import { get } from 'ts-get';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import mutation from '../../mutations/CreateBrand';
import { CreateBrand, CreateBrandVariables } from '../../interfaces/graphql';
import Details from '../../components/Details/Details';
import Fieldset from '../../components/Fieldset/Fieldset';
import SaveButton from '../../components/Clickables/Buttons/SaveButton';

const NewBrand = () => {
  const [name, setName] = useState('');
  const [saveBrand, { data, loading }] = useMutation<CreateBrand, CreateBrandVariables>(mutation);
  const history = useHistory();

  const save = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (name === '') {
      alert('Please provide a name');
      return;
    }
    saveBrand({ variables: { input: { attributes: { name } } } });
  };

  useEffect(() => {
    const externalId = get(data, (d) => d.createBrand.brand.externalId);
    if (externalId) {
      history.push(`/equipment/brands/${externalId}`);
    }
  }, [data, history]);

  return (
    <Details title="Create New Brand">
      <form id="new-form">
        <Fieldset
          label="Brand name (can't be changed later)"
          onChange={({ currentTarget: { value } }: React.SyntheticEvent<HTMLInputElement>) =>
            setName(value)
          }
          inputProps={{ value: name, placeholder: 'enter name...', required: true }}
        />
        <SaveButton disabled={loading} onClick={save}>
          Save
        </SaveButton>
      </form>
    </Details>
  );
};

export default NewBrand;
