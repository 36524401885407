import { Form, FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import BaseModel from '../../models/BaseModel';
import FormComponent from './FormAttributes/FormAttribute';
import IFormHeader from './FormHeaderInterface';
import { flattenHeaders } from './helpers';

import styles from './Form.module.css';
import Button from '../Clickables/Buttons/Button';

interface IKeyValue<T = any> {
  [key: string]: T;
}

export interface IProps {
  entry: BaseModel;
  headers: IFormHeader[];
  flatHeaders?: IFormHeader[];
  newPhotos?: File[];
  deleteEntry?: () => void;
  createEntry?: (params: { [key: string]: string | boolean }) => void;
  editEntry?: (params: { [key: string]: string | boolean }) => void;
  [key: string]: any;
  asyncDeletion?: boolean;
}

interface AttributeToSave {
  attribute: string;
  value: any;
}

const onlyChangedValues =
  (values: any, initialValues: any, touched: any) =>
  ({ attribute, equals }: IFormHeader): boolean => {
    const initialValue: any = initialValues[attribute];
    const newValue: any = values[attribute];
    return equals ? !equals(initialValue, newValue) : !!touched[attribute];
  };

const transformValues =
  (values: any, initialValues: any) =>
  ({ attribute, beforeSave }: IFormHeader): AttributeToSave => ({
    attribute,
    value: beforeSave ? beforeSave(values[attribute], initialValues[attribute]) : values[attribute],
  });

const mergeValues = (obj: IKeyValue, { attribute, value }: AttributeToSave): IKeyValue => ({
  ...obj,
  [attribute]: value,
});

const InnerEdit = (props: FormikProps<IProps>): JSX.Element => {
  const {
    values,
    touched,
    submitForm,
    initialValues,
    isSubmitting,
    dirty,
    errors,
  }: FormikProps<IProps> = props;
  const deleteFunc = values.deleteEntry;
  const { asyncDeletion } = values;
  const [showErrors, setShowErrors] = useState(false);
  const hasErrors = Object.keys(errors).length > 0;
  const { entry } = initialValues;

  const createFunc = values.createEntry
    ? () => {
        const { flatHeaders, headers } = values;
        const create: IKeyValue = (flatHeaders || headers)
          .map(transformValues(values, initialValues))
          .reduce(mergeValues, {});

        if (values.createEntry) {
          if (hasErrors) {
            setShowErrors(true);
          } else {
            setShowErrors(false);
            values.createEntry(values.newPhotos ? { ...create, photos: values.newPhotos } : create);
            submitForm();
          }
        }
      }
    : undefined;

  const editFunc = values.editEntry
    ? () => {
        const { flatHeaders, headers } = values;
        const editableAttributes: IFormHeader[] = (flatHeaders || headers).filter(
          (h) => !entry.isReadOnly(h.attribute),
        );
        const edit: IKeyValue = editableAttributes
          .filter(onlyChangedValues(values, initialValues, touched))
          .map(transformValues(values, initialValues))
          .reduce(mergeValues, {});

        if (values.newPhotos) {
          edit.photos = values.newPhotos;
        }

        if (values.editEntry) {
          if (hasErrors) {
            setShowErrors(true);
          } else {
            setShowErrors(false);
            values.editEntry(edit);
            submitForm();
          }
        }
      }
    : undefined;

  return (
    <Form className={styles.list}>
      <div className={styles.buttons}>
        {editFunc ? (
          <Button disabled={!dirty} onClick={editFunc}>
            Edit {entry.modelType}
          </Button>
        ) : null}{' '}
        {editFunc &&
          showErrors &&
          Object.keys(errors).map((key) => (
            <div className="text-error" key={key}>
              {errors[key]}
            </div>
          ))}
        {deleteFunc ? (
          <>
            <Button variant="buttonDanger" onClick={deleteFunc} disabled={isSubmitting}>
              Delete {entry.modelType}
            </Button>
            {asyncDeletion ? (
              <div className={styles.deletion_note}>
                <b>Note</b>: Deletion may take 30 minutes or more to show here because it needs to
                run in the background. Please check back later to ensure deletion completed
                successfully.
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      {values.headers.map((header) => (
        <FormComponent
          key={header.attribute}
          value={values[header.attribute]}
          entry={entry}
          header={header}
          {...props}
        />
      ))}
      {createFunc &&
        showErrors &&
        Object.keys(errors).map((key) => (
          <div className="text-error" key={key}>
            {errors[key]}
          </div>
        ))}
      {createFunc && (
        <Button
          disabled={hasErrors && showErrors}
          onClick={() => {
            createFunc();
          }}
        >
          create {entry.modelType}
        </Button>
      )}
    </Form>
  );
};

const EditForm = withFormik<IProps, any>({
  handleSubmit: () => {
    // form submission is handled in deleteEntry, editEntry, createEntry instead.
  },
  mapPropsToValues: (props) => {
    const { headers, entry, createEntry, deleteEntry, editEntry, asyncDeletion } = props;
    const flatHeaders = flattenHeaders(headers);
    const entryProps: IProps = {
      headers,
      flatHeaders,
      entry,
      createEntry,
      deleteEntry,
      editEntry,
      asyncDeletion,
    };
    flatHeaders.forEach((header) => {
      // default to defaultValue from Header or '' for the value
      entryProps[header.attribute] = Object.prototype.hasOwnProperty.call(header, 'defaultValue')
        ? header.defaultValue
        : '';
      // if the attribute has a non-falsey, or if the attribute is `false`,
      // replace the default ''
      if (entry[header.attribute] || entry[header.attribute] === false) {
        entryProps[header.attribute] = entry[header.attribute];
      }
    });
    return entryProps;
  },
  validateOnMount: true,
  validationSchema: (props: IProps) => {
    const { validationSchema } = props;
    if (validationSchema) {
      return validationSchema;
    }
    return Yup.object({});
  },
})(InnerEdit);

export default EditForm;
