import { gql } from 'graphql-tag';

export const getPostAvailableActions = gql`
  query GetPostAvailableActions($postId: Int!) {
    post(id: $postId) {
      text {
        id
        status
        availableActions
      }
      video {
        id
        status
        availableActions
      }
      opImages(first: 20) {
        edges {
          __typename
          node {
            id
            status
            availableActions
          }
        }
      }
    }
  }
`;
