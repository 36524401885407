import { FieldProps, FormikProps } from 'formik';
import { Feature, Layer } from 'react-mapbox-gl';

import Map from '../../../components/Map/Map';

const maxLongitude = 180;
const maxLatitude = 90;

const DetailsMap = ({
  values,
  setFieldValue,
  setFieldTouched,
}: FieldProps &
  FormikProps<{
    longitude: number | string;
    latitude: number | string;
    name: string;
    water_type: number;
  }>): JSX.Element => {
  const save = (_map: any, { lngLat }: { lngLat: { lat: number; lng: number } }): void => {
    const { lng, lat } = lngLat;
    setFieldTouched('longitude', true);
    setFieldTouched('latitude', true);
    setFieldValue('longitude', lng);
    setFieldValue('latitude', lat);
  };

  const { longitude, latitude } = values;

  const long =
    Number.isNaN(Number(longitude)) || Math.abs(Number(longitude)) > maxLongitude
      ? 0
      : Number(longitude);
  const lati =
    Number.isNaN(Number(latitude)) || Math.abs(Number(latitude)) > maxLatitude
      ? 0
      : Number(latitude);

  return (
    <Map
      containerStyle={{
        height: '300px',
        width: '100%',
      }}
      center={[long, lati]}
      onClick={save}
    >
      <Layer type="symbol" id="marker" layout={{ 'icon-image': 'POI-bow' }}>
        <Feature coordinates={[long, lati]} />
      </Layer>
    </Map>
  );
};

export default DetailsMap;
