import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import User from '../models/User';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, getToken, logout } from '../helpers/authentication';
import { get } from '../api/rutilus';

type State = {
  isLoggedIn: boolean | 'loading';
  user?: User;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
};

const AuthContext = createContext<State | null>(null);

export function AuthProvider(props: { children: ReactNode }) {
  const [user, setUser] = useState<User>();
  const [isLoggedIn, setIsLoggedIn] = useState<State['isLoggedIn']>('loading');
  const [cookies] = useCookies([ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY]);
  const cookieAccessToken = cookies[ACCESS_TOKEN_KEY];
  const cookieRefreshToken = cookies[REFRESH_TOKEN_KEY];

  const setUserAndIsLoggedIn = (accessToken: string): void => {
    if (accessToken) {
      get('/me')
        .then(async (resp) => resp.json())
        .then((attributes: any) => {
          setUser(User.fromAttributes(attributes));
          setIsLoggedIn(true);
        })
        .catch((error) => {
          if (error) {
            console.error(error);
            setIsLoggedIn(false);
            logout();
          }
        });
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    getToken().then((accessToken) => {
      setUserAndIsLoggedIn(accessToken);
    });
  }, []);

  useEffect(() => {
    if (!cookieAccessToken && !cookieRefreshToken) {
      setUser(undefined);
      setIsLoggedIn(false);
    } else if (!isLoggedIn && cookieAccessToken) {
      setUserAndIsLoggedIn(cookieAccessToken);
    }
  }, [cookieRefreshToken, cookieAccessToken, isLoggedIn]);

  return <AuthContext.Provider value={{ setIsLoggedIn, isLoggedIn, user }} {...props} />;
}

export const useAuth = (): State => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
