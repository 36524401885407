import { Component } from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './ErrorBoundary.module.css';

interface IState {
  hasError: boolean;
}

interface IProps extends RouteComponentProps {
  children: React.ReactNode;
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
    this.resetErrorState = this.resetErrorState.bind(this);
  }

  public componentDidUpdate(nextProps: IProps): void {
    const currentLocation = this.props.location.pathname + this.props.location.search;
    const nextLocation = nextProps.location.pathname + nextProps.location.search;
    if (currentLocation !== nextLocation) {
      this.resetErrorState();
    }
  }

  public componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  public render(): React.ReactElement | React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className={styles.wrapper}>
          <p>Something went wrong.</p>
          <button onClick={this.resetErrorState} type="button">
            Retry
          </button>
        </div>
      );
    }
    return this.props.children;
  }

  private resetErrorState(): void {
    this.setState({ hasError: false });
  }
}

export default withRouter(ErrorBoundary);
