import { Component } from 'react';

import { Link } from 'react-router-dom';

import { mergeQueryParams, stringFromFilter } from '../../helpers/urlhelpers';

import User from '../../models/User';
import Avatar from '../Edit/FormAttributes/Avatar';
import Flex from '../Flex/Flex';

import styles from './UserSummary.module.css';

interface IProps {
  userId: number;
  user?: User;
}

interface IState {
  loading: boolean;
  user?: User;
}

class UserSummary extends Component<IProps, IState> {
  constructor({ user, userId }: IProps) {
    super({ user, userId });

    this.state = { user, loading: false };
  }

  public componentDidMount(): void {
    const { user } = this.state;
    if (!user && this.props.userId) {
      this.setState({ loading: true });
      User.find(this.props.userId).then((foundUser: User) =>
        this.setState({ loading: false, user: foundUser }),
      );
    }
  }

  public render(): React.ReactElement {
    const { loading, user } = this.state;
    const { userId } = this.props;
    return !loading && user ? (
      <Flex>
        <Avatar value={user.avatar} className={styles.avatar} />
        <div className={styles.details}>
          <Link to={`/users/${userId}`}>User #{userId}</Link>{' '}
          <span>
            <strong>{user.name}</strong> ({user.nickname})
          </span>
          <br />
          <span>
            Premium: {user.is_premium ? 'yes' : 'no'}, banned: {user.shadow_ban ? 'yes' : 'no'}
          </span>
          <br />
          <span>
            Human verified: {user.human_verified ? 'yes' : 'no'}, email verified:{' '}
            {user.email_verified ? 'yes' : 'no'}{' '}
          </span>
          <br />
          <span>
            <Link to={{ pathname: '/posts', search: this.userLink('user') }}>See posts</Link>{' '}
            <Link to={{ pathname: '/catches', search: this.userLink('owner') }}>See catches</Link>
          </span>
        </div>
      </Flex>
    ) : (
      <span>Loading User #{userId}</span>
    );
  }

  private userLink(prefix: string): string {
    return mergeQueryParams('', {
      filter: stringFromFilter({
        [`${prefix}_id_eq`]: this.props.userId.toString(),
      }),
    });
  }
}

export default UserSummary;
