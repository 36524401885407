.link {
  color: var(--body-color);
  text-decoration: none;

  &:hover {
    color: lighten(var(--body-color), 30%);
  }
}

.separator {
  padding: 0 10px;
}
