import { Route, useRouteMatch } from 'react-router-dom';

import SidePane from '../../components/SidePane/SidePane';
import AttributeBoolean from '../../components/AttributeComponents/AttributeBoolean';
import AttributeID from '../../components/AttributeComponents/AttributeID';
import Edit from '../../components/Edit/Edit';
import Checkbox from '../../components/Edit/FormAttributes/Checkbox';
import { FormNumber, FormText } from '../../components/Edit/FormAttributes/FormAttribute';
import IFormHeader from '../../components/Edit/FormHeaderInterface';
import Flex from '../../components/Flex/Flex';
import IHeader from '../../components/List/HeadersInterface';
import ModelList from '../../components/ModelList/ModelList';
import Plan from '../../models/Subscriptions/Plan';

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    link: '/subscriptions/plans/',
    title: 'Id',
  },
  { title: 'External ID', attribute: 'external_id' },
  { title: 'Duration In Months', attribute: 'duration_in_months' },
  { title: 'Price', attribute: 'price' },
  { title: 'Trial Period Days', attribute: 'trial_period_days' },
  { title: 'Is Active?', attribute: 'active', component: AttributeBoolean },
];

const formHeaders: IFormHeader[] = [
  { title: 'Id', attribute: 'id', component: FormText },
  { title: 'External ID', attribute: 'external_id', component: FormText },
  {
    title: 'Duration In Months',
    attribute: 'duration_in_months',
    component: FormText,
  },
  { title: 'Price', attribute: 'price', component: FormText },
  {
    title: 'Trial Period Days',
    attribute: 'trial_period_days',
    component: FormNumber,
  },
  { title: 'Is Active?', attribute: 'active', component: Checkbox },
];

const EditPlan = (): JSX.Element => (
  <Edit model={Plan} headers={formHeaders} returnPath="/subscriptions/plans" noDeletion />
);

const Plans = (): JSX.Element => {
  const match = useRouteMatch();
  return (
    <Flex>
      <ModelList headers={headers} model={Plan} />
      <SidePane showOnPath={`${match.path}/:slug`}>
        <Route path={`${match.path}/:id`} render={EditPlan} />
      </SidePane>
    </Flex>
  );
};

export default Plans;
