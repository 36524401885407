.wrapper {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 8px;
  padding: 6px 12px;
}

.title {
  font-size: 0.9rem;
  margin-bottom: 4px;
}

.copybutton button {
  @apply bg-primary;
  border: none;
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  padding: 6px 8px;
  text-transform: uppercase;
}

.copybutton button:hover {
  cursor: pointer;
}

.copybutton.copied button {
  background-color: #bfbfbf;
}
