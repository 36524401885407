import styles from './Flex.module.css';

interface IProps {
  vertical?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Flex: React.FC<IProps> = ({ children, vertical = false, className = '' }) => (
  <div className={`${styles.flex} ${className} ${vertical ? styles.flexVertical : ''}`}>
    {children}
  </div>
);

export default Flex;
