import { Link } from 'react-router-dom';

import styles from './AttributeID.module.css';

const WIDTH = 1000;

const AttributeId = ({
  value,
  link,
  search,
}: {
  value: any;
  link: string;
  search: string;
}): JSX.Element => {
  const pathname = `${link}${value}`;
  const handleClick = (): void => {
    const panelWidth = localStorage.getItem('split-panel');
    if (panelWidth && parseInt(panelWidth, 10) > WIDTH) {
      localStorage.setItem('split-panel', '500');
    }
  };

  return link ? (
    <Link className={styles.link} to={{ pathname, search }} onClick={handleClick}>
      {value} <i className="fas fa-pen fa-xs" />
    </Link>
  ) : (
    value
  );
};

export default AttributeId;
