import { gql } from 'graphql-tag';

export const setVideoStatusAction = gql`
  mutation editVideoMutation($input: EditVideoMutationInput!) {
    editVideo(input: $input) {
      video {
        status
      }
      userErrors {
        path
        message
      }
    }
  }
`;
