.detailsLink {
  margin-left: 5px;
}

.flex {
  align-items: center;
  display: flex;
}

.grow {
  flex: 1;
}
