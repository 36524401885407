/* eslint-disable jsx-a11y/control-has-associated-label */
import { Component } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';

import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import AttributeID from '../../components/AttributeComponents/AttributeID';
import AttributeLink from '../../components/AttributeComponents/AttributeLink';
import {
  AttributeWithTitle,
  FormDateTime,
  FormText,
} from '../../components/Edit/FormAttributes/FormAttribute';
import DateFilterInput from '../../components/FilterInputs/DateFilterInput';
import SelectFilterInput from '../../components/FilterInputs/SelectFilterInput';

import Edit from '../../components/Edit/Edit';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import Flex from '../../components/Flex/Flex';
import IHeader from '../../components/List/HeadersInterface';
import ModelList from '../../components/ModelList/ModelList';
import SidePane from '../../components/SidePane/SidePane';
import UserSummary from '../../components/UserSummary/UserSummary';
import UserReport from '../../models/UserReport';
import User from '../../models/User';

import Badge from '../../components/Badge/Badge';
import PolymorphicLink from '../../components/PolymorphicLink';
import ReportProgress from './ReportProgress';
import SubjectReports from './SubjectReports';

const editUserReport = (): JSX.Element => (
  <Edit model={UserReport} noEdit noDeletion headers={formHeaders} returnPath="/user_reports" />
);

const SubjectLink = PolymorphicLink('subject');

const subjectSummary = ({ entry }: { entry: UserReport }): JSX.Element => {
  switch (entry.subject_type) {
    case 'User':
      return <UserSummary userId={entry.subject_id} />;
    default:
      return <SubjectLink entry={entry} />;
  }
};

const subjectFilter = (props: any): JSX.Element => (
  <SelectFilterInput {...props}>
    <option />
    <option value="Op::Post">Posts</option>
    <option value="Catch">Catches</option>
    <option value="User">Users</option>
  </SelectFilterInput>
);

const statusFilter = (props: any): JSX.Element => (
  <SelectFilterInput {...props}>
    <option />
    <option value="new">New</option>
    <option value="in_progress">In Progress</option>
    <option value="reopen">Reopened</option>
    <option value="unhandled">Unhandled</option>
    <option value="resolved">Resolved</option>
  </SelectFilterInput>
);

const UserReportOnUser = ({ entry }: { entry: User }): JSX.Element => (
  <SubjectReports entry={{ subjectType: 'User', subjectId: entry.id }} />
);

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    filterable: true,
    filterOptions: { filterType: 'eq' },
    link: '/user_reports/',
    title: 'ID',
  },
  {
    attribute: 'created_at',
    component: AttributeDate,
    filterable: true,
    filterOptions: { component: DateFilterInput },
    title: 'Created At',
  },
  {
    attribute: 'user',
    component: AttributeLink,
    filterable: true,
    filterOptions: { filterKey: 'user_nickname' },
    link: '/users/',
    title: 'Reporter',
  },
  { title: 'Reason', attribute: 'reason', filterable: true },
  {
    title: 'Report count',
    attribute: 'subject_report_count',
    filterable: false,
  },
  {
    attribute: 'subject',
    component: SubjectLink,
    filterable: true,
    filterOptions: {
      component: subjectFilter,
      filterKey: 'subject_type',
      filterType: 'eq',
    },
    title: 'Subject',
  },
  {
    attribute: 'status',
    component: ReportProgress,
    filterable: true,
    filterOptions: {
      component: statusFilter,
      filterKey: 'status',
      filterType: 'eq',
    },
    title: 'Status',
  },
];

const formHeaders: IFormHeaders[] = [
  { title: 'Id', attribute: 'id', component: FormText },
  {
    title: 'Status',
    attribute: '',
    component: AttributeWithTitle(ReportProgress),
  },
  { title: 'Created at', attribute: 'created_at', component: FormDateTime },
  { title: 'Reason', attribute: 'reason', component: FormText },
  { title: 'Resolved at', attribute: 'resolved_at', component: FormDateTime },
  {
    title: 'Subject',
    attribute: 'subject',
    component: AttributeWithTitle(subjectSummary),
  },
  {
    title: 'All reports on this subject',
    attribute: '',
    component: AttributeWithTitle(UserReportOnUser),
  },
];

interface IState {
  newReports: string;
}

class UserReports extends Component<RouteComponentProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      newReports: '',
    };
  }

  public componentDidMount(): void {
    // Check how many reports are new.
    UserReport.list({
      filterBy: {
        status_cont: 'new',
      },
      page: 0,
      perPage: 100,
    }).then((entries) => {
      const numberOfNewReports = entries.length;
      const upperLimitOfDisplay = 99;

      if (numberOfNewReports > upperLimitOfDisplay) {
        this.setState({
          newReports: '99+',
        });
      } else {
        this.setState({
          newReports: numberOfNewReports,
        });
      }
    });
  }

  public render(): React.ReactElement {
    return (
      <>
        <div style={{ padding: '12px 24px 0 24px' }}>
          New reports: <Badge>{this.state.newReports}</Badge>
        </div>
        <Flex>
          <ModelList headers={headers} model={UserReport} />
          <SidePane showOnPath={`${this.props.match.path}/:slug`}>
            <Route path={`${this.props.match.path}/:id`} render={editUserReport} />
          </SidePane>
        </Flex>
      </>
    );
  }
}

export default withRouter(UserReports);
