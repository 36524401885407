import { Component } from 'react';

import styles from './Tabs.module.css';

interface IState {
  activeTab: number;
}

interface ITab {
  index: number;
  prop: any;
  title: string;
  Component: any;
}

interface IProps {
  tabs: ITab[];
}

class Tabs extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: 1,
    };
  }

  public render(): React.ReactElement {
    const { activeTab } = this.state;
    const { tabs } = this.props;
    // eslint-disable-next-line fp/no-let
    let tab;

    // eslint-disable-next-line fp/no-loops
    for (const item of tabs) {
      if (item.index === activeTab) {
        tab = item;
        break;
      }
    }

    const RenderComponent = tab && tab.Component ? tab.Component : null;

    return (
      <>
        <ul className={styles.tabs}>{this.renderTabs(tabs)}</ul>
        <div className={styles.content}>
          {RenderComponent ? <RenderComponent prop={tab && tab.prop} /> : null}
        </div>
      </>
    );
  }

  private readonly renderTabs = (tabs: ITab[]): JSX.Element[] | null => {
    const { activeTab } = this.state;

    if (tabs && tabs.length > 0) {
      return tabs.map((tab, index) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
        <li
          key={index}
          className={`${styles.tabItem} ${tab.index === activeTab ? styles.activeTab : ''}`}
          onClick={this.setTab.bind(this, tab.index)}
        >
          {tab.title}
        </li>
      ));
    }

    return null;
  };

  private readonly setTab = (index: number): void => {
    this.setState({ activeTab: index });
  };
}

export default Tabs;
