/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, FormikProps, withFormik } from 'formik';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import Details from '../../components/Details/Details';
import UploadFile from '../../components/UploadFile/UploadFile';
import DateFormat from '../../helpers/DateFormat/DateFormat';
import IPhoto from '../../models/Photo';
import Post from '../../models/Post';
import { IVideo } from '../../models/Video';

import styles from '../../components/Edit/Form.module.css';
import Button from '../../components/Clickables/Buttons/Button';

interface IFormValues {
  text?: string;
  title?: string;
  video?: IVideo;
  photos?: IPhoto[];
  type?: string;
  location?: string;
  date: string;
  newPhotos: File[];
}

const InnerEdit = (props: FormikProps<IFormValues>): JSX.Element => {
  const { isSubmitting } = props;

  return (
    <Details title="Create New Post">
      <Form id="new-form">
        <ul className={styles.list}>
          <li className={styles.list_row}>
            <label>Text</label>
            <Field className={styles.input} type="text" name="text" autoComplete="off" />
          </li>
          <li className={`${styles.upload} ${styles.list_row}`}>
            <label>Images</label>
            <UploadFile type="image" {...props} />
          </li>
          <li className={`${styles.upload} ${styles.list_row}`}>
            <label>Video</label>
            <UploadFile type="video" {...props} />
          </li>
          <li className={styles.list_row}>
            <label>Location</label>
            <Field type="text" name="location" />
          </li>
          <li className={styles.buttons}>
            <Button disabled={isSubmitting}>Create Post</Button>
          </li>
        </ul>
      </Form>
    </Details>
  );
};

const createPhotos = (photos: File[] | undefined, id: string): void => {
  if (!photos) {
    return;
  }

  const data = new FormData();
  photos.forEach((p: File) => data.append('images[]', p));
  Post.addImage(data, `/posts/${id}/images`);
};

const EditPostForm = withFormik<RouteComponentProps, IFormValues>({
  handleSubmit: (values, { props }) => {
    // what is going to happen when the form is submitted
    const post = { text: values.text };
    Post.create(post)
      .then((resp: any) => {
        createPhotos(values.newPhotos, resp.id);
        props.history.push(
          {
            pathname: `/posts/${resp.id}`,
          },
          {
            status: `Post #${resp.id} has been created`,
          },
        );
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  },
  mapPropsToValues: () =>
    // what values to be shown in the inputs
    ({
      date: getDateTime(),
      location: '',
      newPhotos: [],
      photos: undefined,
      text: '',
      title: '',
      type: '',
      video: undefined,
    }),
  enableReinitialize: true,
})(InnerEdit);

const getDateTime = (): string => DateFormat(new Date());

export default withRouter(EditPostForm);
