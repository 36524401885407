import React, { useState } from 'react';

import { FormProps } from '../../components/Edit/FormAttributes/FormAttribute';
import Button from '../../components/Clickables/Buttons/Button';

enum State {
  undeleted = 1,
  deleting,
  deleted,
}

function getButtonText(state: State): string {
  if (state === State.deleted) {
    return 'Cognito User deleted';
  }
  if (state === State.deleting) {
    return 'Deleting...';
  }
  return 'Delete Cognito User';
}

const CognitoDeleteButton = ({ entry }: FormProps): JSX.Element => {
  const [state, setState] = useState(State.undeleted);

  return (
    <div className="flex items-center justify-center px-1">
      <Button
        size="sm"
        variant="buttonDanger"
        onClick={() => {
          setState(State.deleting);
          entry
            .deleteCognitoUser()
            .then(() => setState(State.deleted))
            .catch((error: string) => {
              alert(error);
              setState(State.undeleted);
            });
        }}
        className="w-[160px]"
        disabled={state !== State.undeleted}
      >
        {getButtonText(state)}
      </Button>
    </div>
  );
};

export default CognitoDeleteButton;
