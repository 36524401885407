import reactMapboxGl from 'react-mapbox-gl';

const MAPBOX_TOKEN: string = (() => {
  const token = process.env.REACT_APP_MAPBOX_TOKEN;
  if (token) {
    return token;
  }

  throw new Error('Missing mapbox token');
})();

export const RawMap = reactMapboxGl({
  accessToken: MAPBOX_TOKEN,
  dragRotate: false,
});

interface Props {
  style?: string;
  [key: string]: any;
}

const InnerMap = ({
  style = 'mapbox://styles/fishbrain-dev/cjab6kel633dm2so9xp9nzn3u',
  ...rest
}: Props): JSX.Element => <RawMap {...rest} style={style} />;

export default InnerMap;
