import { ILocalization } from '../models/Species';

export const compareLocalizations = (a: ILocalization[], b: ILocalization[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }
  const sortedA = [...a].sort((x, y) => x.country_id.localeCompare(y.country_id));
  const sortedB = [...b].sort((x, y) => x.country_id.localeCompare(y.country_id));
  return sortedA.every((localization, index) => compareObjects(localization, sortedB[index]));
};

interface ObjectWithIndex {
  [key: string]: any;
}

export const compareObjects = (a: ObjectWithIndex, b: ObjectWithIndex): boolean =>
  Object.keys(a).length === Object.keys(b).length
    ? Object.keys(a).every((key) => a[key] === b[key])
    : false;

export const compareStrings = (a: string, b: string): boolean => a === b;
