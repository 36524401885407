import { Link } from 'react-router-dom';

import BaseModel from '../models/BaseModel';

interface IProps {
  entry: BaseModel;
}

interface Links {
  Catch: string;
  'Equipment::Review': string;
  'Op::Post': string;
  User: string;
  [key: string]: string;
}

interface Titles {
  'Equipment::Review': string;
  'Op::Post': string;
  [key: string]: string;
}

const links: Links = {
  Catch: '/catches',
  'Equipment::Review': '/equipment/reviews',
  'Op::Post': '/posts',
  User: '/users',
};

const titles: Titles = {
  'Equipment::Review': 'Equipment review',
  'Op::Post': 'Post',
};

const polymorphicLink = (key: string): (({ entry }: IProps) => JSX.Element) => {
  const typeKey = `${key}_type`;
  const idKey = `${key}_id`;

  // eslint-disable-next-line react/display-name
  return ({ entry }: IProps) =>
    links[entry[typeKey]] ? (
      <Link to={`${links[entry[typeKey]]}/${entry[idKey]}`}>
        {titles[entry[typeKey]] || entry[typeKey]} #{entry[idKey]}
      </Link>
    ) : (
      <span>
        {titles[entry[typeKey]] || entry[typeKey]} #{entry[idKey]}
      </span>
    );
};

export default polymorphicLink;
