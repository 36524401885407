import React, { useContext, useEffect, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';

import { Grid, TextField, Select, MenuItem, FormHelperText } from '@material-ui/core';

import Post from '../../models/Post';
import {
  GetPostAvailableActions_post_text,
  GetPostAvailableActions_post_video,
  GetPostAvailableActions_post_opImages_edges_node,
  GetPostAvailableActions_post,
} from '../../interfaces/graphql';
import { setTextStatusAction } from '../../mutations/PostText';
import { setImageStatusAction } from '../../mutations/PostImage';
import { setVideoStatusAction } from '../../mutations/PostVideo';

import { getPostAvailableActions } from '../../queries/Post';
import { paginatePostModerationTraces } from '../../queries/PostModerationTraces';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import { ModerationContext } from '../../contexts/ModerationContext';

export const StatusControllerComponentGenerator = <
  T extends
    | GetPostAvailableActions_post_text
    | GetPostAvailableActions_post_video
    | GetPostAvailableActions_post_opImages_edges_node,
>(
  entityExtractor: (post?: GetPostAvailableActions_post | null) => T | null,
  mutateStatus:
    | typeof setTextStatusAction
    | typeof setImageStatusAction
    | typeof setVideoStatusAction,
) => {
  const StatusControl = ({ entry, header }: { entry: Post; header: IFormHeaders }): JSX.Element => {
    const [reason, setReason] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);

    const { post } = useContext(ModerationContext);

    useEffect(() => {
      setStatus(entityExtractor(post)?.status || null);
    }, [post]);

    const statusEntity = entityExtractor(post);
    const [updateStatus, { loading }] = useMutation(mutateStatus, {
      onError: (error: ApolloError) => alert(error.message),
      refetchQueries: [
        { query: getPostAvailableActions, variables: { postId: Number(entry?.id) } },
        { query: paginatePostModerationTraces, variables: { postId: Number(entry?.id) } },
      ],
    });

    const style = status !== 'rejected' ? {} : { backgroundColor: 'red', color: 'white' };
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormHelperText>{header.title}</FormHelperText>
            <Select
              value={status}
              fullWidth
              disabled={loading}
              variant="outlined"
              onChange={(event) => {
                const { value } = event.target;
                if (!value || !statusEntity) {
                  return;
                }

                const variables = {
                  input: {
                    id: String(statusEntity.id),
                    attributes: {
                      moderationAction: value,
                      moderationReason: reason,
                    },
                  },
                };
                updateStatus({ variables });
              }}
              style={style}
            >
              {status ? (
                <MenuItem disabled value={status}>
                  {status}
                </MenuItem>
              ) : null}
              {statusEntity?.availableActions.map((action) => (
                <MenuItem key={action} value={action}>
                  {action}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <FormHelperText>{`${header.title} reason`}</FormHelperText>
            <TextField
              fullWidth
              label="Reason"
              variant="outlined"
              onChange={(event) => {
                setReason(event.currentTarget.value);
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return StatusControl;
};

export const TextStatusControl =
  StatusControllerComponentGenerator<GetPostAvailableActions_post_text>(
    (post) => post?.text || null,
    setTextStatusAction,
  );

export const VideoStatusControl =
  StatusControllerComponentGenerator<GetPostAvailableActions_post_video>(
    (post) => post?.video || null,
    setVideoStatusAction,
  );
