import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'ts-get';

import { FieldProps } from 'formik';

import styles from './Metadata.module.css';

import { getWatersMetadata } from '../../queries/MetaData';
import {
  GetWatersMetadata,
  GetWatersMetadataVariables,
  GetWatersMetadata_fishingWaterMetadata_edges_node,
} from '../../interfaces/graphql';
import Select from '../Input/Select';
import { FormProps } from '../Edit/FormAttributes/FormAttribute';

interface Metadata {
  group: { id: string; name: string };
  id: string;
  name: string;
}

export const Metadata = ({
  header,
  setFieldValue,
  setFieldTouched,
  metadata,
  valuesField = 'metadataIds',
  valuesToAddField,
  valuesToRemoveField,
  groupFilter,
  disable = false,
  className,
}: FieldProps &
  FormProps & {
    metadata: Metadata[] | undefined;
    groupFilter: string[] | undefined;
    disable: boolean;
    className?: string;
    valuesField: string | undefined;
    valuesToAddField: string | undefined;
    valuesToRemoveField: string | undefined;
  }): JSX.Element => {
  const initialMetadata = metadata
    ? metadata.reduce(
        (groups: any, item: Metadata) => ({
          ...groups,
          [item.group.id]: item.id,
        }),
        {},
      )
    : {};

  const METADATA_UNSET_VALUE = '- not set -';

  const [metadataState, setMetadataState] = useState<any>(initialMetadata);

  const { data, loading } = useQuery<GetWatersMetadata, GetWatersMetadataVariables>(
    getWatersMetadata,
    { skip: metadata === undefined },
  );

  if (!metadata) {
    return <></>;
  }

  if (loading) {
    return <>loading...</>;
  }

  const allMetadata = get(data, (it) => it.fishingWaterMetadata.edges, [])
    .filter((edge) => !groupFilter || groupFilter.includes(edge.node.group.id))
    .map(({ node }: { node: GetWatersMetadata_fishingWaterMetadata_edges_node }) => node)
    .reduce(
      (groups: any, item) => ({
        ...groups,
        [item.group.id]: {
          entries: [...(groups[item.group.id]?.entries || []), item],
          groupName: item.group.name,
        },
      }),
      {},
    );

  const onMetadataChange = (id: string, event: React.SyntheticEvent<HTMLInputElement>) => {
    const newState = { ...metadataState, ...{ [id]: event.currentTarget.value } };

    const groupIds = Object.keys(newState);
    const valuesToAdd: string[] = [];
    const valuesToRemove: string[] = [];
    const values: string[] = [];
    groupIds.forEach((groupId) => {
      if (initialMetadata[groupId] === undefined) {
        valuesToAdd.push(newState[groupId]);
      } else if (initialMetadata[groupId] !== newState[groupId]) {
        if (newState[groupId] !== METADATA_UNSET_VALUE) {
          valuesToAdd.push(newState[groupId]);
        }
        valuesToRemove.push(initialMetadata[groupId]);
      }
      values.push(newState[groupId]);
    });

    if (values.length && valuesField) {
      setFieldTouched(valuesField, true);
      setFieldValue(valuesField, values);
    }

    if (valuesToAdd.length && valuesToAddField) {
      setFieldTouched(valuesToAddField, true);
      setFieldValue(valuesToAddField, valuesToAdd);
    }

    if (valuesToRemove.length && valuesToRemoveField) {
      setFieldTouched(valuesToRemoveField, true);
      setFieldValue(valuesToRemoveField, valuesToRemove);
    }

    setMetadataState(newState);
  };

  return (
    <div className={`${className || ''} ${styles.metadata} pb-4 flex flex-col justify-center`}>
      {header.title && <div className="py-4">{header.title}</div>}
      {allMetadata &&
        Object.keys(allMetadata).map((id) => (
          <div className="py-2" key={id}>
            <div>{allMetadata[id].groupName}</div>
            <Select
              name={allMetadata[id].groupName}
              id={allMetadata[id].groupName}
              onChange={(event: React.SyntheticEvent<HTMLInputElement>) =>
                onMetadataChange(id, event)
              }
              value={metadataState[id]}
              disabled={disable}
            >
              <option>{METADATA_UNSET_VALUE}</option>
              {allMetadata[id].entries.map(
                (item: GetWatersMetadata_fishingWaterMetadata_edges_node) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ),
              )}
            </Select>
          </div>
        ))}
    </div>
  );
};
