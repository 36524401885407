import { gql } from 'graphql-tag';

export const deleteGroup = gql`
  mutation deleteGroup($id: String!) {
    deleteRecordByExternalId(input: { externalId: $id, type: GROUP }) {
      userErrors {
        code
        message
        path
      }
    }
  }
`;
