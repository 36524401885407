import { DatePicker } from 'react-date-picker';

import { FormProps } from './FormAttribute';

import 'react-date-picker/dist/DatePicker.css';
import styles from './FormCustomDate.module.css';

const FormCustomDate = ({
  header,
  entry,
  setFieldValue,
  setFieldTouched,
  value,
}: FormProps): JSX.Element => {
  const onChange = (newValue: any): void => {
    setFieldValue(
      header.attribute,
      newValue
        ? new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()))
        : null,
    );
    setFieldTouched(header.attribute, true);
  };

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{header.title}</label>
      <DatePicker
        className={styles.formDatePicker}
        name={header.attribute}
        value={value ? new Date(value) : undefined}
        onChange={onChange}
        disabled={entry.isReadOnly(header.attribute)}
      />
    </div>
  );
};

export default FormCustomDate;
