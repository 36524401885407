import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { get } from 'ts-get';

import Product from '../../models/Equipment/Product';
import { ListProducts, ListProductsVariables } from '../../interfaces/graphql';
import editQuery from '../../queries/Product';
import listProductsQuery from '../../queries/Products';

import AttributeID from '../../components/AttributeComponents/AttributeID';
import { FormText } from '../../components/Edit/FormAttributes/FormAttribute';
import GqlEdit from '../../components/Edit/GqlEdit';
import GqlModelList from '../../components/ModelList/GqlModelList';
import FormHeaders from '../../components/Edit/FormHeaderInterface';
import Header from '../../components/List/HeadersInterface';
import TableWithSingleLayout from '../../components/Layout/TableWithSingleLayout';

const headers: Header[] = [
  {
    title: 'Id',
    attribute: 'id',
    component: AttributeID,
    link: '/equipment/products/',
  },
  { title: 'Name', attribute: 'name' },
  { title: 'Brand', attribute: 'brand', subAttr: ['name'] },
  { title: 'Category', attribute: 'category', subAttr: ['title'] },
  { title: 'Reviews', attribute: 'reviews', subAttr: ['totalCount'] },
  { title: 'Average Rating', attribute: 'reviews', subAttr: ['totalAverage'] },
];

const bookmarks = [
  {
    path: '/equipment/products?filter=orderByRatings',
    title: 'Most rated',
  },
  {
    path: '/equipment/products?filter=orderByRatingsInShop',
    title: 'Most rated (in shop)',
  },
  {
    path: '/equipment/products?filter=all',
    title: 'All',
  },
];

const formHeaders: FormHeaders[] = [
  { title: 'Id', attribute: 'id', component: FormText },
  { title: 'Name', attribute: 'name', component: FormText },
];

const EditProduct = (): JSX.Element => (
  <GqlEdit headers={formHeaders} model={Product} editQuery={editQuery} validationSchema={false} />
);

const Products = () => {
  const location = useLocation();
  const filterString = new URLSearchParams(location.search).get('filter') || 'all';

  // eslint-disable-next-line fp/no-let
  let variables = { filters: {} };
  if (filterString === 'orderByRatings') {
    variables = { filters: { orderByRatings: true } };
  } else if (filterString === 'orderByRatingsInShop') {
    variables = { filters: { orderByRatings: true, inShop: true } };
  }

  const onLoadMore = (fetchMore: QueryResult['fetchMore'], products: any) => {
    fetchMore({
      updateQuery: (previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
        const newEdges = fetchMoreResult.products.edges;
        const { pageInfo } = fetchMoreResult.products;

        return {
          products: {
            pageInfo,
            __typename: previousResult.products.__typename,
            edges: [...previousResult.products.edges, ...newEdges],
          },
        };
      },
      variables: {
        cursor: products.pageInfo.endCursor,
      },
    });
  };

  return (
    <Query<ListProducts, ListProductsVariables>
      query={listProductsQuery}
      variables={variables}
      fetchPolicy="network-only"
    >
      {({ loading, error, data, fetchMore }) => (
        <TableWithSingleLayout singleModelComponent={<EditProduct />} modelType="Product">
          <GqlModelList
            bookmarks={bookmarks}
            isLoading={loading}
            error={error}
            entries={get(data, (d) => d.products)}
            headers={headers}
            path={location.pathname}
            onLoadMore={() =>
              onLoadMore(
                fetchMore,
                get(data, (d) => d.products),
              )
            }
          />
        </TableWithSingleLayout>
      )}
    </Query>
  );
};

export default Products;
