import { get, put, putWithImg } from '../api/rutilus';
import BaseModel from './BaseModel';
import CatchComment, { ICatchComment } from './CatchComment';
import { ICatchPosition } from './CatchPosition';
import FishingWater from './FishingWater';
import Species, { ISpecies } from './Species';
import User, { IUser } from './User';

export interface ICatch {
  readonly id: number;
  readonly post_external_id: string;
  species?: ISpecies;
  owner: IUser;
  caught_at_gmt: string;
  deep_link: string;
  fishing_water_id: number;
  fishing_water_name: string;
  photos?: [
    {
      photo?: {
        base_url?: string;
      };
    },
  ];
}

class Catch extends BaseModel {
  public static endpoint = '/catches';

  public static fromAttributes(attributes: ICatch): Catch {
    return new Catch(attributes);
  }

  public static async addImage(params: any, endpoint: string): Promise<void> {
    return new Promise((resolve, reject) => {
      putWithImg(endpoint, params).then((resp) => (resp.ok ? resolve() : reject()));
    });
  }

  public static get readOnly(): string[] {
    return BaseModel.readOnly.concat(['post_external_id']);
  }

  public ['constructor']: typeof Catch;

  protected attributes: ICatch;

  constructor(attributes: ICatch) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public async update(params: { [key: string]: any }): Promise<void> {
    const { photos, ...otherParams } = params;
    const updateActions: Promise<void>[] = [];
    if (photos && photos.length) {
      updateActions.push(this.updatePhotos(photos));
    }
    if (otherParams && Object.keys(otherParams).length) {
      updateActions.push(
        new Promise((resolve, reject) => {
          put(this.endpoint, otherParams)
            .then(async (resp) => resp.json())
            .then((res) => (res.errors ? reject(res.errors.join(', ')) : resolve()));
        }),
      );
    }
    return Promise.all(updateActions).then(() => undefined);
  }

  public async updatePhotos(photos: File[]): Promise<void> {
    const data = new FormData();
    photos.forEach((photo: File) => data.append('photos_to_create[][photo]', photo));
    return Catch.addImage(data, `/catches/${this.id}`);
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get owner(): User {
    return User.fromAttributes(this.attributes.owner);
  }

  public get imageUrl(): string {
    return this.attributes.photos &&
      this.attributes.photos.length > 0 &&
      this.attributes.photos[0].photo &&
      this.attributes.photos[0].photo.base_url
      ? this.attributes.photos[0].photo.base_url
      : '';
  }

  public get species(): Species | undefined {
    return this.attributes.species ? Species.fromAttributes(this.attributes.species) : undefined;
  }

  public get species_id(): number | undefined {
    const { species } = this.attributes;
    return species && species.id;
  }

  public get fishing_water(): FishingWater | unknown {
    const { fishing_water_id, fishing_water_name } = this.attributes;
    // since there is no Fishing water object coming back from rutilus,
    // create one with the id and name, so it can be used in dropdown
    if (fishing_water_id && fishing_water_name) {
      return FishingWater.fromAttributes({
        catches_counter: 0,
        country: {
          country_name: '',
        },
        id: fishing_water_id,
        externalId: 'xxx',
        latitude: 0,
        longitude: 0,
        name: fishing_water_name,
        region: {
          local_name: '',
        },
        waterType: '3',
      });
    }
    return {};
  }

  public get identifier(): string {
    return this.attributes.id.toString();
  }

  public static get modelType(): string {
    return 'Catch';
  }

  public get modelType(): string {
    return 'Catch';
  }

  protected get class(): typeof Catch {
    return Catch;
  }

  public async fetchCatchComments(): Promise<CatchComment[]> {
    return get(`${this.endpoint}/comments`)
      .then(async (response) => (response.ok ? response.json() : alert(response)))
      .then((jsonResponse: ICatchComment[] = []) =>
        jsonResponse.map((attributes) => CatchComment.fromAttributes(attributes)),
      );
  }

  public async fetchCatchPosition(): Promise<ICatchPosition> {
    return get(`${this.endpoint}/private`)
      .then(async (response) => (response.ok ? response.json() : alert(response)))
      .then((jsonResponse: ICatchPosition) => jsonResponse);
  }

  public async clearPosition(): Promise<void> {
    return put(`${this.endpoint}`, { latitude: '', longitude: '' }).then(async (response) =>
      response.ok ? response.json() : alert(response),
    );
  }
}

export default Catch;
