import { gql } from 'graphql-tag';

export default gql`
  query FindProduct($id: Int!) {
    entry: product(id: $id) {
      id
      name
      __typename
    }
  }
`;
