import { formatRelative } from 'date-fns';

const relativeDate = ({
  date,
  compare = new Date(),
}: {
  date: Date;
  compare?: Date;
}): JSX.Element => <abbr title={date.toJSON()}>{formatRelative(date, compare)}</abbr>;

export default relativeDate;
