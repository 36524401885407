import GqlBaseModel from './GqlBaseModel';

export interface IFishingWater {
  readonly id: number;
  readonly externalId: string;
  name: string;
  waterType: string;
  longitude: number;
  latitude: number;
  readonly catches_counter: number;
  readonly country: {
    country_name: string;
  };
  readonly region: {
    local_name: string;
  };
  hasLocalGuideInfo: boolean;
}

class FishingWater extends GqlBaseModel {
  public static readOnly: string[] = ['id', 'externalId', 'created_at', 'updated_at'];

  public static fromAttributes(attributes: IFishingWater): FishingWater {
    return new FishingWater(attributes);
  }

  public ['constructor']: typeof FishingWater;

  protected attributes: IFishingWater;

  constructor(attributes: IFishingWater) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get identifier(): string {
    if (this.attributes) {
      return this.attributes && this.attributes.name
        ? this.attributes.name
        : this.attributes.id.toString();
    }

    return '';
  }

  public get modelType(): string {
    return 'Fishing Water';
  }

  public static get modelType(): string {
    return 'Fishing Water';
  }
}

export default FishingWater;
