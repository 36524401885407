import { get } from '../../api/rutilus';
import BaseModel, { IListOptions } from '../BaseModel';

import { buildFilterParams, mergeSearchParams } from '../../helpers/urlhelpers';

export interface IPlan {
  readonly id: number;
  readonly external_id: string;
  readonly duration_in_months: number;
  readonly price: string;
  trial_period_days: number | void;
  active: boolean;
}

class Plan extends BaseModel {
  public static endpoint = '/premium/web_plans';

  public static readOnly: string[] = ['id', 'external_id', 'duration_in_months', 'price'];

  public static fromAttributes(attributes: IPlan): Plan {
    return new Plan(attributes);
  }

  public static async list(
    { page, perPage, sortBy, filterBy, searchQuery }: IListOptions = {
      page: 0,
      perPage: 10,
    },
  ): Promise<unknown> {
    // eslint-disable-next-line no-console
    return new Promise(async (resolve, reject = console.error) => {
      const filterParams = buildFilterParams(sortBy, filterBy, this.filterRoot);
      const params = {
        page,
        per_page: perPage,
        ...filterParams,
        ...(searchQuery ? { s: searchQuery } : {}),
      };
      const searchParams = mergeSearchParams(this.fetchParameters, params);
      return get(`${this.endpoint}?${searchParams.toString()}`)
        .then(async (resp) => (resp.ok ? resp.json() : reject(resp)))
        .then(resolve, reject);
    });
  }

  public ['constructor']: typeof Plan;

  protected attributes: IPlan;

  constructor(attributes: IPlan) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get identifier(): string {
    return this.attributes.external_id
      ? this.attributes.external_id
      : this.attributes.id.toString();
  }

  public static get modelType(): string {
    return 'Subscription Plan';
  }

  public get modelType(): string {
    return 'Subscription Plan';
  }

  public get defaultEndpoint(): string {
    return '/premium/web_plans';
  }

  public isReadOnly(attribute: string): boolean {
    return Plan.readOnly.includes(attribute);
  }

  protected get class(): typeof Plan {
    return Plan;
  }
}

export default Plan;
