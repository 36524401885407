import { FieldProps } from 'formik';

import styles from '../../../components/Edit/Form.module.css';
import { FormProps } from '../../../components/Edit/FormAttributes/FormAttribute';
import {
  GetUserSuggestedChange_userSuggestedChange_attachments,
  GetUserSuggestedChange_userSuggestedChange_attachments_edges_node,
} from '../../../interfaces/graphql';
import { getNodesFromConnection } from '../../../helpers/graphql';
import fileIcon from '../assets/file.svg';

const SuggestionAttachments = ({
  header,
  values,
}: FieldProps &
  FormProps<{
    attachments: GetUserSuggestedChange_userSuggestedChange_attachments;
  }>): JSX.Element => {
  const attachments = getNodesFromConnection(values?.attachments);
  if (attachments.length <= 0) {
    return <></>;
  }
  return (
    <div className={styles.list_row}>
      <h2>{header.title}</h2>
      <div className="flex justify-start items-center gap-4 flex-wrap">
        {attachments &&
          attachments.map(
            (
              attachment: GetUserSuggestedChange_userSuggestedChange_attachments_edges_node,
              index,
            ) => (
              <div className="w-20 h-20" key={index}>
                <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={attachment.contentType.startsWith('image') ? attachment.url : fileIcon}
                    width={80}
                    className="h-20 w-20 object-cover"
                    alt=""
                  />
                </a>
              </div>
            ),
          )}
      </div>
    </div>
  );
};

export default SuggestionAttachments;
