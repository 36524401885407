import { useQuery, QueryResult } from '@apollo/client';
import { useLocation, Route, useRouteMatch } from 'react-router-dom';
import { get } from 'ts-get';
import queryString from 'query-string';

import Page from '../../models/Page';
import { GetPages, GetPagesVariables, RolesEnum } from '../../interfaces/graphql';
import {
  paginatePageAmbassadors,
  paginatePageAdmins,
  paginatePagePublishers,
  getPages,
  getPage,
} from '../../queries/Pages';

import { updatePageMutation } from '../../mutations/Pages/UpdatePage';

import AttributeID from '../../components/AttributeComponents/AttributeID';
import { FormText, FormTextarea } from '../../components/Edit/FormAttributes/FormAttribute';
import GqlEdit from '../../components/Edit/GqlEdit';
import GraphqlDropzoneImage, {
  Props as GraphqlDropzoneImageProps,
} from '../../components/Image/GraphqlDropzoneImage';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import IHeader from '../../components/List/HeadersInterface';

import Checkbox from '../../components/Edit/FormAttributes/Checkbox';
import FormDate from '../../components/Edit/FormAttributes/FormDate';
import AttributeBoolean from '../../components/AttributeComponents/AttributeBoolean';
import ViewStaffListComponent from './ViewStaffListComponent';
import AddStaffListComponent from './AddStaffListComponent';
import { IProps as AttributeComponentProps } from '../../components/AttributeComponents/AttributeComponent';
import GqlModelList from '../../components/ModelList/GqlModelList';
import Flex from '../../components/Flex/Flex';
import SidePane from '../../components/SidePane/SidePane';

const headers: IHeader[] = [
  {
    attribute: 'id',
    component: AttributeID,
    link: '/pages/',
    title: 'Id',
  },
  {
    attribute: 'externalId',
    component: AttributeID,
    title: 'ExternalId',
  },
  {
    attribute: 'name',
    title: 'Name',
    component: AttributeID,
    filterable: true,
  },
  {
    attribute: 'published',
    title: 'Publsihed?',
    component: AttributeBoolean,
  },
];

const wrapAndRename = (signedId: string) => ({ signedId });
const formHeaders: IFormHeaders[] = [
  {
    title: '',
    attribute: 'ids',
    row: [
      { title: 'Id', attribute: 'id', component: FormText, readonly: true },
      { title: 'ExternalId', attribute: 'externalId', component: FormText, readonly: true },
    ],
  },
  {
    title: '',
    attribute: 'published',
    row: [
      { title: 'Published?', attribute: 'published', component: Checkbox },
      { title: 'Created at', attribute: 'createdAt', component: FormDate },
    ],
  },
  { title: 'Name', attribute: 'name', component: FormText, readonly: true },
  { title: 'Description', attribute: 'description', component: FormTextarea },
  {
    title: 'Logo',
    attribute: 'logo',
    component: (props: GraphqlDropzoneImageProps) => (
      <GraphqlDropzoneImage {...props} destroy={() => null} />
    ),
    beforeSave: wrapAndRename,
  },
  {
    title: 'Cover Image',
    attribute: 'coverImages',
    component: (props: GraphqlDropzoneImageProps) => (
      <GraphqlDropzoneImage {...props} destroy={() => null} />
    ),
    beforeSave: wrapAndRename,
  },
  {
    title: 'Ambassadors',
    attribute: 'ambassadors',
    component: (props: AttributeComponentProps) => (
      <ViewStaffListComponent
        pageExternalId={props.entry.attributes.externalId}
        staffRole={RolesEnum.AMBASSADOR}
        title="Ambassadors"
        users={props.value}
        onLoadMore={onLoadMoreStuff}
        loadQuery={paginatePageAmbassadors}
      />
    ),
  },
  {
    title: 'Publishers',
    attribute: 'publishers',
    component: (props: AttributeComponentProps) => (
      <ViewStaffListComponent
        pageExternalId={props.entry.attributes.externalId}
        staffRole={RolesEnum.PUBLISHER}
        title="Publishers"
        users={props.value}
        onLoadMore={onLoadMoreStuff}
        loadQuery={paginatePagePublishers}
      />
    ),
  },
  {
    title: 'Admins',
    attribute: 'admins',
    component: (props: AttributeComponentProps) => (
      <ViewStaffListComponent
        pageExternalId={props.entry.attributes.externalId}
        staffRole={RolesEnum.ADMIN}
        title="Admins"
        users={props.value}
        onLoadMore={onLoadMoreStuff}
        loadQuery={paginatePageAdmins}
      />
    ),
  },
  {
    title: 'Add new staff',
    attribute: 'externalId',
    component: (props: AttributeComponentProps) => (
      <AddStaffListComponent pageExternalId={props.value} />
    ),
  },
];

interface UpdatedPage {
  updatePage: { userErrors: { message: string }[] };
}

const EditPage = () => (
  <GqlEdit
    model={Page}
    headers={formHeaders}
    editQuery={getPage}
    mutation={updatePageMutation}
    deletion={undefined}
    afterEdit={(data: UpdatedPage) => {
      const errors = get(data, (d) => d.updatePage.userErrors, []);
      if (errors.length) {
        alert(errors.map((error) => error.message).join(', '));
      }
    }}
  />
);

const onLoadMoreStuff = (fetchMore: QueryResult['fetchMore'], page: any, externalId: string) => {
  fetchMore({
    updateQuery: (_previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
      const result = fetchMoreResult.entry.stuff;
      return {
        entry: {
          stuff: {
            pageInfo: result.pageInfo,
            __typename: page.__typename,
            edges: [...page.edges, ...result.edges],
          },
          __typename: 'Qe',
        },
      };
    },
    variables: {
      cursor: page.pageInfo.endCursor,
      id: externalId,
    },
  });
};

const onLoadMore = (fetchMore: QueryResult['fetchMore'], pages: any) => {
  fetchMore({
    updateQuery: (previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
      const newEdges = fetchMoreResult.pages.edges;
      const { pageInfo } = fetchMoreResult.pages;

      return {
        pages: {
          pageInfo,
          __typename: previousResult.pages.__typename,
          edges: [...previousResult.pages.edges, ...newEdges],
        },
      };
    },
    variables: {
      cursor: pages.pageInfo.endCursor,
    },
  });
};

const Pages = (): JSX.Element => {
  const { pathname, search } = useLocation();
  const match = useRouteMatch();
  const { 'filter.name__start': containingName } = queryString.parse(search);

  const variables =
    containingName && containingName.length && typeof containingName === 'string'
      ? { filters: { containingName } }
      : undefined;
  const { loading, error, data, fetchMore } = useQuery<GetPages, GetPagesVariables>(getPages, {
    fetchPolicy: 'network-only',
    variables,
  });

  return (
    <Flex>
      <GqlModelList
        isLoading={loading}
        error={error}
        entries={get(data, (d) => d.pages)}
        headers={headers}
        path={pathname}
        onLoadMore={() => onLoadMore(fetchMore, data && data.pages)}
      />
      <SidePane showOnPath={`${match.path}/:slugs`}>
        <Route path={`${match.path}/:id`} render={() => <EditPage />} />
      </SidePane>
    </Flex>
  );
};

export default Pages;
