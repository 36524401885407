.container {
  border-bottom: 1px solid #9b9898;
  display: grid;
  grid-template-columns: auto 3fr;
  margin: 5px 0;
}

.avatar {
  align-self: center;
  grid-column: 1;
  grid-row: 1 / 3;
  height: 32px;
  width: 32px;
}

.text {
  font-size: 12px;
  grid-column: 2;
  grid-row: 2;
  margin: 5px;
}

.info {
  color: #9b9898;
  display: flex;
  font-size: 10px;
  grid-column: 2;
}

.info p {
  margin: 5px 0;
  padding: 0 4px;
}
