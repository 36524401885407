import { useQuery } from '@apollo/client';

import { userStats as query } from '../../queries/User';
import { userStats, userStatsVariables } from '../../interfaces/graphql';

import User from '../../models/User';
import Fieldset from '../../components/Fieldset/Fieldset';
import Flex from '../../components/Flex/Flex';

const UserStats = ({ entry }: { entry: User }): JSX.Element => {
  const { loading, error, data } = useQuery<userStats, userStatsVariables>(query, {
    variables: { external_id: entry.external_id },
  });

  if (loading || !data) {
    return (
      <Flex>
        <Fieldset id="e" label="Catches" inputProps={{ readOnly: true, placeholder: 'Loading' }} />
        <Fieldset
          id="e"
          label="Followers"
          inputProps={{ readOnly: true, placeholder: 'Loading' }}
        />
        <Fieldset
          id="e"
          label="Following"
          inputProps={{ readOnly: true, placeholder: 'Loading' }}
        />
      </Flex>
    );
  }

  if (error) {
    throw new Error('Data not found.');
  }

  return (
    <Flex>
      <Fieldset
        label="Catches"
        inputProps={{ readOnly: true, value: data.user.catches.totalCount, type: 'number' }}
      />
      <Fieldset
        label="Followers"
        inputProps={{ readOnly: true, value: data.user.followers.totalCount, type: 'number' }}
      />
      <Fieldset
        label="Following"
        inputProps={{ readOnly: true, value: data.user.followedUsers.totalCount, type: 'number' }}
      />
    </Flex>
  );
};

export default UserStats;
