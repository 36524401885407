import { get, post, postWithImg, put, putWithImg } from '../api/rutilus';
import { compareObjects } from '../helpers/comparators';
import { IImageSize } from './Image';
import BaseModel, { IBaseType } from './BaseModel';

interface SpeciesImage {
  readonly sizes: IImageSize[];
  readonly type: string;
}

export interface ILocalization {
  country_code: string;
  country_id: string;
  country_name: string;
  fish_id: number;
  id: string;
  local_name: string;
  type: 'localized_species_name';
}

export interface ISpecies extends IBaseType {
  fish_reference_link: string;
  fish_reference_name: string;
  followers_count: number;
  image_copyright_expiry_date: string | null;
  image_copyright_status: string | null;
  image_attribution_type: string | null;
  image_attribution_text: string | null;
  image_original_url: string | null;
  image_reference_name: string;
  image_publisher: string | null;
  image_comment: string | null;
  k_index: number;
  description: string | null;
  common_length: number | null;
  common_weight: number | null;
  max_age: number | null;
  max_length: number | null;
  max_weight: number | null;
  max_common_depth: number | null;
  min_common_depth: number | null;
  iucn_status: string | null;
  water_type: number[] | null;
  threat_to_humans: number[] | null;
  localized_species_names?: ILocalization[];
  external_id: string;
  name: string;
  photo: SpeciesImage;
  species: string;
}

const LOCALIZED_NAMES_ENDPOINT = '/localized_species_names';

const BASIC_FIELDS = [
  'name',
  'species',
  'fishbase_id',
  'fish_reference_link',
  'fish_reference_name',
  'fishrules_id',
  'sealifebase_id',
  'image_reference_name',
  'image_publisher',
  'image_comment',
  'image_copyright_expiry_date',
  'image_copyright_status',
  'image_attribution_type',
  'image_original_url',
  'description',
  'max_length',
  'max_weight',
  'max_common_depth',
  'min_common_depth',
  'iucn_status',
];

export const getChangedLocalizations = (
  newValues: ILocalization[],
  originalValues: ILocalization[],
): ILocalization[] =>
  newValues.filter((newValue) => {
    const originalValue = originalValues.find((v) => v.country_code === newValue.country_code);
    return originalValue !== undefined ? !compareObjects(newValue, originalValue) : true;
  });

type ISpeciesFormParams = any;

class Species extends BaseModel {
  public static endpoint = '/species';

  // Note: Rutilus' Species serializer returns a 'photo' attribute,
  //       but when updating we must submit as 'image'.
  public static embeddedImageAttribute = 'image';

  public static fromAttributes(attributes: ISpecies): Species {
    return new Species(attributes);
  }

  public static async create(params: ISpeciesFormParams): Promise<ISpecies> {
    const data: FormData = this.formData(params);

    return new Promise((resolve, reject) => {
      postWithImg(this.endpoint, data).then((resp) =>
        resp.ok ? resolve(resp.json()) : reject(resp.json()),
      );
    });
  }

  public static async find(id: number): Promise<unknown> {
    const searchParams = new URLSearchParams();
    searchParams.append('q[fish_id_eq]', id.toString());
    searchParams.append('per_page', '400');
    const speciesNameURL = `${LOCALIZED_NAMES_ENDPOINT}?${searchParams.toString()}`;
    return super.find(id).then(async (species: any) =>
      get(speciesNameURL)
        .then(async (resp) => (resp.ok ? resp.json() : alert(resp)))
        .then((jsonResponse = []) => {
          // eslint-disable-next-line no-param-reassign
          species.localizedSpeciesNames = jsonResponse;
          return species;
        }),
    );
  }

  private static formData(params: ISpeciesFormParams): FormData {
    const data = new FormData();

    // Note: Rutilus' Species serializer returns a 'photo' attribute,
    //       but when updating we must submit as 'image'.
    if (params.photo && params.photo.length === 1) {
      data.append('image', params.photo[0]);
    }
    if ('water_type' in params) {
      data.append('water_type', JSON.stringify(params.water_type));
    }
    if ('threat_to_humans' in params) {
      data.append('threat_to_humans', JSON.stringify(params.threat_to_humans));
    }
    BASIC_FIELDS.forEach((field) => {
      if (field in params) {
        data.append(field, params[field]);
      }
    });

    return data;
  }

  public ['constructor']: typeof Species;

  protected attributes: ISpecies;

  constructor(attributes: ISpecies) {
    super();
    this.attributes = attributes;
    this.update = this.update.bind(this);
    this.defineAttributeGetters(attributes);
  }

  public async update(params: { [key: string]: any }): Promise<void> {
    const { localizedSpeciesNames, ...speciesParams } = params;
    const data: FormData = Species.formData(params);
    const updateActions: Promise<unknown>[] = [];

    if (localizedSpeciesNames) {
      this.getUpdateLocalizationActions(localizedSpeciesNames).forEach((action) =>
        updateActions.push(action),
      );
    }
    if (Object.keys(speciesParams).length > 0) {
      updateActions.push(
        new Promise((resolve, reject) => {
          putWithImg(this.endpoint, data).then((resp) =>
            resp.ok ? resolve(resp.json()) : reject(resp.json()),
          );
        }),
      );
    }
    return Promise.all(updateActions).then(() => undefined);
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get identifier(): string {
    return this.attributes.name;
  }

  public get photo(): SpeciesImage {
    if (this.attributes.photo) {
      return this.attributes.photo;
    }
    return { sizes: [], type: 'image' };
  }

  public static get modelType(): string {
    return 'Species';
  }

  public get modelType(): string {
    return 'Species';
  }

  protected get class(): typeof Species {
    return Species;
  }

  public get externalId(): string {
    return this.attributes.external_id;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public set localizedSpeciesNames(localizedSpeciesNames: ILocalization[]) {
    this.attributes.localized_species_names = localizedSpeciesNames;
  }

  public get localizedSpeciesNames(): ILocalization[] {
    return this.attributes.localized_species_names || [];
  }

  private getUpdateLocalizationActions(localizedSpeciesNames: ILocalization[]): Promise<unknown>[] {
    return localizedSpeciesNames.map(async (item: ILocalization) => {
      // eslint-disable-next-line fp/no-let
      let restCall: Promise<{ ok?: boolean }>;
      if (item.id.startsWith && item.id.startsWith('temp_')) {
        const updatedItem = {
          country_code: item.country_code,
          fish_id: item.fish_id,
          local_name: item.local_name,
        };
        const headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        };
        restCall = post(`${LOCALIZED_NAMES_ENDPOINT}`, updatedItem, headers);
      } else {
        restCall = put(`${LOCALIZED_NAMES_ENDPOINT}/${item.id}`, item);
      }
      return new Promise(async (resolve, reject) =>
        restCall.then((resp) => (resp.ok ? resolve() : reject())),
      );
    });
  }
}

export default Species;
