import BaseModel from './BaseModel';
import { IImage } from './Image';
import { destroy } from '../api/rutilus';

export interface IUser {
  readonly id: number;
  readonly avatar?: IImage;
  readonly cover_image?: {
    image: IImage;
  };
  readonly cognito_username?: string;
  is_auto_registered: boolean;
  nickname: string;
  first_name: string;
  last_name: string;
  country_code: string;
  language_code: string;
  state_code: string;
  created_at: string;
  is_premium: boolean;
  email: string;
  external_id: string;
  shadow_ban: boolean;
  unbannable: boolean;
  biography?: string;
}

const READONLY_ATTRIBUTES = [
  'is_premium',
  'human_verified',
  'email_verified',
  'time_zone',
  'external_id',
  'private_uuid',
  'premium_end_date',
  'cognito_username',
];

class User extends BaseModel {
  public static endpoint = '/users';

  public static fromAttributes(attributes: IUser): User {
    return new User(attributes);
  }

  public static get readOnly(): string[] {
    return BaseModel.readOnly.concat(READONLY_ATTRIBUTES);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public ['constructor']: typeof User;

  protected attributes: IUser;

  constructor(attributes: IUser) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get avatar(): IImage {
    return this.attributes.avatar || { sizes: [] };
  }

  public get coverImage(): IImage {
    const { cover_image: coverImage } = this.attributes;
    return coverImage && coverImage.image ? coverImage.image : { sizes: [] };
  }

  public get name(): string {
    const { first_name: firstName, last_name: lastName } = this.attributes;
    return `${firstName} ${lastName}`;
  }

  public get identifier(): string {
    return this.attributes ? this.attributes.nickname : '';
  }

  public get modelType(): string {
    return 'User';
  }

  public async deleteCognitoUser(): Promise<Response> {
    const username = this.attributes.cognito_username;
    if (!username) {
      throw new Error('This user has no Cognito username.');
    }
    return destroy(`/admin/cognito_usernames/${username}`);
  }

  public static get modelType(): string {
    return 'User';
  }

  public get defaultEndpoint(): string {
    return '/users';
  }

  public static get listEndpoint(): string {
    return '/admin/users';
  }

  protected get class(): typeof User {
    return User;
  }
}

export default User;
