import { Component } from 'react';

import { FormikProps } from 'formik';

import BaseModel from '../../models/BaseModel';

import IFormHeaders from '../Edit/FormHeaderInterface';
import Image from './Image';

import { put } from '../../api/rutilus';
import styles from '../Edit/Form.module.css';
import FileDropzone from '../FileDropzone/FileDropzone';
import Button from '../Clickables/Buttons/Button';

interface IProps extends FormikProps<Record<string, unknown>> {
  header: IFormHeaders;
  entry: BaseModel;
  name: string;
  value: any;
}

interface IState {
  loading: boolean;
  missing: boolean;
}

const MISSING_IMAGE_REGEX = /missing.png/;

class EmbeddedDropzoneImage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const missing = !this.firstImageUrl || MISSING_IMAGE_REGEX.test(this.firstImageUrl);
    this.state = { missing, loading: false };
    this.removeImage = this.removeImage.bind(this);
  }

  public render(): React.ReactElement {
    const output = this.buildOutput();

    return (
      <div className={styles.list_row}>
        <h4>{this.props.header.title}</h4>
        <div>
          <figure className={styles.media}>{output}</figure>
        </div>
      </div>
    );
  }

  private get firstImageUrl(): string | undefined {
    return this.props.value.sizes[0]?.url;
  }

  private buildOutput(): React.ReactElement {
    const { missing, loading } = this.state;
    const { entry, name } = this.props;
    const image = entry[name];

    if (missing) {
      return <FileDropzone type="image" multiple={false} {...this.props} />;
    }
    if (image.sizes.length === 1) {
      // Waiting on image resizing
      return <Image img={image} geometry="" />;
    }
    if (image.sizes.length > 1) {
      // Image resizing complete
      return (
        <div>
          <Image img={image} geometry="320x200>" />
          <Button size="sm" variant="buttonDanger" disabled={loading} onClick={this.removeImage}>
            Remove
          </Button>
        </div>
      );
    }
    return <strong>Unable to display the image</strong>;
  }

  private removeImage(): void {
    const { entry, name } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove this ${name}?`)) {
      this.setState({ loading: true });
      const { endpoint } = entry.class;
      const params = { [entry.class.embeddedImageAttribute]: null };
      put(`${endpoint}/${entry.id}`, params).then((resp) => {
        if (resp.ok) {
          this.setState({ missing: true, loading: false });
        } else {
          alert(`Could not remove ${name}. HTTP status ${resp.status}`);
          this.setState({ loading: false });
        }
      });
    }
  }
}

export default EmbeddedDropzoneImage;
