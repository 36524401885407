import { gql } from 'graphql-tag';

export const getPlaces = gql`
  query GetPlaces($first: Int!, $filters: PlaceFilters) {
    places(first: $first, filters: $filters) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          name
          slug
          description
          unsplashImage {
            id
          }
          country {
            id
            countryName
          }
          region {
            regionCode
            name
          }
        }
      }
    }
  }
`;

export const getPlace = gql`
  query GetPlace($countryCode: String!, $regionCode: String!, $slug: String!) {
    place(countryCode: $countryCode, regionCode: $regionCode, slug: $slug) {
      id
      externalId
      name
      description
      unsplashImage {
        id
        imageUrl
        photographerName
        photographerUrl
      }
    }
  }
`;
