import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { get } from 'ts-get';

import IHeader from '../../components/List/HeadersInterface';
import Flex from '../../components/Flex/Flex';
import GqlModelList from '../../components/ModelList/GqlModelList';
import { getPromotionPartnerLinks } from '../../queries/PromotionPartnerLinks';
import {
  GetPromotionPartnerLinks,
  GetPromotionPartnerLinksVariables,
} from '../../interfaces/graphql';
import AttributeDate from '../../components/AttributeComponents/AttributeDate';
import AttributeLink from '../../components/AttributeComponents/AttributeLink';
import AttributeObject from '../../components/AttributeComponents/AttributeObject';
import Filter from '../../helpers/filter';

const headers: IHeader[] = [
  {
    attribute: 'id',
    title: 'ID',
  },
  {
    attribute: 'userId',
    title: 'User',
    component: AttributeLink,
    link: '/users/',
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  {
    attribute: 'token',
    title: 'Token',
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  {
    attribute: 'promotion',
    title: 'Promotion',
    filterable: true,
    filterOptions: { filterType: 'eq' },
  },
  {
    attribute: 'partnerUser',
    title: 'Partner users external ref',
  },
  {
    attribute: 'metaParams',
    title: 'Meta Params',
    component: AttributeObject,
  },
  {
    attribute: 'openedAt',
    title: 'Opened at/Redeemed at',
    component: AttributeDate,
  },
  {
    attribute: 'createdAt',
    title: 'Created At',
    component: AttributeDate,
  },
];

const PartnerPromotionsLinks: React.FC = () => {
  const location = useLocation();
  const filters = Filter.parse(location.search);
  const filterVariables = filters.filtersFlatMap;

  const { loading, error, data, fetchMore } = useQuery<
    GetPromotionPartnerLinks,
    GetPromotionPartnerLinksVariables
  >(getPromotionPartnerLinks, {
    variables: filterVariables,
    ...filterVariables,
  });

  const onLoadMore = () => {
    fetchMore({
      updateQuery: (previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
        const newEdges = fetchMoreResult.promotionPartnerLinks.edges;
        const { pageInfo } = fetchMoreResult.promotionPartnerLinks;

        return {
          promotionPartnerLinks: {
            pageInfo,
            edges: [...previousResult.promotionPartnerLinks.edges, ...newEdges],
          },
        };
      },
      variables: {
        cursor: get(data, (d) => d.promotionPartnerLinks.pageInfo.startCursor),
      },
    });
  };

  return (
    <Flex>
      <div>
        <GqlModelList
          isLoading={loading}
          error={error}
          entries={get(data, (d) => d.promotionPartnerLinks)}
          headers={headers}
          path={location.pathname}
          onLoadMore={onLoadMore}
          reverse
        />
      </div>
    </Flex>
  );
};

export default PartnerPromotionsLinks;
