import GqlBaseModel from '../GqlBaseModel';

export interface IProduct {
  readonly id: number;
  readonly name: string;
}

class Product extends GqlBaseModel {
  public static readOnly: string[] = ['id', 'name', 'reviews', 'created_at', 'updated_at'];

  public static endpoint = '/equipment/products';

  public static fromAttributes(attributes: IProduct): Product {
    return new Product(attributes);
  }

  public ['constructor']: typeof Product;

  protected attributes: IProduct;

  constructor(attributes: IProduct) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get endpoint(): string {
    return `${this.class.endpoint}/${this.attributes.id}`;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get identifier(): string {
    return this.attributes.name;
  }

  public get id(): string {
    return this.attributes.id.toString();
  }

  public get modelType(): string {
    return 'Product';
  }

  public static get modelType(): string {
    return 'Product';
  }

  protected get class(): typeof Product {
    return Product;
  }
}

export default Product;
