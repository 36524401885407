import { gql } from 'graphql-tag';

export const UpsertNavionicsSubscription = gql`
  mutation upsertNavionicsSubscription($input: UpsertNavionicsSubscriptionMutationInput!) {
    upsertNavionicsSubscription(input: $input) {
      subscription {
        customerId
        expiresAt
      }
      userErrors {
        path
        message
      }
    }
  }
`;
