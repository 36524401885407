import { Component } from 'react';

import { QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get } from 'ts-get';

import GqlEdit from '../../components/Edit/GqlEdit';
import FormHeaders from '../../components/Edit/FormHeaderInterface';
import Header from '../../components/List/HeadersInterface';
import GqlModelList from '../../components/ModelList/GqlModelList';
import query from '../../queries/Categories';
import editQuery from '../../queries/Category';

/* ATTRIBUTE COMPONENTS */
import AttributeID from '../../components/AttributeComponents/AttributeID';

/* FORM COMPONENTS */
import { FormText } from '../../components/Edit/FormAttributes/FormAttribute';
import Category from '../../models/Equipment/Category';
import { ListCategories, ListCategoriesVariables } from '../../interfaces/graphql';
import TableWithSingleLayout from '../../components/Layout/TableWithSingleLayout';

const headers: Header[] = [
  {
    title: 'Id',
    attribute: 'id',
    component: AttributeID,
    link: '/equipment/categories/',
  },
  { title: 'Title', attribute: 'title' },
  { title: 'Ancestry', attribute: 'ancestry' },
];

const formHeaders: FormHeaders[] = [
  { title: 'Id', attribute: 'id', component: FormText },
  { title: 'Title', attribute: 'title', component: FormText },
  { title: 'Ancestry', attribute: 'ancestry', component: FormText },
];

const EditCategory = (): JSX.Element => (
  <GqlEdit headers={formHeaders} model={Category} editQuery={editQuery} validationSchema={false} />
);

class Categories extends Component<RouteComponentProps> {
  public render(): React.ReactElement {
    const { location } = this.props;

    return (
      <Query<ListCategories, ListCategoriesVariables> query={query} fetchPolicy="network-only">
        {({ loading, error, data, fetchMore }) => (
          <TableWithSingleLayout singleModelComponent={<EditCategory />} modelType="Category">
            <GqlModelList
              isLoading={loading}
              error={error}
              entries={get(data, (d) => d.categories)}
              headers={headers}
              path={location.pathname}
              onLoadMore={this.onLoadMore.bind(
                this,
                fetchMore,
                get(data, (d) => d.categories),
              )}
            />
          </TableWithSingleLayout>
        )}
      </Query>
    );
  }

  private readonly onLoadMore = (fetchMore: QueryResult['fetchMore'], categories: any) => {
    fetchMore({
      updateQuery: (previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
        const newEdges = fetchMoreResult.categories.edges;
        const { pageInfo } = fetchMoreResult.categories;

        return {
          categories: {
            pageInfo,
            __typename: previousResult.categories.__typename,
            edges: [...previousResult.categories.edges, ...newEdges],
          },
        };
      },
      variables: {
        cursor: categories.pageInfo.endCursor,
      },
    });
  };
}

export default withRouter(Categories);
