import { gql } from 'graphql-tag';

export default gql`
  mutation UpdatePlace($input: UpdatePlaceMutationInput!) {
    updatePlace(input: $input) {
      place {
        id
        description
      }
      userErrors {
        message
      }
    }
  }
`;
