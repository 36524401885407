import { Component } from 'react';

import { FormikProps } from 'formik';

import SpeciesModel, { ILocalization } from '../../../models/Species';
import IFormHeaders from '../FormHeaderInterface';
import Countries, { ICountry } from '../../../helpers/Countries';
import TagInput from '../../Input/TagInput';

import styles from '../Form.module.css';
import Button from '../../Clickables/Buttons/Button';

interface IProps extends FormikProps<Record<string, unknown>> {
  header: IFormHeaders;
  entry: SpeciesModel;
}

interface IState {
  countries: ICountry[];
  selectedCountryId?: string;
}

class FormLocalizations extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      countries: [],
      selectedCountryId: undefined,
    };
  }

  public componentDidMount(): void {
    Countries.getCountries().then((countries) => {
      const existingCountries = this.props.entry.localizedSpeciesNames.map((e) => e.country_code);
      this.setState({
        countries: countries.filter((c) => !existingCountries.includes(c.id)),
      });
    });
  }

  public render(): React.ReactElement {
    const { entry, header } = this.props;
    const { countries, selectedCountryId } = this.state;
    return (
      <div className={styles.list_row}>
        <label className={styles.formSpacer}>{header.title}</label>
        <ul className={styles.list}>
          {entry.localizedSpeciesNames.map((item) => (
            <li key={item.id}>
              <label>{item.country_name}</label>
              <TagInput
                value={item.local_name.split('|').filter((v) => v !== '')}
                onChange={(value) => this.handleChange(item.id, value)}
              />
            </li>
          ))}
        </ul>
        <label className={styles.formSpacer} htmlFor="country-select">
          Add Country
        </label>
        <select
          id="country-select"
          disabled={countries.length === 0 && selectedCountryId !== undefined}
          value={this.state.selectedCountryId}
          onChange={this.handleCountryChange}
          className={styles.select}
        >
          <option>-</option>
          {this.getCountryOptionElements()}
        </select>
        <Button disabled={countries.length === 0} onClick={this.handleAddCountry}>
          Add
        </Button>
      </div>
    );
  }

  private readonly getCountryOptionElements = (): React.ReactElement => (
    <>
      {this.state.countries.map((c) => (
        <option value={c.id} key={c.id}>
          {c.country_name}
        </option>
      ))}
    </>
  );

  private readonly handleAddCountry = (): void => {
    const { selectedCountryId } = this.state;
    if (selectedCountryId) {
      const country = this.state.countries.find((c) => c.id === selectedCountryId);
      if (country) {
        const { entry } = this.props;
        const localizedSpeciesName: ILocalization = {
          country_code: country.id,
          country_id: country.iso_no.toString(),
          country_name: country.country_name,
          fish_id: this.props.entry.id,
          // Our types require an ID, but we need to create it via Rutilus and dont want to do so just yet,
          // so a temp id is created and will need to be handled differently when save is called.
          id: `temp_${country.id}`,
          local_name: '',
          type: 'localized_species_name',
        };
        const newValue = entry.localizedSpeciesNames.concat([localizedSpeciesName]);
        entry.localizedSpeciesNames = newValue;
        this.props.setFieldValue('localizedSpeciesNames', newValue);
      }
      this.setState({ selectedCountryId: undefined });
    }
  };

  private readonly handleCountryChange = (event: React.SyntheticEvent<HTMLSelectElement>): void => {
    this.setState({ selectedCountryId: event.currentTarget.value });
  };

  private readonly handleChange = (itemId: string, value: string[]): void => {
    const { entry } = this.props;
    const currentLocale = entry.localizedSpeciesNames.find((l) => l.id === itemId);
    if (currentLocale) {
      const updatedLocale = { ...currentLocale, local_name: value.join('|') };
      const newValue = entry.localizedSpeciesNames.map((l) =>
        l.id === itemId ? updatedLocale : l,
      );
      entry.localizedSpeciesNames = newValue;
      this.props.setFieldValue('localizedSpeciesNames', newValue);
    }
  };
}

export default FormLocalizations;
