import { gql } from 'graphql-tag';

export const administerUserSuggestedChange = gql`
  mutation AdministerUserSuggestedChange($input: AdministerUserSuggestedChangeMutationInput!) {
    administerUserSuggestedChange(input: $input) {
      userSuggestedChange {
        id
        externalId
        status
      }
      userErrors {
        path
        message
        code
      }
    }
  }
`;

export const assignUserSuggestedChange = gql`
  mutation AssignUserSuggestedChange($input: AssignUserSuggestedChangeMutationInput!) {
    assignUserSuggestedChange(input: $input) {
      userSuggestedChange {
        assignee {
          externalId
          nickname
        }
        externalId
      }
      userErrors {
        message
      }
    }
  }
`;
