import { useMutation } from '@apollo/client';

import { reverifyUser as mutation } from '../../mutations/User';
import { reverifyUser, reverifyUserVariables } from '../../interfaces/graphql';

import User from '../../models/User';
import Button from '../../components/Clickables/Buttons/Button';

const UnverifyButton = ({ entry }: { entry: User }): JSX.Element => {
  const [triggerUnverification, { loading }] = useMutation<reverifyUser, reverifyUserVariables>(
    mutation,
  );

  const unverify = () => {
    triggerUnverification({ variables: { input: { userId: entry.id } } });
  };

  return (
    <Button variant="buttonSecondary" disabled={loading} onClick={unverify} size="sm">
      {loading ? 'loading...' : 'Require reverification'}
    </Button>
  );
};

export default UnverifyButton;
