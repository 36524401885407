import { gql } from 'graphql-tag';

export const removeStaffFromPage = gql`
  mutation RemoveStaffMutation($input: RemoveStaffMutationInput!) {
    removeStaffFromPage(input: $input) {
      page {
        ambassadors(first: 50) {
          pageInfo {
            __typename
          }
          edges {
            node {
              id
              externalId
              nickname
            }
          }
        }
        publishers(first: 50) {
          pageInfo {
            __typename
          }
          edges {
            node {
              id
              externalId
              nickname
            }
          }
        }
        admins(first: 50) {
          pageInfo {
            __typename
          }
          edges {
            node {
              id
              externalId
              nickname
            }
          }
        }
      }
      userErrors {
        path
        message
      }
    }
  }
`;
