import { Component } from 'react';

import CatchModel from '../../../models/Catch';
import CatchCommentModel from '../../../models/CatchComment';
import Comment from '../../Comment/Comment';
import styles from '../Form.module.css';
import IFormHeaders from '../FormHeaderInterface';
import Loading from '../../Loading/Loading';

interface IProps {
  header: IFormHeaders;
  entry: CatchModel;
}

interface IState {
  comments: CatchCommentModel[] | void;
}

class FormCatchComments extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { comments: undefined };
  }

  public componentDidMount(): void {
    this.props.entry.fetchCatchComments().then((comments) => this.setState({ comments }));
  }

  public render(): JSX.Element {
    const { comments } = this.state;
    const { header } = this.props;
    return (
      <li className={styles.list_row}>
        <h2>{header.title}</h2>
        <Loading isLoading={!comments}>
          {comments && comments.length > 0 ? (
            <div className={styles.commentsContainer}>
              {comments.map((c) => (
                <Comment key={c.id} attr={c} />
              ))}
            </div>
          ) : (
            <p>No comments.</p>
          )}
        </Loading>
      </li>
    );
  }
}

export default FormCatchComments;
