import { gql } from 'graphql-tag';

export const createCouponGroup = gql`
  mutation CreateCouponGroup($input: CreateCouponGroupMutationInput!) {
    createCouponGroup(input: $input) {
      couponGroup {
        name
        percentOff
        durationInMonths
        couponExternalId
        code
      }
      userErrors {
        path
        message
      }
    }
  }
`;
