.flex {
  display: flex;
  height: calc(100% - var(--header-height));
  position: relative;
}

.flexVertical {
  flex-direction: column;
}

.flex > div {
  flex: 1;
}

.resetFlex > div {
  flex: initial;
}
