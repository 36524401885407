/* eslint-disable jsx-a11y/control-has-associated-label */

import { RansackMatcher } from '../../helpers/ransack';
import { IProps as FilterInputProps } from './FilterInput';

interface Props extends FilterInputProps {
  children?: React.ReactNode;
  filterType: RansackMatcher;
}

const SelectFilterInput = ({
  children,
  inputProps,
  onChange,
  attributeName,
  filterType,
  filters,
}: Props): React.ReactElement<Props> => {
  const value = (filters && filters[filterType]) || '';
  return (
    <select
      value={value}
      onChange={(event: React.SyntheticEvent<HTMLSelectElement>) => {
        onChange(attributeName, filterType, event.currentTarget.value, { refresh: true });
      }}
      {...inputProps}
    >
      {children || (
        <>
          <option />
          <option value="true">True</option>
          <option value="false">False</option>
        </>
      )}
    </select>
  );
};

export default SelectFilterInput;
