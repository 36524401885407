import { Link } from 'react-router-dom';

import Edit from '../../components/Edit/Edit';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import { compareStrings } from '../../helpers/comparators';
import Filter from '../../helpers/filter';
import { RansackMatcher } from '../../helpers/ransack';
import User from '../../models/User';

import UserBlockList from '../../components/Edit/FormAttributes/UserBlockList';
import UserCatches from '../../components/Edit/FormAttributes/UserCatches';
import UserComments from '../../components/Edit/FormAttributes/UserComments';
import UserPosts from '../../components/Edit/FormAttributes/UserPosts';

import UnverifyButton from './UnverificationButton';
import EmailVerificationButton from './EmailVerificationButton';
import UserStats from './UserStats';
import SubjectReports from '../UserReports/SubjectReports';
import GrantPremiumPromotion from './GrantPremiumPromotion';
import RevokePremiumGrantButton from './RevokePremiumGrantButton';
import Avatar from '../../components/Edit/FormAttributes/Avatar';
import Checkbox from '../../components/Edit/FormAttributes/Checkbox';
import CoverImage from '../../components/Edit/FormAttributes/CoverImage';
import {
  AttributeWithTitle,
  FormDateCustom,
  FormText,
  FormTextarea,
} from '../../components/Edit/FormAttributes/FormAttribute';
import Tabs from '../../components/Edit/FormAttributes/Tabs';
import CognitoDeleteButton from './CognitoDeleteButton';
import ShadowBanButton from './ShadowBanButton';
import UserSupportAction from './UserSupportAction';
import NavionicsSubscriptionStatus from './NavionicsSubscriptionStatus';
import VerifyUserEmail from './VerifyUserEmailButton';
import AdminToggle from './AdminToggle';

const userLink = (prefix: string, id: number): string =>
  Filter.empty().setAttribute(`${prefix}_id`, RansackMatcher.Equals, id.toString()).toString();

const userLinks = ({ entry: { id } }: { entry: User }): JSX.Element => (
  <span>
    <Link to={{ pathname: '/posts', search: userLink('user', id) }}>See posts</Link>{' '}
    <Link to={{ pathname: '/catches', search: userLink('owner', id) }}>See catches</Link>
  </span>
);

const UserReportOnUser = ({ entry }: { entry: User }): JSX.Element => (
  <SubjectReports entry={{ subjectType: 'User', subjectId: entry.id }} />
);

const TABS = [
  {
    Component: UserCatches,
    index: 1,
    prop: undefined,
    title: 'Catches',
  },
  {
    Component: UserPosts,
    index: 2,
    prop: undefined,
    title: 'Posts',
  },
  {
    Component: UserComments,
    index: 3,
    prop: undefined,
    title: 'Comments',
  },
  {
    Component: UserBlockList,
    index: 4,
    prop: 'blockedBy',
    title: 'Blocked By',
  },
  {
    Component: UserBlockList,
    index: 5,
    prop: 'blockedTargets',
    title: 'Blocked Targets',
  },
];

const formHeaders: IFormHeaders[] = [
  {
    title: 'Is Admin',
    attribute: 'isAdmin',
    component: AdminToggle,
  },
  { title: 'Avatar', attribute: 'avatar', component: Avatar },
  { title: 'Cover Image', attribute: 'coverImage', component: CoverImage },
  {
    title: '',
    attribute: 'content',
    row: [
      { title: 'Content', attribute: 'links', component: AttributeWithTitle(userLinks) },
      {
        title: 'Verification',
        attribute: 'is_verified',
        component: AttributeWithTitle(UnverifyButton),
      },
      {
        title: 'Email',
        attribute: 'verify_email',
        component: AttributeWithTitle(EmailVerificationButton),
      },
      {
        title: 'Verify Email',
        attribute: 'is_verified',
        component: AttributeWithTitle(VerifyUserEmail),
      },
    ],
  },
  {
    title: '',
    attribute: 'ids',
    row: [
      { title: 'Id', attribute: 'id', component: FormText },
      { title: 'External ID', attribute: 'external_id', component: FormText },
      { title: 'Private ID', attribute: 'private_uuid', component: FormText },
    ],
  },
  { title: 'User Stats', attribute: 'user_stats', component: UserStats },
  {
    title: '',
    attribute: 'full_name',
    row: [
      { title: 'First name', attribute: 'first_name', component: FormText },
      { title: 'Last name', attribute: 'last_name', component: FormText },
    ],
  },
  {
    title: '',
    attribute: 'cognito',
    row: [
      { title: 'Cognito username', attribute: 'cognito_username', component: FormText },
      {
        title: 'Cognito',
        attribute: 'delete_cognito_user',
        component: CognitoDeleteButton,
      },
    ],
  },
  {
    title: '',
    attribute: 'verification stuff',
    row: [
      { title: 'Nickname', attribute: 'nickname', component: FormText },
      { title: 'Human verified?', attribute: 'human_verified', component: Checkbox },
    ],
  },
  {
    title: '',
    attribute: 'email stuff',
    row: [
      { title: 'Email', attribute: 'email', component: FormText },
      { title: 'Email verified by user?', attribute: 'email_verified', component: Checkbox },
    ],
  },
  {
    attribute: 'biography',
    title: 'Biography',
    component: FormTextarea,
  },
  {
    title: '',
    attribute: 'banning',
    row: [
      {
        title: '',
        attribute: 'shadow_ban',
        component: AttributeWithTitle(ShadowBanButton),
      },
      { title: 'Unbannable?', attribute: 'unbannable', component: Checkbox },
    ],
  },
  {
    title: 'Latest user support actions',
    attribute: 'user_support_action',
    component: AttributeWithTitle(UserSupportAction),
  },
  {
    title: '',
    attribute: 'account_creation',
    row: [
      { title: 'Fishbrain Account Created', attribute: 'created_at', component: FormDateCustom },
    ],
  },
  {
    title: '',
    attribute: 'premium',
    row: [
      {
        title: 'Premium end date',
        attribute: 'premium_end_date',
        component: FormDateCustom,
        equals: compareStrings,
      },
      { title: 'Premium active', attribute: 'is_premium', component: Checkbox },
      { title: 'Revoke premium', attribute: 'is_premium', component: RevokePremiumGrantButton },
    ],
  },
  {
    title: '',
    attribute: 'grant_premium',
    row: [
      {
        title: 'Grant premium time',
        attribute: 'premium_promotion',
        component: AttributeWithTitle(GrantPremiumPromotion),
      },
    ],
  },
  {
    title: 'Navionics depth maps subcription',
    attribute: 'navionics_subscription',
    component: AttributeWithTitle(NavionicsSubscriptionStatus),
  },
  {
    title: '',
    attribute: 'country_stuff',
    row: [
      { title: 'Time zone', attribute: 'time_zone', component: FormText },
      { title: 'Country code', attribute: 'country_code', component: FormText },
      { title: 'State code', attribute: 'state_code', component: FormText },
      { title: 'Language code', attribute: 'language_code', component: FormText },
    ],
  },
  {
    title: 'Reports on this user',
    attribute: 'user reports',
    component: AttributeWithTitle(UserReportOnUser),
  },
  { title: 'More Details', attribute: 'extra tabs', component: () => <Tabs tabs={TABS} /> },
];

const UserForm = (): JSX.Element => (
  <Edit model={User} headers={formHeaders} asyncDeletion returnPath="/users" />
);

export default UserForm;
