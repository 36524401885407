import { Form, FormikProps, withFormik } from 'formik';
import { useMutation, ApolloError } from '@apollo/client';

import * as Yup from 'yup';
import { Button } from 'barramundi';

import { useState } from 'react';

import FormComponent from './FormAttributes/FormAttribute';
import IFormHeader from './FormHeaderInterface';
import { flattenHeaders } from './helpers';

import styles from './Form.module.css';

interface ButtonProps<T = unknown> {
  mutation: any;
  entry?: T;
  component: React.ElementType;
  children: React.ReactNode;
  prepare: (entry: T | undefined) => any;
  confirmText?: string;
  disabled?: boolean;
  onCompleted?: (data?: any) => void;
  onError?: (error: ApolloError) => void;

  [rest: string]: unknown;

  hasErrors?: boolean;
  setShowErrors?: (showErrors: boolean) => void;
}

export interface IKeyValue<T = any> {
  [key: string]: T;
}

export const InnerMutation = ({
  entry,
  children,
  mutation,
  component: Component,
  prepare,
  disabled = false,
  confirmText,
  onCompleted = () => null,
  onError = alert,
  hasErrors,
  setShowErrors = () => null,
  ...props
}: ButtonProps) => {
  const [runMutation, { loading }] = useMutation(mutation, {
    onCompleted,
    onError,
  });

  return (
    <Component
      type="button"
      disabled={loading || disabled}
      onClick={() => {
        if (hasErrors) {
          setShowErrors(true);
        } else {
          setShowErrors(false);
          if (!confirmText || window.confirm(confirmText)) {
            runMutation(prepare(entry));
          }
        }
      }}
      {...props}
    >
      {children}
    </Component>
  );
};

export interface IProps {
  entry: any;
  headers: IFormHeader[];
  flatHeaders?: IFormHeader[];
  createEntry?: any;
  afterCreate?: (...a: any[]) => void;
  prepareCreate?: (values: IKeyValue) => { [key: string]: unknown };
  prepareEdit?: (values: IKeyValue) => { [key: string]: unknown };
  prepareDelete?: (entry: any | undefined) => any;
  deleteEntry?: any;
  editEntry?: any;
  confirmDeleteText?: string;
  afterEdit?: (...a: any[]) => void;
  afterDelete?: (...a: any[]) => void;

  [key: string]: any;
}

const InnerEditForm = ({
  values: {
    createEntry,
    afterCreate = () => {
      window.location.reload();
    },
    prepareCreate = (attributes: any) => ({ input: { attributes } }),
    prepareEdit = (attributes: any) => ({ input: { attributes } }),
    editEntry,
    prepareDelete,
    deleteEntry,
    asyncDeletion,
    confirmDeleteText,
    afterEdit = () => {
      window.location.reload();
    },
    afterDelete,
    ...values
  },
  touched,
  initialValues,
  dirty,
  errors,
  ...props
}: FormikProps<IProps>) => {
  const [showErrors, setShowErrors] = useState(false);
  const hasErrors = Object.keys(errors).length > 0;
  const { entry } = initialValues;

  const createFunc = () => {
    const { headers } = values;
    const requiredAttributes: IFormHeader[] = headers.filter((h) => !entry.isReadOnly(h.attribute));

    const createAttributes: IKeyValue = requiredAttributes
      .filter((header: IFormHeader): boolean => {
        const { attribute, equals } = header;
        const initialValue: any = initialValues[attribute];
        const newValue: any = values[attribute];
        return equals ? !equals(initialValue, newValue) : !!touched[attribute];
      })
      .map(
        ({
          attribute,
          beforeSave,
        }: IFormHeader): {
          attribute: string;
          value: any;
        } => ({
          attribute,
          value: beforeSave
            ? beforeSave(values[attribute], initialValues[attribute])
            : values[attribute],
        }),
      )
      .reduce(
        (obj: IKeyValue, { attribute, value }): IKeyValue => ({
          ...obj,
          [attribute]: value,
        }),
        {},
      );

    return { variables: prepareCreate(createAttributes) };
  };

  const editFunc = () => {
    const { flatHeaders, headers } = values;
    const editableAttributes: IFormHeader[] = (flatHeaders || headers).filter(
      (h) => h.attribute !== '' && !entry.isReadOnly(h.attribute),
    );
    const edit: IKeyValue = editableAttributes
      .filter((header: IFormHeader): boolean => {
        const { attribute, equals, relatedAttribute } = header;
        const initialValue: any = initialValues[attribute];
        const newValue: any = values[attribute];

        if (equals) {
          return !equals(initialValue, newValue);
        }

        return !!touched[attribute] || !!(relatedAttribute && touched[relatedAttribute]);
      })
      .map(
        ({
          attribute,
          beforeSave,
        }: IFormHeader): {
          attribute: string;
          value: any;
        } => ({
          attribute,
          value: beforeSave
            ? beforeSave(values[attribute], initialValues[attribute])
            : values[attribute],
        }),
      )
      .reduce(
        (obj: IKeyValue, { attribute, value }): IKeyValue => ({
          ...obj,
          [attribute]: value,
        }),
        {},
      );

    const variables: any = prepareEdit(edit);
    if (!entry.isNew) {
      if (values.id) {
        variables.input.id = parseInt(values.id, 10);
      } else {
        variables.input.externalId = values.externalId;
      }
    }
    return { variables };
  };

  const deleteVars =
    !entry.isNew && entry.id
      ? { variables: { input: { id: parseInt(entry.id, 10) } } }
      : { variables: { input: { externalId: entry.externalId } } };

  return (
    <Form className={styles.list}>
      {showErrors &&
        Object.keys(errors).map((key) => {
          return (
            <div className="text-error" key={key}>
              {errors[key] as string}
            </div>
          );
        })}
      <div className={styles.buttons}>
        {createEntry && (
          <InnerMutation
            mutation={createEntry}
            disabled={!dirty}
            prepare={createFunc}
            component={Button}
            onCompleted={values.afterCreate || afterCreate}
            hasErrors={hasErrors}
            setShowErrors={setShowErrors}
          >
            Save {entry.modelType}
          </InnerMutation>
        )}
        {editEntry && (
          <InnerMutation
            entry={entry}
            mutation={editEntry}
            disabled={!dirty}
            prepare={editFunc}
            component={Button}
            onCompleted={values.afterEdit || afterEdit}
            hasErrors={hasErrors}
            setShowErrors={setShowErrors}
          >
            Save {entry.modelType}
          </InnerMutation>
        )}{' '}
        {deleteEntry && (
          <>
            <InnerMutation
              entry={entry}
              prepare={prepareDelete || (() => deleteVars)}
              component={Button}
              confirmText={
                confirmDeleteText ||
                `Are you sure you want to permanently delete this ${entry.modelType}`
              }
              mutation={deleteEntry}
              onCompleted={afterDelete}
              variant="danger"
            >
              Delete {entry.modelType}
            </InnerMutation>
            {asyncDeletion ? (
              <div className={styles.deletion_note}>
                <b>Note</b>: Deletion may take 30 minutes or more to show here because it needs to
                run in the background. Please check back later to ensure deletion completed
                successfully.
              </div>
            ) : null}
          </>
        )}
      </div>
      {values.headers.map((header, index) => (
        <FormComponent
          value={header.attribute !== '' ? values[header.attribute] : undefined}
          entry={entry}
          header={header}
          key={header.attribute !== '' ? header.attribute : `key-${index}`}
          touched={touched}
          dirty={dirty}
          initialValues={initialValues}
          values={values}
          errors={errors}
          {...props}
        />
      ))}
    </Form>
  );
};

const GqlForm = withFormik<IProps, any>({
  handleSubmit: () => {
    // form submission is handled in deleteEntry, editEntry instead.
  },
  mapPropsToValues: ({
    headers,
    entry,
    createEntry,
    afterCreate,
    prepareCreate,
    prepareEdit,
    prepareDelete,
    deleteEntry,
    asyncDeletion,
    confirmDeleteText,
    editEntry,
    afterDelete,
    afterEdit,
  }: IProps) => {
    const flatHeaders = flattenHeaders(headers);
    const entryProps: IProps = {
      entry,
      headers,
      flatHeaders,
      createEntry,
      afterCreate,
      prepareCreate,
      prepareEdit,
      prepareDelete,
      deleteEntry,
      asyncDeletion,
      confirmDeleteText,
      editEntry,
      afterDelete,
      afterEdit,
    };
    flatHeaders.forEach((header) => {
      // default to '' for the value
      entryProps[header.attribute] = '';
      // if the attribute has a non-falsey, or if the attribute is `false`,
      // replace the default ''
      if (entry[header.attribute] || entry[header.attribute] === false) {
        entryProps[header.attribute] = entry[header.attribute];
      }
    });
    return entryProps;
  },
  validateOnMount: true,
  validationSchema: (props: IProps) => {
    const { validationSchema } = props;

    if (validationSchema) {
      return validationSchema;
    }
    return Yup.object({});
  },
})(InnerEditForm);

export default GqlForm;
