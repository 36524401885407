import { IImage } from '../../../models/Image';
import defaultAvatar from '../../../views/CurrentUser/default.png';

interface IProps {
  value: IImage;
  className?: string;
}

const Avatar = ({ value, className }: IProps): JSX.Element => {
  const avatar = value.sizes.find((s) => s.geometry === '150x150#');
  const imageUrl = avatar ? avatar.url : defaultAvatar;
  return <img alt="Avatar" src={imageUrl} className={className || ''} />;
};

export default Avatar;
