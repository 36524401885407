import { useQuery, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { DocumentNode } from 'graphql';
import { useLocation, Route, useRouteMatch, useParams } from 'react-router-dom';
import { get } from 'ts-get';
import queryString from 'query-string';

import styles from '../../components/ModelList/ModelList.module.css';

import { genericLoadMore } from '../../helpers/graphql';
import Group from '../../models/Page';
import { GetGroupsOrnatus, GetGroupsOrnatusVariables } from '../../interfaces/graphql';
import { getGroup, getGroups, getGroupFeed, getGroupMembers } from '../../queries/Groups';
import { updatePageMutation } from '../../mutations/Pages/UpdatePage';
import { deleteGroup } from '../../mutations/Groups';

import Tabs from '../../components/Edit/FormAttributes/Tabs';
import AttributeID from '../../components/AttributeComponents/AttributeID';
import { FormText, FormTextarea } from '../../components/Edit/FormAttributes/FormAttribute';
import GqlEdit from '../../components/Edit/GqlEdit';
import GraphqlDropzoneImage, {
  Props as GraphqlDropzoneImageProps,
} from '../../components/Image/GraphqlDropzoneImage';
import IFormHeaders from '../../components/Edit/FormHeaderInterface';
import IHeader from '../../components/List/HeadersInterface';
import GqlModelList from '../../components/ModelList/GqlModelList';
import Flex from '../../components/Flex/Flex';
import SidePane from '../../components/SidePane/SidePane';

const mainPageHeader: IHeader[] = [
  {
    attribute: 'externalId',
    component: AttributeID,
    link: '/groups/',
    title: 'External Id',
  },
  {
    attribute: 'id',
    component: AttributeID,
    title: 'Internal ID',
  },
  {
    attribute: 'displayName',
    title: 'Name',
    component: AttributeID,
    filterable: true,
  },
  {
    attribute: 'type',
    title: 'Visibility',
    component: AttributeID,
  },
];

const membersheaders: IHeader[] = [
  { title: 'Id', attribute: 'id', component: AttributeID, link: '/users/' },
  { title: 'Nickname', attribute: 'nickname' },
];

const postsHeaders: IHeader[] = [
  { title: 'Id', attribute: 'id', component: AttributeID, link: '/posts/' },
  { title: 'Text', attribute: 'text', subAttr: ['text'] },
];

interface IGenericListFromConnection {
  prop: string;
  headers: IHeader[];
  query: DocumentNode;
  onLoadMore: (
    fetchMore: QueryResult['fetchMore'],
    group: any,
    prop: string,
    externalId: string,
  ) => unknown;
}

// This supposed to be a generic component that supports displaying
// connection fields as a list
const GenericListFromConnection = ({
  prop,
  headers,
  onLoadMore,
  query,
}: IGenericListFromConnection) => {
  const location = useLocation();
  const { externalId } = useParams<{ externalId: string }>();
  return (
    <Query<any, any>
      query={query}
      variables={{ id: externalId, cursor: '' }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data, fetchMore }) => (
        <div className={styles.scroll}>
          <GqlModelList
            isLoading={loading}
            error={error}
            entries={get(data, (d) => d.entry[prop])}
            headers={headers}
            path={location.pathname}
            onLoadMore={() => onLoadMore(fetchMore, data.entry, prop, externalId)}
          />
        </div>
      )}
    </Query>
  );
};

const TABS = [
  {
    title: 'Members',
    Component: () => (
      <GenericListFromConnection
        onLoadMore={onLoadMoreGroupThings}
        headers={membersheaders}
        query={getGroupMembers}
        prop="members"
      />
    ),
    index: 1,
    prop: undefined,
  },
  {
    title: 'Feed',
    Component: () => (
      <GenericListFromConnection
        onLoadMore={onLoadMoreGroupThings}
        headers={postsHeaders}
        query={getGroupFeed}
        prop="feed"
      />
    ),
    index: 2,
    prop: undefined,
  },
];

const wrapAndRename = (signedId: string) => ({ signedId });

const formHeaders: IFormHeaders[] = [
  { title: 'Id', attribute: 'externalId', component: FormText, readonly: true },
  { title: 'Name', attribute: 'displayName', component: FormText, readonly: true },
  { title: 'Description', attribute: 'description', component: FormTextarea, readonly: true },
  { title: 'Group type', attribute: 'type', component: FormText, readonly: true },
  {
    title: 'Logo',
    attribute: 'logo',
    component: (props: GraphqlDropzoneImageProps) => (
      <GraphqlDropzoneImage {...props} destroy={undefined} />
    ),
    beforeSave: wrapAndRename,
  },
  {
    title: 'Cover Image',
    attribute: 'coverImage',
    component: (props: GraphqlDropzoneImageProps) => (
      <GraphqlDropzoneImage {...props} destroy={undefined} />
    ),
    beforeSave: wrapAndRename,
  },
  {
    title: 'More',
    attribute: 'extra tabs',
    component: () => <Tabs tabs={TABS} />,
  },
];
const prepareDelete = (entry: any) => ({ variables: { id: entry.externalId } });

const EditGroup = () => (
  <GqlEdit
    model={Group}
    headers={formHeaders}
    editQuery={getGroup}
    mutation={updatePageMutation}
    prepareDelete={prepareDelete}
    deletion={deleteGroup}
  />
);

// This will generic loader provides pangination
// for tabs provided quries used follow specification
// 1. `group` field is alliased to `entry`
// 2. Query quires for a single field that is of interest for the particular tab.
const onLoadMoreGroupThings = (
  fetchMore: QueryResult['fetchMore'],
  group: any,
  prop: string,
  externalId: string,
) => {
  fetchMore({
    updateQuery: (_previousResult: any, { fetchMoreResult }: { [key: string]: any }) => {
      const result = fetchMoreResult.entry[prop];
      const groupCopy = { ...group };
      groupCopy[prop] = {
        pageInfo: result.pageInfo,
        __typename: groupCopy[prop].__typename,
        edges: [...groupCopy[prop].edges, ...result.edges],
      };
      groupCopy.entry = groupCopy;
      return groupCopy;
    },
    variables: {
      cursor: group[prop].pageInfo.endCursor,
      id: externalId,
    },
  });
};

const Groups = (): JSX.Element => {
  const { pathname, search } = useLocation();
  const match = useRouteMatch();
  const { 'filter.displayName__start': containingName } = queryString.parse(search);

  const variables =
    containingName && containingName.length && typeof containingName === 'string'
      ? { filters: { containingName } }
      : undefined;

  const { loading, error, data, fetchMore } = useQuery<GetGroupsOrnatus, GetGroupsOrnatusVariables>(
    getGroups,
    {
      fetchPolicy: 'network-only',
      variables,
    },
  );

  const onLoadMore = genericLoadMore('groups');

  return (
    <Flex>
      <GqlModelList
        isLoading={loading}
        error={error}
        entries={get(data, (d) => d.groups)}
        headers={mainPageHeader}
        path={pathname}
        onLoadMore={() => onLoadMore(fetchMore, data && data.groups)}
      />
      <SidePane showOnPath={`${match.path}/:slugs`}>
        <Route path={`${match.path}/:externalId`} render={() => <EditGroup />} />
      </SidePane>
    </Flex>
  );
};

export default Groups;
