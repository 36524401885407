import { get } from '../api/rutilus';
import { IComment } from '../components/Comment/Comment';
import { buildFilterParams, mergeSearchParams } from '../helpers/urlhelpers';
import BaseModel, { IListOptions } from './BaseModel';
import { IFishingWater } from './FishingWater';
import { IImage } from './Image';
import { IUser } from './User';
import { IVideo } from './Video';

export interface IText {
  text: string;
  status: string;
  id: number;
}

export interface IPhoto {
  photo: IImage;
  id: number;
  status: string;
}

export interface ITitle {
  body: string;
}

export interface ILocation {
  fishing_water: IFishingWater;
}

export interface IUserPost {
  readonly id: number;
  photos: IPhoto[];
  video: IVideo;
  owner: IUser;
  text?: IText;
  type: string;
  comments_count: number;
  likes_count: number;
  images_count: number;
  external_id: string;
  liked: boolean;
  title: ITitle;
  comments: IComment[];
  location: ILocation;
  postable_type: string;
  postable_id: number;
}
class UserPost extends BaseModel {
  public static endpoint = '/posts';

  public static modelType = 'User posts';

  public static fromAttributes(attributes: IUserPost): UserPost {
    return new UserPost(attributes);
  }

  public static get readOnly(): string[] {
    return BaseModel.readOnly.concat(
      'reason',
      'status',
      'resolved_at',
      'subject',
      'subject_type',
      'subject_id',
    );
  }

  public static async list(
    { page, perPage, sortBy, searchQuery, urlParams }: IListOptions = {
      page: 0,
      perPage: 10,
      sortBy: 'created_at desc',
      filterBy: {},
    },
  ): Promise<unknown> {
    // eslint-disable-next-line no-console
    return new Promise(async (resolve, reject = console.error) => {
      const filterParams = buildFilterParams(sortBy);
      const params = {
        page,
        per_page: perPage,
        ...filterParams,
        ...(searchQuery ? { s: searchQuery } : {}),
      };
      const searchParams = mergeSearchParams(this.fetchParameters, params);

      return get(
        `${this.endpoint}?${searchParams.toString()}${
          urlParams && urlParams.id ? `&user_id=${urlParams.id}` : ''
        }`,
      )
        .then(async (resp) => (resp.ok ? resp.json() : reject(resp)))
        .then((json) => json && json.map((obj: any) => this.fromAttributes(obj)))
        .then(resolve, reject);
    });
  }

  protected static readonly DEFAULT_OPTIONS = {};

  public modelType = 'User posts';

  public ['constructor']: typeof UserPost;

  protected attributes: IUserPost;

  constructor(attributes: IUserPost) {
    super();
    this.attributes = attributes;
    this.defineAttributeGetters(attributes);
  }

  public get identifier(): string {
    return this.attr('id');
  }

  public get endpoint(): string {
    return `${this.class.endpoint}`;
  }

  public get textBody(): string {
    return this.attributes.text ? this.attributes.text.text : '';
  }

  public get textStatus(): string {
    return this.attributes.text ? this.attributes.text.status : '';
  }

  protected get class(): typeof UserPost {
    return UserPost;
  }
}

export default UserPost;
