import { gql } from 'graphql-tag';

export default gql`
  query ListProducts($cursor: String, $filters: ProductFilters) {
    products(first: 20, after: $cursor, filters: $filters) {
      edges {
        node {
          id
          name
          brand {
            name
          }
          category {
            title
          }
          reviews {
            totalCount
            totalAverage(attribute: RATING)
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
