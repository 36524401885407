import { Route, Switch, useRouteMatch } from 'react-router-dom';

import SplitPane from '../SplitPane/SplitPane';

import Flex from '../Flex/Flex';
import Center from '../Center/Center';

interface IProps {
  singleModelComponent: JSX.Element;
  singleModelIdParam?: string;
  createComponent?: JSX.Element;
  children: React.ReactNode;
  /* The name of the model, e.g. "User". Should be human readable (i.e. it doesnt need to match the actual model name) */
  modelType: string;
}

/* This is for a page with a table on the left and an individual model on the right. If given
   a `createComponent` it will also allow for the creation of that model.
*/
const TableWithSingleLayout = ({
  children,
  singleModelComponent,
  modelType,
  createComponent,
  singleModelIdParam = 'id',
}: IProps): JSX.Element => {
  const match = useRouteMatch();
  return (
    <Flex>
      <SplitPane>
        <div className="h-full overflow-y-auto">{children}</div>
        <Switch>
          {createComponent && (
            <Route path={`${match.path}/new`} exact>
              {createComponent}
            </Route>
          )}
          <Route path={`${match.path}/:${singleModelIdParam}`}>{singleModelComponent}</Route>
          <Center horizontal vertical>
            {`Select a ${modelType} to the left to edit it`}
          </Center>
        </Switch>
      </SplitPane>
    </Flex>
  );
};

export default TableWithSingleLayout;
