import { useQuery, useMutation } from '@apollo/client';
import { Button, Card, Text, Heading } from 'barramundi';
import { Link } from 'react-router-dom';
import { get } from 'ts-get';

import { getSpeciesTips } from '../../queries/SpeciesTips';
import {
  DeleteRecordByExternalId as IDeleteRecordByExternalId,
  DeleteRecordByExternalIdVariables,
  GetSpeciesTips,
  GetSpeciesTipsVariables,
  DeleteRecordByExternalIdTypeEnum,
} from '../../interfaces/graphql';
import Species from '../../models/Species';
import DeleteRecordByExternalId from '../../mutations/DeleteRecordByExternalId';

interface IProps {
  entry: Species;
}

export const SpeciesTips = ({ entry }: IProps): JSX.Element => {
  const { loading, data, refetch } = useQuery<GetSpeciesTips, GetSpeciesTipsVariables>(
    getSpeciesTips,
    {
      variables: { speciesId: entry.id },
    },
  );
  const tips = get(data, (d) => d.singleSpecies.speciesTips.edges.map((e) => e.node), []);
  const [deleteTip] = useMutation<IDeleteRecordByExternalId, DeleteRecordByExternalIdVariables>(
    DeleteRecordByExternalId,
  );

  const onDeleteClicked = (externalId: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this tip?')) {
      deleteTip({
        variables: { input: { externalId, type: DeleteRecordByExternalIdTypeEnum.SPECIES_TIP } },
      })
        .then(async () => refetch())
        // eslint-disable-next-line no-console
        .catch(console.error);
    }
  };

  if (loading) {
    return <p>Loading</p>;
  }

  return (
    <div>
      <Heading as="h2">Species Tips</Heading>
      {tips.map((tip) => (
        <Card p={4} mb={2} key={`tip-${tip.externalId}`}>
          <Text mb={0} fontSize="sm">
            <Link to={`/users/${tip.user.id}`}>{tip.userDisplayName}</Link>
          </Text>
          <Text mb={0} fontStyle="italic" fontSize="sm">
            {tip.userDescription}
          </Text>
          <Text mb={0}>{tip.tip}</Text>
          {/* TODO Update button to our own */}
          <Button
            as="button"
            variant="danger"
            btnSize="sm"
            mt={2}
            onClick={() => onDeleteClicked(tip.externalId)}
          >
            Delete
          </Button>
        </Card>
      ))}
      {/* TODO Update button to our own */}
      <Button link={Link} to={`/species/${entry.id}/create_tip`} mt={2}>
        Add New Tip
      </Button>
    </div>
  );
};
