import { get as tsGet } from 'ts-get';

import AsyncSelect from 'react-select/async';

import { ApolloError, useMutation, useQuery } from '@apollo/client';

import { useState } from 'react';

import { Value } from '../../../components/Input/SearchInput';
import { assignUserSuggestedChange } from '../../../mutations/AdministerUserSuggestedChange';
import {
  AssignUserSuggestedChange,
  AssignUserSuggestedChangeVariables,
  GetUsers,
  GetUsersVariables,
} from '../../../interfaces/graphql';

import UserSuggestedChange from '../../../models/UserSuggestedChange';

import { searchAdminusers } from '../../../queries/AdminUsers';

const SearchAssignee = ({ entry: { assignee, externalId } }: { entry: UserSuggestedChange }) => {
  const [currentAssignee, setCurrentAssignee] = useState(
    assignee ? { value: assignee.externalId, label: assignee.nickname } : { value: '', label: '' },
  );

  const { refetch } = useQuery<GetUsers, GetUsersVariables>(searchAdminusers, {
    variables: { filters: { adminOnly: true, nicknameStartsWith: '' } },
    skip: true,
  });

  const [assignUserSuggestion, { loading: loadingAssignUserSuggestion }] = useMutation<
    AssignUserSuggestedChange,
    AssignUserSuggestedChangeVariables
  >(assignUserSuggestedChange, {
    onCompleted: (response) => {
      const { userErrors } = response.assignUserSuggestedChange || { userErrors: [] };
      if (userErrors.length !== 0) {
        alert(userErrors[0].message);
        return;
      }
      if (
        response.assignUserSuggestedChange &&
        response.assignUserSuggestedChange.userSuggestedChange &&
        response.assignUserSuggestedChange.userSuggestedChange.assignee
      ) {
        setCurrentAssignee({
          value: response.assignUserSuggestedChange.userSuggestedChange.assignee.externalId,
          label: response.assignUserSuggestedChange.userSuggestedChange.assignee.nickname,
        });
      }
    },
    onError: (err: ApolloError) => alert(err.message),
  });

  const setAssignee = (assignee: Value) => {
    if (assignee.value) {
      const variables = {
        input: {
          externalId,
          userExternalId: assignee.value,
        },
      };
      assignUserSuggestion({ variables });
    }
  };

  const promiseOptions = async (inputValue: string): Promise<Value[]> => {
    const { data, error } = await refetch({
      filters: { adminOnly: true, nicknameStartsWith: inputValue },
    });
    const userEdges = tsGet(data, (d) => d.users.edges, []);

    if (error || !data) {
      alert('Error fetching users');
      return new Promise((_, reject) => reject([]));
    }
    return new Promise((resolve) => {
      resolve(
        userEdges.map(({ node }) => ({
          value: node.externalId,
          label: node.nickname,
        })),
      );
    });
  };

  return (
    <AsyncSelect
      defaultOptions
      loadOptions={promiseOptions}
      value={currentAssignee}
      onChange={(assigneeObject) => setAssignee(assigneeObject as Value)}
      cacheOptions
      isLoading={loadingAssignUserSuggestion}
    />
  );
};

export default SearchAssignee;
