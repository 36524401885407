.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  height: 100%;
  width: 100%;
}

.wrapper img {
  border-radius: 100%;
  height: 75%;
  margin-right: calc((2 / 3) * var(--padding-base));
}

.signout {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  margin-top: 5px;
  padding: 0;
  width: 20px;
}

.signout:hover {
  color: darken(white, 20%);
}
