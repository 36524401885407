import { Query } from '@apollo/client/react/components';
import { useLocation, useParams } from 'react-router-dom';
import { get } from 'ts-get';

import query from '../../../queries/User';
import IHeader from '../../List/HeadersInterface';
import GqlModelList from '../../ModelList/GqlModelList';
import AttributeID from '../../AttributeComponents/AttributeID';
import styles from '../../ModelList/ModelList.module.css';
import {
  UserWithBlocksAndBlockedTargets,
  UserWithBlocksAndBlockedTargetsVariables,
} from '../../../interfaces/graphql';

interface IProps {
  prop: 'blockedBy' | 'blockedTargets' | undefined;
}

const headers: IHeader[] = [
  { title: 'Id', attribute: 'id', component: AttributeID, link: '/users/' },
  { title: 'Nickname', attribute: 'nickname' },
];

const UserBlockList = ({ prop }: IProps): React.ReactElement => {
  if (prop === undefined) {
    throw new Error('You need to define props.prop to use this component.');
  }
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  return (
    <Query<UserWithBlocksAndBlockedTargets, UserWithBlocksAndBlockedTargetsVariables>
      query={query}
      variables={{ id: parseInt(id, 10) }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => (
        <div className={styles.scroll}>
          <GqlModelList
            isLoading={loading}
            error={error}
            entries={get(data, (d) => d.user[prop])}
            headers={headers}
            path={location.pathname}
            onLoadMore={() => {
              // eslint-disable-next-line no-console
              console.log('TODO: Not implemented');
            }}
          />
        </div>
      )}
    </Query>
  );
};

export default UserBlockList;
