/* eslint-disable react/button-has-type */

import { IClickableProps, getVariantClass } from '../variants';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    IClickableProps {}

const Button = ({
  className = '',
  size = 'default',
  variant = 'buttonPrimary',
  ...rest
}: IButtonProps): JSX.Element => (
  <button className={`${className} ${getVariantClass(variant, size)} text-base`} {...rest} />
);

export default Button;
