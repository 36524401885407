.fieldset {
  --label-size: 12px;
  --label-padding: 5px;
  --label-padding-bottom: 4px;
  border: none;
  margin: 0;
  min-height: calc(
    var(--label-size) + var(--label-padding) + var(--label-padding-bottom) + var(--input-padding) +
      var(--input-size)
  );
  padding: 0;
  position: relative;
  width: 100%;
}

.fieldset.suggestedchange input {
  @apply border border-solid border-r-error;
}

.fieldset label,
.label {
  color: lighten(var(--body-color), 20%);
  font-size: var(--input-size);
  font-weight: normal;
  left: var(--label-size);
  pointer-events: none;
  position: absolute;
  top: calc(var(--input-padding) + var(--label-padding) + (var(--label-padding-bottom) / 2));
  transition: all 0.07s ease;
}

.fieldset input,
.fieldset select,
.fieldset textarea {
  background: rgba(black, 0.02);
  margin-bottom: var(--input-padding);
  padding: calc(var(--label-size) + var(--label-padding) + var(--label-padding-bottom))
    var(--label-size) calc(var(--label-size) - var(--label-padding));

  &:focus {
    background: rgba(white, 0.3);
    outline: 0;
  }

  &:read-only {
    background: transparent;
  }

  &[type='checkbox'] {
    left: calc(var(--input-padding) + 2px);
    position: absolute;
    top: calc(var(--label-size) + var(--label-padding) + var(--label-padding-bottom));
  }
}

.fieldset input::placeholder,
.fieldset textarea::placeholder {
  color: transparent;
  transition: all 0.07s ease;
}

.fieldset input:focus::placeholder,
.fieldset textarea:focus::placeholder {
  color: #767676;
}

.fieldset input:focus,
.fieldset input:active,
.fieldset input:disabled,
.fieldset input[type='checkbox'],
.fieldset select,
.fieldset textarea:focus,
.fieldset textarea:active,
.fieldset textarea:disabled,
.fieldset textarea:not(:empty),
.fieldset input[value]:not([value='']) {
  + .label,
  + label {
    color: lighten(var(--body-color), 7%);
    font-size: var(--label-size);
    top: var(--label-padding);
  }
}

.fieldset input:disabled + .label {
  cursor: not-allowed;
}

.clearButton {
  background: transparent;
  border: none;
  color: rgba(black, 0.5);
  cursor: pointer;
  font-size: 20px;
  line-height: calc(var(--input-size) + 2.5 * var(--input-padding));
  position: absolute;
  right: var(--label-padding);
  top: 0;
  z-index: 2;

  &:hover,
  &:focus {
    color: black;
  }
}
